import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { changeView } from "../../../actions/draftboard";
import { DraftboardViews, DraftStatus } from "../../../js/constants";
import { Select } from "../../common/Input";
import ColorScheme from "../../styles/colors";
import InfoContainer from "../common/InfoContainer";

const ChangeViewContainer = styled.div`
    // Set container size
    width: 100%;

    // Style text
    font-size: 18px;
    font-weight: 600;
    color: ${ColorScheme.gray1.color};

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const SelectContainer = styled.div`
    // Set container size
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const View = ({ changeView, view, draft }) => {
    if (draft.status === DraftStatus.UPCOMING) return null;
    const onChange = (event) => changeView(event.target.value);
    return (
        <InfoContainer maxWidth="256px" paddingTop="4px">
            <ChangeViewContainer>Current View</ChangeViewContainer>
            <SelectContainer>
                <Select
                    id="draftboardView"
                    fontSize="16px"
                    small
                    onChange={onChange}
                    defaultValue={view}
                >
                    <option value={DraftboardViews.ROUND}>
                        {DraftboardViews.ROUND}
                    </option>
                    <option value={DraftboardViews.ROSTER}>
                        {DraftboardViews.ROSTER}
                    </option>
                    {draft.keeper && (
                        <option value={DraftboardViews.KEEPER}>
                            {DraftboardViews.KEEPER}
                        </option>
                    )}
                </Select>
            </SelectContainer>
        </InfoContainer>
    );
};

const mapStateToProps = (state) => {
    const {
        draftboard: { draft, draftboard },
    } = state;
    const newState = {};
    if (draft) {
        newState.draft = draft;
    }
    if (draftboard) {
        newState.view = draftboard.view;
    }
    return newState;
};

export default connect(mapStateToProps, { changeView })(View);
