class Color {
    constructor(hue, saturation, lightness, alpha) {
        this.hue = hue;
        this.saturation = saturation;
        this.lightness = lightness;
        if (typeof alpha === "undefined") {
            this.alpha = 1;
        } else {
            this.alpha = alpha;
        }
    }

    get color() {
        return `hsla(${this.hue}, ${this.saturation}%, ${this.lightness}%, ${this.alpha})`;
    }

    withAlpha(alpha) {
        return new Color(this.hue, this.saturation, this.lightness, alpha);
    }
}

export default Color;
