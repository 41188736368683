import React from "react";
import { buttonStyle } from "../../common/Button";
import ButtonLabel from "./ButtonLabel";

const FinishDraft = ({ openFinish }) => (
    <ButtonLabel
        label={"Finish Draft"}
        action={openFinish}
        buttonText={"Finish"}
        buttonColor={buttonStyle.Color.GREEN}
    />
);

export default FinishDraft;
