import { Auth } from "@aws-amplify/auth";
import { HOMEPAGE_URL } from "../js/constants";
import history from "../js/history";
import {
    FORGOT_PASSWORD,
    LOGIN,
    REGISTER,
    RESEND_CODE,
    RESET_PASSWORD,
    RESET_RESEND_STATUS,
    SIGN_OUT,
    VERIFY_EMAIL,
} from "./types";

// Client Metadata that is sent to the cognito postconfirmation handler
// This needs to be kept in sync with the cognito handler
const RESET_PASSWORD_META = { requestType: "reset-password" };
const REGISTER_META = { requestType: "register" };

export const login = (email, password) => async (dispatch) => {
    // Set loading prop to true
    dispatch({ type: LOGIN });

    // Perform sign in
    try {
        await Auth.signIn({ username: email, password });
        dispatch({ type: LOGIN, success: true });
        history.push("/");
    } catch (error) {
        dispatch({ type: LOGIN, error });
        return error.code;
    }
};

export const signOut = () => async (dispatch) => {
    try {
        await Auth.signOut();
        dispatch({ type: SIGN_OUT });
        return (window.location = HOMEPAGE_URL);
    } catch (error) {
        // Not much we can do here if we encounter an error
        dispatch({ type: SIGN_OUT, error });
        return (window.location = HOMEPAGE_URL);
    }
};

export const register =
    (email, password, name, birthdate) => async (dispatch) => {
        // Set loading prop to true
        dispatch({ type: REGISTER });

        // Perform account registration
        try {
            const result = await Auth.signUp({
                username: email,
                password,
                attributes: { email, name, birthdate },
            });
            dispatch({
                type: REGISTER,
                email: result.user.username,
                success: true,
            });
            history.push("/verify");
        } catch (error) {
            dispatch({ type: REGISTER, error });
            return error.code;
        }
    };

export const verifyEmail = (code, email) => async (dispatch, getState) => {
    if (!email) {
        const currState = getState();
        email = currState.register.email;
    }

    // Set loading prop to true
    dispatch({ type: VERIFY_EMAIL });

    // Perform email verification
    try {
        await Auth.confirmSignUp(email, code, {
            clientMetadata: REGISTER_META,
        });
        dispatch({ type: VERIFY_EMAIL, verified: true });
        history.push("/register_success");
    } catch (error) {
        dispatch({ type: VERIFY_EMAIL, error });
        return error.code;
    }
};

export const resendCode = (email) => async (dispatch, getState) => {
    // Set loading prop to true
    dispatch({ type: RESEND_CODE });

    if (!email) {
        const currState = getState();
        email = currState.register.email;
    }
    try {
        await Auth.resendSignUp(email);
        dispatch({ type: RESEND_CODE, success: true });
    } catch (error) {
        dispatch({ type: RESEND_CODE, error });
        return error.code;
    }
};

export const resetResendStatus = () => ({ type: RESET_RESEND_STATUS });

export const forgotPassword = (email) => async (dispatch) => {
    // Set loading prop to true
    dispatch({ type: FORGOT_PASSWORD });

    // Send forgot password request
    try {
        await Auth.forgotPassword(email);
        dispatch({ type: FORGOT_PASSWORD, success: true, email });
        history.push("/reset");
    } catch (error) {
        dispatch({ type: FORGOT_PASSWORD, error });
        return error.code;
    }
};

export const resetPassword =
    (code, password, email) => async (dispatch, getState) => {
        if (!email) {
            const currState = getState();
            email = currState.forget.email;
        }

        // Set loading prop to true
        dispatch({ type: RESET_PASSWORD });

        try {
            await Auth.forgotPasswordSubmit(
                email,
                code,
                password,
                RESET_PASSWORD_META
            );
            dispatch({ type: RESET_PASSWORD, success: true });
            history.push("/reset_success");
        } catch (error) {
            dispatch({ type: RESET_PASSWORD, error });
            return error.code;
        }
    };
