import React from "react";
import styled, { css } from "styled-components";
import ColorScheme from "../styles/colors";
import Defaults, { DefaultTransition, FocusShadow } from "../styles/defaults";
import { StyleStrip } from "../styles/Reset";
import Spinner from "./Spinner";

export const buttonStyle = {
    Size: {
        LARGE: "Large",
        MEDIUM: "Medium",
        SMALL: "Small",
    },
    Priority: {
        PRIMARY: "Primary",
        SECONDARY: "Secondary",
    },
    Color: {
        BLUE: "Blue",
        GREEN: "Green",
        GRAY: "Gray",
        RED: "Red",
        YELLOW: "Yellow",
    },
};

const bgcolor = (color, primary) => {
    switch (color) {
        case buttonStyle.Color.GREEN:
            if (primary) {
                return ColorScheme.green5.color;
            }
            return ColorScheme.green1.color;
        case buttonStyle.Color.GRAY:
            if (primary) {
                return ColorScheme.gray4.color;
            }
            return ColorScheme.gray1.color;
        case buttonStyle.Color.RED:
            if (primary) {
                return ColorScheme.red4.color;
            }
            return ColorScheme.red1.color;
        case buttonStyle.Color.YELLOW:
            if (primary) {
                return ColorScheme.yellow4.color;
            }
            return ColorScheme.yellow1.color;
        case buttonStyle.Color.BLUE:
        default:
            if (primary) {
                return ColorScheme.blue4.color;
            }
            return ColorScheme.blue1.color;
    }
};

const bordercolor = (color, primary) => {
    switch (color) {
        case buttonStyle.Color.GREEN:
            if (primary) {
                return "none";
            }
            return `2px solid ${ColorScheme.green4.color}`;
        case buttonStyle.Color.GRAY:
            if (primary) {
                return "none";
            }
            return `2px solid ${ColorScheme.gray5.color}`;
        case buttonStyle.Color.RED:
            if (primary) {
                return "none";
            }
            return `2px solid ${ColorScheme.red3.color}`;
        case buttonStyle.Color.YELLOW:
            if (primary) {
                return "none";
            }
            return `2px solid ${ColorScheme.yellow5.color}`;
        case buttonStyle.Color.BLUE:
        default:
            if (primary) {
                return "none";
            }
            return `2px solid ${ColorScheme.blue4.color}`;
    }
};

const textcolor = (color, primary) => {
    switch (color) {
        case buttonStyle.Color.GREEN:
            if (primary) {
                return `${ColorScheme.gray2.color}`;
            }
            return `${ColorScheme.green4.color}`;
        case buttonStyle.Color.GRAY:
            if (primary) {
                return `${ColorScheme.gray7.color}`;
            }
            return `${ColorScheme.gray5.color}`;
        case buttonStyle.Color.RED:
            if (primary) {
                return `${ColorScheme.gray2.color}`;
            }
            return `${ColorScheme.red3.color}`;
        case buttonStyle.Color.YELLOW:
            if (primary) {
                return `${ColorScheme.yellow7.color}`;
            }
            return `${ColorScheme.yellow5.color}`;
        case buttonStyle.Color.BLUE:
        default:
            if (primary) {
                return `${ColorScheme.gray2.color}`;
            }
            return `${ColorScheme.blue4.color}`;
    }
};

const SpinnerContainer = styled.div`
    display: inline-block;
    margin: auto;
`;

const SpinnerOrText = ({
    loading,
    small,
    medium,
    large,
    color,
    primary,
    text,
}) => {
    if (!loading) return text;
    return (
        <SpinnerContainer>
            <Spinner
                small={small}
                medium={medium}
                large={large}
                color={color}
                primary={primary}
            />
        </SpinnerContainer>
    );
};

const primaryPressDownEffect = css`
    // Animate press down effect
    box-shadow: 0 10px 20px ${ColorScheme.shadowBlack.color};
    ${DefaultTransition}

    &:hover {
        // Animate press down effect
        box-shadow: 0 15px 25px ${ColorScheme.shadowBlack.color};
    }

    &:active {
        // Animate press down effect
        box-shadow: 0 5px 10px ${ColorScheme.shadowBlack.color};
    }
`;

const secondaryPressDownEffect = css`
    // Animate press down effect
    box-shadow: 0 2px 2px ${ColorScheme.shadowBlack.color};
    ${DefaultTransition}

    &:hover {
        // Animate press down effect
        box-shadow: 0 4px 4px ${ColorScheme.shadowBlack.color};
    }

    &:active {
        // Animate press down effect
        box-shadow: 0 1px 1px ${ColorScheme.shadowBlack.color};
    }
`;

const Btn = styled.button`
    ${StyleStrip}
    ${FocusShadow}

    // Set desired button styling
    border-radius: ${Defaults.borderRadius};
    font-family: inherit;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    line-height: 100%;

    // Style button according to props
    width: ${(props) =>
        props.small ? "96px" : props.medium ? "128px" : "192px"};
    height: ${(props) =>
        props.small ? "24px" : props.medium ? "32px" : "48px"};
    font-size: ${(props) =>
        props.small ? "14px" : props.medium ? "18px" : "24px"};
    background-color: ${(props) => bgcolor(props.color, props.primary)};
    color: ${(props) => textcolor(props.color, props.primary)};
    border: ${(props) => bordercolor(props.color, props.primary)};

    // Add hover and press down effect for non-disabled buttons
    ${(props) =>
        !props.disabled &&
        (props.primary && props.large
            ? primaryPressDownEffect
            : secondaryPressDownEffect)}
`;

const Button = ({
    size,
    color,
    priority,
    text,
    type,
    disabled,
    onClick,
    loading,
    analytics,
}) => {
    const primary = priority === buttonStyle.Priority.PRIMARY;
    const btnProps = { type, disabled, color, primary, onClick };
    const [small, medium, large] = [
        size === buttonStyle.Size.SMALL,
        size === buttonStyle.Size.MEDIUM,
        size === buttonStyle.Size.LARGE,
    ];
    return (
        <Btn
            {...btnProps}
            small={small}
            medium={medium}
            large={large}
            disabled={disabled}
            data-amplify-analytics-on={analytics && "click"}
            data-amplify-analytics-name={analytics?.name && analytics.name}
            data-amplify-analytics-attrs={analytics?.attrs && analytics.attrs}
        >
            {!loading ? (
                text
            ) : (
                <SpinnerOrText
                    loading={loading}
                    small={small}
                    medium={medium}
                    large={large}
                    color={color}
                    primary={primary}
                    text={text}
                />
            )}
        </Btn>
    );
};

export default Button;
