import React from "react";
import { buttonStyle } from "../../common/Button";
import ButtonLabel from "./ButtonLabel";

const EditKeepers = ({ editKeepers }) => (
    <ButtonLabel
        label={"Edit Keepers"}
        action={editKeepers}
        buttonText={"Edit"}
        buttonColor={buttonStyle.Color.YELLOW}
    />
);

export default EditKeepers;
