// FIXME: Convert directory into a javascript class
import TrieSearch from "trie-search";
import {
    AUTOCOMPLETE_CARDS_LIMIT,
    AutodraftPositionRank,
    Positions,
} from "../constants";
import { costMultiplier, costSortFn, groupByPosition } from "../util";

let directory = new TrieSearch("fantasyPlayer", {
    ignoreCase: true,
    indexField: "id",
    idFieldOrFunction: "id",
    splitOnRegex: false,
});
let draftedPlayers = new Map();
let allPlayersByRank = [];
let allPlayersByName = [];

// fullPlayerName returns the player's full name (first name/last name)
const fullPlayerName = (player) => `${player.first} ${player.last}`;

// playerSortRank is a sort function which sorts players by rank and player name
const playerSortRank = (a, b) => {
    // Sort by player rank
    if (a.rank !== b.rank) return a.rank - b.rank;

    // If ranks are the same, sort by name
    if (fullPlayerName(a) > fullPlayerName(b)) return -1;
    return 1;
};

// playerSortName is a sort function which sorts players by player name only
const playerSortName = (a, b) => {
    if (fullPlayerName(a) < fullPlayerName(b)) return -1;
    else if (fullPlayerName(a) === fullPlayerName(b)) return 0;
    return 1;
};

// undrafted takes a list of players and filters out all the drafted players
const undrafted = (list) =>
    list.filter((player) => !draftedPlayers.has(player.id));

const filterPosition = (list, position) =>
    list.filter((player) => player.position === position);

// drafted takes a player and returns whether the player has been drafted
const drafted = (player) => draftedPlayers.has(player.id);

// addName is a helper function to add players to the player directory
const addName = (name, player) => directory.map(name, player);

// playerNames takes in a player and returns all the different name forms which
// should be added into the player directory
const playerNames = (player) => {
    // Add player's full name and last name to list
    const fullName = fullPlayerName(player);
    const names = [fullName, player.last];

    // A.J. Green -> aj green
    if (fullName.includes(".")) names.push(fullName.replace(".", ""));
    // Le'Veon Bell -> leveon bell
    if (fullName.includes("'")) names.push(fullName.replace("'", ""));

    return names;
};

// sortPreference is a local variable to store whether we should return
// search results by name or by rank
let sortPreference = playerSortRank;

// sortPreferenceByName sets the sort preference to playerSortName
// if byName is true, playerSortRank if false
export const sortPreferenceByName = (byName) =>
    (sortPreference = byName ? playerSortName : playerSortRank);

// draft adds player.fantasyPlayer into the draftedPlayers map
export const draft = (player) => draftedPlayers.set(player.id, true);

// remove removes the provided player from the draftedPlayers map
export const remove = (player) => draftedPlayers.delete(player.id);

// loadDrafted takes a list of already drafted picks and adds them to draftedPlayers
export const loadDrafted = (picks) => picks.forEach((pick) => draft(pick));

// loadPlayers takes in a list of players and adds them to the player directory
export const loadPlayers = (nflPlayers) => {
    // Create two lists: players sorted by name and by rank
    allPlayersByName = nflPlayers;
    allPlayersByName.sort(playerSortName);
    allPlayersByRank = [...nflPlayers];
    allPlayersByRank.sort(playerSortRank);

    // Add all players into trie
    nflPlayers.forEach((player) => {
        const names = playerNames(player);
        names.forEach((name) => addName(name, player));
    });
};

// find performs a search for all of the players which have a name which matches
// the given prefix, sorted by rank, and truncated to the autocomplete limit
export const find = (prefix, position) => {
    // For empty prefix searches, use allPlayers instead of searching directory
    if (prefix === "") {
        const results = [];
        let allPlayers = allPlayersByRank;
        if (sortPreference === playerSortName) allPlayers = allPlayersByName;

        for (let i = 0; i < allPlayers.length; i++) {
            // Filter drafted players
            if (drafted(allPlayers[i])) continue;

            // Filter players which don't fit the position filter
            if (position !== Positions.ALL && position !== "")
                if (allPlayers[i].position !== position) continue;

            // Add player to results
            results.push(allPlayers[i]);

            // Exit loop once we've hit the return limit
            if (results.length === AUTOCOMPLETE_CARDS_LIMIT) break;
        }
        return results;
    }

    // Perform autocomplete search
    const players = directory.search(prefix);

    // Filter players based on whether they've been drafted or not
    const undraftedPlayers = undrafted(players).sort(sortPreference);

    // Filter based on position, if user requests
    if (position === Positions.ALL || position === "")
        return undraftedPlayers.slice(0, AUTOCOMPLETE_CARDS_LIMIT);
    return filterPosition(players, position).slice(0, AUTOCOMPLETE_CARDS_LIMIT);
};

export const resetDirectory = () => {
    directory = new TrieSearch("fantasyPlayer", {
        ignoreCase: true,
        indexField: "id",
        idFieldOrFunction: "id",
        splitOnRegex: false,
    });
    draftedPlayers = new Map();
    allPlayersByRank = [];
    allPlayersByName = [];
};

export const resetDrafted = () => (draftedPlayers = new Map());

// findUnderCost finds the best possible player under the given cost
const findUnderCost = (position, cost, auctionDraftOptions) => {
    let allPlayers = allPlayersByRank;
    for (let i = 0; i < allPlayers.length; i++) {
        // Filter drafted players
        if (drafted(allPlayers[i])) continue;

        // Filter players which don't fit the position filter
        if (position !== Positions.ALL && position !== "")
            if (allPlayers[i].position !== position) continue;

        // Calculate this draft's cost multiplier
        const multiplier = costMultiplier(auctionDraftOptions);

        // Check if current player fits under the given cost
        const { suggestedCost } = allPlayers[i];
        if (suggestedCost * multiplier <= cost) return allPlayers[i];
    }
    return null;
};

export const autodraft = (
    picks,
    positions,
    auction,
    auctionDraftOptions,
    maxBid
) => {
    // Make sure sortPreference is by rank
    const originalSortPreference = sortPreference;
    sortPreference = playerSortRank;

    // rosterLimit holds the maximum number of roster spots for each position
    const rosterLimit = {};
    for (const [pos, value] of Object.entries(positions)) {
        rosterLimit[pos] = value;
    }

    // If this is an auction draft, sort picks by cost
    const sortedPicks = [...picks];
    if (auction) sortedPicks.sort(costSortFn);

    // Organize roster by position
    const roster = groupByPosition(sortedPicks, rosterLimit);

    // Find best player, given the team's needs in their starting lineup
    let player = null;
    for (let i = 0; i < AutodraftPositionRank.length; i++) {
        const currPosition = AutodraftPositionRank[i];

        // Skip positions that the draft doesn't support
        if (currPosition !== Positions.ALL && !(currPosition in rosterLimit)) {
            continue;
        }

        // Find best player at the position
        if (auction) {
            player = findUnderCost(currPosition, maxBid, auctionDraftOptions);
        } else {
            player = find("", currPosition)[0];
        }

        // If we have a starting roster spot for this player, break out
        if (!(player.position in roster)) break;
        if (roster[player.position].length < rosterLimit[player.position]) {
            break;
        }

        // If we get here, then we've already filled all our starter spots
        player = null;
    }

    // If all of our starters are filled, just return the best possible player
    if (player === null) {
        if (auction) {
            player = findUnderCost(Positions.ALL, maxBid, auctionDraftOptions);
        } else {
            player = find("", Positions.ALL)[0];
        }
    }

    // Reset sortPreference back to original
    sortPreference = originalSortPreference;

    // Return player
    return player;
};
