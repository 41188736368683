import React from "react";
import styled from "styled-components";
import Button, { buttonStyle } from "../../common/Button";
import ColorScheme from "../../styles/colors";

const Header = styled.h1`
    font-size: 24px;
    font-family: inherit;
    font-weight: 400;
    color: ${ColorScheme.blue6.color};
`;

const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const Heading = ({ close }) => (
    <TopRow>
        <Header>Settings</Header>
        <Button
            size={buttonStyle.Size.MEDIUM}
            color={buttonStyle.Color.GRAY}
            priority={buttonStyle.Priority.SECONDARY}
            text="Exit"
            type="button"
            onClick={close}
        />
    </TopRow>
);

export default Heading;
