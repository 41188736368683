import React, { Children } from "react";
import styled from "styled-components";

// Create Grid
const GridDescriptionOptionContainer = styled.div`
    display: grid;
    grid-template-columns: 5% 95%;
    align-items: center;
`;

// Place Input in top-left of grid
const InputContainer = styled.div`
    grid-row: 1 / 2;
    grid-column: 1 / 2;
`;

// Place Label in top-right of grid
const LabelContainer = styled.div`
    grid-row: 1 / 2;
    grid-column: 2 / 3;
`;

// Place description in bottom-right of grid
const DescriptionContainer = styled.div`
    grid-row: 2 / 3;
    grid-column: 2 / 3;
`;

// Place optional add-on component underneath description
const AddOnContainer = styled.div`
    grid-column: 2 / 3;
    grid-row: 3 / 4;
`;

// GridDescriptionOption places its children components onto a 2x2 grid.
// This is used when you want to display multiple input options with
// descriptions attached to them. Useful for checkbox and radio inputs
const GridDescriptionOption = ({ children }) => {
    const gridComponents = Children.toArray(children);
    return (
        <GridDescriptionOptionContainer>
            <InputContainer>{gridComponents[0]}</InputContainer>
            <LabelContainer>{gridComponents[1]}</LabelContainer>
            <DescriptionContainer>{gridComponents[2]}</DescriptionContainer>
            {gridComponents.length === 4 && (
                <AddOnContainer>{gridComponents[3]}</AddOnContainer>
            )}
        </GridDescriptionOptionContainer>
    );
};

// GridOption is a GridDescriptionOption without the description
export const GridOption = ({ children }) => {
    const gridComponents = Children.toArray(children);
    return (
        <GridDescriptionOptionContainer>
            <InputContainer>{gridComponents[0]}</InputContainer>
            <LabelContainer>{gridComponents[1]}</LabelContainer>
            {gridComponents.length === 3 && (
                <DescriptionContainer>{gridComponents[2]}</DescriptionContainer>
            )}
        </GridDescriptionOptionContainer>
    );
};

export default GridDescriptionOption;
