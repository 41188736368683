import React from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { editDraft, editOpen } from "../../../actions/draftdetails";
import {
    DraftStatus,
    MAX_LEAGUE_LENGTH,
    UNSCHEDULED,
} from "../../../js/constants";
import {
    afterDate,
    compose,
    maxLength,
    required,
} from "../../../js/formhelper";
import { convertDateFormat, dateToString, statuslabel } from "../../../js/util";
import { VerticalContainer } from "../../common/Container";
import { DetailsContainer, Header } from "../common/DetailsContainer";
import {
    LabeledInput,
    LabeledValueDivision,
    LabeledValuePartition,
} from "../common/Label";
import { DeleteDraftButton } from "./Buttons";

// NOTE: Body keys must be kept in sync with EditDraft API request
const editDraftDetails = (draft, editDraft, displayInfo) => async (values) => {
    const body = {
        name: values.name,
        draftDate: values.draftDate,
    };
    // FIXME: Reflect error if something fails
    await editDraft(body, draft.draftID);
    displayInfo();
};

const DraftSettingsForm = ({
    draft,
    editDraft,
    displayInfo,
    deleteDraft,
    loading,
    today,
}) => (
    <Form
        onSubmit={editDraftDetails(draft, editDraft, displayInfo)}
        initialValues={{
            name: draft.name,
            draftDate: draft.draftDate === UNSCHEDULED ? "" : draft.draftDate,
        }}
        render={({ handleSubmit }) => (
            <VerticalContainer>
                <form onSubmit={handleSubmit}>
                    <Header
                        heading="Draft Information"
                        primaryBtn={{
                            text: "Save",
                            disabled: loading,
                            loading,
                        }}
                        secondaryBtn={{
                            text: "Cancel",
                            disabled: loading,
                            onClick: displayInfo,
                        }}
                    />
                    <DetailsContainer>
                        <LabeledInput
                            label="League Name"
                            fieldName="name"
                            type="text"
                            validate={compose(
                                required,
                                maxLength("League Name", MAX_LEAGUE_LENGTH)
                            )}
                        />
                        <LabeledInput
                            label="Draft Date"
                            fieldName="draftDate"
                            type="date"
                            min={dateToString(today)}
                            validate={afterDate(today)}
                        />
                        {!draft.draftStartedBefore && (
                            <LabeledValuePartition label="Delete Draft">
                                <DeleteDraftButton deleteDraft={deleteDraft} />
                            </LabeledValuePartition>
                        )}
                    </DetailsContainer>
                </form>
            </VerticalContainer>
        )}
    />
);

const DraftSettingsInfo = ({
    draft,
    displayForm,
    commissioner,
    loading,
    isCommissioner,
}) => (
    <VerticalContainer>
        <Header
            heading="Draft Information"
            secondaryBtn={
                isCommissioner &&
                draft.status === DraftStatus.UPCOMING && {
                    text: "Edit",
                    onClick: displayForm,
                    disabled: loading,
                }
            }
        />
        <DetailsContainer>
            <LabeledValueDivision label="League Name" value={draft.name} />
            {draft.status === DraftStatus.UPCOMING ? (
                <LabeledValueDivision
                    label="Draft Date"
                    value={
                        draft.draftDate !== UNSCHEDULED
                            ? convertDateFormat(draft.draftDate)
                            : "Unscheduled"
                    }
                />
            ) : draft.status === DraftStatus.IN_PROGRESS ? (
                <LabeledValueDivision
                    label="Started"
                    value={convertDateFormat(
                        dateToString(new Date(Date.parse(draft.startTime)))
                    )}
                />
            ) : draft.status === DraftStatus.COMPLETED ? (
                <LabeledValueDivision
                    label="Completed"
                    value={convertDateFormat(
                        dateToString(new Date(Date.parse(draft.endTime)))
                    )}
                />
            ) : null}
            <LabeledValueDivision
                label="Draft Status"
                value={statuslabel(draft.status)}
            />
            <LabeledValueDivision label="Commissioner" value={commissioner} />
        </DetailsContainer>
    </VerticalContainer>
);

const DraftSettings = ({
    draft,
    deleteDraft,
    editDraft,
    editOpen,
    form,
    loading,
    isCommissioner,
}) => {
    const DRAFT_SETTINGS_FORM = "Draft Information";
    const showForm = form === DRAFT_SETTINGS_FORM;
    const displayForm = () => editOpen(DRAFT_SETTINGS_FORM);
    const displayInfo = () => editOpen("");
    const today = new Date();

    return showForm ? (
        <DraftSettingsForm
            draft={draft}
            editDraft={editDraft}
            displayInfo={displayInfo}
            deleteDraft={deleteDraft}
            loading={loading}
            today={today}
        />
    ) : (
        <DraftSettingsInfo
            draft={draft}
            displayForm={displayForm}
            commissioner={draft.commissioners[0].name}
            loading={loading}
            isCommissioner={isCommissioner}
        />
    );
};

const mapStateToProps = (state) => {
    const newState = {
        form: state.draftdetails.form,
        loading: state.draftdetails.loading,
        draftConfig: state.config.draftConfig,
    };
    if (state?.draftdetails?.draft) {
        newState.draft = state.draftdetails.draft;
        if (state?.auth?.user && newState?.draft?.commissioners) {
            const userID = state.auth.user.username;
            const commissioners = newState.draft.commissioners;
            for (let i = 0; i < commissioners.length; i++) {
                if (commissioners[i].userID === userID) {
                    newState.isCommissioner = true;
                    break;
                }
            }
        }
    }
    return newState;
};

export default connect(mapStateToProps, { editDraft, editOpen })(DraftSettings);
