import React, { forwardRef } from "react";
import { useDrop } from "react-dnd";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import {
    replace,
    select,
    selectAutocompletePlayer,
} from "../../../actions/draftboard";
import {
    CARD_HEIGHT,
    CARD_WIDTH,
    CardLocation,
    DraftConfig,
    DraftStatus,
    DraftboardViews,
} from "../../../js/constants";
import { ItemTypes } from "../../../js/dnd";
import { isCommissioner, isMember, isTestDrive } from "../../../js/util";
import { StyleStrip } from "../../styles/Reset";
import ColorScheme, { Draftboard } from "../../styles/colors";
import Defaults, { DefaultTransition } from "../../styles/defaults";
import PlayerCard, { EmptyCard } from "../common/PlayerCard";

const draftable = (team, round, openings) => {
    if (!openings || openings.length === 0) return false;
    for (let i = 0; i < openings.length; i++) {
        const [openTeam, openRound, traded] = openings[i];
        if (traded) continue;
        if (team === openTeam && round === openRound) return true;
    }
    return false;
};

const pickTradedAway = (currTeam, currRound, openings) => {
    if (!openings || openings.length === 0) return false;
    for (let i = 0; i < openings.length; i++) {
        const [openTeam, openRound, traded] = openings[i];
        if (!traded) continue;
        if (currTeam === openTeam && currRound === openRound) return true;
    }
    return false;
};

const tradeNumber = (team, round, trades) => {
    // Assign number to trades
    const tradeMap = {};
    let tradeNum = 1;
    const currTradeKey = `${team}-${round}`;
    for (const [trade1Key, trade2] of Object.entries(trades)) {
        // trade1Key = "3-0", trade2 = {teamIndex: 1, round: 1}
        const trade2Key = `${trade2.teamIndex}-${trade2.round}`;
        const sortedTrade = [trade1Key, trade2Key].sort();
        const tradeKey = sortedTrade.join("-");
        if (currTradeKey === trade1Key || currTradeKey === trade2Key) {
            return tradeNum;
        }
        if (!(tradeKey in tradeMap)) {
            tradeMap[tradeKey] = tradeNum;
            tradeNum++;
        }
    }
    return -1;
};

const TeamContainer = styled.div`
    height: 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 2px;
`;

const PickContainer = styled.div`
    position: relative;
`;

const Shade = styled.div`
    // Make sure shade covers entire parent
    height: 100%;
    width: 100%;

    // Set shade to be placed over the contents of the parent item
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;

    // Set translucent background color
    background-color: ${ColorScheme.gray1.withAlpha(0.8).color};

    // Have props control whether Shade appears or not
    visibility: ${(props) => (props.show ? "visible" : "hidden")};
    opacity: ${(props) => (props.show ? "1" : "0")};
    ${DefaultTransition}
`;

const CardStyle = css`
    // Set card size
    height: ${(props) =>
        props.height ? `${props.height}px` : `${CARD_HEIGHT}px`};
    width: ${(props) => (props.width ? `${props.width}px` : `${CARD_WIDTH}px`)};

    // Style text
    text-transform: uppercase;
    font-family: inherit;
    font-size: 16px;
    font-weight: 700;
    text-align: center;

    // Center text
    display: flex;
    justify-content: center;
    align-items: center;
`;

const WaitingStyle = css`
    ${CardStyle}

    // Set background and text color
    background-color: ${ColorScheme.gray3.color};
    border: 2px solid ${ColorScheme.gray6.color};
    color: ${ColorScheme.gray6.color};
    box-shadow: ${Defaults.shadow};
`;

const DraftableStyle = css`
    ${CardStyle}

    // Set background and text color
    ${Draftboard.Background.blue}
    color: ${ColorScheme.gray1.color};
    outline: ${(props) =>
        props.selected ? `4px solid ${ColorScheme.yellow4.color}` : "none"};

    // Make sure outline is shown above other cards, especially when selected
    z-index: ${(props) => (props.selected ? "6" : "5")};

    // Set some cushion between "Pick Your Player" text and border
    padding: 4px;
`;

const TradedContainer = styled.div`
    ${CardStyle}

    // Set background and text color
    background-color: ${ColorScheme.yellow4.color};
    border: 2px solid ${ColorScheme.yellow6.color};
    color: ${ColorScheme.yellow6.color};
    box-shadow: ${Defaults.shadow};
`;

const DraftableContainer = styled.div`
    ${DraftableStyle}
`;

const WaitingContainer = styled.div`
    ${WaitingStyle}
`;

const DraftableButtonContainer = styled.button`
    ${StyleStrip}
    ${DraftableStyle}

    cursor: pointer;

    &:hover {
        outline: ${`4px solid ${ColorScheme.yellow4.color}`};
        z-index: 6;
    }
`;

const ReplaceableButtonContainer = styled.button`
    ${StyleStrip}
    ${CardStyle}

    // Set background and text color
    background-color: ${ColorScheme.red6.color};
    color: ${ColorScheme.gray1.color};
    outline: ${`4px solid ${ColorScheme.red4.color}`};

    // Make sure outline is shown above other cards, especially when selected
    z-index: 6;

    // Set some cushion between "Replace" text and border
    padding: 4px;

    cursor: pointer;
`;

const Pick = forwardRef(
    (
        {
            fantasyPlayer,
            auction,
            shade,
            height,
            width,
            fontIndex,
            showDraftboardDetails,
        },
        ref
    ) => (
        <PickContainer ref={ref}>
            <Shade show={shade} />
            <PlayerCard
                position={fantasyPlayer.position}
                first={fantasyPlayer.first}
                last={fantasyPlayer.last}
                bye={fantasyPlayer.bye}
                team={fantasyPlayer.team}
                location={CardLocation.DRAFTBOARD}
                auction={auction}
                height={height}
                width={width}
                fontIndex={fontIndex}
                showDetails={showDraftboardDetails}
                cost={fantasyPlayer.cost}
            />
        </PickContainer>
    )
);

const Traded = ({ height, width }) => (
    <TradedContainer height={height} width={width}>
        Traded
    </TradedContainer>
);

const TradeNumber = ({ height, width, i }) => (
    <TradedContainer height={height} width={width}>
        {`Trade ${i}`}
    </TradedContainer>
);

const Draftable = forwardRef(({ selected, height, width }, ref) => (
    <DraftableContainer
        selected={selected}
        height={height}
        width={width}
        ref={ref}
    >
        Pick Your Player
    </DraftableContainer>
));

const WaitingTurn = forwardRef(({ selected, height, width }, ref) => (
    <WaitingContainer
        selected={selected}
        height={height}
        width={width}
        ref={ref}
    >
        Waiting...
    </WaitingContainer>
));

const DraftableButton = forwardRef(
    (
        { team, round, select, nominated, keeper, selected, height, width },
        ref
    ) => (
        <DraftableButtonContainer
            ref={ref}
            selected={selected}
            height={height}
            width={width}
            onClick={() => select(team, round, nominated, keeper)}
        >
            Pick Your Player
        </DraftableButtonContainer>
    )
);

const ReplaceableButton = forwardRef(({ height, width }, ref) => (
    <ReplaceableButtonContainer ref={ref} height={height} width={width}>
        Replace Player
    </ReplaceableButtonContainer>
));

const Droppable = ({
    team,
    round,
    selectAutocompletePlayer,
    height,
    width,
}) => {
    const [{ isOver }, ref] = useDrop({
        accept: ItemTypes.PLAYER,
        drop: (item) => {
            selectAutocompletePlayer(item.index, team, round, team, false);
            return { team, round };
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <Draftable ref={ref} height={height} width={width} selected={isOver} />
    );
};

const DroppableButton = ({
    team,
    round,
    select,
    nominated,
    keeper,
    height,
    width,
}) => {
    const [{ isOver }, ref] = useDrop({
        accept: ItemTypes.PLAYER,
        drop: () => {
            select(team, round, nominated, keeper);
            return { team, round };
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <DraftableButton
            ref={ref}
            team={team}
            round={round}
            select={select}
            nominated={nominated}
            keeper={keeper}
            height={height}
            width={width}
            selected={isOver}
        />
    );
};

const DroppablePickButton = ({
    fantasyPlayer,
    auction,
    auctionCost,
    maximumBids,
    height,
    width,
    fontIndex,
    showDraftboardDetails,
    team,
    round,
    replace,
    keeper,
    view,
}) => {
    const editKeeperView = view === DraftboardViews.EDIT_KEEPER;
    const keeperView = view === DraftboardViews.KEEPER;
    const roundView = view === DraftboardViews.ROUND;
    const isKeeper = fantasyPlayer.keeper;
    const cost = parseInt(auctionCost);

    const [{ isOver }, ref] = useDrop({
        accept: ItemTypes.PLAYER,
        drop: (item) => {
            if ((editKeeperView && !isKeeper) || (roundView && isKeeper)) {
                return;
            }
            if (auction && cost > maximumBids[team] + fantasyPlayer.cost - 1) {
                return;
            }
            replace(item.index, team, round, keeper);
            return { team, round };
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return isOver &&
        (!auction || cost <= maximumBids[team] + fantasyPlayer.cost - 1) &&
        ((isKeeper && editKeeperView) || (!isKeeper && roundView)) ? (
        <ReplaceableButton ref={ref} height={height} width={width} />
    ) : (
        <Pick
            ref={ref}
            fantasyPlayer={fantasyPlayer}
            auction={auction}
            shade={(editKeeperView || keeperView) && !isKeeper}
            height={height}
            width={width}
            fontIndex={fontIndex}
            showDraftboardDetails={showDraftboardDetails}
        />
    );
};

const renderKeeper = (
    view,
    rounds,
    picks,
    auction,
    auctionCost,
    team,
    trades,
    maximumBids,
    height,
    width,
    fontIndex,
    showDraftboardDetails,
    replace,
    select,
    selected
) => {
    const teamPicks = [];
    for (let i = 0; i < rounds; i++) {
        // Render fantasy player, if it exists
        if (picks[i]) {
            const fantasyPlayer = picks[i];
            teamPicks.push(
                <DroppablePickButton
                    fantasyPlayer={fantasyPlayer}
                    auction={auction}
                    auctionCost={auctionCost}
                    maximumBids={maximumBids}
                    height={height}
                    width={width}
                    fontIndex={fontIndex}
                    showDraftboardDetails={showDraftboardDetails}
                    team={team}
                    round={i}
                    replace={replace}
                    keeper={true}
                    view={view}
                    key={`team-${team}-pick-${i}-${fantasyPlayer.id}`}
                />
            );
            continue;
        }

        // If pick was traded, show Traded with trade number
        const tradeNum = tradeNumber(team, i, trades);
        if (tradeNum > 0) {
            teamPicks.push(
                <TradeNumber
                    height={height}
                    width={width}
                    i={tradeNum}
                    key={`traded-${team}-pick-${i}`}
                />
            );
            continue;
        }

        // If draftboard is in the edit keeper state, show DroppableButton,
        // if a player has been selected in the autocomplete area
        if (view === DraftboardViews.EDIT_KEEPER && selected !== -1) {
            if (!auction || maximumBids[team] >= auctionCost) {
                teamPicks.push(
                    <DroppableButton
                        team={team}
                        round={i}
                        nominated={team}
                        keeper={true}
                        select={select}
                        height={height}
                        width={width}
                        key={`draftable-${team}-pick-${i}`}
                    />
                );
                continue;
            }
        }

        teamPicks.push(
            <EmptyCard
                location={CardLocation.DRAFTBOARD}
                height={height}
                width={width}
                key={`team-${team}-pick-${i}-empty`}
            />
        );
    }
    return teamPicks;
};

const renderAnonymousRound = (
    maxLen,
    picks,
    auction,
    team,
    openings,
    view,
    status,
    height,
    width,
    fontIndex,
    showDraftboardDetails
) => {
    const teamPicks = [];
    const roundView = view === DraftboardViews.ROUND;
    const inProgress = status === DraftStatus.IN_PROGRESS;
    for (let i = 0; i < maxLen; i++) {
        if (picks[i]) {
            if (!auction) {
                // Traded takes precedence over fantasy player
                if (
                    pickTradedAway(team, i, openings) &&
                    inProgress &&
                    roundView
                ) {
                    teamPicks.push(
                        <Traded
                            height={height}
                            width={width}
                            key={`traded-${team}-pick-${i}`}
                        />
                    );
                    continue;
                }
            }
            // In javascript, indexing an array out-of-bounds returns undefined,
            // so this is actually safe
            const fantasyPlayer = picks[i];
            teamPicks.push(
                <Pick
                    height={height}
                    width={width}
                    fontIndex={fontIndex}
                    showDraftboardDetails={showDraftboardDetails}
                    fantasyPlayer={fantasyPlayer}
                    auction={auction}
                    shade={false}
                    key={`team-${team}-pick-${i}-${fantasyPlayer.id}`}
                />
            );
            continue;
        } else if (inProgress && roundView) {
            if (draftable(team, i, openings)) {
                if (auction) {
                    teamPicks.push(
                        <EmptyCard
                            height={height}
                            width={width}
                            location={CardLocation.DRAFTBOARD}
                            key={`team-${team}-pick-${i}-empty`}
                        />
                    );
                } else {
                    teamPicks.push(
                        <WaitingTurn
                            team={team}
                            round={i}
                            height={height}
                            width={width}
                            key={`draftable-${team}-pick-${i}`}
                        />
                    );
                }
                continue;
            } else if (pickTradedAway(team, i, openings)) {
                teamPicks.push(
                    <Traded
                        height={height}
                        width={width}
                        key={`traded-${team}-pick-${i}`}
                    />
                );
                continue;
            }
        }
        teamPicks.push(
            <EmptyCard
                height={height}
                width={width}
                location={CardLocation.DRAFTBOARD}
                key={`team-${team}-pick-${i}-empty`}
            />
        );
    }
    return teamPicks;
};

const renderMemberRound = (
    maxLen,
    picks,
    auction,
    team,
    openings,
    trades,
    view,
    status,
    height,
    width,
    fontIndex,
    showDraftboardDetails,
    selectAutocompletePlayer,
    selected,
    draftOrder,
    userID
) => {
    const teamPicks = [];
    const roundView = view === DraftboardViews.ROUND;
    const inProgress = status === DraftStatus.IN_PROGRESS;
    const upcoming = status === DraftStatus.UPCOMING;
    for (let i = 0; i < maxLen; i++) {
        // Render fantasy player, if it exists
        if (picks[i]) {
            // Traded takes precedence over fantasy player
            if (pickTradedAway(team, i, openings) && inProgress && roundView) {
                teamPicks.push(
                    <Traded
                        height={height}
                        width={width}
                        key={`traded-${team}-pick-${i}`}
                    />
                );
                continue;
            }

            // Render fantasy player
            const fantasyPlayer = picks[i];
            teamPicks.push(
                <Pick
                    height={height}
                    width={width}
                    fontIndex={fontIndex}
                    showDraftboardDetails={showDraftboardDetails}
                    fantasyPlayer={fantasyPlayer}
                    auction={auction}
                    shade={false}
                    key={`team-${team}-pick-${i}-${fantasyPlayer.id}`}
                />
            );
            continue;
        } else if (inProgress && roundView && (!auction || selected !== -1)) {
            // If pick was traded, show Traded
            if (pickTradedAway(team, i, openings)) {
                teamPicks.push(
                    <Traded
                        height={height}
                        width={width}
                        key={`traded-${team}-pick-${i}`}
                    />
                );
                continue;
            } else if (draftable(team, i, openings)) {
                // For auctions, do not allow non-commissioners to draft
                if (auction) {
                    teamPicks.push(
                        <EmptyCard
                            height={height}
                            width={width}
                            location={CardLocation.DRAFTBOARD}
                            key={`team-${team}-pick-${i}-empty`}
                        />
                    );
                    continue;
                }

                // If pick was traded to member team, show droppable, else, show waiting
                if (draftOrder[team].userID === userID) {
                    teamPicks.push(
                        <Droppable
                            team={team}
                            round={i}
                            selectAutocompletePlayer={selectAutocompletePlayer}
                            height={height}
                            width={width}
                            key={`draftable-${team}-pick-${i}`}
                        />
                    );
                    continue;
                }
                teamPicks.push(
                    <WaitingTurn
                        team={team}
                        round={i}
                        height={height}
                        width={width}
                        key={`draftable-${team}-pick-${i}`}
                    />
                );
                continue;
            }
        } else if (upcoming) {
            const tradeNum = tradeNumber(team, i, trades);
            if (tradeNum > 0) {
                teamPicks.push(
                    <TradeNumber
                        height={height}
                        width={width}
                        i={tradeNum}
                        key={`traded-${team}-pick-${i}`}
                    />
                );
                continue;
            }
        }
        teamPicks.push(
            <EmptyCard
                height={height}
                width={width}
                location={CardLocation.DRAFTBOARD}
                key={`team-${team}-pick-${i}-empty`}
            />
        );
    }
    return teamPicks;
};

const renderCommissionerRound = (
    maxLen,
    picks,
    auction,
    auctionCost,
    team,
    openings,
    trades,
    view,
    status,
    currTeam,
    maximumBids,
    height,
    width,
    fontIndex,
    showDraftboardDetails,
    replace,
    select,
    selectAutocompletePlayer,
    selected
) => {
    const teamPicks = [];
    const roundView = view === DraftboardViews.ROUND;
    const inProgress = status === DraftStatus.IN_PROGRESS;
    const upcoming = status === DraftStatus.UPCOMING;
    for (let i = 0; i < maxLen; i++) {
        if (picks[i]) {
            // Traded takes precedence over fantasy player
            if (pickTradedAway(team, i, openings) && inProgress && roundView) {
                teamPicks.push(
                    <Traded
                        height={height}
                        width={width}
                        key={`traded-${team}-pick-${i}`}
                    />
                );
                continue;
            }
            // In javascript, indexing an array out-of-bounds returns undefined,
            // so this is actually safe
            const fantasyPlayer = picks[i];
            teamPicks.push(
                <DroppablePickButton
                    height={height}
                    width={width}
                    fontIndex={fontIndex}
                    showDraftboardDetails={showDraftboardDetails}
                    fantasyPlayer={fantasyPlayer}
                    auction={auction}
                    auctionCost={auctionCost}
                    maximumBids={maximumBids}
                    keeper={false}
                    team={team}
                    round={i}
                    replace={replace}
                    view={view}
                    key={`team-${team}-pick-${i}-${fantasyPlayer.id}`}
                />
            );
            continue;
        } else if (inProgress && roundView && (!auction || selected !== -1)) {
            // For auction drafts, we do not render draftable cards unless
            // the user has selected a player in the autocomplete section
            if (draftable(team, i, openings)) {
                // For auctions, render DraftableButton to force users to click
                if (auction) {
                    // Render EmptyCard if team does not have sufficient budget
                    if (maximumBids[team] >= auctionCost) {
                        teamPicks.push(
                            <DroppableButton
                                team={team}
                                round={i}
                                select={select}
                                nominated={currTeam}
                                keeper={false}
                                height={height}
                                width={width}
                                key={`draftable-${team}-pick-${i}`}
                            />
                        );
                        continue;
                    }
                } else {
                    teamPicks.push(
                        <Droppable
                            team={team}
                            round={i}
                            selectAutocompletePlayer={selectAutocompletePlayer}
                            height={height}
                            width={width}
                            key={`draftable-${team}-pick-${i}`}
                        />
                    );
                    continue;
                }
            } else if (pickTradedAway(team, i, openings)) {
                teamPicks.push(
                    <Traded
                        height={height}
                        width={width}
                        key={`traded-${team}-pick-${i}`}
                    />
                );
                continue;
            }
        } else if (upcoming) {
            const tradeNum = tradeNumber(team, i, trades);
            if (tradeNum > 0) {
                teamPicks.push(
                    <TradeNumber
                        height={height}
                        width={width}
                        i={tradeNum}
                        key={`traded-${team}-pick-${i}`}
                    />
                );
                continue;
            }
        }
        teamPicks.push(
            <EmptyCard
                height={height}
                width={width}
                location={CardLocation.DRAFTBOARD}
                key={`team-${team}-pick-${i}-empty`}
            />
        );
    }
    return teamPicks;
};

const renderRound = (
    maxLen,
    picks,
    auction,
    auctionCost,
    team,
    openings,
    trades,
    view,
    status,
    currTeam,
    maximumBids,
    height,
    width,
    fontIndex,
    showDraftboardDetails,
    replace,
    select,
    selectAutocompletePlayer,
    selected,
    draftOrder,
    isCommissioner,
    isMember,
    testDrive,
    userID
) => {
    if (isCommissioner || testDrive) {
        return renderCommissionerRound(
            maxLen,
            picks,
            auction,
            auctionCost,
            team,
            openings,
            trades,
            view,
            status,
            currTeam,
            maximumBids,
            height,
            width,
            fontIndex,
            showDraftboardDetails,
            replace,
            select,
            selectAutocompletePlayer,
            selected
        );
    } else if (isMember) {
        return renderMemberRound(
            maxLen,
            picks,
            auction,
            team,
            openings,
            trades,
            view,
            status,
            height,
            width,
            fontIndex,
            showDraftboardDetails,
            selectAutocompletePlayer,
            selected,
            draftOrder,
            userID
        );
    }
    return renderAnonymousRound(
        maxLen,
        picks,
        auction,
        team,
        openings,
        view,
        status,
        height,
        width,
        fontIndex,
        showDraftboardDetails
    );
};

const TeamPicks = ({
    view,
    picks,
    rounds,
    maxLen,
    currTeam,
    auction,
    auctionCost,
    status,
    team,
    openings,
    trades,
    maximumBids,
    height,
    width,
    fontIndex,
    showDraftboardDetails,
    replace,
    select,
    selectAutocompletePlayer,
    selected,
    draftOrder,
    isCommissioner,
    isMember,
    testDrive,
    userID,
}) => {
    const teamPicks = [];
    switch (view) {
        case DraftboardViews.EDIT_KEEPER:
        case DraftboardViews.KEEPER:
            teamPicks.push(
                ...renderKeeper(
                    view,
                    rounds,
                    picks,
                    auction,
                    auctionCost,
                    team,
                    trades,
                    maximumBids,
                    height,
                    width,
                    fontIndex,
                    showDraftboardDetails,
                    replace,
                    select,
                    selected
                )
            );
            break;
        case DraftboardViews.ROSTER:
        case DraftboardViews.ROUND:
        default:
            teamPicks.push(
                ...renderRound(
                    maxLen,
                    picks,
                    auction,
                    auctionCost,
                    team,
                    openings,
                    trades,
                    view,
                    status,
                    currTeam,
                    maximumBids,
                    height,
                    width,
                    fontIndex,
                    showDraftboardDetails,
                    replace,
                    select,
                    selectAutocompletePlayer,
                    selected,
                    draftOrder,
                    isCommissioner,
                    isMember,
                    testDrive,
                    userID
                )
            );
    }
    return <TeamContainer>{teamPicks}</TeamContainer>;
};

const AllPicks = ({
    draftOrder,
    view,
    rounds,
    auction,
    auctionCost,
    status,
    board,
    currTeam,
    openings,
    trades,
    maximumBids,
    cardHeight,
    cardWidth,
    fontIndex,
    showDraftboardDetails,
    replace,
    select,
    selectAutocompletePlayer,
    selected,
    isCommissioner,
    isMember,
    testDrive,
    userID,
}) => {
    const teams = [];
    let maxLen = rounds;
    for (let i = 0; i < board.length; i++) {
        if (board[i].length > maxLen) {
            maxLen = board[i].length;
        }
    }
    for (let i = 0; i < draftOrder.length; i++) {
        teams.push(
            <TeamPicks
                view={view}
                picks={board[i]}
                rounds={rounds}
                maxLen={maxLen}
                currTeam={currTeam}
                auction={auction}
                auctionCost={auctionCost}
                status={status}
                height={cardHeight}
                width={cardWidth}
                team={i}
                openings={openings}
                trades={trades}
                maximumBids={maximumBids}
                replace={replace}
                select={select}
                selectAutocompletePlayer={selectAutocompletePlayer}
                selected={selected}
                fontIndex={fontIndex}
                showDraftboardDetails={showDraftboardDetails}
                draftOrder={draftOrder}
                isCommissioner={isCommissioner}
                isMember={isMember}
                userID={userID}
                testDrive={testDrive}
                key={`team-order-${i}${currTeam === i ? "-selected" : ""}`}
            />
        );
    }
    return <>{teams}</>;
};

const mapStateToProps = (state) => {
    const newState = { auction: false, testDrive: false };
    if (state.draftboard?.draft?.draftID) {
        newState.testDrive = isTestDrive(state.draftboard.draft.draftID);
    }
    if (state.auth?.user && state.draftboard?.draft?.commissioners) {
        const userID = state.auth.user.username;
        const commissioners = state.draftboard.draft.commissioners;
        const members = state.draftboard.draft.members;
        newState.isCommissioner = isCommissioner(userID, commissioners);
        newState.isMember = isMember(userID, members);
        newState.userID = userID;
    }
    if (state.draftboard?.draft) {
        const { draft } = state.draftboard;
        newState.draftOrder = draft.draftOrder;
        newState.rounds = draft.rounds;
        if (draft.draftConfig === DraftConfig.AUCTION) {
            newState.auction = true;
        }
        newState.status = draft.status;
    }
    if (state.draftboard?.draftboard) {
        const { draftboard } = state.draftboard;
        newState.currTeam = draftboard.currTeam;
        newState.currRound = draftboard.currRound;
        newState.view = draftboard.view;
        newState.openings = draftboard.openings;
        newState.trades = draftboard.trades;
        newState.maximumBids = draftboard.maximumBids;
        newState.cardHeight = draftboard.cardHeight;
        newState.cardWidth = draftboard.cardWidth;
        newState.fontIndex = draftboard.fontIndex;
        newState.showDraftboardDetails = draftboard.showDraftboardDetails;
    }
    if (state.draftboard?.autocomplete) {
        const { autocomplete } = state.draftboard;
        newState.selected = autocomplete.selectedPlayer;
        newState.auctionCost = autocomplete.auction;
    }
    return newState;
};
export default connect(mapStateToProps, {
    replace,
    select,
    selectAutocompletePlayer,
})(AllPicks);
