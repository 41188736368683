import React from "react";
import styled from "styled-components";
import { CANCEL_ICON } from "../../../js/constants";
import Button, { buttonStyle } from "../../common/Button";
import { Icon } from "../../common/IconButton";
import Modal from "../../common/Modal";
import ColorScheme from "../../styles/colors";

const MODAL_WIDTH = "384px";

const deleteDraftDescription = (leagueName) =>
    `Are you sure you want to delete the fantasy draft for ${leagueName}? This cannot be undone.`;

const IconContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const HeaderContainer = styled.div`
    margin: 12px 0px;
`;

const Header = styled.h1`
    width: 100%;
    display: flex;
    justify-content: center;

    color: ${ColorScheme.red3.color};
    font-family: inherit;
    font-weight: 600;
    font-size: 32px;
`;

const Description = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    color: ${ColorScheme.blue5.color};
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    margin: 0px 0px 24px;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
        &:first-child {
            margin-right: 12px;
        }
        &:only-child {
            margin-right: 0px;
        }
    }
`;

const Buttons = ({ close, deleteDraft, deleting }) => (
    <ButtonContainer>
        <Button
            size={buttonStyle.Size.MEDIUM}
            color={buttonStyle.Color.GRAY}
            priority={buttonStyle.Priority.SECONDARY}
            onClick={close}
            disabled={deleting}
            type="button"
            text="Cancel"
        />
        <Button
            size={buttonStyle.Size.MEDIUM}
            color={buttonStyle.Color.RED}
            priority={buttonStyle.Priority.PRIMARY}
            onClick={deleteDraft}
            loading={deleting}
            disabled={deleting}
            type="button"
            text="Delete Draft"
        />
    </ButtonContainer>
);

const DeleteDraftModal = ({
    open,
    close,
    deleteDraft,
    deleting,
    leagueName,
}) => (
    <Modal
        width={MODAL_WIDTH}
        open={open}
        close={close}
        bgcolor={ColorScheme.gray1.color}
    >
        <IconContainer>
            <Icon
                iconHref={CANCEL_ICON}
                color={ColorScheme.red3.color}
                size="48px"
            />
        </IconContainer>
        <HeaderContainer>
            <Header>Please Confirm</Header>
        </HeaderContainer>
        <Description>{deleteDraftDescription(leagueName)}</Description>
        <Buttons close={close} deleteDraft={deleteDraft} deleting={deleting} />
    </Modal>
);

export default DeleteDraftModal;
