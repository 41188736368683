import { css } from "styled-components";
import ColorScheme from "./colors";

const Defaults = {
    shadow: `0px 4px 4px ${ColorScheme.shadowBlack.color}`,
    hoverShadow: `0 15px 20px ${ColorScheme.shadowBlack.color}`,
    activeShadow: `0 2px 2px ${ColorScheme.shadowBlack.color}`,
    insetShadow: `inset 0px 4px 4px ${ColorScheme.shadowBlack.color}`,
    focusShadow: `0 0 0 4px ${ColorScheme.blue2.color}`,
    borderRadius: "8px",
};

export const FocusShadow = css`
    &:focus-visible {
        box-shadow: ${Defaults.focusShadow};
    }
`;

export const DefaultTransition = css`
    transition: all 0.2s;
`;

export default Defaults;
