import React from "react";
import styled from "styled-components";
import ColorScheme from "../../styles/colors";
import InfoContainer from "../common/InfoContainer";

const LeagueNameContainer = styled.div`
    // Set container size
    width: 100%;
    height: 100%;

    // Style text
    font-size: 18px;
    font-weight: 600;
    color: ${ColorScheme.gray1.color};

    // Center league name
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LeagueName = ({ name }) => (
    <InfoContainer maxWidth="256px">
        <LeagueNameContainer>{name}</LeagueNameContainer>
    </InfoContainer>
);

export default LeagueName;
