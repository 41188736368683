import React, { useState } from "react";
import { Field, useFormState } from "react-final-form";
import styled, { css } from "styled-components";
import { DraftClass } from "../../../js/constants";
import { numRounds } from "../../../js/util";
import { buttonStyle } from "../../common/Button";
import { VerticalContainer } from "../../common/Container";
import { NumberField } from "../../common/Field";
import { AdjacentFieldError, Checkbox } from "../../common/Input";
import LeagueType from "../../common/LeagueType";
import ColorScheme from "../../styles/colors";
import {
    FieldContainer,
    LabelContainer,
    LabeledFieldContainer,
} from "../common/Label";

const NUMBER_INPUT_WIDTH = "64px";

const tooManyRounds = (maxRounds) =>
    `Number of Rounds cannot be higher than ${maxRounds}`;

const RosterTextStyle = css`
    font-family: inherit;
    font-size: 24px;
    color: ${ColorScheme.blue4.color};
`;

const RosterLabel = styled.label`
    ${RosterTextStyle}
    cursor: pointer;
`;

const TotalRoundsLabel = styled.div`
    ${RosterTextStyle}
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Total = styled.div`
    font-family: inherit;
    font-size: 24px;
    color: ${ColorScheme.blue6.color};
`;

const IDPInputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
        &:first-child {
            margin-right: 16px;
        }
    }
`;

const IDPLabel = styled.label`
    font-family: inherit;
    font-size: 18px;
    color: ${(props) =>
        props.enabled ? ColorScheme.blue4.color : ColorScheme.gray3.color};
    cursor: ${(props) => (props.enabled ? "pointer" : "not-allowed")};

    display: flex;
    align-items: baseline;
    > * {
        &:last-child {
            margin-left: 8px;
        }
    }
`;

export const IDPSetting = ({ showIDP, setShowIDP, idpAllowed }) => {
    const [checked, setChecked] = useState(showIDP);

    const toggleIDP = (onChange) => () => {
        setChecked(!checked);
        onChange(!checked);
        setShowIDP(!checked);
    };

    return (
        <IDPInputContainer>
            <Field name="idp" type="checkbox">
                {({ input: { onChange } }) => (
                    <Checkbox
                        id="idp"
                        type="checkbox"
                        disabled={!idpAllowed}
                        checked={checked}
                        onChange={toggleIDP(onChange)}
                    />
                )}
            </Field>
            <IDPLabel enabled={idpAllowed} htmlFor="idp">
                Add Individual Defensive Players (IDP)
                <LeagueType
                    size={buttonStyle.Size.LARGE}
                    draftType={DraftClass.PREMIUM}
                    disabled={!idpAllowed}
                />
            </IDPLabel>
        </IDPInputContainer>
    );
};

const Rows = ({ positions, showIDP, initial }) => {
    const rows = [];
    for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (!position.idp || showIDP) {
            rows.push(
                <Row
                    key={`roster-row-${position.abbreviation}`}
                    position={position}
                    index={i}
                    initial={initial[i].value}
                />
            );
        }
    }
    return <VerticalContainer spacing="12px">{rows}</VerticalContainer>;
};

const Row = ({ position, index, initial }) => (
    <LabeledFieldContainer>
        <LabelContainer>
            <RosterLabel htmlFor={position.abbreviation}>
                {`${position.position} (${position.abbreviation})`}
            </RosterLabel>
        </LabelContainer>
        <FieldContainer>
            <NumberField
                initial={initial}
                fieldName={`positions[${index}].value`}
                printField={`${position.position}`}
                label={position.abbreviation}
                min="0"
                max={`${position.max}`}
                width={NUMBER_INPUT_WIDTH}
            />
        </FieldContainer>
    </LabeledFieldContainer>
);

export const TotalRounds = ({ draftType, idp, draftConfig }) => {
    const { values } = useFormState();
    const totalRounds = numRounds(
        values.positions,
        draftType,
        idp,
        draftConfig
    );
    const maxRounds = draftConfig[draftType].maxRounds;
    return (
        <LabeledFieldContainer>
            <LabelContainer>
                <TotalRoundsLabel>Number of Rounds</TotalRoundsLabel>
            </LabelContainer>
            <FieldContainer>
                <TotalContainer>
                    <Total>{totalRounds}</Total>
                    {totalRounds > maxRounds && (
                        <AdjacentFieldError>
                            {tooManyRounds(maxRounds)}
                        </AdjacentFieldError>
                    )}
                </TotalContainer>
            </FieldContainer>
        </LabeledFieldContainer>
    );
};

const RosterSettings = ({ draftType, draftConfig, positions, showIDP }) => (
    <Rows
        positions={draftConfig.positions}
        showIDP={showIDP && draftConfig[draftType].idp}
        initial={positions}
    />
);

export const rosterValidate = (draftConfig, draftType, idp) => (values) => {
    const totalRounds = numRounds(
        values.positions,
        draftType,
        idp,
        draftConfig
    );
    const maxRounds = draftConfig[draftType].maxRounds;
    if (totalRounds > maxRounds) {
        return {
            FORM_ERROR: tooManyRounds(maxRounds),
        };
    }
    return {};
};

export default RosterSettings;
