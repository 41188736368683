import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ColorScheme from "../../styles/colors";
import InfoContainer from "../common/InfoContainer";
import TextCard from "./TextCard";

const Container = styled.div`
    // Set container size
    width: 100%;
    height: 100%;

    // Center children
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LabelContainer = styled.div`
    flex: 50%;

    // Style label
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    color: ${ColorScheme.gray1.color};

    display: flex;
    justify-content: center;
    align-items: center;
`;

const OnTheClock = ({ currTeam }) => (
    <InfoContainer maxWidth="256px">
        <Container>
            <LabelContainer>On the Clock</LabelContainer>
            <TextCard cardText={currTeam} />
        </Container>
    </InfoContainer>
);

const mapStateToProps = (state) => {
    const {
        draftboard: { draft, draftboard },
    } = state;
    const newState = {};
    if (draft?.draftOrder && draftboard?.currTeam !== undefined) {
        newState.currTeam = draft.draftOrder[draftboard.currTeam].team;
    }
    return newState;
};

export default connect(mapStateToProps)(OnTheClock);
