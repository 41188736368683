import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn } from "../../actions/auth";
import { filterDrafts, getDrafts } from "../../actions/getdrafts";
import { DraftStatus } from "../../js/constants";
import history from "../../js/history";
import { tracker } from "../App";
import LoadingPage from "../common/LoadingPage";
import { StyleReset } from "../styles/Reset";
import Header from "./common/Header";
import { MainColumnContainer, TwoColumnContainer } from "./common/PageLayout";
import CreateDraftButton from "./dashboard/Button";
import Drafts from "./dashboard/Drafts";
import NoDrafts from "./dashboard/NoDrafts";
import Sidebar from "./dashboard/Sidebar";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.createDraft = () => history.push("/drafts/new");
        this.filterDraft = (status) => this.props.filterDrafts(status);
    }

    componentDidMount = async () => {
        await this.props.signIn(tracker);
        await this.props.getDrafts();
    };

    componentWillUnmount = () => {
        // Reset the filter status
        this.props.filterDrafts(DraftStatus.ALL);
    };

    render = () => {
        if (!this.props.drafts || !this.props.user) return <LoadingPage />;
        // If user has no drafts, show a default page
        if (this.props.drafts.length === 0) {
            return (
                <>
                    <StyleReset />
                    <Header />
                    <NoDrafts
                        createDraft={this.createDraft}
                        user={this.props.user}
                    />
                </>
            );
        }
        return (
            <>
                <StyleReset />
                <Header />
                <TwoColumnContainer>
                    <Sidebar />
                    <MainColumnContainer>
                        <CreateDraftButton
                            createDraft={this.createDraft}
                            user={this.props.user}
                        />
                        <Drafts
                            drafts={this.props.drafts}
                            status={this.props.status}
                        />
                    </MainColumnContainer>
                </TwoColumnContainer>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    const newState = {};
    if (state.getdrafts) {
        newState.status = state.getdrafts.status;
        newState.drafts = state.getdrafts.drafts;
    }
    if (state.auth) {
        newState.user = state.auth.user;
    }
    return newState;
};

export default connect(mapStateToProps, { filterDrafts, getDrafts, signIn })(
    Dashboard
);
