// Login actions
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const RESEND_CODE = "RESEND_CODE";
export const RESET_FORGET_STATUS = "RESET_FORGET_STATUS";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_RESEND_STATUS = "RESET_RESEND_STATUS";
export const SIGN_OUT = "SIGN_OUT";
export const VERIFY_EMAIL = "VERIFY_EMAIL";

// Header actions
export const HEADER_SHOW_PROFILE = "HEADER_SHOW_PROFILE";
export const HEADER_HIDE_PROFILE = "HEADER_HIDE_PROFILE";
export const HEADER_SHOW_HELP = "HEADER_SHOW_HELP";
export const HEADER_HIDE_HELP = "HEADER_HIDE_HELP";

// Auth actions
export const AUTH_SIGNED_IN = "AUTH_SIGNED_IN";
export const UNAUTH_USER = "UNAUTH_USER";

// Profile actions
export const GET_USER = "GET_USER";
export const RENDER_EMAIL_PREFERENCE_DETAILS =
    "RENDER_EMAIL_PREFERENCE_DETAILS";
export const RENDER_EMAIL_PREFERENCE_FORM = "RENDER_EMAIL_PREFERENCE_FORM";
export const RENDER_PASSWORD_FORM = "RENDER_PASSWORD_FORM";
export const RENDER_PROFILE_FORM = "RENDER_PROFILE_FORM";
export const RENDER_PROFILE_DETAILS = "RENDER_PROFILE_DETAILS";
export const RENDER_VERIFY_FORM = "RENDER_VERIFY_FORM";
export const RESEND_VERIFY_CODE = "RESEND_VERIFY_CODE";
export const RESET_RESEND_VERIFY_STATUS = "RESET_RESEND_VERIFY_STATUS";
export const RESET_PASSWORD_STATUS = "RESET_PASSWORD_STATUS";
export const UPDATE_EMAIL_PREFERENCE = "UPDATE_EMAIL_PREFERENCE";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_USER = "UPDATE_USER";
export const VERIFY_EMAIL_UPDATE = "VERIFY_EMAIL_UPDATE";

// Config actions
export const SET_DRAFT_CONFIG = "SET_DRAFT_CONFIG";

// Create draft actions
export const CREATE_DRAFT = "CREATE_DRAFT";

// Dashboard actions
export const FILTER_DRAFTS = "FILTER_DRAFTS";
export const GET_DRAFTS = "GET_DRAFTS";

// Draft details actions
export const DELETE_DRAFT = "DELETE_DRAFT";
export const EDIT_DRAFT = "EDIT_DRAFT";
export const EDIT_OPEN = "EDIT_OPEN";
export const GET_DRAFT = "GET_DRAFT";
export const RESET_DRAFT = "RESET_DRAFT";

// Draftboard load/unload actions
export const GET_DRAFTBOARD = "GET_DRAFTBOARD";
export const GET_PLAYERS = "GET_PLAYERS";
export const GET_RESULTS = "GET_RESULTS";
export const RESET_DRAFTBOARD = "RESET_DRAFTBOARD";

// Draftboard autocomplete actions
export const AUCTION_CHANGE = "AUCTION_CHANGE";
export const FILTER_CHANGE = "FILTER_CHANGE";
export const SEARCH_CHANGE = "SEARCH_CHANGE";
export const SET_AUTOCOMPLETE_PLAYERS = "SET_AUTOCOMPLETE_PLAYERS";

// Draftboard autocomplete selection actions
export const RESET_SELECT_PLAYER = "RESET_SELECT_PLAYER";
export const SELECT_PLAYER = "SELECT_PLAYER";
export const TOGGLE_SELECT_PLAYER = "TOGGLE_SELECT_PLAYER";

// Draftboard settings actions
export const UPDATE_AUTODRAFT = "UPDATE_AUTODRAFT";
export const SORT_BY_NAME = "SORT_BY_NAME";
export const SORT_BY_RANK = "SORT_BY_RANK";
export const SET_AUCTION_DISPLAY = "SET_AUCTION_DISPLAY";
export const SET_CARD_HEIGHT = "SET_CARD_HEIGHT";
export const SET_CARD_WIDTH = "SET_CARD_WIDTH";
export const SET_DISPLAY_PLAYER_DETAILS = "SET_DISPLAY_PLAYER_DETAILS";
export const SET_FONT_INDEX = "SET_FONT_INDEX";

// Draftboard infobar actions
export const CHANGE_VIEW = "CHANGE_VIEW";
export const PAUSE_TIMER = "PAUSE_TIMER";
export const RESUME_TIMER = "RESUME_TIMER";
export const RESET_TIMER = "RESET_TIMER";

// Draftboard draft actions
export const ADD_ACTION = "ADD_ACTION";
export const INGEST_ACTIONS = "INGEST_ACTIONS";
export const POST_ACTIONS = "POST_ACTIONS";
export const LOADING = "LOADING";
