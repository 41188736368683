import React from "react";
import { Field, useFormState } from "react-final-form";
import styled from "styled-components";
import { AuctionNominationOrder } from "../../../js/constants";
import { VerticalContainer } from "../../common/Container";
import { NumberField } from "../../common/Field";
import { Select } from "../../common/Input";
import ColorScheme from "../../styles/colors";
import Detail from "../common/Detail";
import {
    FieldContainer,
    LabelContainer,
    LabeledFieldContainer,
} from "../common/Label";

const MAX_BUDGET = "2000";
const NUMBER_INPUT_WIDTH = "96px";
const OPTION_WIDTH = "192px";

const AuctionFieldLabel = styled.label`
    font-size: ${(props) => (props.small ? "16px" : "20px")};
    color: ${ColorScheme.blue4.color};
    cursor: pointer;
`;

const AuctionInputNumberField = ({ label, printField, min, max, small }) => {
    const { values } = useFormState();
    const initial = values.auctionDraftOptions[label];
    return (
        <NumberField
            initial={initial}
            fieldName={`auctionDraftOptions.${label}`}
            label={label}
            printField={printField}
            min={min}
            max={max}
            width={NUMBER_INPUT_WIDTH}
            small={small}
        />
    );
};

const AuctionOption = ({ label, display, max, small }) => (
    <LabeledFieldContainer>
        <LabelContainer>
            <AuctionFieldLabel htmlFor={label} small={small}>
                {display}
            </AuctionFieldLabel>
        </LabelContainer>
        <FieldContainer>
            <AuctionInputNumberField
                label={label}
                min="0"
                max={max}
                printField={display}
                small={small}
            />
        </FieldContainer>
    </LabeledFieldContainer>
);

const NominationOrderOption = ({ label, small }) => (
    <LabeledFieldContainer>
        <LabelContainer>
            <AuctionFieldLabel htmlFor={label} small={small}>
                Nomination Order
            </AuctionFieldLabel>
        </LabelContainer>
        <FieldContainer>
            <Field name={`auctionDraftOptions.${label}`}>
                {({ input }) => (
                    <Select
                        id={label}
                        width={OPTION_WIDTH}
                        fontSize={small && "16px"}
                        {...input}
                    >
                        <option value={AuctionNominationOrder.LINEAR}>
                            {AuctionNominationOrder.LINEAR}
                        </option>
                        <option value={AuctionNominationOrder.SNAKE}>
                            {AuctionNominationOrder.SNAKE}
                        </option>
                        <option value={AuctionNominationOrder.HIGHEST_BID}>
                            {AuctionNominationOrder.HIGHEST_BID}
                        </option>
                    </Select>
                )}
            </Field>
        </FieldContainer>
    </LabeledFieldContainer>
);

export const AuctionSummary = ({ options }) => (
    <>
        <Detail labelText="Budget" fieldText={`$${options.budget}`} />
        <Detail labelText="Minimum Bid" fieldText={`$${options.minimumBid}`} />
        <Detail
            labelText="Nomination Order"
            fieldText={options.nominationOrder}
        />
    </>
);

const AuctionOptions = ({ small }) => (
    <VerticalContainer marginTop="16px" spacing="12px">
        <AuctionOption
            label="budget"
            display="Budget/Salary Cap"
            max={MAX_BUDGET}
            small={small}
        />
        <AuctionOption
            label="minimumBid"
            display="Minimum Bid"
            max={10}
            small={small}
        />
        <NominationOrderOption label="nominationOrder" small={small} />
    </VerticalContainer>
);

export default AuctionOptions;
