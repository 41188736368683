import { CREATE_DRAFT, SIGN_OUT } from "../actions/types";

export const createDraftReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_DRAFT:
            if (!action.error && !action.success) {
                return { ...state, loading: true };
            } else if (action.error) {
                return {
                    ...state,
                    loading: false,
                    success: false,
                    error: action.error,
                };
            }
            return { ...state, loading: false, success: true };
        case SIGN_OUT:
            return {};
        default:
            return state;
    }
};
