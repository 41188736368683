import React, { useState } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import styled from "styled-components";
import { editDraft, editOpen } from "../../../actions/draftdetails";
import { DraftStatus } from "../../../js/constants";
import { parsePositions, positionsList } from "../../../js/util";
import { VerticalContainer } from "../../common/Container";
import Line from "../../common/Line";
import { DetailsContainer, Header } from "../common/DetailsContainer";
import { LabeledValueDivision } from "../common/Label";
import RosterSettings, {
    IDPSetting,
    rosterValidate,
    TotalRounds,
} from "../displaydraft/RosterSettings";

// NOTE: Body keys must be kept in sync with EditDraft API request
const editDraftDetails =
    (draftID, editDraft, draftConfig, displayInfo) => async (values) => {
        const body = {
            positions: parsePositions(values, draftConfig),
            idp: values.idp,
        };
        // FIXME: Reflect error if something fails
        await editDraft(body, draftID);
        displayInfo();
    };

const positionsString = (positions, draftConfig) => {
    let roster = "";
    for (let i = 0; i < draftConfig.positions.length; i++) {
        const currPosition = draftConfig.positions[i].abbreviation;
        if (positions[currPosition] && positions[currPosition] > 0) {
            if (i > 0) roster += ", ";
            roster += `${positions[currPosition]} ${currPosition}`;
        }
    }
    return roster;
};

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    > * {
        margin-top: 24px;
        &:first-child {
            margin-top: 0px;
        }
    }
`;

const PositionsForm = ({
    draft,
    draftConfig,
    editDraft,
    displayInfo,
    loading,
}) => {
    const [showIDP, setShowIDP] = useState(draft.idp);
    const cancel = () => {
        displayInfo();
        setShowIDP(draft.idp);
    };
    return (
        <Form
            keepDirtyOnReinitialize
            onSubmit={editDraftDetails(
                draft.draftID,
                editDraft,
                draftConfig,
                displayInfo
            )}
            initialValues={{
                positions: positionsList(draft.positions, draftConfig),
                idp: showIDP,
            }}
            validate={rosterValidate(draftConfig, draft.draftType, draft.idp)}
            render={({ handleSubmit }) => (
                <StyledForm onSubmit={handleSubmit}>
                    <Header
                        heading="Roster Settings"
                        primaryBtn={{
                            text: "Save",
                            disabled: loading,
                            loading,
                        }}
                        secondaryBtn={{
                            text: "Cancel",
                            disabled: loading,
                            onClick: cancel,
                        }}
                    />
                    <DetailsContainer>
                        <RosterSettings
                            draftType={draft.draftType}
                            draftConfig={draftConfig}
                            positions={positionsList(
                                draft.positions,
                                draftConfig
                            )}
                            showIDP={showIDP}
                        />
                        <Line />
                        <TotalRounds
                            draftType={draft.draftType}
                            idp={showIDP}
                            draftConfig={draftConfig}
                        />
                        {draftConfig[draft.draftType].idp && (
                            <>
                                <Line />
                                <IDPSetting
                                    showIDP={showIDP}
                                    setShowIDP={setShowIDP}
                                    idpAllowed={
                                        draftConfig[draft.draftType].idp
                                    }
                                />
                            </>
                        )}
                    </DetailsContainer>
                </StyledForm>
            )}
        />
    );
};

const PositionsInfo = ({
    draft,
    draftConfig,
    displayForm,
    loading,
    isCommissioner,
}) => (
    <VerticalContainer>
        <Header
            heading="Roster Settings"
            secondaryBtn={
                isCommissioner &&
                draft.status === DraftStatus.UPCOMING && {
                    text: "Edit",
                    onClick: displayForm,
                    disabled: loading,
                }
            }
        />
        <DetailsContainer>
            <LabeledValueDivision
                label="Positions"
                value={positionsString(draft.positions, draftConfig)}
            />
            <LabeledValueDivision label="Total Rounds" value={draft.rounds} />
        </DetailsContainer>
    </VerticalContainer>
);

const Positions = ({
    draft,
    draftConfig,
    editDraft,
    editOpen,
    form,
    loading,
    isCommissioner,
}) => {
    const ROSTER_SETTINGS_FORM = "Roster Settings";
    const showForm = form === ROSTER_SETTINGS_FORM;
    const displayForm = () => editOpen(ROSTER_SETTINGS_FORM);
    const displayInfo = () => editOpen("");

    return showForm ? (
        <PositionsForm
            draft={draft}
            draftConfig={draftConfig}
            editDraft={editDraft}
            displayInfo={displayInfo}
            loading={loading}
        />
    ) : (
        <PositionsInfo
            draft={draft}
            draftConfig={draftConfig}
            displayForm={displayForm}
            loading={loading}
            isCommissioner={isCommissioner}
        />
    );
};

const mapStateToProps = (state) => {
    const newState = {
        draftConfig: state.config.draftConfig,
    };
    if (state?.draftdetails?.draft) {
        newState.draft = state.draftdetails.draft;
        if (state?.auth?.user && newState?.draft?.commissioners) {
            const userID = state.auth.user.username;
            const commissioners = newState.draft.commissioners;
            for (let i = 0; i < commissioners.length; i++) {
                if (commissioners[i].userID === userID) {
                    newState.isCommissioner = true;
                    break;
                }
            }
        }
    }
    newState.form = state.draftdetails.form;
    newState.loading = state.draftdetails.loading;
    return newState;
};

export default connect(mapStateToProps, { editDraft, editOpen })(Positions);
