import React from "react";
import styled from "styled-components";
import ColorScheme from "../styles/colors";
import { StyleReset } from "../styles/Reset";
import Logo from "./Logo";
import { LoadingSpinner } from "./Spinner";

const SpinnerContainer = styled.div`
    width: 100%;
    height: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const LogoContainer = styled.div`
    width: 512px;
`;

const LoadingPage = () => (
    <>
        <StyleReset bgcolor={ColorScheme.gray2.color} />
        <SpinnerContainer>
            <LogoContainer>
                <Logo />
            </LogoContainer>
            <LoadingSpinner />
        </SpinnerContainer>
    </>
);

export default LoadingPage;
