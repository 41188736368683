import React, { useState } from "react";
import { Field } from "react-final-form";
import styled, { css } from "styled-components";
import { MAX_TEAM_LENGTH } from "../../../js/constants";
import { NumberField } from "../../common/Field";
import {
    RowContainer,
    ThreeColumnFieldStyle,
    ThreeColumnFirstFieldStyle,
    ThreeColumnTable
} from "../../common/Table";
import ColorScheme from "../../styles/colors";
import {
    InputLabel,
    LabeledSectionContainer,
    OffsetFieldContainer,
    OffsetLabelContainer
} from "../common/Label";

const LEAGUE_SIZE_WIDTH = "96px";

const TextStyle = css`
    // Style text
    font-family: inherit;
    font-size: 18px;

    // Give space between input text and bounding box
    padding: 8px 16px;

    background-color: transparent;
`;

const InputStyle = css`
    ${TextStyle}
    color: ${ColorScheme.blue6.color};

    // Style bounding box
    width: ${(props) => (props.width ? props.width : "100%")};
    outline: none;
    border: none;
`;

const Order = styled.div`
    ${TextStyle}
    ${ThreeColumnFirstFieldStyle}
    color: ${ColorScheme.gray6.color};
`;

const TeamNameInput = styled.input`
    ${ThreeColumnFieldStyle}
    ${InputStyle}

    // Style placeholder text
    ::placeholder {
        color: ${ColorScheme.blue3.color};
    }

    :focus::placeholder {
        color: transparent;
    }
`;

const EmailInput = styled.input`
    ${ThreeColumnFieldStyle}
    ${InputStyle}

    // Style placeholder text
    ::placeholder {
        color: ${ColorScheme.gray3.color};
    }

    :focus::placeholder {
        color: transparent;
    }
`;

const TeamName = styled.div`
    ${ThreeColumnFieldStyle}
    ${TextStyle}
    color: ${ColorScheme.blue6.color};
`;

const EmptyTeamName = styled.div`
    ${ThreeColumnFieldStyle}
    ${TextStyle}
    color: ${ColorScheme.blue4.color};
`;

const Email = styled.div`
    ${ThreeColumnFieldStyle}
    ${TextStyle}
    color: ${ColorScheme.blue6.color};

    // Make sure email address stays within column
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const EmptyEmail = styled.div`
    ${ThreeColumnFieldStyle}
    ${TextStyle}
    color: ${ColorScheme.gray3.color};
`;

const Row = ({ index, initialTeam, initialEmail }) => {
    // Make team and email controlled inputs
    const [team, setTeam] = useState(initialTeam);
    const [email, setEmail] = useState(initialEmail);

    const updateTeam = (onChange) => (event) => {
        const team = event.target.value;
        // Prevent team names longer than MAX_TEAM_LENGTH
        if (team.length > MAX_TEAM_LENGTH) return;
        setTeam(team);
        onChange(event);
    };
    const updateEmail = (onChange) => (event) => {
        setEmail(event.target.value);
        onChange(event);
    };

    return (
        <RowContainer index={index}>
            <Order>{index + 1}</Order>
            <Field name={`draftOrder[${index}].team`}>
                {({ input: { onChange } }) => (
                    <TeamNameInput
                        placeholder={`Team ${index + 1}`}
                        onChange={updateTeam(onChange)}
                        value={team}
                    />
                )}
            </Field>
            <Field name={`draftOrder[${index}].email`}>
                {({ input: { onChange } }) => (
                    <EmailInput
                        placeholder="None"
                        onChange={updateEmail(onChange)}
                        type="email"
                        value={email}
                    />
                )}
            </Field>
        </RowContainer>
    );
};

const rows = (leagueSize, draftOrder) => {
    const rows = [];
    for (let i = 0; i < leagueSize; i++) {
        let team = "";
        let email = "";
        if (draftOrder && draftOrder[i]) {
            team = draftOrder[i].team || team;
            email = draftOrder[i].email || email;
        }
        rows.push(
            <Row
                key={`team-order-${i}`}
                index={i}
                initialTeam={team}
                initialEmail={email}
            />
        );
    }
    return rows;
};

const SummaryRow = ({ index, team, email }) => {
    return (
        <RowContainer index={index}>
            <Order>{index + 1}</Order>
            {team === "" ? (
                <EmptyTeamName>{`Team ${index + 1}`}</EmptyTeamName>
            ) : (
                <TeamName>{team}</TeamName>
            )}
            {email === "" ? (
                <EmptyEmail>None</EmptyEmail>
            ) : (
                <Email>{email}</Email>
            )}
        </RowContainer>
    );
};

const summaryRows = (leagueSize, draftOrder) => {
    const rows = [];
    for (let i = 0; i < leagueSize; i++) {
        let team = "";
        let email = "";
        if (draftOrder && i < draftOrder.length && draftOrder[i]) {
            team = draftOrder[i].team || team;
            email = draftOrder[i].email || email;
        }
        rows.push(
            <SummaryRow
                key={`summary-team-order-${i}`}
                index={i}
                team={team}
                email={email}
            />
        );
    }
    return rows;
};

// FIXME: Need to add upsell error if user tries to add past maximum
export const LeagueSize = ({
    fieldName,
    label,
    leagueSize,
    MIN_TEAMS,
    MAX_TEAMS,
}) => (
    <LabeledSectionContainer>
        <OffsetLabelContainer>
            <InputLabel htmlFor={label}>League Size</InputLabel>
        </OffsetLabelContainer>
        <OffsetFieldContainer>
            <NumberField
                initial={leagueSize}
                fieldName={fieldName}
                label={label}
                printField="League Size"
                min={MIN_TEAMS}
                max={MAX_TEAMS}
                width={LEAGUE_SIZE_WIDTH}
            />
        </OffsetFieldContainer>
    </LabeledSectionContainer>
);

export const DraftOrderSummary = ({ leagueSize, draftOrder }) => {
    return (
        <ThreeColumnTable headerFields={["Pick", "Team Name", "Email"]}>
            {summaryRows(parseInt(leagueSize), draftOrder)}
        </ThreeColumnTable>
    );
};

const DraftOrder = ({ leagueSize, draftOrder }) => (
    <ThreeColumnTable headerFields={["Pick", "Team Name", "Email (optional)"]}>
        {rows(parseInt(leagueSize), draftOrder)}
    </ThreeColumnTable>
);

export default DraftOrder;
