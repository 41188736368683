import React, { Component } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import {
    resendCode,
    resetResendStatus,
    verifyEmailUpdate,
} from "../../../actions/profile";
import { cognitoErrors } from "../../../js/errors";
import { required } from "../../../js/formhelper";
import Button, { buttonStyle } from "../../common/Button";
import ButtonPlaceholder from "../../common/ButtonPlaceholder";
import { VerticalContainer } from "../../common/Container";
import {
    ButtonContainer,
    DetailsContainer,
    MessageContainer,
} from "../common/DetailsContainer";
import { LabeledInput } from "../common/Label";

const errors = {
    CODE_BLANK: { code: "Code cannot be blank" },
    INVALID_CODE: { code: "Invalid code" },
};

class VerifyForm extends Component {
    constructor(props) {
        super(props);
        this.verify = async ({ code }) => {
            const error = await this.props.verifyEmailUpdate(code);
            if (!error) return;
            if (error === cognitoErrors.CodeMismatch) {
                return errors.INVALID_CODE;
            }
        };
        this.resendCode = () => {
            this.props.resendCode();
            setTimeout(() => this.props.resetResendStatus(), 3500);
        };
    }

    renderResendButton = () => {
        if (this.props.resendCodeStatus) {
            if (this.props.resendCodeStatus.success) {
                return (
                    <ButtonPlaceholder
                        size={buttonStyle.Size.MEDIUM}
                        color={buttonStyle.Color.GREEN}
                        text="Code Sent"
                    />
                );
            } else if (this.props.resendCodeStatus.error) {
                return (
                    <ButtonPlaceholder
                        size={buttonStyle.Size.MEDIUM}
                        color={buttonStyle.Color.RED}
                        text="Try Again"
                    />
                );
            }
        }
        return (
            <Button
                size={buttonStyle.Size.MEDIUM}
                color={buttonStyle.Color.BLUE}
                priority={buttonStyle.Priority.SECONDARY}
                disabled={this.props.resendCodeStatus.loading}
                loading={this.props.resendCodeStatus.loading}
                onClick={this.resendCode}
                type="button"
                text="Resend"
            />
        );
    };

    render = () => (
        <Form
            onSubmit={this.verify}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <VerticalContainer>
                        <DetailsContainer>
                            <MessageContainer>
                                To complete the email change, we've sent you a
                                verification code which you must input below.
                                Please check your new email address.
                            </MessageContainer>
                            <LabeledInput
                                label="Verification Code"
                                fieldName="code"
                                type="text"
                                validate={required}
                            />
                            <ButtonContainer>
                                {this.renderResendButton()}
                                <Button
                                    size={buttonStyle.Size.MEDIUM}
                                    color={buttonStyle.Color.BLUE}
                                    priority={buttonStyle.Priority.PRIMARY}
                                    disabled={submitting}
                                    loading={
                                        this.props.verifyEmailUpdateStatus
                                            .loading
                                    }
                                    type="submit"
                                    text="Submit"
                                />
                            </ButtonContainer>
                        </DetailsContainer>
                    </VerticalContainer>
                </form>
            )}
        />
    );
}

const mapStateToProps = (state) => {
    const newState = {};
    if (state.auth) {
        newState.user = state.auth.user;
    }
    if (state.profile) {
        if (state.profile.verifyEmailUpdate) {
            newState.verifyEmailUpdateStatus = state.profile.verifyEmailUpdate;
        }
        if (state.profile.resendCode) {
            newState.resendCodeStatus = state.profile.resendCode;
        }
    }
    return newState;
};
export default connect(mapStateToProps, {
    resendCode,
    resetResendStatus,
    verifyEmailUpdate,
})(VerifyForm);
