import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { changeView } from "../../actions/draftboard";
import { DraftConfig, DraftStatus, DraftboardViews } from "../../js/constants";
import { isCommissioner, isMember, isTestDrive } from "../../js/util";
import ColorScheme, { Draftboard } from "../styles/colors";
import Autodraft from "./infobar/Autodraft";
import EditKeepers from "./infobar/EditKeepers";
import Empty from "./infobar/Empty";
import FinishDraft from "./infobar/FinishDraft";
import LastPick from "./infobar/LastPick";
import LeagueName from "./infobar/LeagueName";
import Message from "./infobar/Message";
import OnTheClock from "./infobar/OnTheClock";
import ResetDraft from "./infobar/ResetDraft";
import SaveKeepers from "./infobar/SaveKeepers";
import StartDraft from "./infobar/StartDraft";
import Timer from "./infobar/Timer";
import Undo from "./infobar/Undo";
import View from "./infobar/View";

const FooterContainer = styled.div`
    // Occupy entire width of the page, but set height to 48px
    width: 100%;
    height: 96px;

    // Force footer to stay at the bottom
    position: sticky;
    bottom: 0;
    z-index: 10;

    // Add padding between footer contents and FooterContainer
    padding: 8px;

    // Style footer container
    ${Draftboard.Background.blue}
    box-shadow: 0 -10px 20px ${ColorScheme.shadowBlack.color};

    // Center container's contents
    display: flex;
    justify-content: center;
    align-items: center;
`;

const InfoBarContainer = styled.div`
    // Center info bar
    max-width: 1280px;
    width: 100%;
    height: 100%;
    margin: auto;

    // Align info bar contents horizontally
    display: flex;

    > * {
        margin-right: 12px;
        &:first-child {
            margin-right: 12px;
        }
        &:only-child {
            margin-right: 0px;
        }
    }
`;

const CommissionerInfoBar = ({
    draft,
    view,
    changeView,
    keepersPage,
    testdrive,
    lastKeeper,
    lastPick,
    totalPicks,
    newActions,
    openFinish,
}) => {
    const viewEditKeepers = () => changeView(DraftboardViews.EDIT_KEEPER);
    const viewKeepers = () => changeView(DraftboardViews.KEEPER);
    const draftFull = totalPicks === draft.rounds * draft.numPlayers;
    if (draft.status === DraftStatus.COMPLETED) {
        return (
            <InfoBarContainer>
                <LeagueName name={draft.name} />
                <View />
                <Empty />
                <Empty />
                <Empty />
            </InfoBarContainer>
        );
    } else if (draft.status === DraftStatus.UPCOMING) {
        // For non-keeper drafts, only show "Start Draft" button
        if (!draft.keeper) {
            return (
                <InfoBarContainer>
                    <LeagueName name={draft.name} />
                    <Empty />
                    <Empty />
                    <Empty />
                    <StartDraft testdrive={testdrive} />
                </InfoBarContainer>
            );
        }
        // If we're on the keeper page, don't show "Start Draft"
        if (keepersPage) {
            if (lastKeeper === null) {
                // editKeepers should be set to true here
                // If there are no keepers and user presses save keepers,
                // SaveKeepers should just do a PostActions and have
                // its view remain on the editKeepers page.
                // This could occur if user previously saved keepers,
                // but then removed them all
                return (
                    <InfoBarContainer>
                        <LeagueName name={draft.name} />
                        <Empty />
                        <Empty />
                        {newActions ? <SaveKeepers /> : <Empty />}
                        <Message message="Select Your Keepers" />
                    </InfoBarContainer>
                );
            } else {
                if (view === DraftboardViews.EDIT_KEEPER) {
                    return (
                        <InfoBarContainer>
                            <LeagueName name={draft.name} />
                            <Empty />
                            <Empty />
                            <SaveKeepers resetKeeperView={viewKeepers} />
                            <Undo />
                        </InfoBarContainer>
                    );
                }
                return (
                    <InfoBarContainer>
                        <LeagueName name={draft.name} />
                        <Empty />
                        <Empty />
                        <Empty />
                        <EditKeepers editKeepers={viewEditKeepers} />
                    </InfoBarContainer>
                );
            }
        }
        // Page = Draft page (i.e. not keepers page)
        if (view === DraftboardViews.EDIT_KEEPER) {
            return (
                <InfoBarContainer>
                    <LeagueName name={draft.name} />
                    <Empty />
                    <Empty />
                    <SaveKeepers resetKeeperView={viewKeepers} />
                    {lastKeeper === null ? (
                        <Message message="Select Your Keepers" />
                    ) : (
                        <Undo />
                    )}
                </InfoBarContainer>
            );
        }
        return (
            <InfoBarContainer>
                <LeagueName name={draft.name} />
                <Empty />
                <Empty />
                <EditKeepers editKeepers={viewEditKeepers} />
                <StartDraft testdrive={testdrive} />
            </InfoBarContainer>
        );
    } else if (draft.status === DraftStatus.IN_PROGRESS) {
        if (view === DraftboardViews.ROSTER) {
            return (
                <InfoBarContainer>
                    <LeagueName name={draft.name} />
                    <View />
                    {draft.timer.enabled ? <Timer /> : <Empty />}
                    <Empty />
                    <Empty />
                </InfoBarContainer>
            );
        } else if (view === DraftboardViews.KEEPER) {
            return (
                <InfoBarContainer>
                    <LeagueName name={draft.name} />
                    <View />
                    {draft.timer.enabled ? <Timer /> : <Empty />}
                    <Empty />
                    <EditKeepers editKeepers={viewEditKeepers} />
                </InfoBarContainer>
            );
        } else if (view === DraftboardViews.EDIT_KEEPER) {
            if (lastKeeper === null) {
                return (
                    <InfoBarContainer>
                        <LeagueName name={draft.name} />
                        <Empty />
                        {draft.timer.enabled ? <Timer /> : <Empty />}
                        <SaveKeepers resetKeeperView={viewKeepers} />
                        <Message message="Select Your First Keeper" />
                    </InfoBarContainer>
                );
            }
            return (
                <InfoBarContainer>
                    <LeagueName name={draft.name} />
                    <Empty />
                    {draft.timer.enabled ? <Timer /> : <Empty />}
                    <SaveKeepers resetKeeperView={viewKeepers} />
                    <Undo />
                </InfoBarContainer>
            );
        } else if (view === DraftboardViews.ROUND) {
            if (lastPick === null) {
                return (
                    <InfoBarContainer>
                        <LeagueName name={draft.name} />
                        <View />
                        {draft.timer.enabled ? <Timer /> : <Autodraft />}
                        <OnTheClock />
                        {testdrive ? (
                            <Message message="Draft Your First Player" />
                        ) : (
                            <ResetDraft testdrive={testdrive} />
                        )}
                    </InfoBarContainer>
                );
            } else if (draftFull) {
                return (
                    <InfoBarContainer>
                        <LeagueName name={draft.name} />
                        <View />
                        <Empty />
                        <FinishDraft openFinish={openFinish} />
                        <Undo />
                    </InfoBarContainer>
                );
            }
            return (
                <InfoBarContainer>
                    <LeagueName name={draft.name} />
                    <View />
                    {draft.timer.enabled ? <Timer /> : <Autodraft />}
                    <OnTheClock />
                    <Undo />
                </InfoBarContainer>
            );
        }
    }
    return null;
};

const MemberInfoBar = ({
    draft,
    view,
    lastPick,
    totalPicks,
    currTeam,
    draftOrder,
    userID,
}) => {
    const draftFull = totalPicks === draft.rounds * draft.numPlayers;
    const isMemberTeam =
        currTeam >= 0 ? draftOrder[currTeam].userID === userID : false;
    if (
        draft.status === DraftStatus.COMPLETED ||
        draft.status === DraftStatus.UPCOMING
    ) {
        return (
            <InfoBarContainer>
                <LeagueName name={draft.name} />
                <View />
                <Empty />
                <Empty />
                <Empty />
            </InfoBarContainer>
        );
    } else if (draft.status === DraftStatus.IN_PROGRESS) {
        if (view === DraftboardViews.ROSTER) {
            return (
                <InfoBarContainer>
                    <LeagueName name={draft.name} />
                    <View />
                    {draft.timer.enabled ? <Timer /> : <Empty />}
                    <Empty />
                    <Empty />
                </InfoBarContainer>
            );
        } else if (view === DraftboardViews.KEEPER) {
            return (
                <InfoBarContainer>
                    <LeagueName name={draft.name} />
                    <View />
                    <Empty />
                    <Empty />
                    <Empty />
                </InfoBarContainer>
            );
        } else if (view === DraftboardViews.ROUND) {
            if (lastPick === null) {
                if (isMemberTeam) {
                    return (
                        <InfoBarContainer>
                            <LeagueName name={draft.name} />
                            <View />
                            {draft.timer.enabled ? (
                                <Timer />
                            ) : draft.draftConfig === DraftConfig.AUCTION ? (
                                <Empty />
                            ) : (
                                <Autodraft />
                            )}
                            <OnTheClock />
                            <Message message="Draft Your First Player" />
                        </InfoBarContainer>
                    );
                }
                return (
                    <InfoBarContainer>
                        <LeagueName name={draft.name} />
                        <View />
                        {draft.timer.enabled ? <Timer /> : <Empty />}
                        <OnTheClock />
                        <Message message="Waiting for First Pick" />
                    </InfoBarContainer>
                );
            } else if (draftFull) {
                return (
                    <InfoBarContainer>
                        <LeagueName name={draft.name} />
                        <View />
                        <Empty />
                        <Message message="Waiting for Draft to Complete" />
                        <LastPick />
                    </InfoBarContainer>
                );
            }
            if (isMemberTeam) {
                return (
                    <InfoBarContainer>
                        <LeagueName name={draft.name} />
                        <View />
                        {draft.timer.enabled ? (
                            <Timer />
                        ) : draft.draftConfig === DraftConfig.AUCTION ? (
                            <Empty />
                        ) : (
                            <Autodraft />
                        )}
                        <OnTheClock />
                        <LastPick />
                    </InfoBarContainer>
                );
            }
            return (
                <InfoBarContainer>
                    <LeagueName name={draft.name} />
                    <View />
                    {draft.timer.enabled ? <Timer /> : <Empty />}
                    <OnTheClock />
                    <LastPick />
                </InfoBarContainer>
            );
        }
    }
    return null;
};

// Draftboard component blocks infobar from rendering on screenshot page
const renderInfoBar = (
    draft,
    view,
    changeView,
    keepersPage,
    testdrive,
    lastKeeper,
    lastPick,
    totalPicks,
    newActions,
    isCommissioner,
    openFinish,
    currTeam,
    draftOrder,
    userID,
    isMember
) => {
    if (isCommissioner || testdrive) {
        return (
            <CommissionerInfoBar
                draft={draft}
                view={view}
                changeView={changeView}
                keepersPage={keepersPage}
                testdrive={testdrive}
                lastKeeper={lastKeeper}
                lastPick={lastPick}
                totalPicks={totalPicks}
                newActions={newActions}
                openFinish={openFinish}
            />
        );
    } else if (isMember) {
        return (
            <MemberInfoBar
                draft={draft}
                view={view}
                lastPick={lastPick}
                totalPicks={totalPicks}
                currTeam={currTeam}
                draftOrder={draftOrder}
                userID={userID}
            />
        );
    }
    // TODO: Differentiate between logged in and not logged in bystander?
    return (
        <InfoBarContainer>
            <LeagueName name={draft.name} />
            <View />
            <Empty />
            <Empty />
            <Empty />
        </InfoBarContainer>
    );
};

const InfoBar = ({
    draft,
    view,
    changeView,
    lastKeeper,
    lastPick,
    totalPicks,
    newActions,
    isCommissioner,
    keepersPage,
    testdrive,
    openFinish,
    currTeam,
    draftOrder,
    userID,
    isMember,
}) => (
    <FooterContainer>
        {renderInfoBar(
            draft,
            view,
            changeView,
            keepersPage,
            testdrive,
            lastKeeper,
            lastPick,
            totalPicks,
            newActions,
            isCommissioner,
            openFinish,
            currTeam,
            draftOrder,
            userID,
            isMember
        )}
    </FooterContainer>
);

const mapStateToProps = (state) => {
    const newState = { testdrive: false };

    if (state.auth?.user?.username) newState.isUser = true;
    if (state.draftboard?.draft?.draftID) {
        newState.draftID = state.draftboard.draft.draftID;
        newState.testdrive = isTestDrive(newState.draftID);
    }
    if (state.auth?.user && state.draftboard?.draft?.commissioners) {
        const userID = state.auth.user.username;
        const commissioners = state.draftboard.draft.commissioners;
        const members = state.draftboard.draft.members;
        newState.isCommissioner = isCommissioner(userID, commissioners);
        newState.isMember = isMember(userID, members);
        newState.userID = userID;
    }
    if (state.draftboard?.draft) {
        newState.draft = state.draftboard.draft;
        newState.draftOrder = state.draftboard.draft.draftOrder;
    }
    if (state.draftboard?.draftboard) {
        const { draftboard } = state.draftboard;
        newState.currTeam = draftboard.currTeam;
        newState.view = draftboard.view;
        newState.lastKeeper = draftboard.lastKeeper;
        newState.lastPick = draftboard.lastPick;
        newState.totalPicks = draftboard.totalPicks;
        newState.newActions =
            draftboard.commitIndex !== draftboard.actions.length;
    }
    return newState;
};

export default connect(mapStateToProps, { changeView })(InfoBar);
