import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
    hideHelp,
    hideProfile,
    showHelp,
    showProfile,
} from "../../../actions/header";
import { signOut } from "../../../actions/login";
import {
    CHAT_ICON,
    CONTACT_URL,
    FEEDBACK_ICON,
    GUIDE_URL,
    HELP_ICON,
    MAP_ICON,
    PROFILE_ICON,
    SIGN_OUT_ICON,
} from "../../../js/constants";
import {
    IconWithTextAnchor,
    IconWithTextButton,
    IconWithTextLink,
} from "../../common/IconButton";
import Logo from "../../common/Logo";
import ColorScheme from "../../styles/colors";
import IconDropdown from "./IconDropdown";

const HeaderContainer = styled.div`
    // Occupy entire width of the page, but set height to 48px
    width: 100%;
    height: 48px;

    // Force header to stay at the top
    position: sticky;
    top: 0;
    z-index: 10;

    // Style header container
    background-color: ${ColorScheme.gray1.color};
    box-shadow: 0 10px 20px ${ColorScheme.shadowBlack.color};
`;

const StyledHeader = styled.header`
    // Center header
    max-width: 1024px;
    width: 100%;
    height: 100%;
    margin: auto;

    // Push logo to the beginning and navigation icons to the end and center
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LogoContainer = styled.div`
    // Constrain logo to 256px and make sure it occupies the entire width
    max-width: 256px;
    width: 100%;
`;

const NavContainer = styled.div`
    // Evenly space out group items
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

const Name = styled.div`
    // Make sure name doesn't overflow width, if the name is too long
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // Make sure name has padding around it
    padding: 6px 16px;

    // Style name
    font-size: 18px;
    font-weight: 600;
    color: ${ColorScheme.blue6.color};

    // Add bottom border
    border-bottom: 1px solid ${ColorScheme.gray4.color};
`;

const Help = ({ hide, show, display }) => (
    <IconDropdown
        hide={hide}
        show={show}
        displayDropdown={display}
        iconColor={`${ColorScheme.blue5.color}`}
        iconHref={HELP_ICON}
    >
        <IconWithTextAnchor
            iconHref={MAP_ICON}
            dest={GUIDE_URL}
            destText="Guide"
            newTab
        />
        <IconWithTextAnchor
            iconHref={CHAT_ICON}
            dest={CONTACT_URL}
            destText="Contact Us"
            newTab
        />
    </IconDropdown>
);

const Profile = ({ name, hide, show, display, signOut }) => (
    <IconDropdown
        hide={hide}
        show={show}
        displayDropdown={display}
        iconColor={`${ColorScheme.blue5.color}`}
        iconHref={PROFILE_ICON}
    >
        {name && <Name>{name}</Name>}
        <IconWithTextLink
            iconHref={PROFILE_ICON}
            dest="/profile"
            destText="Profile"
        />
        <IconWithTextAnchor
            iconHref={FEEDBACK_ICON}
            dest={CONTACT_URL}
            destText="Feedback"
            newTab
        />
        <IconWithTextButton
            iconHref={SIGN_OUT_ICON}
            action={signOut}
            actionText="Sign Out"
        />
    </IconDropdown>
);

const Header = (props) => (
    <HeaderContainer>
        <StyledHeader>
            <LogoContainer>
                <Logo link href="/" />
            </LogoContainer>
            <NavContainer>
                <Help
                    hide={props.hideHelp}
                    show={props.showHelp}
                    display={props.displayHelp}
                />
                <Profile
                    name={props.name}
                    hide={props.hideProfile}
                    show={props.showProfile}
                    display={props.displayProfile}
                    signOut={props.signOut}
                />
            </NavContainer>
        </StyledHeader>
    </HeaderContainer>
);

const mapStateToProps = (state) => {
    const newState = {
        displayProfile: state.header.showProfile,
        displayHelp: state.header.showHelp,
    };
    if (state.auth && state.auth.user) {
        newState.name = state.auth.user.attributes.name;
    }
    return newState;
};

export default connect(mapStateToProps, {
    hideHelp,
    hideProfile,
    showHelp,
    showProfile,
    signOut,
})(Header);
