import { AUTH_SIGNED_IN, SIGN_OUT, UNAUTH_USER } from "../actions/types";

export const authReducer = (state = {}, action) => {
    switch (action.type) {
        case AUTH_SIGNED_IN:
            return { user: action.user };
        case UNAUTH_USER:
            return { user: { authenticated: false } };
        case SIGN_OUT:
            return {};
        default:
            return state;
    }
};
