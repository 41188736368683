// Cognito Errors:
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmSignUp.html
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ForgotPassword.html
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmForgotPassword.html
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ResendConfirmationCode.html
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_UpdateUserAttributes.html
// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ChangePassword.html

export const httpErrors = {
    StatusBadRequest: 400, // RFC 7231, 6.5.1
    StatusUnauthorized: 401, // RFC 7235, 3.1
    StatusPaymentRequired: 402, // RFC 7231, 6.5.2
    StatusForbidden: 403, // RFC 7231, 6.5.3
    StatusNotFound: 404, // RFC 7231, 6.5.4
    StatusMethodNotAllowed: 405, // RFC 7231, 6.5.5
    StatusNotAcceptable: 406, // RFC 7231, 6.5.6
    StatusProxyAuthRequired: 407, // RFC 7235, 3.2
    StatusRequestTimeout: 408, // RFC 7231, 6.5.7
    StatusConflict: 409, // RFC 7231, 6.5.8
    StatusGone: 410, // RFC 7231, 6.5.9
    StatusLengthRequired: 411, // RFC 7231, 6.5.10
    StatusPreconditionFailed: 412, // RFC 7232, 4.2
    StatusRequestEntityTooLarge: 413, // RFC 7231, 6.5.11
    StatusRequestURITooLong: 414, // RFC 7231, 6.5.12
    StatusUnsupportedMediaType: 415, // RFC 7231, 6.5.13
    StatusRequestedRangeNotSatisfiable: 416, // RFC 7233, 4.4
    StatusExpectationFailed: 417, // RFC 7231, 6.5.14
    StatusTeapot: 418, // RFC 7168, 2.3.3
    StatusMisdirectedRequest: 421, // RFC 7540, 9.1.2
    StatusUnprocessableEntity: 422, // RFC 4918, 11.2
    StatusLocked: 423, // RFC 4918, 11.3
    StatusFailedDependency: 424, // RFC 4918, 11.4
    StatusTooEarly: 425, // RFC 8470, 5.2.
    StatusUpgradeRequired: 426, // RFC 7231, 6.5.15
    StatusPreconditionRequired: 428, // RFC 6585, 3
    StatusTooManyRequests: 429, // RFC 6585, 4
    StatusRequestHeaderFieldsTooLarge: 431, // RFC 6585, 5
    StatusUnavailableForLegalReasons: 451, // RFC 7725, 3
};

// FIXME: Need to handle all exceptions for each Cognito API call
export const cognitoErrors = {
    UserNotFound: "UserNotFoundException",
    NotAuthorized: "NotAuthorizedException",
    UserNotConfirmed: "UserNotConfirmedException",
    UsernameExists: "UsernameExistsException",
    UserLambdaValidation: "UserLambdaValidationException",
    CodeMismatch: "CodeMismatchException",
    InvalidParameter: "InvalidParameterException",
    InternalError: "InternalErrorException",
    InvalidLambda: "InvalidLambdaResponseException",
    CodeDeliveryFailure: "CodeDeliveryFailureException",
    InvalidPassword: "InvalidPasswordException",
    ExpiredCode: "ExpiredCodeException",
    LimitExceeded: "LimitExceededException",
    TooManyRequests: "TooManyRequestsException",
    TooManyFailedAttempts: "TooManyFailedAttemptsException",
    AliasExists: "AliasExistsException",
};

// Error Types: https://stripe.com/docs/api/errors
// Error Codes: https://stripe.com/docs/error-codes
export const stripeErrors = {
    APIConnectionError: "api_connection_error",
    APIError: "api_error",
    AuthenticationError: "authentication_error",
    CardError: "card_error",
    IdempotencyError: "idempotency_error",
    InvalidRequestError: "invalid_request_error",
    RateLimitError: "rate_limit_error",
    ValidationError: "validation_error",
};

// Error causes from LiveDraftX API
// Must be kept in sync with error.go in backend
export const apiErrors = {
    CreditCard: "Credit Card",
    DynamoDB: "DynamoDB",
    Internal: "Internal",
    User: "User",
};
