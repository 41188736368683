import { Auth } from "@aws-amplify/auth";
import { AUTH_SIGNED_IN, UNAUTH_USER } from "../actions/types";
import history from "../js/history";

export const signIn = (tracker) => async (dispatch) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userAttrs = {
            attributes: user.attributes,
            username: user.username,
        };
        if (tracker) tracker.setUserID(user.attributes.email);
        return dispatch({ type: AUTH_SIGNED_IN, user: userAttrs });
    } catch (error) {
        history.push("/login");
    }
};

export const signInBypassCache = (tracker) => async (dispatch) => {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });
        const userAttrs = {
            attributes: user.attributes,
            username: user.username,
        };
        if (tracker) tracker.setUserID(user.attributes.email);
        return dispatch({ type: AUTH_SIGNED_IN, user: userAttrs });
    } catch (error) {
        history.push("/login");
    }
};

export const unauthSignIn = (tracker) => async (dispatch) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userAttrs = {
            attributes: user.attributes,
            username: user.username,
        };
        if (tracker) tracker.setUserID(user.attributes.email);
        return dispatch({ type: AUTH_SIGNED_IN, user: userAttrs });
    } catch (error) {
        return dispatch({ type: UNAUTH_USER });
    }
};
