import React from "react";
import styled from "styled-components";
import { FINISH_DRAFT_DESCRIPTION } from "../../js/descriptions";
import Button, { buttonStyle } from "../common/Button";
import Modal from "../common/Modal";
import ColorScheme from "../styles/colors";

// FIXME: This is basically a copy of DeleteDraftModal.jsx
const MODAL_WIDTH = "384px";

const HeaderContainer = styled.div`
    margin: 12px 0px;
`;

const Header = styled.h1`
    width: 100%;
    display: flex;
    justify-content: center;

    color: ${ColorScheme.green4.color};
    font-family: inherit;
    font-weight: 600;
    font-size: 32px;
`;

const Description = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    color: ${ColorScheme.blue5.color};
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    text-align: center;

    margin: 0px 0px 24px;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
        &:first-child {
            margin-right: 12px;
        }
        &:only-child {
            margin-right: 0px;
        }
    }
`;

const Buttons = ({ close, finish, finishingDraft }) => (
    <ButtonContainer>
        <Button
            size={buttonStyle.Size.MEDIUM}
            color={buttonStyle.Color.GRAY}
            priority={buttonStyle.Priority.SECONDARY}
            disabled={finishingDraft}
            onClick={close}
            type="button"
            text="Cancel"
        />
        <Button
            size={buttonStyle.Size.MEDIUM}
            color={buttonStyle.Color.GREEN}
            priority={buttonStyle.Priority.PRIMARY}
            loading={finishingDraft}
            disabled={finishingDraft}
            onClick={finish}
            type="button"
            text="Finish"
        />
    </ButtonContainer>
);

const FinishModal = ({ open, close, finish, finishingDraft }) => (
    <Modal
        open={open}
        close={close}
        width={MODAL_WIDTH}
        bgcolor={ColorScheme.gray2.color}
    >
        <HeaderContainer>
            <Header>Please Confirm</Header>
        </HeaderContainer>
        <Description>{FINISH_DRAFT_DESCRIPTION}</Description>
        <Buttons
            close={close}
            finish={finish}
            finishingDraft={finishingDraft}
        />
    </Modal>
);

export default FinishModal;
