import { timeDiff } from "./util";

const MAX_CONNECTION_DURATION = 1000 * 60 * 60 * 2; // 2 hours in milliseconds

let conn = null;

class Connection {
    constructor(url, ingest) {
        this.url = url;
        this.ws = new WebSocket(url);
        this.ws.onmessage = this.onmessage.bind(this);
        this.ws.onclose = this.onclose.bind(this);
        this.ws.onerror = this.onerror.bind(this);
        this.connectTime = new Date();
        this.ingestActions = ingest;
    }

    // Websocket event handlers
    onclose(event) {
        if (event.wasClean) {
            // If we disconnect due to API Gateway connection timeout, reconnect
            const timeSinceConnect = timeDiff(new Date(), this.connectTime);
            if (timeSinceConnect > MAX_CONNECTION_DURATION) {
                this.ws = new WebSocket(this.url);
                this.ws.onmessage = this.onmessage.bind(this);
                this.ws.onclose = this.onclose.bind(this);
                this.ws.onerror = this.onerror.bind(this);
            }
        }
    }
    onmessage(event) {
        const data = JSON.parse(event.data);
        const { actions } = data;
        this.ingestActions(actions);
    }
    onerror(data) {
        // FIXME: What should I do here?
        // console.log(data);
    }

    // Websocket functions
    send(data) {
        this.ws.send(JSON.stringify(data));
    }

    disconnect() {
        // TODO: Check bufferedAmount before closing
        // TODO: https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/bufferedAmount
        this.ws.close();
    }

    heartbeat() {
        this.send({ action: "ping" });
    }
}

export const connectRemote = (auth, id, ingest) => {
    const websocketURL = `${process.env.REMOTE_DRAFT_URL}?draftID=${id}${
        auth ? "&auth=" + auth : ""
    }`;
    conn = new Connection(websocketURL, ingest);
};

export const sendRemote = (data) => {
    if (conn) {
        conn.send(data);
    }
};

export const disconnectRemote = () => {
    if (conn) {
        conn.ws.close();
    }
};

export const ping = () => {
    if (conn) {
        conn.heartbeat();
    }
};
