import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { autodraft } from "../../actions/draftboard";
import {
    DraftboardViews,
    DraftConfig,
    DraftStatus,
    LOGO_BLACK_PICTURE_URL,
    Positions,
} from "../../js/constants";
import { costSortFn, groupByPosition } from "../../js/util";
import AllInfo from "./board/AllInfo";
import AllPicks from "./board/AllPicks";
import Sidebar from "./board/Sidebar";

const rosterBoard = (board, draft, draftConfig) => {
    // rosterLimit holds the maximum number of roster spots for each position
    const rosterLimit = {};
    for (const [pos, value] of Object.entries(draft.positions)) {
        rosterLimit[pos] = value;
    }

    // rosterOrder holds the order in which roster spots should show up
    const rosterOrder = [];
    for (let i = 0; i < draftConfig.positions.length; i++) {
        const abbr = draftConfig.positions[i].abbreviation;
        if (!(abbr in rosterLimit)) continue;
        rosterOrder.push(abbr);
    }

    // Fill in rosters for all teams
    // A single roster is a map of positions to a list of players,
    // sorted by draft order or cost
    const rosters = [];
    for (let i = 0; i < board.length; i++) {
        const picks = [...board[i]];

        // For auction drafts, we need to sort picks by cost
        if (draft.draftConfig === DraftConfig.AUCTION) picks.sort(costSortFn);

        // Sort current team picks by position, and add to rosters
        rosters.push(groupByPosition(picks, rosterLimit));
    }

    // Fill in the new board using rosters
    const newBoard = [];
    for (let i = 0; i < rosters.length; i++) {
        const currentRoster = rosters[i];
        const newRoster = [];

        // Iterate over rosterOrder so we can fill in newRoster
        for (let j = 0; j < rosterOrder.length; j++) {
            const position = rosterOrder[j];
            const positionPicks =
                position in currentRoster ? currentRoster[position] : [];

            // Allow bench spots to occupy as many spots as needed
            const positionLimit =
                position === Positions.BN
                    ? Math.max(positionPicks.length, rosterLimit[position])
                    : rosterLimit[position];

            // Fill in newRoster
            for (let k = 0; k < positionLimit; k++) {
                if (k > positionPicks.length) {
                    newRoster.push(null);
                    continue;
                }
                newRoster.push(positionPicks[k]);
            }
        }
        newBoard.push(newRoster);
    }
    return newBoard;
};

const BoardContainer = styled.div`
    // Height of header + footer = 96px * 2
    min-height: calc(100vh - 192px);

    // Set 12px margin between info/action bar and board
    padding: 12px 0;

    // Set scrollbars to just the grid
    position: relative;
    overflow-x: auto;
    display: flex;
`;

const Grid = styled.div`
    // Horizontally center the contents of Grid
    margin: 0 auto;
`;

const PickGrid = styled.div`
    // Align sidebar and picks side-by-side
    display: flex;
    position: relative;

    // Set background image with 50% opacity with a before pseudo-element
    // Only show background image for non-completed drafts
    ${(props) =>
        !props.completed &&
        `&::before {
            content: "";
            top: 50%;
            left: 50%;
            width: 75%;
            height: 50%;
            position: absolute;
            background-image: url(${LOGO_BLACK_PICTURE_URL});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            transform: translate(-50%, -50%);
            opacity: 0.5;
        }`}
`;

class Board extends Component {
    constructor(props) {
        super(props);
        this.timerCheck = null;
    }

    componentDidUpdate = () => {
        // Clear any previous timers
        clearTimeout(this.timerCheck);

        // If draft is currently In Progress, check to see if current team
        // has been set to autodraft
        const { draft, currTeam, autodraft } = this.props;
        if (draft.status === DraftStatus.IN_PROGRESS && currTeam !== -1) {
            // Run autodraft check every time the board re-renders
            // We run this on a 1.5 second delay for a more "natural" experience
            this.timerCheck = setTimeout(() => {
                const team = draft.draftOrder[currTeam];
                if (team.autodraft) autodraft();
            }, 1500);
        }
    };

    render = () => {
        const { board, draft, view, draftConfig } = this.props;
        let draftboard = board;
        if (view === DraftboardViews.ROSTER) {
            draftboard = rosterBoard(board, draft, draftConfig);
        }
        let completed = draft.status === DraftStatus.COMPLETED;
        return (
            <BoardContainer>
                <Grid>
                    <AllInfo />
                    <PickGrid completed={completed}>
                        <Sidebar board={draftboard} />
                        <AllPicks board={draftboard} />
                    </PickGrid>
                </Grid>
            </BoardContainer>
        );
    };
}

const mapStateToProps = (state) => {
    const newState = {};
    if (state.draftboard?.draft) {
        newState.draft = state.draftboard.draft;
    }
    if (state.draftboard?.draftboard) {
        newState.board = state.draftboard.draftboard.board;
        newState.view = state.draftboard.draftboard.view;
        newState.currTeam = state.draftboard.draftboard.currTeam;
    }
    if (state.config?.draftConfig) {
        newState.draftConfig = state.config.draftConfig;
    }
    return newState;
};

export default connect(mapStateToProps, { autodraft })(Board);
