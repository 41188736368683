import React from "react";
import styled from "styled-components";
import { DRAFT_CREATED_DESCRIPTION } from "../../js/descriptions";
import history from "../../js/history";
import Button, { buttonStyle } from "../common/Button";
import ColorScheme from "../styles/colors";
import { StyleReset } from "../styles/Reset";
import Header from "./common/Header";
import PageHeader from "./common/PageHeader";
import { InfoContainer, NarrowPageContainer } from "./common/PageLayout";

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TextContainer = styled.div`
    color: ${ColorScheme.blue4.color};
    font-size: 24px;
    text-align: center;
    margin: 24px 0;
`;

const DraftCreated = () => (
    <>
        <StyleReset />
        <Header />
        <NarrowPageContainer>
            <PageHeader heading="Draft Created" />
            <InfoContainer addTopMargin>
                <TextContainer>{DRAFT_CREATED_DESCRIPTION}</TextContainer>
            </InfoContainer>
            <ButtonContainer>
                <Button
                    size={buttonStyle.Size.LARGE}
                    color={buttonStyle.Color.BLUE}
                    priority={buttonStyle.Priority.PRIMARY}
                    onClick={() => history.push("/")}
                    type="button"
                    text="Back Home"
                />
            </ButtonContainer>
        </NarrowPageContainer>
    </>
);

export default DraftCreated;
