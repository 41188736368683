import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import ColorScheme from "../styles/colors";
import { DefaultTransition, FocusShadow } from "../styles/defaults";
import { StyleStrip } from "../styles/Reset";

const IconContainerStyle = css`
    // Make sure icon is centered
    display: flex;
    justify-content: center;
    align-items: center;
`;

const IconContainer = styled.div`
    ${StyleStrip}
    ${FocusShadow}
    ${DefaultTransition}
    ${IconContainerStyle}
`;

const IconButtonContainer = styled.button`
    ${StyleStrip}
    ${FocusShadow}
    ${DefaultTransition}
    ${IconContainerStyle}

    // Change mouse appearance on hover
    cursor: pointer;
`;

// Common style for both IconWithTextLink and IconWithTextButton
const IconWithTextContainerStyle = css`
    ${StyleStrip}
    ${FocusShadow}
    ${DefaultTransition}

    // Align icon and link side-by-side
    display: flex;
    align-items: center;
    vertical-align: baseline;

    // Make sure container occupies entire width of Dropdown
    width: 100%;
    padding: 6px 16px;

    // Style container elements
    color: ${ColorScheme.blue4.color};
    cursor: pointer;

    // Change background color when user hovers over Icon
    &:hover {
        background-color: ${ColorScheme.gray2.color};
    }

    > * {
        &:first-child {
            margin-right: 8px;
        }
    }
`;

const Container = styled.button`
    ${IconWithTextContainerStyle}
`;

const LabelLink = styled(Link)`
    ${IconWithTextContainerStyle}
`;

const LabelAnchor = styled.a`
    ${IconWithTextContainerStyle}
`;

const svgDropShadow = css`
    filter: drop-shadow(0px 2px 2px ${ColorScheme.shadowBlack.color});
    ${DefaultTransition}

    &:hover {
        filter: drop-shadow(0px 4px 4px ${ColorScheme.shadowBlack.color});
    }

    &:active {
        filter: none;
    }
`;

const SVG = styled.svg`
    width: ${(props) => (props.size ? props.size : "16px")};
    height: ${(props) => (props.size ? props.size : "16px")};

    ${(props) => props.shadow && svgDropShadow}
`;

export const Icon = ({ iconHref, color, size, shadow }) => (
    <IconContainer>
        <SVG size={size} shadow={shadow}>
            <use
                fill={`${color ? color : ColorScheme.blue4.color}`}
                href={iconHref}
            />
        </SVG>
    </IconContainer>
);

export const IconWithTextLink = ({ iconHref, dest, color, destText }) => (
    <LabelLink to={dest}>
        <SVG>
            <use
                fill={`${color ? color : ColorScheme.blue4.color}`}
                href={iconHref}
            />
        </SVG>
        {destText}
    </LabelLink>
);

export const IconWithTextAnchor = ({
    iconHref,
    dest,
    color,
    destText,
    newTab,
}) => (
    <LabelAnchor href={dest} target={newTab ? "_blank" : "_self"}>
        <SVG>
            <use
                fill={`${color ? color : ColorScheme.blue4.color}`}
                href={iconHref}
            />
        </SVG>
        {destText}
    </LabelAnchor>
);

export const IconWithTextButton = ({ iconHref, action, color, actionText }) => (
    <Container onClick={action}>
        <SVG>
            <use
                fill={`${color ? color : ColorScheme.blue4.color}`}
                href={iconHref}
            />
        </SVG>
        {actionText}
    </Container>
);

const IconButton = ({ iconHref, action, color, size, shadow }) => (
    <IconButtonContainer onClick={() => action()}>
        <SVG size={size} shadow={shadow}>
            <use
                fill={`${color ? color : ColorScheme.blue4.color}`}
                href={iconHref}
            />
        </SVG>
    </IconButtonContainer>
);

export default IconButton;
