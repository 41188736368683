import React from "react";
import { Field } from "react-final-form";
import styled, { css } from "styled-components";
import { DraftConfig } from "../../../js/constants";
import { customOrderList, numRounds } from "../../../js/util";
import {
    RowContainer,
    TwoColumnFieldStyle,
    TwoColumnTable,
} from "../../common/Table";
import ColorScheme from "../../styles/colors";
import Defaults, { FocusShadow } from "../../styles/defaults";
import Detail from "../common/Detail";

const RowStyle = css`
    // Style input text
    font-family: inherit;
    font-size: 18px;

    // Give space between input text and bounding box
    padding: 8px 16px;

    // Style bounding box
    width: ${(props) => (props.width ? props.width : "100%")};
    outline: none;
    border: none;
    border-radius: ${Defaults.borderRadius};

    background-color: transparent;
`;

const RoundLabel = styled.label`
    ${TwoColumnFieldStyle}
    ${RowStyle}

    color: ${ColorScheme.blue6.color};
    cursor: pointer;
`;

const FieldContainer = styled.div`
    ${TwoColumnFieldStyle}
`;

const Select = styled.select`
    ${FocusShadow}
    ${RowStyle}

    width: ${(props) => (props.width ? props.width : "100%")};
    outline: none;
    border: none;

    color: ${ColorScheme.blue4.color};
`;

const Row = ({ index }) => (
    <RowContainer index={index}>
        <FieldContainer>
            <RoundLabel htmlFor={`round-${index + 1}`}>
                {`Round ${index + 1}`}
            </RoundLabel>
        </FieldContainer>
        <FieldContainer>
            <Field name={`customDraftOrder[${index}]`}>
                {({ input }) => (
                    <Select id={`round-${index + 1}`} width="auto" {...input}>
                        <option value={DraftConfig.SNAKE}>
                            {DraftConfig.SNAKE}
                        </option>
                        <option value={DraftConfig.LINEAR}>
                            {DraftConfig.LINEAR}
                        </option>
                    </Select>
                )}
            </Field>
        </FieldContainer>
    </RowContainer>
);

const rows = (totalRounds) => {
    const rows = [];
    for (let i = 0; i < totalRounds; i++) {
        rows.push(<Row key={`custom-draft-round-${i}`} index={i} />);
    }
    return rows;
};

export const CustomDraftSummary = ({ order, numRounds }) => {
    const draftOrder = [];
    const addToOrder = (label, order) =>
        draftOrder.push(
            <Detail
                labelText={label}
                fieldText={order}
                aligned
                key={`customdraftorder-${draftOrder.length + 1}`}
            />
        );

    const customDraftOrder = customOrderList(order, numRounds);
    let currOrder = customDraftOrder[0];
    let startRound = 1;
    for (let i = 0; i < customDraftOrder.length; i++) {
        if (customDraftOrder[i] !== currOrder) {
            let label = `Rounds ${startRound}-${i}`;
            if (i === startRound) label = `Round ${startRound}`;
            addToOrder(label, currOrder);

            // Setup next iteration
            currOrder = customDraftOrder[i];
            startRound = i + 1;
        }
        if (i === customDraftOrder.length - 1) {
            let label = `Rounds ${startRound}-${i + 1}`;
            if (i + 1 === startRound) label = `Round ${startRound}`;
            addToOrder(label, currOrder);
        }
    }
    return <>{draftOrder}</>;
};

const CustomDraftOrder = ({ positions, draftType, idp, draftConfig }) => (
    <TwoColumnTable headerFields={["Round", "Draft Order"]}>
        {rows(numRounds(positions, draftType, idp, draftConfig))}
    </TwoColumnTable>
);

export default CustomDraftOrder;
