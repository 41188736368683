import React, { useState } from "react";
import { Field, Form, useFormState } from "react-final-form";
import { connect } from "react-redux";
import styled from "styled-components";
import { editDraft, editOpen } from "../../../actions/draftdetails";
import {
    CREATE_DRAFT_DEFAULT,
    DraftClass,
    DraftStatus,
    MAX_TIMER_DURATION,
    MIN_TIMER_DURATION,
    PremiumFeatures,
} from "../../../js/constants";
import { featureEnabled } from "../../../js/util";
import { VerticalContainer } from "../../common/Container";
import { NumberField } from "../../common/Field";
import { Checkbox } from "../../common/Input";
import ColorScheme from "../../styles/colors";
import Detail from "../common/Detail";
import { DetailsContainer, Header } from "../common/DetailsContainer";
import { GridOption } from "../common/GridDescriptionOption";
import {
    InputLabel,
    LabeledFieldContainer,
    LabeledValueDivision,
    OffsetFieldContainer,
    OffsetLabelContainer,
} from "../common/Label";
import { TradePicksForm, TradePicksInfo } from "./TradePicks";

const NUMBER_INPUT_WIDTH = "96px";

const FeatureContainer = styled.div`
    margin-top: 12px;
`;

// NOTE: Body keys must be kept in sync with EditDraft API request
const editDraftDetails = (draft, editDraft, displayInfo) => async (values) => {
    const body = {
        timer: { enabled: values.timer.enabled },
        keeper: values.keeper,
        tradePicks: values.tradePicks,
        remoteDraft: values.remoteDraft,
    };
    if (values.timer.enabled) {
        body.timer.duration = parseInt(values.timer.duration);
    }
    if (!values.tradePicks) body.trades = [];
    else if (values.trades) body.trades = values.trades;

    // FIXME: Reflect error if something fails
    await editDraft(body, draft.draftID);
    displayInfo();
};

const DurationLabel = styled.label`
    font-size: 16px;
    color: ${ColorScheme.blue4.color};
    cursor: pointer;
`;

const TimerDuration = ({ initial }) => (
    <LabeledFieldContainer>
        <OffsetLabelContainer>
            <DurationLabel htmlFor="timerDuration">
                Duration (seconds)
            </DurationLabel>
        </OffsetLabelContainer>
        <OffsetFieldContainer>
            <NumberField
                initial={initial}
                fieldName="timer.duration"
                label="timerDuration"
                printField="Duration"
                min={MIN_TIMER_DURATION.toString()}
                max={MAX_TIMER_DURATION.toString()}
                width={NUMBER_INPUT_WIDTH}
                small
            />
        </OffsetFieldContainer>
    </LabeledFieldContainer>
);

const Timer = ({ enabled, checked, setChecked, remoteDraftChecked }) => {
    const { values } = useFormState();
    const updateChecked = (onChange) => () => {
        setChecked(!checked);
        onChange(!checked);
    };
    return (
        <FeatureContainer>
            <GridOption>
                <Field name="timer.enabled" type="checkbox">
                    {({ input: { onChange } }) => (
                        <Checkbox
                            id="timer"
                            type="checkbox"
                            disabled={!enabled}
                            checked={checked}
                            onChange={updateChecked(onChange)}
                        />
                    )}
                </Field>
                <InputLabel htmlFor="timer" disabled={!enabled}>
                    {`Draft Timer${
                        remoteDraftChecked
                            ? " (Not Supported for Remote Drafts)"
                            : ""
                    }`}
                </InputLabel>
                {checked && <TimerDuration initial={values.timer.duration} />}
            </GridOption>
        </FeatureContainer>
    );
};

const RemoteDraft = ({
    enabled,
    checked,
    setChecked,
    timerChecked,
    draftType,
}) => {
    const updateChecked = (onChange) => () => {
        setChecked(!checked);
        onChange(!checked);
    };
    return (
        <FeatureContainer>
            <GridOption>
                <Field name="remoteDraft" type="checkbox">
                    {({ input: { onChange } }) => (
                        <Checkbox
                            id="Remote Draft"
                            type="checkbox"
                            disabled={!enabled}
                            checked={checked}
                            onChange={updateChecked(onChange)}
                        />
                    )}
                </Field>
                <InputLabel htmlFor="Remote Draft" disabled={!enabled}>
                    {`Remote Draft${
                        timerChecked && draftType === DraftClass.PREMIUM
                            ? " (Not Supported with Draft Timers)"
                            : ""
                    }`}
                </InputLabel>
            </GridOption>
        </FeatureContainer>
    );
};

const AdvancedFeature = ({ fieldName, label, enabled, children }) => {
    const { values } = useFormState();

    // Make checkbox a controlled input
    const [checked, setChecked] = useState(values[fieldName]);
    const updateChecked = (onChange) => () => {
        setChecked(!checked);
        onChange(!checked);
    };

    return (
        <FeatureContainer>
            <GridOption>
                <Field name={fieldName} type="checkbox">
                    {({ input: { onChange } }) => (
                        <Checkbox
                            id={label}
                            type="checkbox"
                            disabled={!enabled}
                            checked={checked}
                            onChange={updateChecked(onChange)}
                        />
                    )}
                </Field>
                <InputLabel htmlFor={label} disabled={!enabled}>
                    {label}
                </InputLabel>
                {checked && children}
            </GridOption>
        </FeatureContainer>
    );
};

// Need to move AllFeatures out into a separate component
// due to the way React re-renders components when props change (?)
const AllFeatures = ({ draft, draftConfig }) => {
    // Make timer and remote draft controlled inputs
    const [timerChecked, setTimerChecked] = useState(draft.timer.enabled);
    const [remoteDraftChecked, setRemoteDraftChecked] = useState(
        draft.remoteDraft
    );
    return (
        <>
            <DetailsContainer>
                <Timer
                    enabled={
                        featureEnabled(
                            PremiumFeatures.TIMER,
                            draft.draftType,
                            draftConfig
                        ) && !remoteDraftChecked
                    }
                    checked={timerChecked}
                    setChecked={setTimerChecked}
                    remoteDraftChecked={remoteDraftChecked}
                />
                <RemoteDraft
                    enabled={
                        featureEnabled(
                            PremiumFeatures.REMOTE_DRAFT,
                            draft.draftType,
                            draftConfig
                        ) && !timerChecked
                    }
                    checked={remoteDraftChecked}
                    setChecked={setRemoteDraftChecked}
                    draftType={draft.draftType}
                    timerChecked={timerChecked}
                />
                <AdvancedFeature
                    fieldName="keeper"
                    label="Keeper Draft"
                    enabled={featureEnabled(
                        PremiumFeatures.KEEPER,
                        draft.draftType,
                        draftConfig
                    )}
                />
                <AdvancedFeature
                    fieldName="tradePicks"
                    label="Trade Picks"
                    enabled={featureEnabled(
                        PremiumFeatures.TRADE_PICKS,
                        draft.draftType,
                        draftConfig
                    )}
                >
                    <TradePicksForm
                        trades={draft.trades}
                        draftOrder={draft.draftOrder}
                        rounds={draft.rounds}
                    />
                </AdvancedFeature>
            </DetailsContainer>
        </>
    );
};

const AdvancedFeaturesForm = ({
    draft,
    draftConfig,
    editDraft,
    displayInfo,
    loading,
}) => (
    <Form
        onSubmit={editDraftDetails(draft, editDraft, displayInfo)}
        initialValues={{
            timer: {
                enabled: draft.timer.enabled,
                duration:
                    draft.timer.duration || CREATE_DRAFT_DEFAULT.timer.duration,
            },
            remoteDraft: draft.remoteDraft,
            keeper: draft.keeper,
            tradePicks: draft.tradePicks,
        }}
        render={({ handleSubmit }) => (
            <VerticalContainer>
                <form onSubmit={handleSubmit}>
                    <Header
                        heading="Advanced Features"
                        primaryBtn={{
                            text: "Save",
                            disabled: loading,
                            loading,
                        }}
                        secondaryBtn={{
                            text: "Cancel",
                            disabled: loading,
                            onClick: displayInfo,
                        }}
                    />
                    <AllFeatures
                        draft={draft}
                        draftConfig={draftConfig}
                        displayInfo={displayInfo}
                        loading={loading}
                    />
                </form>
            </VerticalContainer>
        )}
    />
);

const AdvancedFeaturesInfo = ({
    draft,
    displayForm,
    loading,
    isCommissioner,
}) => (
    <VerticalContainer>
        <Header
            heading="Advanced Features"
            secondaryBtn={
                isCommissioner &&
                draft.status === DraftStatus.UPCOMING && {
                    text: "Edit",
                    onClick: displayForm,
                    disabled: loading,
                }
            }
        />
        <DetailsContainer>
            <LabeledValueDivision
                label="Draft Timer"
                value={draft.timer.enabled ? "Enabled" : "Disabled"}
            >
                {draft.timer.enabled && (
                    <Detail
                        labelText="Duration"
                        fieldText={`${draft.timer.duration} seconds`}
                    />
                )}
            </LabeledValueDivision>
            <LabeledValueDivision
                label="Remote Draft"
                value={draft.remoteDraft ? "Enabled" : "Disabled"}
            />
            <LabeledValueDivision
                label="Keeper Draft"
                value={draft.keeper ? "Enabled" : "Disabled"}
            />
            <LabeledValueDivision
                label="Trade Picks"
                value={draft.tradePicks ? "Enabled" : "Disabled"}
            >
                {draft.tradePicks && (
                    <TradePicksInfo
                        trades={draft.trades}
                        draftOrder={draft.draftOrder}
                    />
                )}
            </LabeledValueDivision>
        </DetailsContainer>
    </VerticalContainer>
);

const AdvancedFeatures = ({
    draft,
    draftConfig,
    editDraft,
    editOpen,
    form,
    loading,
    isCommissioner,
}) => {
    const DRAFT_SETTINGS_FORM = "Advanced Features";
    const showForm = form === DRAFT_SETTINGS_FORM;
    const displayForm = () => editOpen(DRAFT_SETTINGS_FORM);
    const displayInfo = () => editOpen("");

    return showForm ? (
        <AdvancedFeaturesForm
            draft={draft}
            draftConfig={draftConfig}
            editDraft={editDraft}
            displayInfo={displayInfo}
            loading={loading}
        />
    ) : (
        <AdvancedFeaturesInfo
            draft={draft}
            displayForm={displayForm}
            loading={loading}
            isCommissioner={isCommissioner}
        />
    );
};

const mapStateToProps = (state) => {
    const newState = {
        form: state.draftdetails.form,
        loading: state.draftdetails.loading,
        draftConfig: state.config.draftConfig,
    };
    if (state?.draftdetails?.draft) {
        newState.draft = state.draftdetails.draft;
        if (state?.auth?.user && newState?.draft?.commissioners) {
            const userID = state.auth.user.username;
            const commissioners = newState.draft.commissioners;
            for (let i = 0; i < commissioners.length; i++) {
                if (commissioners[i].userID === userID) {
                    newState.isCommissioner = true;
                    break;
                }
            }
        }
    }
    return newState;
};

export default connect(mapStateToProps, { editDraft, editOpen })(
    AdvancedFeatures
);
