import { convertDateFormat, dateToString } from "./util";

// required returns an error if the field is empty
export const required = (value) => (value ? undefined : "Required");

// minLength returns an error if the strlength of the value for a given field
// is less than the given length
export const minLength = (field, len) => (value) =>
    value.length >= len
        ? undefined
        : `${field} must be at least ${len} characters long`;

// maxLength returns an error if the strlength of the value for a given field
// is greater than the given length
export const maxLength = (field, len) => (value) =>
    value.length <= len
        ? undefined
        : `${field} cannot be more than ${len} characters long`;

// minValue returns an error if the numeric value for the given field
// is less than the given value
export const minValue = (field, val) => (value) =>
    parseInt(value) >= val ? undefined : `${field} cannot be lower than ${val}`;

// maxValue returns an error if the numeric value for the given field
// is greater than the given value
export const maxValue = (field, val) => (value) =>
    parseInt(value) <= val
        ? undefined
        : `${field} cannot be higher than ${val}`;

// afterDate validates the value from a date input is after the provided date
export const afterDate = (date) => (value) => {
    if (!value) return undefined;
    const givenDate = dateToString(date);
    return value >= givenDate
        ? undefined
        : `Date must be after ${convertDateFormat(givenDate)}`;
};

// beforeDate validates the value from a date input is before the provided date
export const beforeDate = (date) => (value) => {
    if (!value) return undefined;
    const givenDate = dateToString(date);
    return value <= givenDate
        ? undefined
        : `Date must be before ${convertDateFormat(givenDate)}`;
};

// compose takes in multiple validators and chains them together to produce
// a single validator function
export const compose =
    (...validators) =>
    (value) =>
        validators.reduce(
            (err, validator) => err || validator(value),
            undefined
        );

// confirmCheck is a form level validator which validates
// that the values in the confirm + password fields match
export const confirmCheck = (values) => {
    const errors = {};
    if (!values.confirm) {
        errors.confirm = "Required";
    } else if (values.confirm !== values.password) {
        errors.confirm = "Must match";
    }
    return errors;
};
