import styled from "styled-components";

export const VerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
    > * {
        margin-top: ${(props) => (props.spacing ? props.spacing : "0px")};
        &:first-child {
            margin-top: 0px;
        }
    }
`;

export const TextContainer = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
