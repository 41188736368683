import React from "react";
import styled from "styled-components";
import ColorScheme from "../../styles/colors";
import Defaults from "../../styles/defaults";

export const InfoContainer = styled.div`
    width: 100%;
    margin: 24px auto 0px;
    padding: 24px 32px;

    // Style container
    background-color: ${ColorScheme.gray1.color};
    box-shadow: ${Defaults.shadow};
    border-radius: ${Defaults.borderRadius};

    display: flex;
    flex-direction: column;
`;

export const InfoContainerH2 = styled.h2`
    font-size: 18px;
    font-family: inherit;
    font-weight: 400;
    color: ${ColorScheme.blue5.color};
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`;

export const Header = ({ heading }) => (
    <HeaderContainer>
        <InfoContainerH2>{heading}</InfoContainerH2>
    </HeaderContainer>
);
