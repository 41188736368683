// Account Creation
export const VERIFY_EMAIL_DESCRIPTION =
    "Thank you for signing up. We've sent you a verification code which you can input below. Please check your email.";
export const VERIFY_EMAIL_DESCRIPTION_WITH_EMAIL =
    "Thank you for signing up. We've sent you a verification code which you can input below. Please check your email:";
export const REGISTER_SUCCESS_DESCRIPTION =
    "Congratulations! Your email has been verified. You can now sign in to your new LiveDraftX account.";

// Dashboard
export const NO_DRAFTS_DESCRIPTION =
    "Experience the future of fantasy drafting.";
export const DRAFT_DOES_NOT_EXIST = "Fumble! This draft does not exist.";

// Create Draft - Email Feature
export const EMAIL_NOTIFICATION_DESCRIPTION =
    "By default, LiveDraftX sends draft reminders and draft results to all teams whose emails you’ve provided.";
export const DISCOUNT_DESCRIPTION =
    "All New Members Receive $5 Off Their First Draft With Coupon Code";

// Create Draft - Draft Type
export const SNAKE_DESCRIPTION =
    "In a snake draft, the team who selects last in one round selects first in the next round.";
export const LINEAR_DESCRIPTION =
    "In a linear draft, the team who selects first in one round selects first in the next round.";
export const AUCTION_DESCRIPTION =
    "In an auction/salary cap draft, each team has a budget to use to bid on players to draft.";
export const CUSTOM_DESCRIPTION =
    "In a custom ordered draft, you can decide draft ordering on a per-round basis.";

// Draft Details - Trade Picks
export const TRADE_PICKS_WARNING_DESCRIPTION =
    "Please have your team order finalized before submitting trades.";

// Draft Created
export const DRAFT_CREATED_DESCRIPTION =
    "Thank you for choosing LiveDraftX for your fantasy draft. An email with the draft details will be sent to you shortly.";

// Create Draft - Advanced Features
export const TIMER_DESCRIPTION =
    "Set a time limit on how long it takes to make a draft choice.";
export const KEEPER_DESCRIPTION =
    "Enable the selection of keeper picks. You can submit keepers once the draft is created.";
export const TRADE_PICKS_DESCRIPTION =
    "Allow teams to trade draft picks. You can submit traded picks once the draft is created.";
export const REMOTE_DRAFT_DESCRIPTION =
    "Allow league members to make draft picks remotely from their LiveDraftX account.";
export const TIMER_ERROR =
    "Draft timer is not currently supported for remote drafts.";
export const REMOTE_DRAFT_ERROR =
    "Remote draft is not currently supported for drafts with timers.";

// Draftboard
export const IN_PROGRESS_DESCRIPTION = "Draft is currently in progress";
export const EXIT_DRAFT_DESCRIPTION =
    "Are you sure you want to exit the draft?";
export const FINISH_DRAFT_DESCRIPTION =
    "Are you sure you want to finish the draft?";
export const COMPLETE_DRAFT_DESCRIPTION =
    "Congratulations! Your draft is now completed. Your draft results will be emailed to you shortly.";
