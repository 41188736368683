import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { filterDrafts } from "../../../actions/getdrafts";
import { DraftStatus } from "../../../js/constants";
import { statuslabel } from "../../../js/util";
import { StyleStrip } from "../../styles/Reset";
import { SideColumnContainer, SideColumnElement } from "../common/PageLayout";

const statuses = [
    DraftStatus.ALL,
    DraftStatus.IN_PROGRESS,
    DraftStatus.UPCOMING,
    DraftStatus.COMPLETED,
];

const Status = styled.button`
    ${StyleStrip}
    cursor: ${(props) => (props.selected ? "default" : "pointer")};
`;

const StatusFilter = ({ status, displayStatus, filterDrafts, selected }) => (
    <SideColumnElement selected={status === displayStatus}>
        <Status
            selected={selected}
            onClick={() => !selected && filterDrafts(displayStatus)}
        >
            {statuslabel(displayStatus)}
        </Status>
    </SideColumnElement>
);

const Sidebar = ({ status, filterDrafts }) => {
    const sidebarElements = [];
    for (let i = 0; i < statuses.length; i++) {
        const selected = status === statuses[i];
        sidebarElements.push(
            <StatusFilter
                status={status}
                displayStatus={statuses[i]}
                filterDrafts={filterDrafts}
                selected={selected}
                key={`${statuses[i]}${selected ? "-selected" : ""}`}
            />
        );
    }
    return <SideColumnContainer>{sidebarElements}</SideColumnContainer>;
};

const mapStateToProps = (state) => {
    if (state.getdrafts) {
        return { status: state.getdrafts.status };
    }
    return {};
};

export default connect(mapStateToProps, { filterDrafts })(Sidebar);
