import React, { useState } from "react";
import { useFormState } from "react-final-form";
import Line from "../../common/Line";
import { InfoContainer } from "../common/PageLayout";
import RosterSettings, {
    IDPSetting,
    rosterValidate,
    TotalRounds,
} from "../displaydraft/RosterSettings";
import CreateDraftForm from "./CreateDraftForm";

const Positions = ({ draftConfig }) => {
    const { values } = useFormState();
    const [showIDP, setShowIDP] = useState(values.idp);
    const idpAllowed = draftConfig[values.draftType].idp;
    return (
        <CreateDraftForm.Page>
            <InfoContainer>
                <RosterSettings
                    draftType={values.draftType}
                    draftConfig={draftConfig}
                    positions={values.positions}
                    showIDP={showIDP}
                />
                <Line />
                <TotalRounds
                    draftType={values.draftType}
                    draftConfig={draftConfig}
                    idp={showIDP}
                />
                <Line />
                <IDPSetting
                    showIDP={showIDP}
                    setShowIDP={setShowIDP}
                    idpAllowed={idpAllowed}
                />
            </InfoContainer>
        </CreateDraftForm.Page>
    );
};

export const rosterFormValidate = (draftConfig) => (values) => {
    return rosterValidate(draftConfig, values.draftType, values.idp)(values);
};

export default Positions;
