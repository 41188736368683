import React, { useState } from "react";
import { Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { connect } from "react-redux";
import { editDraft, editOpen } from "../../../actions/draftdetails";
import { DraftStatus } from "../../../js/constants";
import { draftOrder } from "../../../js/util";
import { VerticalContainer } from "../../common/Container";
import { DetailsContainer, Header } from "../common/DetailsContainer";
import { LabeledValueDivision } from "../common/Label";
import DraftOrder, {
    DraftOrderSummary,
    LeagueSize,
} from "../displaydraft/DraftOrder";

// NOTE: Body keys must be kept in sync with EditDraft API request
const editDraftDetails =
    (draftID, editDraft, displayInfo) => async (values) => {
        const newDraftOrder = draftOrder(values.leagueSize, values.draftOrder);
        const body = { draftOrder: newDraftOrder };
        // FIXME: Reflect error if something fails
        await editDraft(body, draftID);
        displayInfo();
    };

const LeagueMembersForm = ({
    draft,
    draftConfig,
    editDraft,
    displayInfo,
    loading,
}) => {
    const LABEL = "leagueSize";
    const MIN_TEAMS = draftConfig[draft.draftType].minLeagueSize;
    const MAX_TEAMS = draftConfig[draft.draftType].maxLeagueSize;

    const [leagueSize, setMembers] = useState(draft.numPlayers);
    const updateLeagueSize = (size) => {
        if (!size) return;
        const leagueSize = parseInt(size);
        if (leagueSize < MIN_TEAMS || leagueSize > MAX_TEAMS) return;
        setMembers(size);
    };

    return (
        <Form
            onSubmit={editDraftDetails(draft.draftID, editDraft, displayInfo)}
            initialValues={{ leagueSize, draftOrder: draft.draftOrder }}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Header
                        heading="Draft Order"
                        primaryBtn={{
                            text: "Save",
                            disabled: loading,
                            loading,
                        }}
                        secondaryBtn={{
                            text: "Cancel",
                            disabled: loading,
                            onClick: displayInfo,
                        }}
                    />
                    <DetailsContainer>
                        <LeagueSize
                            label={LABEL}
                            fieldName={LABEL}
                            leagueSize={leagueSize}
                            MIN_TEAMS={MIN_TEAMS}
                            MAX_TEAMS={MAX_TEAMS}
                        />
                        <DraftOrder
                            leagueSize={leagueSize}
                            draftOrder={draft.draftOrder}
                        />
                        <OnChange name={LABEL}>
                            {(value) => updateLeagueSize(value)}
                        </OnChange>
                    </DetailsContainer>
                </form>
            )}
        />
    );
};

const LeagueMembersInfo = ({ draft, displayForm, loading, isCommissioner }) => (
    <VerticalContainer>
        <Header
            heading="Draft Order"
            secondaryBtn={
                isCommissioner &&
                draft.status === DraftStatus.UPCOMING && {
                    text: "Edit",
                    disabled: loading,
                    onClick: displayForm,
                }
            }
        />
        <DetailsContainer>
            <LabeledValueDivision
                label="League Size"
                value={draft.numPlayers}
            />
            <DraftOrderSummary
                leagueSize={draft.numPlayers}
                draftOrder={draft.draftOrder}
            />
        </DetailsContainer>
    </VerticalContainer>
);

const LeagueMembers = ({
    draft,
    draftConfig,
    editDraft,
    editOpen,
    form,
    loading,
    isCommissioner,
}) => {
    const LEAGUE_MEMBERS_FORM = "League Members";
    const showForm = form === LEAGUE_MEMBERS_FORM;
    const displayForm = () => editOpen(LEAGUE_MEMBERS_FORM);
    const displayInfo = () => editOpen("");

    return showForm ? (
        <LeagueMembersForm
            draft={draft}
            draftConfig={draftConfig}
            editDraft={editDraft}
            displayInfo={displayInfo}
            loading={loading}
        />
    ) : (
        <LeagueMembersInfo
            draft={draft}
            displayForm={displayForm}
            loading={loading}
            isCommissioner={isCommissioner}
        />
    );
};

const mapStateToProps = (state) => {
    const newState = {};
    if (state?.draftdetails?.draft) {
        newState.draft = state.draftdetails.draft;
        if (state?.auth?.user && newState?.draft?.commissioners) {
            const userID = state.auth.user.username;
            const commissioners = newState.draft.commissioners;
            for (let i = 0; i < commissioners.length; i++) {
                if (commissioners[i].userID === userID) {
                    newState.isCommissioner = true;
                    break;
                }
            }
        }
    }
    newState.form = state.draftdetails.form;
    newState.loading = state.draftdetails.loading;
    newState.draftConfig = state.config.draftConfig;
    return newState;
};

export default connect(mapStateToProps, { editDraft, editOpen })(LeagueMembers);
