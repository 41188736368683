import React, { Component } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { renderProfileDetails, updateUser } from "../../../actions/profile";
import { cognitoErrors } from "../../../js/errors";
import Button, { buttonStyle } from "../../common/Button";
import { VerticalContainer } from "../../common/Container";
import {
    ButtonContainer,
    DetailsContainer,
    Header,
} from "../common/DetailsContainer";
import { LabeledInput } from "../common/Label";

const errors = {
    ACCOUNT_ALREADY_EXISTS: {
        email: "An account already exists with this email address",
    },
};

class PersonalInfoForm extends Component {
    constructor(props) {
        super(props);
        this.updateInfo = async ({ email, name }) => {
            const error = await this.props.updateUser(email, name);
            if (!error) return;
            if (error === cognitoErrors.AliasExists) {
                return errors.ACCOUNT_ALREADY_EXISTS;
            }
        };
        this.cancel = (e) => {
            e.preventDefault();
            this.props.renderProfileDetails();
        };
    }

    // FIXME: Weird error where @ character jumps while typing "ktseng007@yahoo.com"
    render = () => (
        <Form
            onSubmit={this.updateInfo}
            initialValues={{
                email: this.props.user.attributes.email,
                name: this.props.user.attributes.name,
            }}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <VerticalContainer>
                        <Header heading="Personal Information" />
                        <DetailsContainer>
                            <LabeledInput
                                label="Email"
                                fieldName="email"
                                type="email"
                            />
                            <LabeledInput label="Name" fieldName="name" />
                            <ButtonContainer>
                                <Button
                                    size={buttonStyle.Size.MEDIUM}
                                    color={buttonStyle.Color.BLUE}
                                    priority={buttonStyle.Priority.SECONDARY}
                                    onClick={this.cancel}
                                    type="button"
                                    text="Cancel"
                                />
                                <Button
                                    size={buttonStyle.Size.MEDIUM}
                                    color={buttonStyle.Color.BLUE}
                                    priority={buttonStyle.Priority.PRIMARY}
                                    disabled={submitting}
                                    loading={
                                        this.props.updateUserStatus.loading
                                    }
                                    type="submit"
                                    text="Submit"
                                />
                            </ButtonContainer>
                        </DetailsContainer>
                    </VerticalContainer>
                </form>
            )}
        />
    );
}

const mapStateToProps = (state) => {
    const newState = {};
    if (state.auth) {
        newState.user = state.auth.user;
    }
    if (state.profile) {
        // Retrieve updateUser / updatePassword request status
        if (state.profile.updateUser) {
            newState.updateUserStatus = state.profile.updateUser;
        }
    }
    return newState;
};
export default connect(mapStateToProps, {
    renderProfileDetails,
    updateUser,
})(PersonalInfoForm);
