import React, { Component } from "react";
import styled from "styled-components";
import ColorScheme from "../../styles/colors";
import Defaults, { DefaultTransition } from "../../styles/defaults";

const containerInset = (height) => {
    const insetHeight = parseInt(height) + 12;
    return `${insetHeight}px 8px auto auto`;
};

const Container = styled.div`
    // Style container box
    background-color: ${ColorScheme.gray1.color};
    border-radius: ${Defaults.borderRadius};
    box-shadow: ${Defaults.shadow};

    // Confine width
    min-width: 192px;
    max-width: 256px;

    // Ensure border-radius always shows
    overflow: hidden;

    // Ensure dropdown elements are properly positioned
    padding: 6px 0px;
    font: 16px;

    // Position container 12px below element
    position: absolute;
    inset: ${(props) => containerInset(props.elementHeight)};

    // Align items vertically
    display: flex;
    flex-direction: column;

    // Only display dropdown if displaySelf is true
    // Create fade-in / fade-out effect
    visibility: ${(props) => (props.displaySelf ? "visible" : "hidden")};
    opacity: ${(props) => (props.displaySelf ? "1" : "0")};
    ${DefaultTransition}
`;

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        // Reset dropdown state during unmount
        this.props.hide();
        document.removeEventListener("click", this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        // Ignore events when dropdown is not currently shown
        if (!this.props.displaySelf) {
            return;
        }
        // If click happens outside of dropdown, call hide
        if (this.ref && !this.ref.current.contains(event.target)) {
            this.props.hide();
        }
    };

    render = () => (
        <Container
            displaySelf={this.props.displaySelf}
            ref={this.ref}
            elementHeight={this.props.elementHeight}
        >
            {this.props.children}
        </Container>
    );
}

export default Dropdown;
