import React from "react";
import styled from "styled-components";
import { IN_PROGRESS_DESCRIPTION } from "../../../js/descriptions";
import Logo from "../../common/Logo";
import { StyleReset } from "../../styles/Reset";
import ColorScheme from "../../styles/colors";

const Container = styled.div`
    width: 100%;
    height: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const LogoContainer = styled.div`
    width: 512px;
`;

const InProgressContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    color: ${ColorScheme.red3.color};
    font-family: inherit;
    font-weight: 600;
    font-size: 30px;
`;

const DraftInProgress = () => (
    <>
        <StyleReset bgcolor={ColorScheme.gray2.color} />
        <Container>
            <LogoContainer>
                <Logo />
            </LogoContainer>
            <InProgressContainer>{IN_PROGRESS_DESCRIPTION}</InProgressContainer>
        </Container>
    </>
);

export default DraftInProgress;
