import styled, { css } from "styled-components";
import ColorScheme from "../../styles/colors";
import Defaults from "../../styles/defaults";

const topmargin = (addTopMargin) => {
    if (addTopMargin) return "";
    return `> * {
        &:first-child {
            margin-top: 0px;
        }
    };`;
};

export const InfoContainer = styled.div`
    // Set width, margins, and padding for info container
    max-width: ${(props) => (props.small ? "640px" : "768px")};
    width: 100%;
    height: 100%;
    margin: 32px auto;
    padding: 24px 32px;

    // Style container
    background-color: ${ColorScheme.gray1.color};
    box-shadow: ${Defaults.shadow};
    border-radius: ${Defaults.borderRadius};

    // Remove top margin from first-child
    ${(props) => topmargin(props.addTopMargin)}
`;

export const InfoContainerH2 = styled.h2`
    font-size: 24px;
    font-family: inherit;
    font-weight: 400;
    color: ${ColorScheme.blue5.color};
`;

const maxWidthContainer = css`
    // Constrain width to 100%
    max-width: 1024px;
    width: 100%;
    height: 100%;

    // Make sure content is centered
    margin: 32px auto;
`;

const narrowWidthContainer = css`
    // Constrain width to 100%
    max-width: 768px;
    width: 100%;
    height: 100%;

    // Make sure content is centered
    margin: 32px auto;
`;

export const PageContainer = styled.div`
    ${maxWidthContainer}
`;

export const NarrowPageContainer = styled.div`
    ${narrowWidthContainer}
`;

export const TwoColumnContainer = styled.div`
    ${maxWidthContainer}

    // Position two columns to be side-by-side
    display: flex;
`;

export const SideColumnContainer = styled.ul`
    // Stick SideColumnContainer to the side
    position: sticky;
    top: 48px;

    // Set width + height of container
    max-width: 256px;
    width: 100%;
    height: 50vh;

    // Strip bullet points from list
    list-style-type: none;

    // Make sure list elements are sufficiently padded
    padding: 24px 32px 0px;

    // Have list elements displayed vertically
    display: flex;
    flex-direction: column;
`;

export const SideColumnElement = styled.li`
    font: inherit;
    font-size: 18px;
    color: ${(props) =>
        props.selected ? ColorScheme.blue5.color : ColorScheme.blue3.color};
`;

export const MainColumnContainer = styled.div`
    max-width: 768px;
    width: 100%;
`;
