import React from "react";
import styled, { css } from "styled-components";
import ColorScheme from "../styles/colors";
import Defaults from "../styles/defaults";

export const TableContainer = styled.div`
    // Set width
    max-width: 640px;
    width: 100%;

    // Center table and add margin between it and previous component
    margin: 32px auto 0;

    // Style table border
    border: 1px solid ${ColorScheme.gray2.color};
    border-radius: ${Defaults.borderRadius};

    // Make sure table rows don't hide table border
    overflow: hidden;

    // Align table items vertically
    display: flex;
    flex-direction: column;
`;

export const HeaderContainer = styled.div`
    // Style header box
    background-color: ${ColorScheme.gray3.color};

    // Style header text
    color: ${ColorScheme.blue7.color};
    font-size: 20px;

    // Align header fields horizontally
    display: flex;
    align-items: baseline;

    // Add header shadow
    box-shadow: ${Defaults.shadow};

    // Make sure background shadow sits above table rows
    z-index: 1;
`;

export const RowsContainer = styled.div`
    // Set background color
    background-color: ${ColorScheme.gray1.color};

    // Align rows vertically
    display: flex;
    flex-direction: column;
`;

export const RowContainer = styled.div`
    margin-top: 4px;

    // Set background color so that it alternates rows
    background-color: ${(props) =>
        props.index % 2 ? ColorScheme.gray2.color : "transparent"};

    // Align row fields horizontally
    display: flex;
    align-items: baseline;
`;

export const TwoColumnFieldStyle = css`
    // Align table fields
    flex: 50%;
`;

const TwoColumnHeaderField = styled.div`
    ${TwoColumnFieldStyle}
    padding: 4px 16px;
`;

export const ThreeColumnFirstFieldStyle = css`
    flex: 15%;
`;

export const ThreeColumnFieldStyle = css`
    flex: 42.5%;
`;

const ThreeColumnHeaderFirstField = styled.div`
    ${ThreeColumnFirstFieldStyle}
    padding: 4px 16px;
`;

const ThreeColumnHeaderField = styled.div`
    ${ThreeColumnFieldStyle}
    padding: 4px 16px;
`;

export const TwoColumnTable = ({ headerFields, children }) => (
    <TableContainer>
        <HeaderContainer>
            <TwoColumnHeaderField>{headerFields[0]}</TwoColumnHeaderField>
            <TwoColumnHeaderField>{headerFields[1]}</TwoColumnHeaderField>
        </HeaderContainer>
        <RowsContainer>{children}</RowsContainer>
    </TableContainer>
);

export const ThreeColumnTable = ({ headerFields, children }) => (
    <TableContainer>
        <HeaderContainer>
            <ThreeColumnHeaderFirstField>
                {headerFields[0]}
            </ThreeColumnHeaderFirstField>
            <ThreeColumnHeaderField>{headerFields[1]}</ThreeColumnHeaderField>
            <ThreeColumnHeaderField>{headerFields[2]}</ThreeColumnHeaderField>
        </HeaderContainer>
        <RowsContainer>{children}</RowsContainer>
    </TableContainer>
);
