import React, { Component } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { renderProfileDetails, updatePassword } from "../../../actions/profile";
import { MIN_PASSWORD_LENGTH } from "../../../js/constants";
import { cognitoErrors } from "../../../js/errors";
import { compose, minLength, required } from "../../../js/formhelper";
import Button, { buttonStyle } from "../../common/Button";
import { VerticalContainer } from "../../common/Container";
import {
    ButtonContainer,
    DetailsContainer,
    Header,
} from "../common/DetailsContainer";
import { LabeledInput } from "../common/Label";

const errors = {
    INCORRECT_PASSWORD: { current: "Incorrect password" },
    LIMIT_EXCEEDED: { current: "Attempt limit exceeded. Please wait" },
};

class UpdatePasswordForm extends Component {
    constructor(props) {
        super(props);
        this.updatePassword = async ({ current, password }) => {
            const error = await this.props.updatePassword(current, password);
            if (!error) return;
            if (error === cognitoErrors.NotAuthorized) {
                return errors.INCORRECT_PASSWORD;
            } else if (error === cognitoErrors.LimitExceeded) {
                return errors.LIMIT_EXCEEDED;
            }
        };
        this.cancel = (e) => {
            e.preventDefault();
            this.props.renderProfileDetails();
        };
    }

    render = () => (
        <Form
            onSubmit={this.updatePassword}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <VerticalContainer>
                        <Header heading="Update Password" />
                        <DetailsContainer>
                            <LabeledInput
                                label="Current Password"
                                fieldName="current"
                                type="password"
                                validate={required}
                            />
                            <LabeledInput
                                label="New Password"
                                fieldName="password"
                                type="password"
                                minLength={MIN_PASSWORD_LENGTH}
                                validate={compose(
                                    required,
                                    minLength(
                                        "New Password",
                                        MIN_PASSWORD_LENGTH
                                    )
                                )}
                            />
                            <ButtonContainer>
                                <Button
                                    size={buttonStyle.Size.MEDIUM}
                                    color={buttonStyle.Color.BLUE}
                                    priority={buttonStyle.Priority.SECONDARY}
                                    onClick={this.cancel}
                                    type="button"
                                    text="Cancel"
                                />
                                <Button
                                    size={buttonStyle.Size.MEDIUM}
                                    color={buttonStyle.Color.BLUE}
                                    priority={buttonStyle.Priority.PRIMARY}
                                    disabled={submitting}
                                    loading={
                                        this.props.updatePasswordStatus.loading
                                    }
                                    type="submit"
                                    text="Submit"
                                />
                            </ButtonContainer>
                        </DetailsContainer>
                    </VerticalContainer>
                </form>
            )}
        />
    );
}

const mapStateToProps = (state) => {
    const newState = {};
    if (state.profile && state.profile.updatePassword) {
        newState.updatePasswordStatus = state.profile.updatePassword;
    }
    return newState;
};
export default connect(mapStateToProps, {
    renderProfileDetails,
    updatePassword,
})(UpdatePasswordForm);
