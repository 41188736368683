import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
    HOMEPAGE_URL,
    LOGO_BLACK_PICTURE_URL,
    LOGO_PICTURE_URL,
    LOGO_WHITE_PICTURE_URL,
} from "../../js/constants";

const Img = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const ImgLink = styled(Link)`
    // Center logo inside container
    display: flex;
    align-items: center;
`;

const Logo = ({ link, color, href }) => {
    const imgSrc =
        color === "white"
            ? LOGO_WHITE_PICTURE_URL
            : color === "black"
            ? LOGO_BLACK_PICTURE_URL
            : LOGO_PICTURE_URL;
    if (link) {
        if (!href) {
            return (
                <a href={HOMEPAGE_URL}>
                    <Img src={imgSrc} alt="LiveDraftX Logo" />
                </a>
            );
        }
        return (
            <ImgLink to={href}>
                <Img src={imgSrc} alt="LiveDraftX Logo" />
            </ImgLink>
        );
    }
    return <Img src={imgSrc} alt="LiveDraftX Logo" />;
};

export default Logo;
