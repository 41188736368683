import React, { useState } from "react";
import { Field, useFormState } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { MAX_LEAGUE_LENGTH } from "../../../js/constants";
import {
    afterDate,
    compose,
    maxLength,
    required,
} from "../../../js/formhelper";
import { dateToString } from "../../../js/util";
import Input, { FieldError, inputError } from "../../common/Input";
import {
    InputLabel,
    LabeledFieldContainer,
    LabeledSectionContainer,
    OffsetFieldContainer,
    OffsetLabelContainer,
} from "../common/Label";
import { InfoContainer, InfoContainerH2 } from "../common/PageLayout";
import DraftOrder, { LeagueSize } from "../displaydraft/DraftOrder";
import CreateDraftForm from "./CreateDraftForm";
import EmailFeature from "./EmailFeature";

const INPUT_WIDTH = "384px";
const DRAFT_DATE_WIDTH = "192px";

const LeagueName = () => (
    <LabeledFieldContainer>
        <OffsetLabelContainer>
            <InputLabel htmlFor="name">League Name</InputLabel>
        </OffsetLabelContainer>
        <OffsetFieldContainer>
            <Field
                name="name"
                validate={compose(
                    required,
                    maxLength("League Name", MAX_LEAGUE_LENGTH)
                )}
            >
                {({ input, meta }) => {
                    const { error, showError } = inputError(meta);
                    return (
                        <>
                            <Input
                                id="name"
                                type="text"
                                width={INPUT_WIDTH}
                                error={showError}
                                {...input}
                            />
                            {showError && <FieldError>{error}</FieldError>}
                        </>
                    );
                }}
            </Field>
        </OffsetFieldContainer>
    </LabeledFieldContainer>
);

const DraftDate = ({ today }) => (
    <LabeledSectionContainer>
        <OffsetLabelContainer>
            <InputLabel htmlFor="draftDate">Draft Date</InputLabel>
        </OffsetLabelContainer>
        <OffsetFieldContainer>
            <Field name="draftDate" validate={afterDate(today)}>
                {({ input, meta }) => {
                    const { error, showError } = inputError(meta);
                    return (
                        <>
                            <Input
                                id="draftDate"
                                type="date"
                                width={DRAFT_DATE_WIDTH}
                                error={showError}
                                min={dateToString(today)}
                                {...input}
                            />
                            {showError && <FieldError>{error}</FieldError>}
                        </>
                    );
                }}
            </Field>
        </OffsetFieldContainer>
    </LabeledSectionContainer>
);

const EnterDraftDetails = ({ draftConfig }) => {
    const { values } = useFormState();
    const limit = draftConfig[values.draftType];
    const MIN_TEAMS = limit["minLeagueSize"];
    const MAX_TEAMS = limit["maxLeagueSize"];
    const LABEL = "leagueSize";
    const today = new Date();

    // Create state to share league size between LeagueSize / DraftOrder components
    const [leagueSize, setMembers] = useState(values.leagueSize);
    const updateLeagueSize = (size) => {
        if (!size) return;
        const leagueSize = parseInt(size);
        if (leagueSize < MIN_TEAMS || leagueSize > MAX_TEAMS) return;
        setMembers(size);
    };

    return (
        <CreateDraftForm.Page>
            <InfoContainer>
                <LeagueName />
                <DraftDate today={today} />
            </InfoContainer>
            <InfoContainer>
                <InfoContainerH2>Draft Order</InfoContainerH2>
                <LeagueSize
                    label={LABEL}
                    fieldName={LABEL}
                    leagueSize={leagueSize}
                    MIN_TEAMS={MIN_TEAMS}
                    MAX_TEAMS={MAX_TEAMS}
                />
                <DraftOrder
                    leagueSize={leagueSize}
                    draftOrder={values.draftOrder}
                />
                <OnChange name={LABEL}>
                    {(value) => updateLeagueSize(value)}
                </OnChange>
            </InfoContainer>
            <EmailFeature />
        </CreateDraftForm.Page>
    );
};

export default EnterDraftDetails;
