import React from "react";
import { connect } from "react-redux";
import { start } from "../../../actions/draftboard";
import { buttonStyle } from "../../common/Button";
import ButtonLabel from "./ButtonLabel";

const StartDraft = ({ start, startingDraft, testdrive }) => (
    <ButtonLabel
        label={"Start Draft"}
        action={() => start(testdrive)}
        buttonText={"Start"}
        buttonColor={buttonStyle.Color.GREEN}
        loading={startingDraft}
    />
);

const mapStateToProps = (state) => ({
    startingDraft: state.draftboard?.draftboard?.loading?.startingDraft,
});
export default connect(mapStateToProps, { start })(StartDraft);
