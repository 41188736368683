import React, { Component } from "react";
import { connect } from "react-redux";
import { profileInit } from "../../actions/profile";
import LoadingPage from "../common/LoadingPage";
import { StyleReset } from "../styles/Reset";
import Header from "./common/Header";
import PageHeader from "./common/PageHeader";
import { InfoContainer, PageContainer } from "./common/PageLayout";
import EmailPreference from "./profile/EmailPreference";
import PersonalInfo from "./profile/PersonalInfo";
import PersonalInfoForm from "./profile/PersonalInfoForm";
import UpdatePasswordForm from "./profile/UpdatePasswordForm";
import VerifyForm from "./profile/VerifyForm";

class Profile extends Component {
    componentDidMount = () => {
        this.props.profileInit();
    };

    renderPersonalInfo = () => {
        if (!this.props.user) return null;
        if (this.props.shouldRenderForm) return <PersonalInfoForm />;
        if (this.props.shouldRenderPassword) return <UpdatePasswordForm />;
        if (this.props.shouldRenderVerify) return <VerifyForm />;
        return <PersonalInfo />;
    };

    render = () => {
        if (!this.props.emailPreference) return <LoadingPage />;
        return (
            <>
                <StyleReset />
                <Header />
                <PageContainer>
                    <PageHeader heading="Profile" />
                    <InfoContainer>{this.renderPersonalInfo()}</InfoContainer>
                    <EmailPreference />
                </PageContainer>
            </>
        );
    };
}

const mapStateToProps = (state) => {
    const newState = {};
    if (state.auth) {
        newState.user = state.auth.user;
    }
    if (state.profile) {
        // Retrieve user metadata
        if (state.profile?.metadata) {
            const { emailPreference } = state.profile.metadata;
            newState.emailPreference = emailPreference;
        }

        // Retrieve render state
        newState.shouldRenderForm = state.profile.shouldRenderForm;
        newState.shouldRenderPassword = state.profile.shouldRenderPassword;
        newState.shouldRenderVerify = state.profile.shouldRenderVerify;
    }
    return newState;
};
export default connect(mapStateToProps, { profileInit })(Profile);
