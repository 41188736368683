import { RestAPI } from "@aws-amplify/api-rest";
import { cognitoAuthHeader } from "../js/auth";
import { FILTER_DRAFTS, GET_DRAFTS } from "./types";

export const filterDrafts = (status) => ({ type: FILTER_DRAFTS, status });

export const getDrafts = () => async (dispatch, getState) => {
    const currState = getState();
    if (currState.auth?.user?.username) {
        const userID = currState.auth.user.username;
        try {
            // Send GetDrafts API request
            const request = { headers: await cognitoAuthHeader() };
            const requestURL = `/user/${userID}/drafts`;
            const response = await RestAPI.get(
                process.env.API_NAME,
                requestURL,
                request
            );
            dispatch({ type: GET_DRAFTS, drafts: response.drafts });
        } catch (error) {
            return { field: "internal" };
        }
    }
};
