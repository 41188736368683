import React from "react";
import styled from "styled-components";
import { DraftStatus } from "../../../js/constants";
import Button, { buttonStyle } from "../../common/Button";

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
        &:first-child {
            margin-right: 12px;
        }
        &:only-child {
            margin-right: 0px;
        }
    }

    // Need ButtonContainer to always occupy button height
    height: 48px;
`;

const Buttons = ({
    form,
    startDraft,
    draftResults,
    submitKeepers,
    keeper,
    isCommissioner,
    isMember,
    status,
    remoteDraft,
}) => {
    // Commissioner does not necessarily mean they are a member
    if (!isMember && !isCommissioner) return null;

    // Show Draft Results button, if draft is already completed
    if (status === DraftStatus.COMPLETED) {
        return (
            <ButtonContainer>
                <Button
                    size={buttonStyle.Size.LARGE}
                    color={buttonStyle.Color.BLUE}
                    priority={buttonStyle.Priority.PRIMARY}
                    onClick={draftResults}
                    type="button"
                    text="Draft Results"
                />
            </ButtonContainer>
        );
    }

    // If currently editing draft, don't show buttons
    if (form !== "") return <ButtonContainer />;

    if (isCommissioner) {
        return (
            <ButtonContainer>
                <Button
                    size={buttonStyle.Size.LARGE}
                    color={buttonStyle.Color.BLUE}
                    priority={buttonStyle.Priority.PRIMARY}
                    onClick={startDraft}
                    type="button"
                    text={
                        status === DraftStatus.UPCOMING
                            ? "Start Draft"
                            : "Resume Draft"
                    }
                />
                {keeper && status === DraftStatus.UPCOMING && (
                    <Button
                        size={buttonStyle.Size.LARGE}
                        color={buttonStyle.Color.BLUE}
                        priority={buttonStyle.Priority.SECONDARY}
                        onClick={submitKeepers}
                        type="button"
                        text="Submit Keepers"
                    />
                )}
            </ButtonContainer>
        );
    } else if (isMember) {
        // Don't allow non-commissioners to start draft / submit keepers
        // Don't allow members to enter draft unless it is a remote draft
        if (status !== DraftStatus.IN_PROGRESS || remoteDraft) {
            return (
                <ButtonContainer>
                    <Button
                        size={buttonStyle.Size.LARGE}
                        color={buttonStyle.Color.BLUE}
                        priority={buttonStyle.Priority.PRIMARY}
                        onClick={startDraft}
                        type="button"
                        text={
                            status === DraftStatus.UPCOMING
                                ? "View Draft"
                                : "Enter Draft"
                        }
                    />
                </ButtonContainer>
            );
        }
    }
    return null;
};

export const DeleteDraftButton = ({ deleteDraft }) => (
    <Button
        size={buttonStyle.Size.SMALL}
        color={buttonStyle.Color.RED}
        priority={buttonStyle.Priority.SECONDARY}
        onClick={deleteDraft}
        type="button"
        text="Delete"
    />
);

export default Buttons;
