import React, { useState } from "react";
import { Field } from "react-final-form";
import styled, { css } from "styled-components";
import { CANCEL_ICON } from "../../../js/constants";
import { TRADE_PICKS_WARNING_DESCRIPTION } from "../../../js/descriptions";
import Button, { buttonStyle } from "../../common/Button";
import IconButton from "../../common/IconButton";
import { FieldError, inputError } from "../../common/Input";
import {
    HeaderContainer,
    RowContainer,
    RowsContainer,
    TableContainer,
} from "../../common/Table";
import ColorScheme from "../../styles/colors";
import Defaults, { FocusShadow } from "../../styles/defaults";

// Check valid trades:
// 1. All trades must have at least two teams
// 2. A team cannot trade the same pick more than once
// 3. A team cannot trade with itself
const validate = (value) => {
    if (!value || value.length === 0) return undefined;
    const teamPickMap = {};
    for (let i = 0; i < value.length; i++) {
        const { picks } = value[i];
        if (picks.length < 2) {
            return "Trades must include two teams";
        }
        const currTradeTeams = new Set();
        for (let j = 0; j < picks.length; j++) {
            const { teamIndex, round } = picks[j];
            if (currTradeTeams.has(teamIndex)) {
                return "A team may not trade with itself";
            }
            if (!(teamIndex in teamPickMap)) {
                teamPickMap[teamIndex] = [round];
            } else {
                const playerPicks = teamPickMap[teamIndex];
                for (let k = 0; k < playerPicks.length; k++) {
                    if (round === playerPicks[k]) {
                        return "A team may not trade the same pick more than once";
                    }
                }
                teamPickMap[teamIndex].push(round);
            }
            currTradeTeams.add(teamIndex);
        }
    }
    return undefined;
};

const DescriptionContainer = styled.div`
    max-width: 640px;
    width: 100%;
    margin: 12px auto 0;
    font-size: 18px;
    color: ${ColorScheme.blue5.color};
    text-align: center;
`;

const RowStyle = css`
    // Style input text
    font-family: inherit;
    font-size: 18px;

    // Give space between input text and bounding box
    padding: 8px 0px;

    // Style bounding box
    width: ${(props) => (props.width ? props.width : "100%")};
    outline: none;
    border: none;
    border-radius: ${Defaults.borderRadius};

    background-color: transparent;
`;

const Select = styled.select`
    ${FocusShadow}
    ${RowStyle}

    width: ${(props) => (props.width ? props.width : "100%")};
    outline: none;
    border: none;

    color: ${ColorScheme.blue4.color};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
`;

const DeleteTradeButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    flex: 5%;
`;

const SpacerContainer = styled.div`
    flex: 5%;
    text-align: center;
`;

const PlaceholderContainer = styled.div`
    ${RowStyle}
    text-align: center;
`;

const ErrorContainer = styled.div`
    margin-top: 12px;
    display: flex;
    justify-content: center;
`;

const PickField = styled.div`
    display: flex;
    padding: 4px 16px;
    flex: 45%;
`;

const TeamField = styled.div`
    flex: 75%;
`;

const RoundField = styled.div`
    flex: 25%;
`;

const TradeDetailContainer = styled.div`
    display: flex;
    align-items: baseline;
`;

const TradeInfo = styled.div`
    font-family: inherit;
    color: ${ColorScheme.blue5.color};
`;

const TradeSeparator = styled.span`
    font-family: inherit;
    color: ${ColorScheme.blue3.color};
    margin: 0px 8px;
`;

const teamOptions = (teams) => {
    const options = [];
    for (let i = 0; i < teams.length; i++) {
        options.push(
            <option value={i} key={`team-${i + 1}-option`}>
                {teams[i]}
            </option>
        );
    }
    return options;
};

const roundOptions = (rounds) => {
    const options = [];
    for (let i = 0; i < rounds; i++) {
        options.push(
            <option value={i} key={`round-${i + 1}-option`}>
                {i + 1}
            </option>
        );
    }
    return options;
};

const DeleteTradeButton = ({ deleteTrade, index }) => (
    <DeleteTradeButtonContainer>
        <IconButton
            iconHref={CANCEL_ICON}
            action={() => deleteTrade(index)}
            color={ColorScheme.blue4.color}
            type="button"
        />
    </DeleteTradeButtonContainer>
);

const TeamInput = ({ teams, index, pickIndex, value, updateTrade }) => (
    <TeamField>
        <Select
            id={`round-${index + 1}`}
            width="auto"
            value={value}
            onChange={(event) => {
                updateTrade(index, pickIndex, event.target.value, null);
            }}
        >
            {teamOptions(teams)}
        </Select>
    </TeamField>
);

const RoundInput = ({ rounds, index, pickIndex, value, updateTrade }) => (
    <RoundField>
        <Select
            id={`round-${index + 1}`}
            width="auto"
            value={value}
            onChange={(event) => {
                updateTrade(index, pickIndex, null, event.target.value);
            }}
        >
            {roundOptions(rounds)}
        </Select>
    </RoundField>
);

const TradeRow = ({
    index,
    teams,
    rounds,
    value,
    deleteTrade,
    updateTrade,
}) => (
    <RowContainer index={index}>
        <DeleteTradeButton deleteTrade={deleteTrade} index={index} />
        <PickField>
            <TeamInput
                index={index}
                pickIndex={0}
                teams={teams}
                value={value && value.picks[0].teamIndex}
                updateTrade={updateTrade}
            />
            <RoundInput
                index={index}
                pickIndex={0}
                rounds={rounds}
                value={value && value.picks[0].round}
                updateTrade={updateTrade}
            />
        </PickField>
        <SpacerContainer>for</SpacerContainer>
        <PickField>
            <TeamInput
                index={index}
                pickIndex={1}
                teams={teams}
                value={value && value.picks[1].teamIndex}
                updateTrade={updateTrade}
            />
            <RoundInput
                index={index}
                pickIndex={1}
                rounds={rounds}
                value={value && value.picks[1].round}
                updateTrade={updateTrade}
            />
        </PickField>
    </RowContainer>
);

const TradePlaceholder = () => (
    <RowContainer index={0}>
        <PlaceholderContainer>No Trades Submitted</PlaceholderContainer>
    </RowContainer>
);

const AddTradeButton = ({ addTrade }) => (
    <ButtonContainer>
        <Button
            size={buttonStyle.Size.MEDIUM}
            color={buttonStyle.Color.BLUE}
            priority={buttonStyle.Priority.SECONDARY}
            onClick={addTrade}
            type="button"
            text="Add Trade"
        />
    </ButtonContainer>
);

const alltrades = (trades, teams, rounds, deleteTrade, updateTrade) => {
    const tradeRows = [];
    if (!trades || trades.length === 0) return [];
    for (let i = 0; i < trades.length; i++) {
        tradeRows.push(
            <TradeRow
                key={`trade-${i + 1}`}
                index={i}
                teams={teams}
                rounds={rounds}
                value={trades[i]}
                deleteTrade={deleteTrade}
                updateTrade={updateTrade}
            />
        );
    }
    return tradeRows;
};

const TradeDetail = ({ trade, draftOrder }) => {
    const teamString = (pickIndex) =>
        `${draftOrder[trade.picks[pickIndex].teamIndex].team}, Round ${
            trade.picks[pickIndex].round + 1
        }`;
    return (
        <TradeDetailContainer>
            <TradeInfo>{teamString(0)}</TradeInfo>
            <TradeSeparator>&#8212;</TradeSeparator>
            <TradeInfo>{teamString(1)}</TradeInfo>
        </TradeDetailContainer>
    );
};

export const TradePicksInfo = ({ trades, draftOrder }) => {
    // Collect all team names
    const teams = [];
    for (let i = 0; i < draftOrder.length; i++) {
        teams.push(draftOrder[i].team);
    }

    const tradeDetails = [];
    if (!trades || trades.length === 0) return null;
    for (let i = 0; i < trades.length; i++) {
        tradeDetails.push(
            <TradeDetail
                trade={trades[i]}
                draftOrder={draftOrder}
                key={`trade-info-${i + 1}`}
            />
        );
    }
    return tradeDetails;
};

export const TradePicksForm = ({ trades, draftOrder, rounds }) => {
    // Collect all team names
    const teams = [];
    for (let i = 0; i < draftOrder.length; i++) {
        teams.push(draftOrder[i].team);
    }

    // Create state to keep track of all trades
    // NOTE: Perform deep copy of trades or else redux store gets contaminated
    const [currTrades, setTrades] = useState(
        trades ? JSON.parse(JSON.stringify(trades)) : []
    );

    // Create helper functions to add/delete/update a trade
    const deleteTrade = (onChange) => (index) => {
        const newTrades = [...currTrades];
        newTrades.splice(index, 1);
        onChange(newTrades);
        setTrades(newTrades);
    };
    const addTrade = (onChange) => () => {
        const newTrades = currTrades.concat([
            {
                picks: [
                    { teamIndex: 0, round: 0 },
                    { teamIndex: 0, round: 0 },
                ],
            },
        ]);
        onChange(newTrades);
        setTrades(newTrades);
    };
    const updateTrade = (onChange) => (index, pickIndex, teamIndex, round) => {
        const newTrades = [...currTrades];
        if (teamIndex) {
            newTrades[index].picks[pickIndex].teamIndex = parseInt(teamIndex);
        } else if (round) {
            newTrades[index].picks[pickIndex].round = parseInt(round);
        }
        onChange(newTrades);
        setTrades(newTrades);
    };

    return (
        <Field name="trades" validate={validate}>
            {({ input: { onChange }, meta }) => {
                const { error, showError } = inputError(meta);
                return (
                    <>
                        <DescriptionContainer>
                            {TRADE_PICKS_WARNING_DESCRIPTION}
                        </DescriptionContainer>
                        <TableContainer>
                            <HeaderContainer>
                                {currTrades.length !== 0 && <SpacerContainer />}
                                <PickField>
                                    <TeamField>Team</TeamField>
                                    <RoundField>Round</RoundField>
                                </PickField>
                                <SpacerContainer />
                                <PickField>
                                    <TeamField>Team</TeamField>
                                    <RoundField>Round</RoundField>
                                </PickField>
                            </HeaderContainer>
                            <RowsContainer>
                                {currTrades.length === 0 ? (
                                    <TradePlaceholder />
                                ) : (
                                    alltrades(
                                        currTrades,
                                        teams,
                                        rounds,
                                        deleteTrade(onChange),
                                        updateTrade(onChange)
                                    )
                                )}
                            </RowsContainer>
                        </TableContainer>
                        <AddTradeButton addTrade={addTrade(onChange)} />
                        {showError && (
                            <ErrorContainer>
                                <FieldError>{error}</FieldError>
                            </ErrorContainer>
                        )}
                    </>
                );
            }}
        </Field>
    );
};
