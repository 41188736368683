import React, { Children, cloneElement, Component } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import styled from "styled-components";
import { signIn } from "../../../actions/auth";
import { createDraft } from "../../../actions/createdraft";
import { CREATE_DRAFT_DEFAULT, DraftConfig } from "../../../js/constants";
import history from "../../../js/history";
import { tracker } from "../../App";
import Button, { buttonStyle } from "../../common/Button";
import CheckoutModal from "../common/CheckoutModal";
import {
    MainColumnContainer,
    SideColumnContainer,
    SideColumnElement,
    TwoColumnContainer,
} from "../common/PageLayout";
import UpsellHeader from "../common/UpsellHeader";
import SelectDraft from "./SelectDraft";

const ButtonContainer = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    > * {
        &:first-child {
            margin-right: 12px;
        }
        &:only-child {
            margin-right: 0;
        }
    }
`;

const StyledForm = styled.form`
    width: 100%;
`;

class CreateDraftForm extends Component {
    static Page = ({ children }) => children;

    constructor(props) {
        super(props);
        this.state = {
            pageIndex: 0,
            showPortal: false,
            values: CREATE_DRAFT_DEFAULT,
        };
    }

    componentDidMount = () => {
        this.props.signIn(tracker);
    };

    nextPage = (values) => {
        this.setState((state) => ({
            pageIndex: Math.min(
                state.pageIndex + 1,
                this.props.children.length - 1
            ),
            values,
        }));
    };

    prevPage = () => {
        this.setState((state) => ({
            pageIndex: Math.max(state.pageIndex - 1, 0),
        }));
    };

    handleSubmit = async (values) => {
        const { children } = this.props;
        const { pageIndex } = this.state;
        const isLastPage = pageIndex === Children.count(children) - 1;

        if (!isLastPage) {
            return this.nextPage(values);
        }

        const result = await this.props.createDraft(values);
        if (!result) history.push("/drafts/created");
    };

    validate = (values) => {
        const activePage = Children.toArray(this.props.children)[
            this.state.pageIndex
        ];
        return activePage.props.validate
            ? activePage.props.validate(values)
            : {};
    };

    updateDraftType = (form, draftConfig) => (newDraftType) => {
        const { values } = form.getState();
        const config = draftConfig[newDraftType];
        const newValues = { ...values, draftType: newDraftType };
        // Reset league size
        if (values.leagueSize > config.maxLeagueSize) {
            newValues.leagueSize = config.maxLeagueSize;
        }
        // Reset draft config
        if (!config.validDraftTypes.includes(values.draftConfig)) {
            newValues.draftConfig = DraftConfig.SNAKE;
        }
        // Reset timer value
        if (values.timer.enabled && !config.timer) {
            newValues.timer = { ...values.timer, enabled: false };
        }
        // Reset non-supported features
        const paidFeatures = ["idp", "remoteDraft", "keeper", "tradePicks"];
        for (const feature of paidFeatures) {
            if (values[feature] && !config[feature]) {
                newValues[feature] = false;
            }
        }
        this.setState((state) => ({ ...state, values: newValues }));
    };

    openPortal = () =>
        this.setState((state) => ({ ...state, showPortal: true }));

    closePortal = () => {
        if (this.props.loading) return;
        this.setState((state) => ({ ...state, showPortal: false }));
    };

    renderFormProgress = () => {
        const { children } = this.props;
        const { pageIndex } = this.state;
        const elements = [];
        for (let i = 0; i < Children.toArray(children).length; i++) {
            const child = Children.toArray(children)[i];
            elements.push(
                <SideColumnElement
                    selected={i === pageIndex}
                    key={`create-draft-nav-${i}${
                        i === pageIndex && "-selected"
                    }`}
                >
                    {child.props.title}
                </SideColumnElement>
            );
        }
        return elements;
    };

    renderFormButtons = () => {
        const { children, draftConfig, loading } = this.props;
        const { pageIndex, values } = this.state;
        const isLastPage = pageIndex === Children.count(children) - 1;
        return (
            <ButtonContainer>
                {pageIndex > 0 && (
                    <Button
                        size={buttonStyle.Size.MEDIUM}
                        color={buttonStyle.Color.BLUE}
                        priority={buttonStyle.Priority.SECONDARY}
                        onClick={this.prevPage}
                        type="button"
                        text="Previous"
                    />
                )}
                {!isLastPage ? (
                    <Button
                        size={buttonStyle.Size.MEDIUM}
                        color={buttonStyle.Color.BLUE}
                        priority={buttonStyle.Priority.PRIMARY}
                        type="submit"
                        text="Next"
                        analytics={{ name: `create-draft-${pageIndex}` }}
                    />
                ) : draftConfig[values.draftType].price === 0 ? (
                    <Button
                        size={buttonStyle.Size.MEDIUM}
                        color={buttonStyle.Color.BLUE}
                        priority={buttonStyle.Priority.PRIMARY}
                        disabled={loading}
                        loading={loading}
                        type="submit"
                        text="Complete"
                        analytics={{ name: "create-draft-complete-free" }}
                    />
                ) : (
                    <Button
                        size={buttonStyle.Size.MEDIUM}
                        color={buttonStyle.Color.BLUE}
                        priority={buttonStyle.Priority.PRIMARY}
                        onClick={this.openPortal}
                        type="button"
                        text="Complete"
                        analytics={{ name: "create-draft-checkout" }}
                    />
                )}
            </ButtonContainer>
        );
    };

    renderForm = (handleSubmit, updateDraft) => {
        const { children, createDraft, draftConfig, loading } = this.props;
        const { pageIndex, values, showPortal } = this.state;
        const activePage = Children.toArray(children)[pageIndex];
        const isSummaryPage = activePage.props.title === "Summary";
        const price = draftConfig[values.draftType].price;

        return (
            <StyledForm onSubmit={handleSubmit}>
                <UpsellHeader
                    draftType={values.draftType}
                    heading={activePage.props.title}
                    updateDraftType={updateDraft}
                    show={!isSummaryPage}
                    showButton
                />
                {cloneElement(activePage, { draftConfig })}
                {this.renderFormButtons()}
                {showPortal && (
                    <CheckoutModal
                        open={showPortal}
                        close={this.closePortal}
                        loading={loading}
                        action={createDraft}
                        price={price}
                        values={values}
                    />
                )}
            </StyledForm>
        );
    };

    render = () => {
        const { draftConfig } = this.props;
        const { pageIndex, values } = this.state;
        return (
            <Form
                initialValues={values}
                validate={this.validate}
                onSubmit={this.handleSubmit}
                keepDirtyOnReinitialize
                render={({ handleSubmit, form }) => {
                    const updateDraft = this.updateDraftType(form, draftConfig);
                    return pageIndex === 0 ? (
                        <StyledForm onSubmit={handleSubmit}>
                            <SelectDraft
                                updateDraftType={updateDraft}
                                draftConfig={draftConfig}
                            />
                        </StyledForm>
                    ) : (
                        <TwoColumnContainer>
                            <SideColumnContainer>
                                {this.renderFormProgress()}
                            </SideColumnContainer>
                            <MainColumnContainer>
                                {this.renderForm(handleSubmit, updateDraft)}
                            </MainColumnContainer>
                        </TwoColumnContainer>
                    );
                }}
            />
        );
    };
}

const mapStateToProps = (state) => {
    const newState = {};
    if (state.auth) {
        newState.user = state.auth.user;
    }
    if (state.createdraft) {
        newState.loading = state.createdraft.loading;
    }
    return newState;
};
export default connect(mapStateToProps, { createDraft, signIn })(
    CreateDraftForm
);
