import React, { Component } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { forgotPassword } from "../../actions/login";
import { cognitoErrors } from "../../js/errors";
import { required } from "../../js/formhelper";
import Button, { buttonStyle } from "../common/Button";
import {
    ButtonWithLinkContainer,
    InfoContainer,
    MessageContainer,
} from "./common/Container";
import Display from "./common/Display";
import { LabeledInput } from "./common/LabeledInput";
import MessageLink from "./common/MessageLink";

const errors = {
    EMAIL_BLANK: { email: "Email cannot be blank" },
    USER_DOES_NOT_EXIST: { email: "Invalid email address" },
};

class Forgot extends Component {
    constructor(props) {
        super(props);
        this.forget = async ({ email }) => {
            const error = await this.props.forgotPassword(email);
            if (!error) return;
            if (error === cognitoErrors.UserNotFound) {
                return errors.USER_DOES_NOT_EXIST;
            } else if (error === cognitoErrors.InvalidParameter) {
                return errors.USER_DOES_NOT_EXIST;
            }
        };
    }

    renderForm = () => (
        <Form
            onSubmit={this.forget}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <InfoContainer>
                        <MessageContainer>
                            Enter the email address you used to register your
                            account.
                        </MessageContainer>
                        <LabeledInput
                            labelText="Email"
                            fieldName="email"
                            type="email"
                            tabIndex="1"
                            validate={required}
                        />
                    </InfoContainer>
                    <ButtonWithLinkContainer>
                        <Button
                            priority={buttonStyle.Priority.PRIMARY}
                            size={buttonStyle.Size.LARGE}
                            color={buttonStyle.Color.BLUE}
                            loading={this.props.loading}
                            disabled={submitting}
                            type="submit"
                            text="Continue"
                        />
                        <MessageLink
                            message=""
                            dest="/login"
                            destText="Return to sign in"
                        />
                    </ButtonWithLinkContainer>
                </form>
            )}
        />
    );

    render = () => <Display>{this.renderForm()}</Display>;
}

const mapStateToProps = (state) => {
    if (state.forget.forgot) {
        return { loading: state.forget.forgot.loading };
    }
    return {};
};
export default connect(mapStateToProps, { forgotPassword })(Forgot);
