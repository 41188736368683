import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn } from "../../actions/auth";
import { refreshDraftConfig } from "../../actions/config";
import {
    deleteDraft,
    editDraft,
    getDraft,
    resetDraft,
    upgradeDraft,
} from "../../actions/draftdetails";
import {
    DraftClass,
    DraftStatus,
    KEEPERS_ONLY_PAGE,
    RESULTS_PAGE,
} from "../../js/constants";
import history from "../../js/history";
import { tracker } from "../App";
import DraftDoesNotExist from "../common/DraftDoesNotExist";
import LoadingPage from "../common/LoadingPage";
import { StyleReset } from "../styles/Reset";
import CheckoutModal from "./common/CheckoutModal";
import Header from "./common/Header";
import { InfoContainer, PageContainer } from "./common/PageLayout";
import UpsellHeader from "./common/UpsellHeader";
import AdvancedFeatures from "./draftdetails/AdvancedFeatures";
import Buttons from "./draftdetails/Buttons";
import DeleteDraftModal from "./draftdetails/DeleteDraftModal";
import DraftSettings from "./draftdetails/DraftSettings";
import DraftType from "./draftdetails/DraftType";
import LeagueMembers from "./draftdetails/LeagueMembers";
import Positions from "./draftdetails/Positions";

class DraftDetails extends Component {
    constructor(props) {
        super(props);

        // Create state to show/hide delete/checkout portals
        this.state = {
            upsellPrice: 0,
            upsellDraftType: DraftClass.BASIC,
            showDeletePortal: false,
            showCheckoutPortal: false,
        };

        // Create methods
        this.startDraft = (draftID) => () =>
            history.push(`/drafts/${draftID}/draftboard`);
        this.submitKeepers = (draftID) => () =>
            history.push(`/drafts/${draftID}/draftboard?${KEEPERS_ONLY_PAGE}`);
        this.draftResults = (draftID) => () =>
            history.push(`/drafts/${draftID}/draftboard?${RESULTS_PAGE}`);
    }

    componentDidMount = async () => {
        const draftID = this.props.match.params.id;
        if (!this.props.refreshed) {
            await this.props.refreshDraftConfig();
        }
        await this.props.signIn(tracker);
        await this.props.getDraft(draftID);
    };

    componentWillUnmount = () => this.props.resetDraft();

    openDeleteDraftPortal = () =>
        this.setState((state) => ({ ...state, showDeletePortal: true }));

    closeDeleteDraftPortal = () => {
        // Prevent close attempts if draft is currently being deleted
        if (this.props.deleting) return;
        this.setState((state) => ({ ...state, showDeletePortal: false }));
    };

    openCheckoutPortal = () =>
        this.setState((state) => ({ ...state, showCheckoutPortal: true }));

    closeCheckoutPortal = () => {
        // Prevent close attempts if draft is currently being updated
        if (this.props.loading) return;
        this.setState((state) => ({ ...state, showCheckoutPortal: false }));
    };

    deleteDraft = (draftID) => async () => {
        const result = await this.props.deleteDraft(draftID);
        if (!result) history.push("/");
    };

    updateDraftType = (newDraftType) => {
        const currDraftType = this.props.draft.draftType;
        const currPrice = this.props.draftConfig[currDraftType].price;
        const newPrice = this.props.draftConfig[newDraftType].price;

        this.setState((state) => ({
            ...state,
            showCheckoutPortal: true,
            upsellPrice: newPrice - currPrice,
            upsellDraftType: newDraftType,
        }));
    };

    upgradeDraft = (draftID) => async (values, cardElement, stripe, price) => {
        const result = await this.props.upgradeDraft(
            draftID,
            values,
            cardElement,
            stripe,
            price
        );
        if (!result || result === undefined) return this.closeCheckoutPortal();
        return result;
    };

    renderPage = () => (
        <>
            <InfoContainer>
                <DraftSettings deleteDraft={this.openDeleteDraftPortal} />
            </InfoContainer>
            <InfoContainer>
                <DraftType />
            </InfoContainer>
            <InfoContainer>
                <Positions />
            </InfoContainer>
            <InfoContainer>
                <AdvancedFeatures />
            </InfoContainer>
            <InfoContainer>
                <LeagueMembers />
            </InfoContainer>
            {this.state.showDeletePortal && (
                <DeleteDraftModal
                    open={this.state.showDeletePortal}
                    close={this.closeDeleteDraftPortal}
                    leagueName={this.props.draft.name}
                    deleteDraft={this.deleteDraft(this.props.draft.draftID)}
                    deleting={this.props.deleting}
                />
            )}
            {this.state.showCheckoutPortal && (
                <CheckoutModal
                    open={this.state.showCheckoutPortal}
                    close={this.closeCheckoutPortal}
                    loading={this.props.loading}
                    action={this.upgradeDraft(this.props.draft.draftID)}
                    price={this.state.upsellPrice}
                    values={{ draftType: this.state.upsellDraftType }}
                />
            )}
        </>
    );

    render = () => (
        <>
            <StyleReset />
            <Header />
            <PageContainer>
                {this.props.draft === null ? (
                    <DraftDoesNotExist />
                ) : this.props.draft ? (
                    <>
                        <UpsellHeader
                            draftType={this.props.draft.draftType}
                            heading="Draft Details"
                            showButton={
                                this.props.isCommissioner &&
                                this.props.draft.status === DraftStatus.UPCOMING
                            }
                            updateDraftType={this.updateDraftType}
                            show
                        />
                        <Buttons
                            form={this.props.form}
                            startDraft={this.startDraft(
                                this.props.draft.draftID
                            )}
                            submitKeepers={this.submitKeepers(
                                this.props.draft.draftID
                            )}
                            draftResults={this.draftResults(
                                this.props.draft.draftID
                            )}
                            keeper={this.props.draft.keeper}
                            status={this.props.draft.status}
                            isCommissioner={this.props.isCommissioner}
                            isMember={this.props.isMember}
                            remoteDraft={this.props.draft.remoteDraft}
                        />
                    </>
                ) : (
                    <LoadingPage />
                )}
                {this.props.draft && this.renderPage(this.props.isCommissioner)}
            </PageContainer>
        </>
    );
}

const mapStateToProps = (state) => {
    const newState = {
        isCommissioner: false,
        refreshed: state.config.refreshed,
        draftConfig: state.config.draftConfig,
        deleting: state.draftdetails.deleting,
        form: state.draftdetails.form,
        loading: state.draftdetails.loading,
    };
    if (state?.draftdetails?.draft !== undefined) {
        newState.draft = state.draftdetails.draft;
        if (state?.auth?.user && newState?.draft?.commissioners) {
            const userID = state.auth.user.username;
            const commissioners = newState.draft.commissioners;
            newState.isCommissioner = false;
            for (let i = 0; i < commissioners.length; i++) {
                if (commissioners[i].userID === userID) {
                    newState.isCommissioner = true;
                    break;
                }
            }
            const members = newState.draft.draftOrder;
            newState.isMember = false;
            for (let i = 0; i < members.length; i++) {
                if (members[i].userID === userID) {
                    newState.isMember = true;
                    break;
                }
            }
        }
    }
    return newState;
};

export default connect(mapStateToProps, {
    deleteDraft,
    editDraft,
    getDraft,
    refreshDraftConfig,
    resetDraft,
    signIn,
    upgradeDraft,
})(DraftDetails);
