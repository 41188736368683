import React from "react";
import styled from "styled-components";
import {
    MAXIMIZE_ICON,
    SETTINGS_ICON,
    SIGN_OUT_ICON,
} from "../../../js/constants";
import { toggleFullscreen } from "../../../js/util";
import IconButton from "../../common/IconButton";
import ColorScheme from "../../styles/colors";

const IconContainer = styled.div`
    display: flex;
    align-items: center;

    margin: 8px;
    > * {
        margin-left: 8px;
    }

    position: absolute;
    top: 0;
    right: 0;
`;

const MaximizeIcon = ({ toggleFullscreen }) => (
    <IconButton
        iconHref={MAXIMIZE_ICON}
        action={toggleFullscreen}
        color={ColorScheme.gray4.color}
        shadow
    />
);

// FIXME: Find a way to identify when fullscreen is active/inactive
// const MinimizeIcon = ({ toggleFullscreen }) => (
//     <IconButton
//         iconHref={MINIMIZE_ICON}
//         action={toggleFullscreen}
//         color={ColorScheme.gray4.color}
//         shadow
//     />
// );

const SettingsIcon = ({ openSettings }) => (
    <IconButton
        iconHref={SETTINGS_ICON}
        action={openSettings}
        color={ColorScheme.gray4.color}
        shadow
    />
);

const ExitIcon = ({ action }) => (
    <IconButton
        iconHref={SIGN_OUT_ICON}
        action={action}
        color={ColorScheme.gray4.color}
        shadow
    />
);

const ActionIcons = ({
    openSettings,
    openExit,
    exitDraft,
    isCommissioner,
    isMember,
    completed,
}) => {
    const exitAction = () => (completed ? exitDraft() : openExit());
    return (
        <IconContainer>
            <MaximizeIcon toggleFullscreen={toggleFullscreen} />
            <ExitIcon action={exitAction} />
            {(isCommissioner || isMember) && !completed && (
                <SettingsIcon openSettings={openSettings} />
            )}
        </IconContainer>
    );
};

export default ActionIcons;
