import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
    autodraft,
    pauseTimer,
    resumeTimer,
} from "../../../actions/draftboard";
import { PAUSE_ICON, PLAY_ICON } from "../../../js/constants";
import { getMinSec } from "../../../js/util";
import IconButton from "../../common/IconButton";
import ColorScheme from "../../styles/colors";
import InfoContainer from "../common/InfoContainer";
import Empty from "./Empty";
import TextCard from "./TextCard";

const TIMER_WARNING = 5;

const Container = styled.div`
    // Set container size
    width: 100%;
    height: 100%;

    // Center children
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LabelContainer = styled.div`
    flex: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Label = styled.div`
    // Style label
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: ${ColorScheme.gray1.color};
`;

class Timer extends Component {
    constructor(props) {
        super(props);
        const { start, duration } = this.props;
        this.timer = null;
        this.state = { start, duration };
    }

    componentDidMount = () => {
        // Set initial timer state
        const { start, paused, currDuration } = this.props;
        this.setState(() => ({ start, paused, currDuration }));

        // Start timer
        this.timer = setInterval(this.timerFn, 1000);
    };

    componentWillUnmount = () => clearInterval(this.timer);

    componentDidUpdate = () => {
        const { start, paused, currDuration } = this.props;

        // If timer start time changes, clear previous timer, reset state,
        // and restart timer
        if (this.state.start !== start) {
            clearInterval(this.timer);
            this.setState(() => ({ start, paused, currDuration }));
            this.timer = setInterval(this.timerFn, 1000);
        }
    };

    timerFn = () => {
        const { autodraft, start, paused, currDuration } = this.props;
        if (!paused) {
            const currTime = new Date().getTime();
            const timeElapsed = Math.floor((currTime - start) / 1000);
            this.setState((state) => ({
                ...state,
                currDuration: currDuration - timeElapsed,
            }));
            if (timeElapsed >= currDuration) autodraft();
        }
    };

    render = () => {
        const [mins, secs] = getMinSec(this.state.currDuration);
        const { paused, pauseTimer, resumeTimer, draftFull } = this.props;
        if (draftFull) return <Empty />;
        return (
            <InfoContainer maxWidth="256px">
                <Container>
                    <LabelContainer>
                        <Label>Timer</Label>
                        <IconButton
                            iconHref={paused ? PLAY_ICON : PAUSE_ICON}
                            action={paused ? resumeTimer : pauseTimer}
                            color={ColorScheme.gray1.color}
                            size="20px"
                            shadow
                        />
                    </LabelContainer>
                    <TextCard
                        cardText={`${mins}:${secs.toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                        })}`}
                        fontSize="36px"
                        color={
                            this.state.currDuration <= TIMER_WARNING
                                ? ColorScheme.red4.color
                                : null
                        }
                    />
                </Container>
            </InfoContainer>
        );
    };
}

const mapStateToProps = (state) => {
    const {
        draftboard: { draftboard, draft },
    } = state;
    const newState = {};
    if (draft?.timer.enabled) {
        newState.start = draftboard.timer.start;
        newState.paused = draftboard.timer.paused;
        newState.currDuration = draftboard.timer.duration;
    }
    newState.draftFull =
        draftboard.totalPicks === draft.rounds * draft.numPlayers;
    return newState;
};

export default connect(mapStateToProps, { autodraft, pauseTimer, resumeTimer })(
    Timer
);
