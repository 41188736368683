import React from "react";
import { Field } from "react-final-form";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { bordercolor, FieldError, inputError } from "../../common/Input";
import Spinner from "../../common/Spinner";
import ColorScheme from "../../styles/colors";
import Defaults, { FocusShadow } from "../../styles/defaults";
import { StyleStrip } from "../../styles/Reset";

const InputContainer = styled.div`
    padding: 0px 12px 24px;
    &:last-child {
        padding: 0px 12px;
    }
`;

const LabelContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const Label = styled.label`
    color: ${ColorScheme.blue6.color};
    font-size: 18px;
`;

const LabelLink = styled(Link)`
    color: ${ColorScheme.blue4.color};
    font-size: 14px;
    text-decoration: none;
`;

// Can't use common Input component, because this is styled slightly differently
const Input = styled.input`
    ${FocusShadow}

    // Style input text
    font-family: inherit;
    color: ${ColorScheme.blue6.color};
    font-size: 20px;

    // Give space between input text and bounding box
    padding: 8px 12px;

    // Style bounding box
    width: 100%;
    outline: none; // outline property doesn't hug border-radius
    border: ${(props) => bordercolor(props.error)};
    border-radius: ${Defaults.borderRadius};
    background-color: ${ColorScheme.gray1.color};
`;

export const LabeledInput = ({
    labelText,
    fieldName,
    type,
    tabIndex,
    max,
    min,
    minLength,
    validate,
    placeholder,
    initialValue,
}) => (
    <InputContainer>
        <Label htmlFor={fieldName}>{labelText}</Label>
        <Field name={fieldName} validate={validate} initialValue={initialValue}>
            {({ input, meta }) => {
                const { error, showError } = inputError(meta);
                return (
                    <>
                        <Input
                            id={fieldName}
                            type={type}
                            tabIndex={tabIndex}
                            max={max}
                            min={min}
                            minLength={minLength}
                            placeholder={placeholder}
                            error={showError}
                            {...input}
                        />
                        {showError && <FieldError>{error}</FieldError>}
                    </>
                );
            }}
        </Field>
    </InputContainer>
);

export const LabeledInputWithLink = ({
    labelText,
    fieldName,
    type,
    dest,
    destText,
    tabIndex,
    minLength,
    validate,
}) => (
    <InputContainer>
        <LabelContainer>
            <Label htmlFor={fieldName}>{labelText}</Label>
            <LabelLink to={dest}>{destText}</LabelLink>
        </LabelContainer>
        <Field name={fieldName} minLength={minLength} validate={validate}>
            {({ input, meta }) => {
                const { error, showError } = inputError(meta);
                return (
                    <>
                        <Input
                            id={fieldName}
                            type={type}
                            tabIndex={tabIndex}
                            minLength={minLength}
                            error={showError}
                            {...input}
                        />
                        {showError && <FieldError>{error}</FieldError>}
                    </>
                );
            }}
        </Field>
    </InputContainer>
);

const ActionStyle = css`
    ${StyleStrip}
    font-size: 14px;
`;

const ActionText = styled.button`
    ${ActionStyle}
    ${FocusShadow}
    color: ${ColorScheme.blue4.color};
    cursor: pointer;
`;

const ActionError = styled.div`
    ${ActionStyle}
    color: ${ColorScheme.red4.color};
`;

const ActionSuccess = styled.div`
    ${ActionStyle}
    color: ${ColorScheme.green4.color};
`;

const Action = ({ action }) => {
    if (action.loading) {
        return <Spinner medium />;
    }
    if (action.showMsg) {
        return <ActionText onClick={action.callback}>{action.text}</ActionText>;
    }
    if (action.error) {
        return <ActionError>{action.error.message}</ActionError>;
    }
    if (action.success) {
        return <ActionSuccess>{action.success.message}</ActionSuccess>;
    }
    return null;
};

export const LabeledInputWithAction = ({
    labelText,
    fieldName,
    type,
    action,
    tabIndex,
    minLength,
    validate,
}) => (
    <InputContainer>
        <LabelContainer>
            <Label htmlFor={fieldName}>{labelText}</Label>
            <Action action={action} />
        </LabelContainer>
        <Field name={fieldName} minLength={minLength} validate={validate}>
            {({ input, meta }) => {
                const { error, showError } = inputError(meta);
                return (
                    <>
                        <Input
                            id={fieldName}
                            type={type}
                            tabIndex={tabIndex}
                            minLength={minLength}
                            error={showError}
                            {...input}
                        />
                        {showError && <FieldError>{error}</FieldError>}
                    </>
                );
            }}
        </Field>
    </InputContainer>
);
