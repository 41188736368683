import { FILTER_DRAFTS, GET_DRAFTS, SIGN_OUT } from "../actions/types";
import { DraftStatus } from "../js/constants";

const DEFAULT_STATE = {
    status: DraftStatus.ALL,
};

export const getDraftsReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FILTER_DRAFTS:
            return { ...state, status: action.status };
        case GET_DRAFTS:
            if (action.drafts) {
                return { ...state, drafts: action.drafts };
            }
            return {};
        case SIGN_OUT:
            return DEFAULT_STATE;
        default:
            return state;
    }
};
