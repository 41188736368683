import { RestAPI } from "@aws-amplify/api-rest";
import { Auth } from "@aws-amplify/auth";
import {
    GET_USER,
    RENDER_EMAIL_PREFERENCE_DETAILS,
    RENDER_EMAIL_PREFERENCE_FORM,
    RENDER_PASSWORD_FORM,
    RENDER_PROFILE_DETAILS,
    RENDER_PROFILE_FORM,
    RENDER_VERIFY_FORM,
    RESEND_VERIFY_CODE,
    RESET_PASSWORD_STATUS,
    RESET_RESEND_VERIFY_STATUS,
    UPDATE_EMAIL_PREFERENCE,
    UPDATE_PASSWORD,
    UPDATE_USER,
    VERIFY_EMAIL_UPDATE,
} from "../actions/types";
import { cognitoAuthHeader } from "../js/auth";
import { signIn, signInBypassCache } from "./auth";

// FIXME: Need to change all references of "/user" to "/users"

// Client Metadata that is sent to the cognito postconfirmation handler
// This needs to be kept in sync with the cognito handler
const UPDATE_EMAIL_META = { requestType: "change-email" };

export const getUserMetadata = (userID) => async (dispatch) => {
    try {
        const req = { headers: await cognitoAuthHeader() };
        const url = `/user/${userID}`;
        const metadata = await RestAPI.get(process.env.API_NAME, url, req);
        return dispatch({ type: GET_USER, metadata });
    } catch (error) {
        // FIXME: Should identify error and retry, if possible
        return;
    }
};

// profileInit chains signIn and getUser
export const profileInit = () => async (dispatch, getState) => {
    await dispatch(signIn());
    const {
        auth: {
            user: {
                attributes: { sub },
            },
        },
    } = getState();
    return dispatch(getUserMetadata(sub));
};

export const renderEmailPreferenceDetails = () => ({
    type: RENDER_EMAIL_PREFERENCE_DETAILS,
});
export const renderEmailPreferenceForm = () => ({
    type: RENDER_EMAIL_PREFERENCE_FORM,
});
export const renderPasswordForm = () => ({ type: RENDER_PASSWORD_FORM });
export const renderProfileForm = () => ({ type: RENDER_PROFILE_FORM });
export const renderProfileDetails = () => ({ type: RENDER_PROFILE_DETAILS });
export const renderVerifyForm = () => ({ type: RENDER_VERIFY_FORM });
export const resetResendStatus = () => ({ type: RESET_RESEND_VERIFY_STATUS });
export const resetPasswordStatus = () => ({ type: RESET_PASSWORD_STATUS });

export const resendCode = () => async (dispatch, getState) => {
    // Set loading prop to true
    dispatch({ type: RESEND_VERIFY_CODE });

    try {
        await Auth.verifyCurrentUserAttribute("email", UPDATE_EMAIL_META);
        dispatch({ type: RESEND_VERIFY_CODE, success: true });
    } catch (error) {
        dispatch({ type: RESEND_VERIFY_CODE, error });
        return error.code;
    }
};

export const updateUser = (email, name) => async (dispatch, getState) => {
    // Set loading prop to true
    dispatch({ type: UPDATE_USER });

    // Get current user
    await dispatch(signIn());
    const user = await Auth.currentAuthenticatedUser();

    try {
        const updatedAttributes = {};
        if (user.attributes.name !== name) updatedAttributes.name = name;
        if (user.attributes.email !== email) updatedAttributes.email = email;

        // Ignore requests which haven't changed any user attributes
        if (Object.keys(updatedAttributes).length > 0) {
            if ("email" in updatedAttributes) {
                await Auth.updateUserAttributes(
                    user,
                    updatedAttributes,
                    UPDATE_EMAIL_META
                );
            } else {
                await Auth.updateUserAttributes(user, updatedAttributes);
            }

            // If user update is successful, trigger user metadata sync
            await dispatch(signIn());
        }

        // If email changed, we want to render the verify form
        // If not, we want to render personal info
        dispatch({
            type: UPDATE_USER,
            success: true,
            emailChanged: "email" in updatedAttributes,
        });
    } catch (error) {
        dispatch({ type: UPDATE_USER, error });
        return error.code;
    }
};

export const updatePassword =
    (oldPassword, newPassword) => async (dispatch, getState) => {
        // Set loading prop to true
        dispatch({ type: UPDATE_PASSWORD });

        // Get current user
        await dispatch(signIn());
        const user = await Auth.currentAuthenticatedUser();

        try {
            if (oldPassword !== newPassword) {
                await Auth.changePassword(user, oldPassword, newPassword);
            }
            dispatch({ type: UPDATE_PASSWORD, success: true });
        } catch (error) {
            dispatch({ type: UPDATE_PASSWORD, error });
            return error.code;
        }
    };

export const verifyEmailUpdate = (code) => async (dispatch) => {
    // Set loading prop to true
    dispatch({ type: VERIFY_EMAIL_UPDATE });

    try {
        await Auth.verifyCurrentUserAttributeSubmit("email", code);

        // If verification is successful, trigger non-cached user metadata sync
        await dispatch(signInBypassCache());
        dispatch({ type: VERIFY_EMAIL_UPDATE, success: true });
    } catch (error) {
        dispatch({ type: VERIFY_EMAIL_UPDATE, error });
        return error.code;
    }
};

export const updateEmailPreferences = (vals) => async (dispatch, getState) => {
    const currState = getState();

    // Extract userID from state
    if (!currState.auth?.user?.username) return;
    const userID = currState.auth.user.username;

    // If preferences haven't changed, return
    const currPreferences = currState.profile.metadata.emailPreference;
    if (Object.keys(currPreferences).length === Object.keys(vals).length) {
        let preferenceChanged = false;
        for (const [key, value] of Object.entries(vals)) {
            if (!(key in currPreferences) || currPreferences[key] !== value) {
                preferenceChanged = true;
                break;
            }
        }
        if (!preferenceChanged) return dispatch(renderEmailPreferenceDetails());
    }

    // Set loading prop to true
    dispatch({ type: UPDATE_EMAIL_PREFERENCE });

    try {
        const body = { emailPreference: vals };
        const request = { body, headers: await cognitoAuthHeader() };
        const url = `/user/${userID}`;
        await RestAPI.patch(process.env.API_NAME, url, request);
        dispatch({
            type: UPDATE_EMAIL_PREFERENCE,
            metadata: body,
            success: true,
        });
    } catch (error) {
        // FIXME: Should identify error and retry, if possible
        dispatch({ type: UPDATE_EMAIL_PREFERENCE, error });
    }
};
