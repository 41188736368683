import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
    renderPasswordForm,
    renderProfileForm,
    renderVerifyForm,
    resetPasswordStatus,
} from "../../../actions/profile";
import Button, { buttonStyle } from "../../common/Button";
import ButtonPlaceholder from "../../common/ButtonPlaceholder";
import { VerticalContainer } from "../../common/Container";
import { DetailsContainer, Header } from "../common/DetailsContainer";
import {
    Label,
    LabeledPartitionContainer,
    LabeledValuePartition,
    OffsetFieldContainer,
    OffsetLabelContainer,
    SuccessValue,
    Value,
} from "../common/Label";

const EmailContainer = styled.div`
    flex: 70%;
    display: flex;
    align-items: baseline;

    > * {
        &:last-child {
            margin-left: 8px;
        }
        &:only-child {
            margin-left: 0px;
        }
    }
`;

const PasswordSuccess = () => (
    <LabeledPartitionContainer>
        <OffsetLabelContainer>
            <Label>Password</Label>
        </OffsetLabelContainer>
        <OffsetFieldContainer>
            <SuccessValue>Password Updated</SuccessValue>
        </OffsetFieldContainer>
    </LabeledPartitionContainer>
);

const PasswordButton = ({ renderPasswordForm }) => (
    <LabeledPartitionContainer>
        <OffsetLabelContainer>
            <Label>Password</Label>
        </OffsetLabelContainer>
        <OffsetFieldContainer>
            <Button
                size={buttonStyle.Size.SMALL}
                color={buttonStyle.Color.BLUE}
                priority={buttonStyle.Priority.SECONDARY}
                onClick={renderPasswordForm}
                type="button"
                text="Update"
            />
        </OffsetFieldContainer>
    </LabeledPartitionContainer>
);

class PersonalInfo extends Component {
    componentDidMount = () => {
        if (this.props.passwordSuccess) {
            setTimeout(() => this.props.resetPasswordStatus(), 3500);
        }
    };

    renderEmailLabel = () => (
        <LabeledPartitionContainer>
            <OffsetLabelContainer>
                <Label>Email</Label>
            </OffsetLabelContainer>
            <EmailContainer>
                <Value>{this.props.user.attributes.email}</Value>
                {this.props.user.attributes.email_verified ? (
                    <ButtonPlaceholder
                        size={buttonStyle.Size.SMALL}
                        color={buttonStyle.Color.GREEN}
                        text="Verified"
                    />
                ) : (
                    <Button
                        size={buttonStyle.Size.SMALL}
                        color={buttonStyle.Color.RED}
                        priority={buttonStyle.Priority.PRIMARY}
                        onClick={this.props.renderVerifyForm}
                        type="button"
                        text="Not Verified"
                    />
                )}
            </EmailContainer>
        </LabeledPartitionContainer>
    );

    render = () => (
        <VerticalContainer>
            <Header
                heading="Personal Information"
                secondaryBtn={{
                    text: "Edit",
                    onClick: this.props.renderProfileForm,
                }}
            />
            <DetailsContainer>
                {this.renderEmailLabel()}
                <LabeledValuePartition
                    label="Name"
                    value={this.props.user.attributes.name}
                />
                {this.props.passwordSuccess ? (
                    <PasswordSuccess />
                ) : (
                    <PasswordButton
                        renderPasswordForm={this.props.renderPasswordForm}
                    />
                )}
            </DetailsContainer>
        </VerticalContainer>
    );
}

const mapStateToProps = (state) => {
    const newState = {};
    if (state.auth) {
        newState.user = state.auth.user;
    }
    if (state.profile && state.profile.updatePassword) {
        newState.passwordSuccess = state.profile.updatePassword.success;
    }
    return newState;
};
export default connect(mapStateToProps, {
    renderPasswordForm,
    renderProfileForm,
    renderVerifyForm,
    resetPasswordStatus,
})(PersonalInfo);
