import React from "react";
import styled from "styled-components";
import Button, { buttonStyle } from "../../common/Button";
import ColorScheme from "../../styles/colors";
import InfoContainer from "../common/InfoContainer";

const Container = styled.div`
    // Set container size
    width: 100%;
    height: 100%;

    // Center children
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LabelContainer = styled.div`
    flex: 50%;

    // Style label
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    color: ${ColorScheme.gray1.color};

    display: flex;
    justify-content: center;
    align-items: center;
`;

const ButtonContainer = styled.div`
    flex: 50%;
    margin-left: 12px;
`;

const ButtonLabel = ({ label, action, buttonText, buttonColor, loading }) => (
    <InfoContainer maxWidth="256px">
        <Container>
            <LabelContainer>{label}</LabelContainer>
            <ButtonContainer>
                <Button
                    size={buttonStyle.Size.MEDIUM}
                    color={buttonColor}
                    priority={buttonStyle.Priority.PRIMARY}
                    text={buttonText}
                    type="button"
                    disabled={loading}
                    onClick={action}
                    loading={loading}
                />
            </ButtonContainer>
        </Container>
    </InfoContainer>
);

export default ButtonLabel;
