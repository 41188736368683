import { CardElement } from "@stripe/react-stripe-js";
import React from "react";
import { Field } from "react-final-form";
import styled from "styled-components";
import Defaults from "../styles/defaults";
import { bordercolor, FieldError, inputError } from "./Input";

// For whatever reason, stripe style doesn't accept strings with the '%'
// character, like hsla strings have, so switching over to hex here
const BLUE4 = "#3182C9";
const GRAY2 = "#E3E9ED";
const RED4 = "#DC3232";

const OPTIONS = {
    style: {
        base: {
            fontSize: "18px",
            fontFamily: "Inter, sans-serif",
            color: BLUE4,
            "::placeholder": { color: GRAY2 },
        },
        invalid: { color: RED4, iconColor: RED4 },
    },
    hidePostalCode: true,
};

const CreditCardContainer = styled.div`
    border-radius: ${Defaults.borderRadius};
    border: ${(props) => bordercolor(props.error)};
    padding: 8px 12px;
`;

const FieldContainer = styled.div`
    position: relative;
`;

const validate = (price) => (value) => {
    if (price === 0) return undefined;
    if (!value) return "Required";
    if (!value.complete) return "Incomplete";
    return value.error || undefined;
};

export const CreditCardField = ({ fieldName, price }) => {
    // setCreditCardState is an onChange handler to connect
    // the stripe element's error state with React Final Form's state
    const setCreditCardState = (formOnChange) => (event) => {
        if (event.complete) {
            formOnChange({ complete: true });
        } else if (event.error) {
            formOnChange({ error: event.error.message });
        } else {
            formOnChange({ complete: false });
        }
    };

    return (
        <Field name={fieldName} validate={validate(price)}>
            {({ input: { onBlur, onChange, onFocus }, meta }) => {
                const { error, showError } = inputError(meta);
                return (
                    <FieldContainer>
                        <CreditCardContainer error={showError}>
                            <CardElement
                                options={OPTIONS}
                                onBlur={onBlur}
                                onFocus={onFocus}
                                onChange={setCreditCardState(onChange)}
                            />
                        </CreditCardContainer>
                        {showError && <FieldError>{error}</FieldError>}
                    </FieldContainer>
                );
            }}
        </Field>
    );
};
