import React from "react";
import styled from "styled-components";
import ColorScheme from "../styles/colors";
import { buttonStyle } from "./Button";

const bordertop = (color, primary, small) => {
    const borderThickness = small ? "3px" : "4px";
    switch (color) {
        case buttonStyle.Color.GREEN:
            if (primary) {
                return `${borderThickness} solid ${ColorScheme.green3.color};`;
            }
            return `${borderThickness} solid ${ColorScheme.green4.color};`;
        case buttonStyle.Color.GRAY:
            if (primary) {
                return `${borderThickness} solid ${ColorScheme.gray3.color};`;
            }
            return `${borderThickness} solid ${ColorScheme.gray5.color};`;
        case buttonStyle.Color.RED:
            if (primary) {
                return `${borderThickness} solid ${ColorScheme.red2.color};`;
            }
            return `${borderThickness} solid ${ColorScheme.red3.color};`;
        case buttonStyle.Color.YELLOW:
            if (primary) {
                return `${borderThickness} solid ${ColorScheme.yellow3.color};`;
            }
            return `${borderThickness} solid ${ColorScheme.yellow5.color};`;
        case buttonStyle.Color.BLUE:
        default:
            if (primary) {
                return `${borderThickness} solid ${ColorScheme.blue3.color};`;
            }
            return `${borderThickness} solid ${ColorScheme.blue4.color};`;
    }
};

const border = (color, primary, small) => {
    const borderThickness = small ? "3px" : "4px";
    if (primary) {
        return `${borderThickness} solid ${ColorScheme.gray1.color}`;
    }
    switch (color) {
        case buttonStyle.Color.GREEN:
            return `${borderThickness} solid ${ColorScheme.green2.color};`;
        case buttonStyle.Color.GRAY:
            return `${borderThickness} solid ${ColorScheme.gray3.color};`;
        case buttonStyle.Color.RED:
            return `${borderThickness} solid ${ColorScheme.red2.color};`;
        case buttonStyle.Color.YELLOW:
            return `${borderThickness} solid ${ColorScheme.yellow3.color};`;
        case buttonStyle.Color.BLUE:
        default:
            return `${borderThickness} solid ${ColorScheme.blue2.color};`;
    }
};

const Spinner = styled.div`
    border: ${(props) => border(props.color, props.primary, props.small)};
    border-top: ${(props) =>
        bordertop(props.color, props.primary, props.small)};
    border-radius: 50%;
    width: ${(props) =>
        props.small ? "12px" : props.medium ? "16px" : "24px"};
    height: ${(props) =>
        props.small ? "12px" : props.medium ? "16px" : "24px"};
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const LargeSpinnerTag = `<svg class="lds-spinner" width="200px" height="200px" xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;"><g transform="rotate(0 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#442317">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(30 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#442317">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(60 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#442317">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(90 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#442317">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(120 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#442317">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(150 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#442317">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(180 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#442317">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(210 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#442317">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(240 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#442317">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(270 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#442317">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(300 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#442317">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(330 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#442317">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
  </rect>
</g></svg>`;

export const LoadingSpinner = () => (
    <div dangerouslySetInnerHTML={{ __html: LargeSpinnerTag }} />
);

export default Spinner;
