import React, { Component } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { register } from "../../actions/login";
import { MIN_AGE, MIN_PASSWORD_LENGTH } from "../../js/constants";
import { cognitoErrors } from "../../js/errors";
import { beforeDate, compose, minLength, required } from "../../js/formhelper";
import { dateToString, getPastDate } from "../../js/util";
import Button, { buttonStyle } from "../common/Button";
import { ButtonWithLinkContainer, InfoContainer } from "./common/Container";
import Display from "./common/Display";
import { LabeledInput } from "./common/LabeledInput";
import MessageLink from "./common/MessageLink";

const errors = {
    EMAIL_BLANK: { email: "Email cannot be blank" },
    PASSWORD_BLANK: { password: "Password cannot be blank" },
    NAME_BLANK: { name: "Name cannot be blank" },
    BIRTHDATE_BLANK: { birthdate: "Birthday cannot be blank" },
    ACCOUNT_ALREADY_EXISTS: {
        email: "An account already exists with this email address",
    },
    MINIMUM_AGE: { birthdate: "You must be at least 13 years old to register" },
};

class Register extends Component {
    constructor(props) {
        super(props);
        this.register = async ({ email, name, birthdate, password }) => {
            const error = await this.props.register(
                email,
                password,
                name,
                birthdate
            );
            if (!error) return;
            if (error === cognitoErrors.UsernameExists) {
                return errors.ACCOUNT_ALREADY_EXISTS;
            } else if (error === cognitoErrors.UserLambdaValidation) {
                return errors.MINIMUM_AGE;
            }
        };
    }

    renderForm = () => (
        <Form
            onSubmit={this.register}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <InfoContainer>
                        <LabeledInput
                            labelText="Email"
                            fieldName="email"
                            type="email"
                            tabIndex="1"
                            validate={required}
                        />
                        <LabeledInput
                            labelText="Full Name"
                            fieldName="name"
                            type="text"
                            tabIndex="2"
                            validate={required}
                        />
                        <LabeledInput
                            labelText="Birthday"
                            fieldName="birthdate"
                            max={dateToString(getPastDate(MIN_AGE))}
                            type="date"
                            tabIndex="3"
                            validate={compose(
                                required,
                                beforeDate(getPastDate(MIN_AGE))
                            )}
                        />
                        <LabeledInput
                            labelText="Password"
                            fieldName="password"
                            type="password"
                            minLength={MIN_PASSWORD_LENGTH}
                            tabIndex="4"
                            validate={compose(
                                required,
                                minLength("Password", MIN_PASSWORD_LENGTH)
                            )}
                        />
                    </InfoContainer>
                    <ButtonWithLinkContainer>
                        <Button
                            priority={buttonStyle.Priority.PRIMARY}
                            size={buttonStyle.Size.LARGE}
                            color={buttonStyle.Color.BLUE}
                            loading={this.props.loading}
                            disabled={submitting}
                            type="submit"
                            text="Sign Up"
                        />
                        <MessageLink
                            message="Have an account? "
                            dest="/login"
                            destText="Sign in"
                        />
                    </ButtonWithLinkContainer>
                </form>
            )}
        />
    );
    render = () => <Display>{this.renderForm()}</Display>;
}

const mapStateToProps = (state) => {
    if (state.register) {
        return { loading: state.register.loading };
    }
    return { loading: false };
};

export default connect(mapStateToProps, { register })(Register);
