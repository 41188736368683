import styled from "styled-components";
import ColorScheme from "../../styles/colors";
import Defaults from "../../styles/defaults";

export const InfoContainer = styled.div`
    background-color: ${ColorScheme.gray1.color};
    margin: 32px;
    padding: 32px;
    margin-bottom: 64px;
    border-radius: ${Defaults.borderRadius};
    box-shadow: ${Defaults.shadow};

    @media (max-width: 670px) {
        margin: 0px;
        margin-top: 32px;
        margin-bottom: 32px;
    }

    @media (max-width: 400px) {
        padding: 12px 0px;
    }
`;

export const EmailContainer = styled.div`
    white-space: pre-line;
    font-weight: 700;
    text-align: center;
`;

export const MessageContainer = styled.p`
    padding: 12px;
    font-size: 20px;
    color: ${ColorScheme.blue6.color};
    margin-bottom: 32px;

    &:only-child {
        margin-bottom: 0px;
    }
`;

export const FormContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1020px) {
        width: 75%;
    }

    @media (max-width: 670px) {
        width: 90%;
    }

    @media (max-width: 470px) {
        width: 100%;
    }
`;

export const ButtonWithLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > * {
        &:last-child {
            margin-top: 24px;
        }
        &:only-child {
            margin-top: 0px;
        }
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const LogoContainer = styled.div`
    margin: 0px 32px;
    @media (max-width: 400px) {
        margin: 0px 16px;
    }
`;

// FIXME: Add media queries
const Container = styled.div`
    // Bound width and height
    max-width: 1024px;
    max-height: 768px;

    // Make sure Container takes up the maximum allowed area
    width: 100%;
    height: 100%;

    // Center container in middle of screen
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    // Style container
    background-color: ${ColorScheme.gray2.color};
    border-radius: ${Defaults.borderRadius};

    // Make sure image and login containers are side-by-side
    display: flex;

    @media (max-width: 1020px) {
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 500px) {
        max-height: 768px;
    }
`;

export default Container;
