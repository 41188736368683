import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import App from "./components/App";
import reducers from "./reducers";

ReactDOM.render(
    <Provider
        store={configureStore({
            reducer: reducers,
            middlware:
                process.env.ENVIRONMENT === "dev"
                    ? compose(
                          applyMiddleware(thunkMiddleware),
                          window.__REDUX_DEVTOOLS_EXTENSION__ &&
                              window.__REDUX_DEVTOOLS_EXTENSION__()
                      )
                    : applyMiddleware(thunkMiddleware),
            devTools: process.env.ENVIRONMENT === "dev",
        })}
    >
        <App />
    </Provider>,
    document.querySelector("#root")
);
