import React, { useState } from "react";
import { Field, useFormState } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import styled from "styled-components";
import { DraftClass, DraftConfig } from "../../../js/constants";
import {
    AUCTION_DESCRIPTION,
    CUSTOM_DESCRIPTION,
    LINEAR_DESCRIPTION,
    SNAKE_DESCRIPTION,
} from "../../../js/descriptions";
import { draftlabel } from "../../../js/util";
import { buttonStyle } from "../../common/Button";
import { Radio } from "../../common/Input";
import LeagueType from "../../common/LeagueType";
import ColorScheme from "../../styles/colors";
import GridDescriptionOption from "../common/GridDescriptionOption";
import { InfoContainer } from "../common/PageLayout";
import AuctionOptions from "../displaydraft/AuctionOptions";
import CustomDraftOrder from "../displaydraft/CustomDraftOrder";
import CreateDraftForm from "./CreateDraftForm";

const DraftConfigOptionContainer = styled.div`
    margin-top: 16px;
`;

const DraftConfigLabel = styled.label`
    color: ${(props) =>
        props.enabled ? ColorScheme.blue5.color : ColorScheme.gray4.color};
    font-size: 30px;
    font-weight: 400;
    cursor: ${(props) => (props.enabled ? "pointer" : "not-allowed")};

    display: flex;
    align-items: center;
    > * {
        &:last-child {
            margin-left: 8px;
        }
    }
`;

const DraftConfigDescription = styled.div`
    color: ${(props) =>
        props.enabled ? ColorScheme.blue3.color : ColorScheme.gray4.color};
    font-size: 16px;
    font-weight: 400;
`;

const DraftConfigAddOn = styled.div`
    // Place optional component underneath the description
    grid-column: 2 / 3;
    grid-row: 3 / 4;
`;

const DraftConfigOption = ({
    draftType,
    description,
    enabled,
    selected,
    children,
}) => (
    <DraftConfigOptionContainer>
        <GridDescriptionOption>
            <Field name="draftConfig" type="radio" value={draftType}>
                {({ input }) => (
                    <Radio
                        id={draftType}
                        type="radio"
                        disabled={!enabled}
                        small
                        {...input}
                    />
                )}
            </Field>
            <DraftConfigLabel htmlFor={draftType} enabled={enabled}>
                {draftlabel(draftType)}
                {draftType === DraftConfig.AUCTION && (
                    <LeagueType
                        size={buttonStyle.Size.LARGE}
                        draftType={DraftClass.PRO}
                        disabled={!enabled}
                    />
                )}
                {draftType === DraftConfig.CUSTOM && (
                    <LeagueType
                        size={buttonStyle.Size.LARGE}
                        draftType={DraftClass.PREMIUM}
                        disabled={!enabled}
                    />
                )}
            </DraftConfigLabel>
            <DraftConfigDescription enabled={enabled}>
                {description}
            </DraftConfigDescription>
            {enabled && selected === draftType && children && (
                <DraftConfigAddOn>{children}</DraftConfigAddOn>
            )}
        </GridDescriptionOption>
    </DraftConfigOptionContainer>
);

const DraftType = ({ draftConfig }) => {
    const { values } = useFormState();
    const draftTypeAllowed = (draftType) =>
        draftConfig[values.draftType].validDraftTypes.includes(draftType);

    // Need to pass selected state to each DraftConfigOption
    const [selected, setSelected] = useState(values.draftConfig);
    const updateSelected = (value) => {
        if (!value) return;
        setSelected(value);
    };

    return (
        <CreateDraftForm.Page>
            <InfoContainer>
                <DraftConfigOption
                    draftType={DraftConfig.SNAKE}
                    description={SNAKE_DESCRIPTION}
                    enabled={draftTypeAllowed(DraftConfig.SNAKE)}
                    selected={selected}
                />
                <DraftConfigOption
                    draftType={DraftConfig.LINEAR}
                    description={LINEAR_DESCRIPTION}
                    enabled={draftTypeAllowed(DraftConfig.LINEAR)}
                    selected={selected}
                />
                <DraftConfigOption
                    draftType={DraftConfig.AUCTION}
                    description={AUCTION_DESCRIPTION}
                    enabled={draftTypeAllowed(DraftConfig.AUCTION)}
                    selected={selected}
                >
                    <AuctionOptions />
                </DraftConfigOption>
                <DraftConfigOption
                    draftType={DraftConfig.CUSTOM}
                    description={CUSTOM_DESCRIPTION}
                    enabled={draftTypeAllowed(DraftConfig.CUSTOM)}
                    selected={selected}
                >
                    <CustomDraftOrder
                        positions={values.positions}
                        draftType={values.draftType}
                        idp={values.idp}
                        draftConfig={draftConfig}
                    />
                </DraftConfigOption>
                <OnChange name="draftConfig">
                    {(value) => updateSelected(value)}
                </OnChange>
            </InfoContainer>
        </CreateDraftForm.Page>
    );
};

export default DraftType;
