import React from "react";
import styled, { css } from "styled-components";
import ColorScheme from "../../styles/colors";

const LabelStyle = css`
    font-family: inherit;
    color: ${ColorScheme.blue5.color};
`;

const FieldStyle = css`
    font-family: inherit;
    color: ${ColorScheme.blue3.color};
`;

const DetailLabel = styled.div`
    ${LabelStyle}
    ${(props) => props.aligned && "flex: 25%;"}
    font-size: 16px;
    margin-right: 12px;
`;

const DetailValue = styled.div`
    ${FieldStyle}
    ${(props) => props.aligned && "flex: 75%;"}
    font-size: 16px;
`;

const DetailContainer = styled.div`
    display: flex;
    align-items: baseline;
`;

const Detail = ({ labelText, fieldText, aligned }) => (
    <DetailContainer>
        <DetailLabel aligned={aligned}>{`${labelText}:`}</DetailLabel>
        <DetailValue aligned={aligned}>{fieldText}</DetailValue>
    </DetailContainer>
);

export default Detail;
