import React, { useState } from "react";
import styled from "styled-components";
import { Radio } from "../../common/Input";
import ColorScheme from "../../styles/colors";
import { InfoContainer, InfoContainerH2 } from "./Container";

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 24px;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
`;

const RadioLabel = styled.label`
    color: ${ColorScheme.blue4.color};
    font-size: 16px;
    font-weight: 400;
    margin-left: 12px;
    cursor: pointer;
`;

const AutocompleteSortOption = ({
    sortByName,
    sortByRank,
    autocompleteByRank,
}) => {
    const Options = { Rank: "rank", Name: "name" };
    const [checked, setChecked] = useState(
        autocompleteByRank ? Options.Rank : Options.Name
    );
    const onchange = (val) => () => {
        setChecked(val);
        if (val === Options.Rank) sortByRank();
        else if (val === Options.Name) sortByName();
    };
    return (
        <InfoContainer>
            <InfoContainerH2>Autocomplete Results</InfoContainerH2>
            <OptionsContainer>
                <InputContainer>
                    <Radio
                        name="autocomplete-sort"
                        id={Options.Rank}
                        type="radio"
                        onChange={onchange(Options.Rank)}
                        checked={checked === Options.Rank}
                        small
                    />
                    <RadioLabel htmlFor={Options.Rank}>Sort by Rank</RadioLabel>
                </InputContainer>
                <InputContainer>
                    <Radio
                        name="autocomplete-sort"
                        id={Options.Name}
                        type="radio"
                        onChange={onchange(Options.Name)}
                        checked={checked === Options.Name}
                        small
                    />
                    <RadioLabel htmlFor={Options.Name}>Sort by Name</RadioLabel>
                </InputContainer>
            </OptionsContainer>
        </InfoContainer>
    );
};

export default AutocompleteSortOption;
