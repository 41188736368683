import {
    HEADER_HIDE_HELP,
    HEADER_HIDE_PROFILE,
    HEADER_SHOW_HELP,
    HEADER_SHOW_PROFILE,
    SIGN_OUT,
} from "../actions/types";

const DEFAULT = {
    showProfile: false,
    showHelp: false,
};

// headerReducer keeps track of whether to show the header dropdown
export const headerReducer = (state = DEFAULT, action) => {
    switch (action.type) {
        case HEADER_SHOW_PROFILE:
            return { ...state, showHelp: false, showProfile: true };
        case HEADER_HIDE_PROFILE:
            return { ...state, showProfile: false };
        case HEADER_SHOW_HELP:
            return { ...state, showHelp: true, showProfile: false };
        case HEADER_HIDE_HELP:
            return { ...state, showHelp: false };
        case SIGN_OUT:
            return DEFAULT;
        default:
            return state;
    }
};
