import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { configReducer } from "./config";
import { createDraftReducer } from "./createdraft";
import { draftboardReducer } from "./draftboard";
import { draftDetailsReducer } from "./draftdetails";
import { getDraftsReducer } from "./getdrafts";
import { headerReducer } from "./header";
import { forgetReducer, loginReducer, registerReducer } from "./login";
import { profileReducer } from "./profile";
export default combineReducers({
    auth: authReducer,
    config: configReducer,
    createdraft: createDraftReducer,
    draftboard: draftboardReducer,
    draftdetails: draftDetailsReducer,
    forget: forgetReducer,
    getdrafts: getDraftsReducer,
    header: headerReducer,
    login: loginReducer,
    profile: profileReducer,
    register: registerReducer,
});
