import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { undo } from "../../../actions/draftboard";
import {
    CardLocation,
    DraftboardViews,
    DraftConfig,
    UNDO_ICON,
} from "../../../js/constants";
import IconButton from "../../common/IconButton";
import ColorScheme from "../../styles/colors";
import { StyleStrip } from "../../styles/Reset";
import InfoContainer from "../common/InfoContainer";
import { PlayerButton } from "../common/PlayerCard";

const Container = styled.div`
    // Set container size
    width: 100%;
    height: 100%;

    // Center children
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
`;

const Label = styled.button`
    ${StyleStrip}
    cursor: pointer;

    // Style label
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: ${ColorScheme.gray1.color};
`;

const Undo = ({ auction, lastKeeper, lastPick, undo, view }) => {
    const editKeepers = view === DraftboardViews.EDIT_KEEPER;
    const player = editKeepers ? lastKeeper : lastPick;
    const onClick = () => undo(editKeepers);
    return (
        <InfoContainer maxWidth="256px">
            <Container>
                <LabelContainer>
                    <Label onClick={onClick}>Undo</Label>
                    <IconButton
                        iconHref={UNDO_ICON}
                        action={onClick}
                        color={ColorScheme.gray1.color}
                        size="20px"
                        shadow
                    />
                </LabelContainer>
                <PlayerButton
                    position={player.position}
                    first={player.first}
                    last={player.last}
                    bye={player.bye}
                    team={player.team}
                    cost={auction && player.cost}
                    auction={auction}
                    location={CardLocation.UNDO}
                    onClick={onClick}
                />
            </Container>
        </InfoContainer>
    );
};

const mapStateToProps = (state) => {
    const {
        draftboard: { draftboard, draft },
    } = state;
    const newState = { auction: false };
    if (draftboard?.view) {
        newState.view = draftboard.view;
    }
    if (draftboard?.lastKeeper) {
        newState.lastKeeper = draftboard.lastKeeper;
    }
    if (draftboard?.lastPick) {
        newState.lastPick = draftboard.lastPick;
    }
    if (draft?.draftConfig === DraftConfig.AUCTION) {
        newState.auction = true;
    }
    return newState;
};

export default connect(mapStateToProps, { undo })(Undo);
