import styled, { css } from "styled-components";
import ColorScheme from "../styles/colors";
import Defaults, { DefaultTransition, FocusShadow } from "../styles/defaults";

export const bordercolor = (error) => {
    if (error) {
        return `${ColorScheme.red4.color} solid 1px`;
    }
    return `${ColorScheme.gray5.color} solid 1px`;
};

const InputStyle = css`
    ${FocusShadow}

    // Style input value
    font-family: inherit;
    color: ${(props) => (props.color ? props.color : ColorScheme.blue4.color)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};

    // Give space between input value and bounding box
    padding: ${(props) => (props.small ? "4px 6px" : "8px 12px")};

    // Style bounding box
    width: ${(props) => (props.width ? props.width : "100%")};
    outline: none; // outline property doesn't hug border-radius
    border: ${(props) => bordercolor(props.error)};
    border-radius: ${Defaults.borderRadius};
    background-color: ${(props) =>
        props.backgroundColor
            ? props.backgroundColor
            : ColorScheme.gray1.color};
`;

const SpecialInputStyle = css`
    ${DefaultTransition}

    // Remove default appearance
    appearance: none;

    // Set size
    height: ${(props) => (props.small ? "12px" : "16px")};
    max-width: ${(props) => (props.small ? "12px" : "16px")};
    width: 100%;

    // Set default background color
    background-color: ${(props) =>
        props.dark ? ColorScheme.gray4.color : ColorScheme.gray2.color};

    // Remove border
    border: none;

    // Set cursor appropriately
    cursor: pointer;

    // Change background color appropriately on events
    &:hover {
        background-color: ${(props) =>
            props.dark ? ColorScheme.gray5.color : ColorScheme.gray3.color};
    }
    &:checked {
        background-color: ${ColorScheme.blue4.color};
    }
    &:disabled {
        cursor: not-allowed;
        background-color: ${(props) =>
            props.dark ? ColorScheme.gray4.color : ColorScheme.gray2.color};
    }
`;

const ErrorStyle = css`
    color: ${ColorScheme.red4.color};
    font-size: 14px;
`;

export const FieldError = styled.div`
    ${ErrorStyle}
    position: absolute;
`;

export const AdjacentFieldError = styled.div`
    ${ErrorStyle}
    margin-left: 12px;
`;

export const inputError = (meta) => {
    const showSubmitError = meta.submitError && !meta.modifiedSinceLastSubmit;
    const showError = meta.touched && (meta.error || showSubmitError);
    const error = meta.error || meta.submitError;
    return { error, showError };
};

export const Checkbox = styled.input`
    ${SpecialInputStyle}
    border-radius: 4px;
`;

export const Radio = styled.input`
    ${SpecialInputStyle}
    border-radius: 50%;
`;

export const Select = styled.select`
    ${InputStyle}
`;

const Input = styled.input`
    ${InputStyle}
`;

export default Input;
