import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { HOME_ICON } from "../../js/constants";
import { DRAFT_DOES_NOT_EXIST } from "../../js/descriptions";
import Logo from "../common/Logo";
import ColorScheme from "../styles/colors";
import { StyleReset } from "../styles/Reset";

const Container = styled.div`
    width: 100%;
    height: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const LogoContainer = styled.div`
    width: 512px;
`;

const MessageContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${ColorScheme.red3.color};
    font-family: inherit;
    font-weight: 600;
    font-size: 30px;
`;

const HomeLink = styled(Link)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: none;
    color: ${ColorScheme.blue4.color};
    font-family: inherit;
    font-weight: 600;
    font-size: 24px;
`;

const SVG = styled.svg`
    width: 20px;
    height: 20px;
    margin-right: 8px;
`;

const DraftDoesNotExist = () => (
    <>
        <StyleReset bgcolor={ColorScheme.gray2.color} />
        <Container>
            <LogoContainer>
                <Logo />
            </LogoContainer>
            <MessageContainer>{DRAFT_DOES_NOT_EXIST}</MessageContainer>
            <MessageContainer>
                <HomeLink to="/">
                    <SVG>
                        <use fill={ColorScheme.blue4.color} href={HOME_ICON} />
                    </SVG>
                    <div>Return home</div>
                </HomeLink>
            </MessageContainer>
        </Container>
    </>
);

export default DraftDoesNotExist;
