import { SET_DRAFT_CONFIG, SIGN_OUT } from "../actions/types";

const DEFAULT_STATE = {
    refreshed: false,
    // draftConfig must be kept in-sync with draftconfig.json
    draftConfig: {
        Basic: {
            minLeagueSize: 8,
            maxLeagueSize: 11,
            maxRounds: 16,
            idp: false,
            validDraftTypes: ["Snake", "Linear"],
            timer: false,
            keeper: false,
            tradePicks: false,
            remoteDraft: false,
            price: 0,
        },
        Pro: {
            minLeagueSize: 8,
            maxLeagueSize: 15,
            maxRounds: 20,
            idp: false,
            validDraftTypes: ["Snake", "Linear", "Auction"],
            timer: true,
            keeper: false,
            tradePicks: false,
            remoteDraft: false,
            price: 1499,
        },
        Premium: {
            minLeagueSize: 8,
            maxLeagueSize: 24,
            maxRounds: 30,
            idp: true,
            validDraftTypes: ["Snake", "Linear", "Auction", "Custom"],
            timer: true,
            keeper: true,
            tradePicks: true,
            remoteDraft: true,
            price: 2499,
        },
        positions: [
            {
                abbreviation: "QB",
                position: "Quarterback",
                idp: false,
                max: 5,
            },
            {
                abbreviation: "RB",
                position: "Running Back",
                idp: false,
                max: 5,
            },
            {
                abbreviation: "WR",
                position: "Wide Receiver",
                idp: false,
                max: 5,
            },
            { abbreviation: "TE", position: "Tight End", idp: false, max: 5 },
            { abbreviation: "FLEX", position: "Flex", idp: false, max: 5 },
            { abbreviation: "K", position: "Kicker", idp: false, max: 5 },
            { abbreviation: "DST", position: "Defense", idp: false, max: 5 },
            { abbreviation: "BN", position: "Bench", idp: false, max: 10 },
            // IDP Positions
            {
                abbreviation: "DB",
                position: "Defensive Back",
                idp: true,
                max: 5,
            },
            {
                abbreviation: "DL",
                position: "Defensive Lineman",
                idp: true,
                max: 5,
            },
            { abbreviation: "LB", position: "Linebacker", idp: true, max: 5 },
            {
                abbreviation: "DEF",
                position: "Defensive Flex",
                idp: true,
                max: 5,
            },
        ],
    },
};

export const configReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_DRAFT_CONFIG:
            return { refreshed: true, draftConfig: action.config };
        case SIGN_OUT:
            return DEFAULT_STATE;
        default:
            return state;
    }
};
