import React from "react";
import styled from "styled-components";
import {
    CARD_HEIGHT,
    CARD_WIDTH,
    FONT_SIZES,
    MINUS_ICON,
    PLUS_ICON,
} from "../../../js/constants";
import Button, { buttonStyle } from "../../common/Button";
import IconButton from "../../common/IconButton";
import ColorScheme from "../../styles/colors";
import Defaults from "../../styles/defaults";
import { InfoContainer, InfoContainerH2 } from "./Container";

const SIZE_INCR = 2;

const OptionsContainer = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
`;

const InputLabel = styled.div`
    color: ${ColorScheme.blue4.color};
    font-size: 20px;
    font-weight: 400;
    margin-right: 16px;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${ColorScheme.blue4.color};
    border-radius: ${Defaults.borderRadius};
    padding: 8px 12px;

    > * {
        &:first-child {
            margin-right: 12px;
        }
        &:only-child {
            margin-right: 0px;
        }
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
`;

const AdjustSetting = ({ plus, minus }) => (
    <Buttons>
        <IconButton
            iconHref={MINUS_ICON}
            color={ColorScheme.blue5.color}
            action={minus}
            size="24px"
            shadow
        />
        <IconButton
            iconHref={PLUS_ICON}
            color={ColorScheme.blue5.color}
            action={plus}
            size="24px"
            shadow
        />
    </Buttons>
);

const GridSize = ({
    fontIndex,
    width,
    height,
    setFontIndex,
    setCardHeight,
    setCardWidth,
}) => {
    const addWidth = () => setCardWidth(width + SIZE_INCR);
    const subtractWidth = () => {
        if (width > SIZE_INCR) setCardWidth(width - SIZE_INCR);
    };
    const addHeight = () => setCardHeight(height + SIZE_INCR);
    const subtractHeight = () => {
        if (height > SIZE_INCR) setCardHeight(height - SIZE_INCR);
    };
    const addFont = () =>
        setFontIndex(Math.min(fontIndex + 1, FONT_SIZES.length - 1));
    const subtractFont = () => setFontIndex(Math.max(fontIndex - 1, 0));
    const reset = () => {
        setCardHeight(CARD_HEIGHT);
        setCardWidth(CARD_WIDTH);
        setFontIndex(0);
    };

    return (
        <InfoContainer>
            <InfoContainerH2>Draft Board Appearance</InfoContainerH2>
            <OptionsContainer>
                <InputContainer>
                    <InputLabel>Width</InputLabel>
                    <AdjustSetting plus={addWidth} minus={subtractWidth} />
                </InputContainer>
                <InputContainer>
                    <InputLabel>Height</InputLabel>
                    <AdjustSetting plus={addHeight} minus={subtractHeight} />
                </InputContainer>
                <InputContainer>
                    <InputLabel>Font</InputLabel>
                    <AdjustSetting plus={addFont} minus={subtractFont} />
                </InputContainer>
            </OptionsContainer>
            <ButtonContainer>
                <Button
                    size={buttonStyle.Size.MEDIUM}
                    color={buttonStyle.Color.BLUE}
                    priority={buttonStyle.Priority.SECONDARY}
                    text="Reset"
                    type="button"
                    onClick={reset}
                />
            </ButtonContainer>
        </InfoContainer>
    );
};

export default GridSize;
