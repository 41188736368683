import React from "react";
import styled from "styled-components";
import ColorScheme from "../../styles/colors";
import Defaults from "../../styles/defaults";

const CardContainer = styled.div`
    flex: 50%;
    margin-left: 12px;
`;

const Card = styled.div`
    // Set dimensions (should be equal to PlayerCard)
    height: 64px;
    width: 128px;

    // Style card
    background-color: ${ColorScheme.gray3.color};
    border: 2px solid
        ${(props) => (props.color ? props.color : ColorScheme.gray7.color)};
    box-shadow: ${Defaults.shadow};

    // Style text
    font-family: inherit;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
    font-weight: 700;
    color: ${(props) => (props.color ? props.color : ColorScheme.gray7.color)};

    // Make sure text doesn't overflow
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // Center text
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TextCard = ({ cardText, fontSize, color }) => (
    <CardContainer>
        <Card fontSize={fontSize} color={color}>
            {cardText}
        </Card>
    </CardContainer>
);

export default TextCard;
