import {
    GET_USER,
    RENDER_EMAIL_PREFERENCE_DETAILS,
    RENDER_EMAIL_PREFERENCE_FORM,
    RENDER_PASSWORD_FORM,
    RENDER_PROFILE_DETAILS,
    RENDER_PROFILE_FORM,
    RENDER_VERIFY_FORM,
    RESEND_VERIFY_CODE,
    RESET_PASSWORD_STATUS,
    RESET_RESEND_VERIFY_STATUS,
    SIGN_OUT,
    UPDATE_EMAIL_PREFERENCE,
    UPDATE_PASSWORD,
    UPDATE_USER,
    VERIFY_EMAIL_UPDATE,
} from "../actions/types";

const DEFAULT_STATE = {
    resendCode: { loading: false },
    shouldRenderEmailPreferenceForm: false,
    shouldRenderForm: false,
    shouldRenderPassword: false,
    shouldRenderVerify: false,
    updateUser: { loading: false },
    updatePassword: { loading: false },
    updateEmailPreference: { loading: false },
    verifyEmailUpdate: { loading: false },
};

export const profileReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case GET_USER:
            return { ...state, metadata: action.metadata };
        case RENDER_EMAIL_PREFERENCE_DETAILS:
            return {
                ...state,
                shouldRenderEmailPreferenceForm: false,
            };
        case RENDER_EMAIL_PREFERENCE_FORM:
            return {
                ...state,
                shouldRenderEmailPreferenceForm: true,
            };
        case RENDER_PASSWORD_FORM:
            return {
                ...state,
                shouldRenderPassword: true,
                shouldRenderForm: false,
                shouldRenderVerify: false,
            };
        case RENDER_PROFILE_DETAILS:
            return {
                ...state,
                shouldRenderPassword: false,
                shouldRenderForm: false,
                shouldRenderVerify: false,
            };
        case RENDER_PROFILE_FORM:
            return {
                ...state,
                shouldRenderPassword: false,
                shouldRenderForm: true,
                shouldRenderVerify: false,
            };
        case RENDER_VERIFY_FORM:
            return {
                ...state,
                shouldRenderPassword: false,
                shouldRenderForm: false,
                shouldRenderVerify: true,
            };
        case RESEND_VERIFY_CODE:
            if (!action.error && !action.success) {
                return { ...state, resendCode: { loading: true } };
            } else if (action.error) {
                return {
                    ...state,
                    resendCode: {
                        success: false,
                        error: action.error,
                        loading: false,
                    },
                };
            }
            return {
                ...state,
                resendCode: { success: true, loading: false },
                shouldRenderForm: false,
                shouldRenderPassword: false,
                shouldRenderVerify: true,
                updatePassword: { loading: false },
                updateUser: { loading: false },
                verifyEmailUpdate: { loading: false },
            };
        case RESET_RESEND_VERIFY_STATUS:
            return { ...state, resendCode: { loading: false } };
        case RESET_PASSWORD_STATUS:
            return {
                ...state,
                updatePassword: { ...state.updatePassword, success: false },
            };
        case UPDATE_EMAIL_PREFERENCE:
            if (!action.error && !action.success) {
                return { ...state, updateEmailPreference: { loading: true } };
            } else if (action.error) {
                return {
                    ...state,
                    updateEmailPreference: {
                        success: false,
                        error: action.error,
                        loading: false,
                    },
                };
            }
            return {
                ...state,
                metadata: action.metadata,
                shouldRenderEmailPreferenceForm: false,
                updateEmailPreference: { success: true, loading: false },
            };
        case UPDATE_PASSWORD:
            if (!action.error && !action.success) {
                return { ...state, updatePassword: { loading: true } };
            } else if (action.error) {
                return {
                    ...state,
                    updatePassword: {
                        success: false,
                        error: action.error,
                        loading: false,
                    },
                };
            }
            return {
                ...state,
                resendCode: { loading: false },
                shouldRenderForm: false,
                shouldRenderPassword: false,
                shouldRenderVerify: false,
                updatePassword: { loading: false, success: true },
                updateUser: { loading: false },
                verifyEmailUpdate: { loading: false },
            };
        case UPDATE_USER:
            if (!action.error && !action.success) {
                return { ...state, updateUser: { loading: true } };
            } else if (action.error) {
                return {
                    ...state,
                    updateUser: {
                        success: false,
                        error: action.error,
                        loading: false,
                    },
                };
            }
            return {
                ...state,
                resendCode: { loading: false },
                shouldRenderForm: false,
                shouldRenderPassword: false,
                shouldRenderVerify: action.emailChanged,
                updatePassword: { loading: false },
                updateUser: { success: true, loading: false },
                verifyEmailUpdate: { loading: false },
            };
        case VERIFY_EMAIL_UPDATE:
            if (!action.error && !action.success) {
                return { ...state, verifyEmailUpdate: { loading: true } };
            } else if (action.error) {
                return {
                    ...state,
                    verifyEmailUpdate: {
                        success: false,
                        error: action.error,
                        loading: false,
                    },
                };
            }
            return {
                ...state,
                resendCode: { loading: false },
                shouldRenderForm: false,
                shouldRenderPassword: false,
                shouldRenderVerify: false,
                updatePassword: { loading: false },
                updateUser: { loading: false },
                verifyEmailUpdate: { success: true, loading: false },
            };
        case SIGN_OUT:
            return DEFAULT_STATE;
        default:
            return state;
    }
};
