import {
    ADD_ACTION,
    AUCTION_CHANGE,
    CHANGE_VIEW,
    FILTER_CHANGE,
    GET_DRAFTBOARD,
    GET_PLAYERS,
    GET_RESULTS,
    INGEST_ACTIONS,
    LOADING,
    PAUSE_TIMER,
    POST_ACTIONS,
    RESET_DRAFTBOARD,
    RESET_SELECT_PLAYER,
    RESET_TIMER,
    RESUME_TIMER,
    SEARCH_CHANGE,
    SELECT_PLAYER,
    SET_AUCTION_DISPLAY,
    SET_AUTOCOMPLETE_PLAYERS,
    SET_CARD_HEIGHT,
    SET_CARD_WIDTH,
    SET_DISPLAY_PLAYER_DETAILS,
    SET_FONT_INDEX,
    SIGN_OUT,
    SORT_BY_NAME,
    SORT_BY_RANK,
    TOGGLE_SELECT_PLAYER,
    UPDATE_AUTODRAFT,
} from "../actions/types";
import {
    AuctionDisplay,
    CARD_HEIGHT,
    CARD_WIDTH,
    DraftActions,
    DraftStatus,
    DraftboardViews,
    Positions,
} from "../js/constants";
import { draft, remove, resetDrafted } from "../js/draftboard/directory";
import {
    actionIndex,
    actions,
    actionsValid,
    auctionBudgets,
    board,
    currTeamRoundOpenings,
    getPlayer,
    graftActions,
    initializeDraftboard,
    lastKeeper,
    lastPick,
    maximumBids,
    processAction,
    repeatAction,
    resetDraftboardActions,
    totalPicks,
    trades,
} from "../js/draftboard/draftboard";

const DEFAULT_STATE = {
    directory: { loaded: false },
    autocomplete: {
        auction: 0,
        filter: Positions.ALL,
        search: "",
        players: [],
        sortByRank: true,
        // Indicates which player in the autocomplete section has been selected
        selectedPlayer: -1,
    },
    draftboard: {
        // Indicates whether draftboard has been initialized
        loaded: false,
        // Indicates what view the user is currently on
        view: DraftboardViews.ROUND,
        // Indicates the dimensions/font sizes of the cards on the grid
        cardHeight: CARD_HEIGHT,
        cardWidth: CARD_WIDTH,
        fontIndex: 0,
        // Indicates whether to show or hide player details on draftboard
        showDraftboardDetails: "On",
        // Indicates whether to show budget or maximum bid
        auctionDisplay: AuctionDisplay.BUDGET,
        // Holds all metadata for the timer component
        timer: {
            start: null,
            stop: null,
            duration: 0,
        },
        // Holds what index we need to commit next to the backend
        commitIndex: 0,
        // Holds information for rendering the draftboard
        // These are derived from the draftboard library
        lastKeeper: null,
        lastPick: null,
        board: [],
        auctionBudgets: [],
        maximumBids: [],
        actions: [],
        currTeam: 0,
        currRound: 0,
        openings: [],
        trades: [],
        totalPicks: 0,
        actionIndex: 0,
        // Indicates which buttons should display a loading spinner
        loading: {
            savingKeepers: false,
            startingDraft: false,
            resettingDraft: false,
            exitingDraft: false,
            finishingDraft: false,
        },
    },
};

const loadDraftboardState = (state) => {
    state.draftboard.lastKeeper = lastKeeper();
    state.draftboard.lastPick = lastPick();
    state.draftboard.auctionBudgets = auctionBudgets();
    state.draftboard.maximumBids = maximumBids();
    state.draftboard.actions = actions();
    state.draftboard.trades = trades();
    state.draftboard.board = board();
    state.draftboard.totalPicks = totalPicks();
    state.draftboard.actionIndex = actionIndex();
    const [currTeam, currRound, openings] = currTeamRoundOpenings();
    state.draftboard.currTeam = currTeam;
    state.draftboard.currRound = currRound;
    state.draftboard.openings = openings;
};

export const draftboardReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        // Draftboard onload actions
        case GET_DRAFTBOARD: {
            if (action.draft === null) return { ...state, draft: null };
            if (!action.draft) return state;

            // Load GetDraftboard response into newState
            const { draft } = action;
            const newState = {
                ...state,
                autocomplete: { ...state.autocomplete },
                draft,
                draftboard: {
                    ...state.draftboard,
                    commitIndex: draft.actions ? draft.actions.length : 0,
                },
            };

            // Fill in auction budget for autocomplete parameters
            if (draft?.auctionDraftOptions) {
                newState.autocomplete.auction =
                    draft.auctionDraftOptions.minimumBid;
            }

            // Create draftboard from actions and load state into store
            initializeDraftboard(draft);
            newState.draftboard.loaded = true;
            loadDraftboardState(newState);

            // Fill in timer parameters, if necessary
            if (draft?.timer.enabled) {
                const total = draft.rounds * draft.numPlayers;
                const currTotal = newState.draftboard.totalPicks;
                const inProgress = draft.status === DraftStatus.IN_PROGRESS;
                if (inProgress && currTotal < total) {
                    newState.draftboard.timer = {
                        start: new Date().getTime(),
                        stop: 0,
                        duration: draft.timer.duration,
                    };
                }
            }
            return newState;
        }
        case GET_RESULTS: {
            if (!action.draft) {
                return state;
            }
            const { draft } = action;
            const newState = { ...state, draft };

            // Create draftboard from actions and load state into store
            initializeDraftboard(draft);
            newState.draftboard.loaded = true;
            loadDraftboardState(newState);

            return newState;
        }
        case GET_PLAYERS:
            if (action.loaded) {
                return { ...state, directory: { loaded: true } };
            }
            return state;
        // Draftboard autocomplete actions
        case SEARCH_CHANGE:
            if (action.val || action.val === "") {
                return {
                    ...state,
                    autocomplete: {
                        ...state.autocomplete,
                        search: action.val,
                        selectedPlayer: -1,
                    },
                };
            }
            return state;
        case AUCTION_CHANGE:
            return {
                ...state,
                autocomplete: {
                    ...state.autocomplete,
                    auction: action.val,
                },
            };
        case FILTER_CHANGE:
            if (action.val || action.val === "") {
                return {
                    ...state,
                    autocomplete: {
                        ...state.autocomplete,
                        selectedPlayer: -1,
                        filter: action.val,
                    },
                };
            }
            return state;
        case SET_AUTOCOMPLETE_PLAYERS:
            if (action.players) {
                return {
                    ...state,
                    autocomplete: {
                        ...state.autocomplete,
                        players: action.players,
                    },
                };
            }
            return state;
        // Draftboard settings actions
        case SORT_BY_NAME:
            return {
                ...state,
                autocomplete: { ...state.autocomplete, sortByRank: false },
            };
        case SORT_BY_RANK:
            return {
                ...state,
                autocomplete: { ...state.autocomplete, sortByRank: true },
            };
        case UPDATE_AUTODRAFT:
            const newDraftOrder = structuredClone(state.draft.draftOrder);
            newDraftOrder[action.playerIndex].autodraft = action.autodraft;
            return {
                ...state,
                draft: { ...state.draft, draftOrder: newDraftOrder },
            };
        case SET_AUCTION_DISPLAY:
            if (action.setting) {
                return {
                    ...state,
                    draftboard: {
                        ...state.draftboard,
                        auctionDisplay: action.setting,
                    },
                };
            }
            return state;
        case SET_DISPLAY_PLAYER_DETAILS:
            if (action.setting) {
                return {
                    ...state,
                    draftboard: {
                        ...state.draftboard,
                        showDraftboardDetails: action.setting,
                    },
                };
            }
            return state;
        case SET_CARD_HEIGHT:
            if (action.height) {
                return {
                    ...state,
                    draftboard: {
                        ...state.draftboard,
                        cardHeight: action.height,
                    },
                };
            }
            return state;
        case SET_CARD_WIDTH:
            if (action.width) {
                return {
                    ...state,
                    draftboard: {
                        ...state.draftboard,
                        cardWidth: action.width,
                    },
                };
            }
            return state;
        case SET_FONT_INDEX:
            if (action.index || action.index === 0) {
                return {
                    ...state,
                    draftboard: {
                        ...state.draftboard,
                        fontIndex: action.index,
                    },
                };
            }
            return state;
        // Draftboard infobar actions
        case CHANGE_VIEW:
            if (action.view) {
                return {
                    ...state,
                    draftboard: { ...state.draftboard, view: action.view },
                };
            }
            return state;
        // Draftboard timer actions
        case PAUSE_TIMER: {
            if (!state.draft.timer.enabled) return state;
            const { duration, start } = state.draftboard.timer;
            const currTime = new Date().getTime();
            const timeLeft = duration - Math.floor((currTime - start) / 1000);
            return {
                ...state,
                draftboard: {
                    ...state.draftboard,
                    timer: { start, duration: timeLeft, paused: true },
                },
            };
        }
        case RESUME_TIMER:
            if (!state.draft.timer.enabled) return state;
            const { duration } = state.draftboard.timer;
            const start = new Date().getTime();
            return {
                ...state,
                draftboard: {
                    ...state.draftboard,
                    timer: { start, duration, paused: false },
                },
            };
        case RESET_TIMER:
            if (!state.draft.timer.enabled) return state;
            return {
                ...state,
                draftboard: {
                    ...state.draftboard,
                    timer: {
                        start: new Date().getTime(),
                        duration: state.draft.timer.duration,
                        paused: false,
                    },
                },
            };
        // Draftboard autocomplete actions
        case RESET_SELECT_PLAYER:
            return {
                ...state,
                autocomplete: {
                    ...state.autocomplete,
                    selectedPlayer: -1,
                },
            };
        case SELECT_PLAYER:
            if (!action.index && action.index !== 0) return state;
            return {
                ...state,
                autocomplete: {
                    ...state.autocomplete,
                    selectedPlayer: action.index,
                },
            };
        case TOGGLE_SELECT_PLAYER:
            if (!action.index && action.index !== 0) return state;
            const { selectedPlayer } = state.autocomplete;
            return {
                ...state,
                autocomplete: {
                    ...state.autocomplete,
                    selectedPlayer:
                        action.index === selectedPlayer ? -1 : action.index,
                },
            };
        // Draftboard draft actions
        case LOADING:
            if (action.action) {
                switch (action.action) {
                    case DraftActions.EXIT_DRAFT:
                        return {
                            ...state,
                            draftboard: {
                                ...state.draftboard,
                                loading: {
                                    ...state.draftboard.loading,
                                    exitingDraft: true,
                                },
                            },
                        };
                    case DraftActions.START:
                        return {
                            ...state,
                            draftboard: {
                                ...state.draftboard,
                                loading: {
                                    ...state.draftboard.loading,
                                    startingDraft: true,
                                },
                            },
                        };
                    case DraftActions.RESET:
                        return {
                            ...state,
                            draftboard: {
                                ...state.draftboard,
                                loading: {
                                    ...state.draftboard.loading,
                                    resettingDraft: true,
                                },
                            },
                        };
                    case DraftActions.FINISH:
                        return {
                            ...state,
                            draftboard: {
                                ...state.draftboard,
                                loading: {
                                    ...state.draftboard.loading,
                                    finishingDraft: true,
                                },
                            },
                        };
                    case DraftActions.SAVE_KEEPERS:
                        return {
                            ...state,
                            draftboard: {
                                ...state.draftboard,
                                loading: {
                                    ...state.draftboard.loading,
                                    savingKeepers: true,
                                },
                            },
                        };
                    default:
                        return state;
                }
            }
            return state;
        case POST_ACTIONS:
            if (
                action.success &&
                action.lastIndex > state.draftboard.commitIndex
            ) {
                return {
                    ...state,
                    draftboard: {
                        ...state.draftboard,
                        commitIndex: action.lastIndex,
                        loading: {
                            resettingDraft: false,
                            savingKeepers: false,
                            startingDraft: false,
                            exitingDraft: false,
                            finishingDraft: false,
                        },
                    },
                };
            } else if (action.error) {
                // Failure is ok, since we can rely on the next POST_ACTIONS
                return {
                    ...state,
                    draftboard: {
                        ...state.draftboard,
                        loading: {
                            resettingDraft: false,
                            savingKeepers: false,
                            startingDraft: false,
                            exitingDraft: false,
                            finishingDraft: false,
                        },
                    },
                };
            }
            return state;
        case ADD_ACTION:
            if (action.action && !repeatAction(action.action)) {
                // Send action to draftboard and update store
                const newState = structuredClone(state);
                processAction(action.action);
                loadDraftboardState(newState);

                // Perform action specific state modifications
                switch (action.action.action) {
                    case DraftActions.START:
                        newState.draft = {
                            ...newState.draft,
                            status: DraftStatus.IN_PROGRESS,
                        };
                        // Initialize timer
                        const { draft } = newState;
                        if (draft.timer.enabled) {
                            const total = draft.rounds * draft.numPlayers;
                            const currTotal = newState.draftboard.totalPicks;
                            if (currTotal < total) {
                                newState.draftboard.timer = {
                                    start: new Date().getTime(),
                                    stop: 0,
                                    duration: draft.timer.duration,
                                };
                            }
                        }
                        break;
                    case DraftActions.RESET:
                        // Reset timer
                        newState.draft = {
                            ...newState.draft,
                            status: DraftStatus.UPCOMING,
                        };
                        newState.draftboard.timer = {
                            start: null,
                            stop: null,
                            duration: 0,
                        };
                        break;
                    case DraftActions.FINISH:
                        newState.draft = {
                            ...state.draft,
                            status: DraftStatus.COMPLETED,
                        };
                        // Reset commit index
                        newState.draftboard.commitIndex = 0;
                        break;
                    default:
                        break;
                }
                return newState;
            }
            return state;
        case INGEST_ACTIONS:
            if (action.actions) {
                // If ingested actions are invalid (i.e. contradict the existing
                // draftboard), we assume ingested actions are correct. Reset
                // draftboard state and re-construct + re-process all actions
                // from the beginning
                const { actions } = action;
                let allActions = actions;
                if (!actionsValid(actions)) {
                    allActions = graftActions(actions);
                    resetDraftboardActions();
                    resetDrafted();
                }

                // Process allActions
                let startSeen = false;
                let resetSeen = false;
                let finishSeen = false;
                let newActions = false;
                for (let i = 0; i < allActions.length; i++) {
                    const currAction = allActions[i];
                    if (repeatAction(currAction)) continue;

                    // Reflect SELECT and UNDO actions onto directory
                    // This happens before processAction, because we need access
                    // to lastPick() / lastKeeper() before it gets updated
                    switch (currAction.action) {
                        case DraftActions.SELECT:
                            draft(currAction.fantasyPlayer);
                            break;
                        case DraftActions.UNDO:
                            if (currAction.keeper) remove(lastKeeper());
                            else remove(lastPick());
                            break;
                        case DraftActions.REPLACE:
                            const { team, round } = currAction;
                            const oldPlayer = getPlayer(team, round);
                            draft(currAction.fantasyPlayer);
                            remove(oldPlayer);
                            break;
                        case DraftActions.START:
                            startSeen = true;
                            resetSeen = false;
                            break;
                        case DraftActions.RESET:
                            resetSeen = true;
                            startSeen = false;
                            break;
                        case DraftActions.FINISH:
                            finishSeen = true;
                            break;
                        default:
                            break;
                    }
                    processAction(currAction);
                    newActions = true;
                }
                if (!newActions) return state;

                // Load draftboard state into store and set commit index
                const newState = structuredClone(state);
                loadDraftboardState(newState);
                const newCommitIndex = actions[actions.length - 1].index + 1;

                // Perform action specific newState modifications
                if (startSeen) {
                    newState.draft = {
                        ...newState.draft,
                        status: DraftStatus.IN_PROGRESS,
                    };
                    newState.draftboard = {
                        ...newState.draftboard,
                        loading: {
                            ...newState.draftboard.loading,
                            startingDraft: false,
                        },
                    };
                    if (newCommitIndex > state.draftboard.commitIndex) {
                        newState.draftboard.commitIndex = newCommitIndex;
                    }
                    // Initialize timer
                    const { draft } = newState;
                    if (draft.timer.enabled) {
                        const total = draft.rounds * draft.numPlayers;
                        const currTotal = newState.draftboard.totalPicks;
                        if (currTotal < total) {
                            newState.draftboard.timer = {
                                start: new Date().getTime(),
                                stop: 0,
                                duration: draft.timer.duration,
                            };
                        }
                    }
                }
                if (resetSeen) {
                    newState.draft = {
                        ...newState.draft,
                        status: DraftStatus.UPCOMING,
                    };
                    newState.draftboard = {
                        ...newState.draftboard,
                        loading: {
                            ...newState.draftboard.loading,
                            resettingDraft: false,
                        },
                    };
                    // Reset timer
                    newState.draftboard.timer = {
                        start: null,
                        stop: null,
                        duration: 0,
                    };
                    if (newCommitIndex > state.draftboard.commitIndex) {
                        newState.draftboard.commitIndex = newCommitIndex;
                    }
                }
                if (finishSeen) {
                    newState.draft = {
                        ...newState.draft,
                        status: DraftStatus.COMPLETED,
                    };
                    newState.draftboard = {
                        ...newState.draftboard,
                        loading: {
                            ...newState.draftboard.loading,
                            finishingDraft: false,
                        },
                    };
                    // Reset commit index
                    newState.draftboard.commitIndex = 0;
                }
                return newState;
            }
            return state;
        case RESET_DRAFTBOARD:
        case SIGN_OUT:
            return DEFAULT_STATE;
        default:
            return state;
    }
};
