import React from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import {
    AuctionDisplay,
    CARD_WIDTH,
    DraftboardViews,
    DraftConfig,
    DraftStatus,
} from "../../../js/constants";
import ColorScheme, { Draftboard } from "../../styles/colors";
import Defaults, { DefaultTransition } from "../../styles/defaults";

const InfoContainer = styled.div`
    display: flex;
    margin-bottom: 8px;
`;

const NameContainer = styled.div`
    margin-left: 2px;
    box-shadow: ${Defaults.shadow};
`;

const ContainerSize = css`
    width: ${(props) => (props.width ? `${props.width}px` : `${CARD_WIDTH}px`)};
    height: 24px;
`;

const TeamNameContainer = styled.div`
    ${ContainerSize}
    ${(props) =>
        props.selected
            ? Draftboard.Background.red
            : Draftboard.Background.blue};

    // Center text
    display: flex;
    justify-content: center;
    align-items: center;

    // Style text
    color: ${ColorScheme.gray1.color};
    font-size: ${(props) => (props.length <= 13 ? "16px" : "14px")};
    font-weight: 700;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const AuctionContainer = styled.div`
    ${ContainerSize}
    background-color: ${ColorScheme.gray2.color};
    position: relative;

    > * {
        ${DefaultTransition}
        &:first-child {
            transform: translate(0);
            opacity: 100;
        }
        &:last-child {
            transform: translate(0, 10px);
            opacity: 0;
        }
    }

    &:hover > * {
        &:first-child {
            transform: translate(0, -10px);
            opacity: 0;
        }
        &:last-child {
            transform: translate(0);
            opacity: 100;
        }
    }
`;

const AuctionText = styled.div`
    ${ContainerSize}
    position: absolute;

    // Center text
    display: flex;
    justify-content: center;
    align-items: center;

    // Style text
    color: ${Draftboard.Text.blue.color};
    font-size: 16px;
    font-weight: 600;
`;

const EmptySpacer = styled.div`
    height: 48px;
    width: 32px;
    background-color: transparent;
`;

const TeamInfo = ({
    name,
    budget,
    selected,
    auction,
    auctionDisplay,
    maxBid,
    width,
}) => (
    <NameContainer>
        <TeamNameContainer
            width={width}
            selected={selected}
            length={name.length}
        >
            {name}
        </TeamNameContainer>
        {auction ? (
            <AuctionContainer width={width}>
                {auctionDisplay === AuctionDisplay.BUDGET ? (
                    <>
                        <AuctionText width={width}>{`$${budget}`}</AuctionText>
                        <AuctionText
                            width={width}
                        >{`Max Bid: $${maxBid}`}</AuctionText>
                    </>
                ) : (
                    <>
                        <AuctionText
                            width={width}
                        >{`Max Bid: $${maxBid}`}</AuctionText>
                        <AuctionText width={width}>{`$${budget}`}</AuctionText>
                    </>
                )}
            </AuctionContainer>
        ) : (
            <AuctionContainer width={width} />
        )}
    </NameContainer>
);

const AllInfo = ({
    draftOrder,
    auction,
    currTeam,
    inProgress,
    cardWidth,
    view,
}) => {
    const info = [];
    info.push(<EmptySpacer key="empty-spacer" />);
    const showSelected = inProgress && view === DraftboardViews.ROUND;
    for (let i = 0; i < draftOrder.length; i++) {
        info.push(
            <TeamInfo
                name={draftOrder[i].team}
                budget={auction.enabled && auction.budgets[i]}
                selected={showSelected && currTeam === i}
                auction={auction.enabled}
                auctionDisplay={auction.enabled && auction.auctionDisplay}
                maxBid={auction.enabled && auction.maxBids[i]}
                width={cardWidth}
                key={`team-info-${i}${currTeam === i ? "-selected" : ""}`}
            />
        );
    }
    return <InfoContainer>{info}</InfoContainer>;
};

const mapStateToProps = (state) => {
    const newState = { auction: { enabled: false } };
    if (state.draftboard?.draft) {
        const { draft } = state.draftboard;
        newState.draftOrder = draft.draftOrder;
        newState.inProgress = draft.status === DraftStatus.IN_PROGRESS;
        if (draft.draftConfig === DraftConfig.AUCTION) {
            newState.auction.enabled = true;
            newState.auction.minimumBid = draft.auctionDraftOptions.minimumBid;
        }
    }
    if (state.draftboard?.draftboard) {
        const { draftboard } = state.draftboard;
        if (newState.auction.enabled) {
            newState.auction.budgets = draftboard.auctionBudgets;
            newState.auction.maxBids = draftboard.maximumBids;
            newState.auction.auctionDisplay = draftboard.auctionDisplay;
        }
        newState.view = draftboard.view;
        newState.currTeam = draftboard.currTeam;
        newState.cardWidth = draftboard.cardWidth;
    }
    return newState;
};
export default connect(mapStateToProps)(AllInfo);
