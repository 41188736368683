import { RestAPI } from "@aws-amplify/api-rest";
import { CREATE_DRAFT } from "../actions/types";
import { cognitoAuthHeader } from "../js/auth";
import { DraftConfig, UNSCHEDULED } from "../js/constants";
import { apiErrors } from "../js/errors";
import history from "../js/history";
import { createPayment } from "../js/stripe";
import {
    customOrderList,
    draftOrder,
    numRounds,
    parsePositions,
} from "../js/util";

// Construct CreateDraft request
const createRequest = (values, draftConfig, paymentID) => {
    const request = {
        name: values.name,
        draftType: values.draftType,
        draftConfig: values.draftConfig,
        idp: values.idp,
        timer: { enabled: values.timer.enabled },
        keeper: values.keeper,
        tradePicks: values.tradePicks,
        remoteDraft: values.remoteDraft,
        emailSetting: values.emailSetting,
        positions: parsePositions(values, draftConfig),
        draftOrder: draftOrder(values.leagueSize, values.draftOrder),
    };

    // Add draft date to request, if provided
    request.draftDate = values.draftDate ? values.draftDate : UNSCHEDULED;
    // Add paymentID, if provided
    if (paymentID !== "") request.paymentID = paymentID;
    // Add timer duration, if provided
    if (values.timer.enabled && values.timer.duration)
        request.timer.duration = parseInt(values.timer.duration);
    // Add coupon code
    if (values.payment?.couponCode)
        request.couponCode = values.payment?.couponCode;
    // Add save credit card flag, if provided
    // if (values.payment && values.payment.saveCreditCard) {
    //     request.saveCreditCard = true;
    // }

    // Add specialized draft settings, if necessary
    if (values.draftConfig === DraftConfig.AUCTION) {
        request.auctionDraftOptions = {
            budget: parseInt(values.auctionDraftOptions.budget),
            minimumBid: parseInt(values.auctionDraftOptions.minimumBid),
            nominationOrder: values.auctionDraftOptions.nominationOrder,
        };
    } else if (values.draftConfig === DraftConfig.CUSTOM) {
        const rounds = numRounds(
            values.positions,
            values.draftType,
            values.idp,
            draftConfig
        );
        request.customDraftOrder = customOrderList(
            values.customDraftOrder,
            rounds
        );
    }
    return request;
};

export const createDraft =
    (values, cardElement, stripe, price) => async (dispatch, getState) => {
        // Set loading prop to true
        dispatch({ type: CREATE_DRAFT });

        // Create paymentMethod
        let id = "";
        let error = null;
        if (price > 0) {
            const response = await createPayment(values, cardElement, stripe);
            id = response.id;
            error = response.error;
            if (error) return { field: "creditCard", error };
        }

        // Get draftConfig from state
        const { draftConfig } = getState().config;

        try {
            // Send CreateDraft API request
            const request = {
                body: createRequest(values, draftConfig, id),
                headers: await cognitoAuthHeader(),
            };
            await RestAPI.post(process.env.API_NAME, "/drafts", request);
            dispatch({ type: CREATE_DRAFT, success: true });
            history.push("/drafts/created");
        } catch (err) {
            dispatch({ type: CREATE_DRAFT, error: err.response.data });
            const { error, message } = err.response.data;
            switch (error) {
                case apiErrors.CreditCard:
                    return { field: "creditCard", error: { message } };
                case apiErrors.DynamoDB:
                    return { field: "internal", error: { message } };
                case apiErrors.Internal:
                    return { field: "internal", error: { message } };
                case apiErrors.User:
                    return { field: "user", error: { message } };
                default:
                    return { field: "internal", error: { message } };
            }
        }
    };
