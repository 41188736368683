import React from "react";
import styled from "styled-components";
import { NO_DRAFTS_DESCRIPTION } from "../../../js/descriptions";
import ColorScheme from "../../styles/colors";
import Defaults from "../../styles/defaults";
import CreateDraftButton from "./Button";

const NoDraftsContainer = styled.div`
    max-width: 640px;
    width: 100%;

    // Center container and add padding between header and container
    margin: 32px auto;
    padding: 64px 32px;

    // Style container
    border-radius: ${Defaults.borderRadius};
    background-color: ${ColorScheme.blue1.color};
    border: 1px solid ${ColorScheme.blue4.color};
    box-shadow: ${Defaults.shadow};
`;

const NoDraftsHeader = styled.h1`
    // Style text
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    color: ${ColorScheme.blue6.color};
`;

const NoDraftsDescription = styled.div`
    // Style text
    font-size: 20px;
    text-align: center;
    color: ${ColorScheme.blue3.color};
`;

const NoDrafts = ({ createDraft, user }) => (
    <>
        <NoDraftsContainer>
            <NoDraftsHeader>Create Your First Draft</NoDraftsHeader>
            <NoDraftsDescription>{NO_DRAFTS_DESCRIPTION}</NoDraftsDescription>
        </NoDraftsContainer>
        <CreateDraftButton createDraft={createDraft} user={user} />
    </>
);

export default NoDrafts;
