import React from "react";
import { Field } from "react-final-form";
import styled, { css } from "styled-components";
import Input, { FieldError, inputError } from "../../common/Input";
import ColorScheme from "../../styles/colors";

const INPUT_WIDTH = "384px";

const LabeledDivision = css`
    width: 100%;
    display: flex;
    align-items: baseline;
`;

const LabelStyle = css`
    font-size: 20px;
    font-family: inherit;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
    color: ${(props) =>
        props.disabled ? ColorScheme.gray4.color : ColorScheme.blue5.color};
`;

const LabelContainerStyle = css`
    text-align: right;
    margin-right: 24px;
`;

const ValueStyle = css`
    font-size: 18px;
    font-family: inherit;
`;

export const Value = styled.div`
    ${ValueStyle};
    color: ${ColorScheme.blue4.color};
`;

export const SuccessValue = styled.div`
    ${ValueStyle};
    color: ${ColorScheme.green4.color};
`;

export const LabeledPartitionContainer = styled.div`
    ${LabeledDivision}
    margin-top: 32px;
`;

export const LabeledSectionContainer = styled.div`
    ${LabeledDivision}
    margin-top: 24px;
`;

export const LabeledDivisionContainer = styled.div`
    ${LabeledDivision}
    margin-top: 12px;
`;

export const LabeledFieldContainer = styled.div`
    ${LabeledDivision}
`;

export const LabelContainer = styled.div`
    ${LabelContainerStyle}
    flex: 50%;
`;

export const FieldContainer = styled.div`
    flex: 50%;
`;

export const OffsetLabelContainer = styled.div`
    ${LabelContainerStyle}
    flex: 30%;
`;

export const OffsetFieldContainer = styled.div`
    flex: 70%;
`;

export const Label = styled.div`
    ${LabelStyle}
`;

export const InputLabel = styled.label`
    ${LabelStyle}
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const LabeledInput = ({
    label,
    fieldName,
    type,
    tabIndex,
    min,
    minLength,
    validate,
}) => (
    <LabeledSectionContainer>
        <OffsetLabelContainer>
            <InputLabel htmlFor={fieldName}>{label}</InputLabel>
        </OffsetLabelContainer>
        <OffsetFieldContainer>
            <Field name={fieldName} validate={validate}>
                {({ input, meta }) => {
                    const { error, showError } = inputError(meta);
                    return (
                        <>
                            <Input
                                id={fieldName}
                                type={type}
                                tabIndex={tabIndex}
                                min={min}
                                minLength={minLength}
                                error={showError}
                                width={INPUT_WIDTH}
                                {...input}
                            />
                            {showError && <FieldError>{error}</FieldError>}
                        </>
                    );
                }}
            </Field>
        </OffsetFieldContainer>
    </LabeledSectionContainer>
);

export const LabeledValueDivision = ({ label, value, children }) => (
    <LabeledDivisionContainer>
        <OffsetLabelContainer>
            <Label>{label}</Label>
        </OffsetLabelContainer>
        <OffsetFieldContainer>
            <Value>{value}</Value>
            {children}
        </OffsetFieldContainer>
    </LabeledDivisionContainer>
);

export const LabeledValuePartition = ({ label, value, children }) => (
    <LabeledPartitionContainer>
        <OffsetLabelContainer>
            <Label>{label}</Label>
        </OffsetLabelContainer>
        <OffsetFieldContainer>
            <Value>{value}</Value>
            {children}
        </OffsetFieldContainer>
    </LabeledPartitionContainer>
);
