import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { DraftboardViews, DraftStatus } from "../../js/constants";
import Logo from "../common/Logo";
import ColorScheme, { Draftboard } from "../styles/colors";
import ActionIcons from "./actionbar/ActionIcons";
import AutocompleteCards from "./actionbar/AutocompleteCards";
import SearchBar from "./actionbar/SearchBar";
import InfoContainer from "./common/InfoContainer";

const HeaderContainer = styled.div`
    // Occupy entire width of the page, but set height to 48px
    width: 100%;
    height: 96px;

    // Force header to stay at the top
    ${(props) =>
        !props.static &&
        `position: sticky;
        top: 0;
        z-index: 10;`}

    // Add padding between header contents and HeaderContainer
    padding: 8px;

    // Style header container
    ${Draftboard.Background.blue}
    box-shadow: 0 10px 20px ${ColorScheme.shadowBlack.color};

    // Center container's contents
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ActionBarContainer = styled.div`
    // Center action bar
    width: 100%;
    height: 100%;
    margin: auto;

    // Center action bar contents
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ActionBar = ({
    isCommissioner,
    isMember,
    inProgress,
    completed,
    screenshotPage,
    openSettings,
    openExit,
    exitDraft,
    view,
}) => {
    const showActionBar =
        (isCommissioner || isMember) &&
        (view === DraftboardViews.EDIT_KEEPER ||
            (inProgress && view === DraftboardViews.ROUND));
    return (
        <HeaderContainer static={completed && screenshotPage}>
            {showActionBar ? (
                <InfoContainer maxWidth="1024px">
                    <ActionBarContainer>
                        <SearchBar isCommissioner={isCommissioner} />
                        <AutocompleteCards />
                    </ActionBarContainer>
                </InfoContainer>
            ) : (
                <Logo color="white" />
            )}
            {!(completed && screenshotPage) && (
                <ActionIcons
                    openSettings={openSettings}
                    openExit={openExit}
                    exitDraft={exitDraft}
                    completed={completed}
                    isCommissioner={isCommissioner}
                    isMember={isMember}
                />
            )}
        </HeaderContainer>
    );
};

const mapStateToProps = (state) => {
    const newState = {
        submittingKeepers: false,
        inProgress: false,
        completed: false,
    };

    if (state.draftboard?.draft) {
        const { status } = state.draftboard.draft;
        newState.draft = state.draftboard.draft;
        if (status === DraftStatus.IN_PROGRESS) newState.inProgress = true;
        if (status === DraftStatus.COMPLETED) newState.completed = true;
        if (state.draftboard?.draftboard) {
            newState.view = state.draftboard.draftboard.view;
        }
    }
    return newState;
};

export default connect(mapStateToProps)(ActionBar);
