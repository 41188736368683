// Homepage Constants
export const HOMEPAGE_URL = process.env.HOME_PAGE_URL;
export const GUIDE_URL = process.env.GUIDE_URL;
export const CONTACT_URL = process.env.CONTACT_URL;
export const FEATURES_URL = process.env.FEATURES_URL;

// Asset constants
export const LOGIN_PICTURE_URL = process.env.LOGIN_PICTURE_URL;
export const LOGO_PICTURE_URL = process.env.LOGO_PICTURE_URL;
export const LOGO_WHITE_PICTURE_URL = process.env.LOGO_WHITE_PICTURE_URL;
export const LOGO_BLACK_PICTURE_URL = process.env.LOGO_BLACK_PICTURE_URL;
export const DRAFT_CONFIG_URL = process.env.DRAFT_CONFIG_URL;
export const STANDARD_PLAYERS_URL = process.env.STANDARD_PLAYERS_URL;
export const IDP_PLAYERS_URL = process.env.IDP_PLAYERS_URL;

// SVG constants
const SVG_URL = process.env.SVG_URL;
export const HELP_ICON = `${SVG_URL}#icon-question-circle-o`;
export const PROFILE_ICON = `${SVG_URL}#icon-user`;
export const FEEDBACK_ICON = `${SVG_URL}#icon-bullhorn`;
export const SIGN_OUT_ICON = `${SVG_URL}#icon-exit`;
export const EDIT_ICON = `${SVG_URL}#icon-pencil`;
export const CANCEL_ICON = `${SVG_URL}#icon-cancel-circle`;
export const SETTINGS_ICON = `${SVG_URL}#icon-cog`;
export const MAXIMIZE_ICON = `${SVG_URL}#icon-enlarge2`;
export const MINIMIZE_ICON = `${SVG_URL}#icon-shrink2`;
export const UNDO_ICON = `${SVG_URL}#icon-undo2`;
export const PAUSE_ICON = `${SVG_URL}#icon-pause`;
export const PLAY_ICON = `${SVG_URL}#icon-play2`;
export const CHAT_ICON = `${SVG_URL}#icon-bubble`;
export const PLUS_ICON = `${SVG_URL}#icon-plus`;
export const MINUS_ICON = `${SVG_URL}#icon-minus`;
export const HOME_ICON = `${SVG_URL}#icon-home`;
export const MAP_ICON = `${SVG_URL}#icon-map`;

// Stripe Key
export const STRIPE_KEY = process.env.STRIPE_KEY;

// Page View constants
export const TEST_DRIVE_ID = "testdrive";
export const KEEPERS_ONLY_PAGE = "keepers";
export const SCREENSHOT_PAGE = "screenshot";
export const RESULTS_PAGE = "results";
export const DRAFT_CONFIG = "config";

// Draft Limits
export const MAX_TEAM_LENGTH = 20;
export const MAX_LEAGUE_LENGTH = 16;
export const MAX_ROUNDS = 30;
export const MAX_TIMER_DURATION = 600;
export const MIN_TIMER_DURATION = 5;

// User Limits
export const MIN_AGE = 13;
export const MIN_PASSWORD_LENGTH = 8;

// Backend sync period
export const DRAFT_SYNC_PERIOD = 600000; // 10 minutes
export const REMOTE_DRAFT_HEARTBEAT_PERIOD = 540000; // 9 minutes
export const DEBOUNCE_POST_PERIOD = 1000; // 1 second

// Default auction budget bid ratio
export const DEFAULT_BUDGET_BID_RATIO = 200;

// Player Card Locations
export const CardLocation = {
    AUTOCOMPLETE: "Autocomplete",
    DRAFTBOARD: "Draftboard",
    UNDO: "Undo",
};

// Grid Size Settings
export const CARD_WIDTH = 128;
export const CARD_HEIGHT = 64;
export const FONT_SIZES = [10, 12, 14, 16, 18, 20, 24, 32];

// Autocomplete Limits
export const AUTOCOMPLETE_CARDS_LIMIT = 4;

// Auction Display Setting
export const AuctionDisplay = {
    BUDGET: "budget",
    MAX_BID: "max-bid",
};

// Draftboard views
export const DraftboardViews = {
    ROSTER: "Roster",
    ROUND: "Round-by-Round",
    KEEPER: "Keepers",
    EDIT_KEEPER: "Edit Keepers",
};

// String constants that must be kept in-sync with the backend
export const CURRENT_SEASON = "current";
export const UNSCHEDULED = "Unscheduled";

export const DraftClass = {
    BASIC: "Basic",
    PRO: "Pro",
    PREMIUM: "Premium",
};

export const DraftConfig = {
    SNAKE: "Snake",
    LINEAR: "Linear",
    AUCTION: "Auction",
    CUSTOM: "Custom",
};

export const AuctionNominationOrder = {
    HIGHEST_BID: "Highest Bid",
    LINEAR: "Linear",
    SNAKE: "Snake",
};

export const EmailNotification = {
    ALL: "All",
    SELF: "Self",
    NONE: "None",
};

export const PremiumFeatures = {
    TIMER: "timer",
    KEEPER: "keeper",
    TRADE_PICKS: "tradePicks",
    REMOTE_DRAFT: "remoteDraft",
};

export const DraftStatus = {
    ALL: "All",
    UPCOMING: "Upcoming",
    IN_PROGRESS: "InProgress",
    COMPLETED: "Completed",
};

export const DraftActions = {
    START: "Start",
    FINISH: "Finish",
    RESET: "Reset",
    SELECT: "Select",
    UNDO: "Undo",
    REPLACE: "Replace",
    NOP: "Nop",

    // Not actually draft actions, but used for loading spinner
    SAVE_KEEPERS: "SaveKeepers",
    EXIT_DRAFT: "ExitDraft",
};

export const Positions = {
    ALL: "All",
    QB: "QB",
    RB: "RB",
    WR: "WR",
    TE: "TE",
    K: "K",
    DST: "DST",
    FLEX: "FLEX",
    DB: "DB",
    DL: "DL",
    LB: "LB",
    DEF: "DEF",
    BN: "BN",
};

export const AutodraftPositionRank = [
    Positions.ALL,
    Positions.RB,
    Positions.WR,
    Positions.TE,
    Positions.QB,
    Positions.DST,
    Positions.K,
];

// TODO: Make flex positions user configurable
export const Flex = {
    [Positions.WR]: true,
    [Positions.RB]: true,
    [Positions.TE]: true,
};
export const DefFlex = {
    [Positions.DB]: true,
    [Positions.DL]: true,
    [Positions.LB]: true,
};

// Create draft defaults
export const CREATE_DRAFT_DEFAULT = {
    leagueSize: "10",
    draftType: DraftClass.BASIC,
    draftConfig: DraftConfig.SNAKE,
    idp: false,
    // These must be in the same order as draftconfig.json
    positions: [
        { position: "QB", value: "1" },
        { position: "RB", value: "2" },
        { position: "WR", value: "2" },
        { position: "TE", value: "1" },
        { position: "FLEX", value: "1" },
        { position: "K", value: "1" },
        { position: "DST", value: "1" },
        { position: "DB", value: "0" },
        { position: "DL", value: "0" },
        { position: "LB", value: "0" },
        { position: "DEF", value: "0" },
        { position: "BN", value: "6" },
    ],
    auctionDraftOptions: {
        budget: "200",
        minimumBid: "1",
        nominationOrder: AuctionNominationOrder.LINEAR,
    },
    customDraftOrder: [],
    emailSetting: EmailNotification.ALL,
    timer: { enabled: false, duration: "60" },
    remoteDraft: false,
    keeper: false,
    tradePicks: false,
};
