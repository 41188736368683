import React from "react";
import { useDrag } from "react-dnd";
import { connect } from "react-redux";
import styled from "styled-components";
import {
    selectAutocompletePlayer,
    toggleSelect,
} from "../../../actions/draftboard";
import {
    AUTOCOMPLETE_CARDS_LIMIT,
    CardLocation,
    DraftConfig,
    DraftboardViews,
} from "../../../js/constants";
import { ItemTypes } from "../../../js/dnd";
import { isCommissioner, isMember, isTestDrive } from "../../../js/util";
import PlayerCard, { HeadlessCard, PlayerButton } from "../common/PlayerCard";

// width = 128px * 4 + 8px * 3
const AutocompleteContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    margin-left: 32px;
    width: 536px;

    > * {
        margin-left: 12px;
        &:first-child {
            margin-left: 0;
        }
    }
`;

const pickTradedAway = (currTeam, currRound, openings) => {
    if (!openings || openings.length === 0) return false;
    for (let i = 0; i < openings.length; i++) {
        const [openTeam, openRound, traded] = openings[i];
        if (!traded) continue;
        if (currTeam === openTeam && currRound === openRound) return true;
    }
    return false;
};

const pickTradedToMe = (currTeam, currRound, trades) => {
    if (!trades) return false;
    const currTradeKey = `${currTeam}-${currRound}`;
    return currTradeKey in trades;
};

const DraggablePlayerButton = ({
    index,
    auction,
    view,
    position,
    first,
    last,
    bye,
    team,
    selected,
    onClick,
}) => {
    const [{ isDragging }, ref] = useDrag({
        type: ItemTypes.PLAYER,
        item: () => {
            // At the start of the drag, select the currently dragging player
            if (auction || view === DraftboardViews.EDIT_KEEPER) {
                onClick();
            }
            return { index };
        },
        end: (_, monitor) => {
            const result = monitor.getDropResult();
            // If the drag didn't complete successfully, deselect the player
            if (!result) {
                if (
                    auction ||
                    view === DraftboardViews.EDIT_KEEPER ||
                    view === DraftboardViews.ROUND
                ) {
                    onClick();
                }
            }
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
        <PlayerButton
            ref={ref}
            position={position}
            first={first}
            last={last}
            bye={bye}
            team={team}
            location={CardLocation.AUTOCOMPLETE}
            selected={selected || isDragging}
            onClick={onClick}
        />
    );
};

const AutocompleteCards = ({
    players,
    selectAutocompletePlayer,
    toggleSelect,
    selectedPlayer,
    openings,
    trades,
    auction,
    view,
    currTeam,
    currRound,
    draftOrder,
    userID,
    isCommissioner,
    isMember,
    testDrive,
}) => {
    if (players.length === 0 || (!isMember && !isCommissioner && !testDrive)) {
        return <AutocompleteContainer />;
    }
    const cards = [];
    const onCurrTeam = currTeam >= 0 && draftOrder[currTeam].userID === userID;
    const currTeamPicking =
        (onCurrTeam && !pickTradedAway(currTeam, currRound, openings)) ||
        (!onCurrTeam && pickTradedToMe(currTeam, currRound, trades));
    for (let i = 0; i < AUTOCOMPLETE_CARDS_LIMIT; i++) {
        const onClick = () => {
            if (auction || view === DraftboardViews.EDIT_KEEPER) {
                return toggleSelect(i);
            }

            // There should be at most one opening in non-auction drafts
            if (openings && openings.length > 0) {
                for (let j = 0; j < openings.length; j++) {
                    // Make sure to select the location that isn't traded
                    const [team, round, traded] = openings[j];
                    if (traded) continue;
                    selectAutocompletePlayer(i, team, round, team, false);
                }
            }
        };
        if (i < players.length) {
            const { position, first, last, bye, team, id } = players[i];
            if (isCommissioner || currTeamPicking || testDrive) {
                cards.push(
                    <DraggablePlayerButton
                        index={i}
                        auction={auction}
                        view={view}
                        position={position}
                        first={first}
                        last={last}
                        bye={bye}
                        team={team}
                        location={CardLocation.AUTOCOMPLETE}
                        selected={i === selectedPlayer}
                        onClick={onClick}
                        key={`autocomplete-${id}`}
                    />
                );
            } else {
                cards.push(
                    <PlayerCard
                        index={i}
                        auction={auction}
                        view={view}
                        position={position}
                        first={first}
                        last={last}
                        bye={bye}
                        team={team}
                        location={CardLocation.AUTOCOMPLETE}
                        key={`autocomplete-${id}`}
                    />
                );
            }
            continue;
        }
        cards.push(<HeadlessCard key={`empty-autocomplete-${i}`} />);
    }
    return <AutocompleteContainer>{cards}</AutocompleteContainer>;
};

const mapStateToProps = (state) => {
    const newState = {
        players: state.draftboard?.autocomplete?.players,
        selectedPlayer: state.draftboard?.autocomplete?.selectedPlayer,
        openings: state.draftboard?.draftboard?.openings,
        trades: state.draftboard?.draftboard?.trades,
        auction: state.draftboard?.draft?.draftConfig === DraftConfig.AUCTION,
        view: state.draftboard?.draftboard?.view,
        currTeam: state.draftboard?.draftboard?.currTeam,
        currRound: state.draftboard?.draftboard?.currRound,
        draftOrder: state.draftboard?.draft?.draftOrder,
        testDrive: false,
    };
    if (state.draftboard?.draft?.draftID) {
        newState.draftID = state.draftboard.draft.draftID;
        newState.testDrive = isTestDrive(newState.draftID);
    }
    if (state.auth?.user && state.draftboard?.draft?.commissioners) {
        const userID = state.auth.user.username;
        const commissioners = state.draftboard.draft.commissioners;
        const members = state.draftboard.draft.members;
        newState.isCommissioner = isCommissioner(userID, commissioners);
        newState.isMember = isMember(userID, members);
        newState.userID = userID;
    }
    return newState;
};
export default connect(mapStateToProps, {
    selectAutocompletePlayer,
    toggleSelect,
})(AutocompleteCards);
