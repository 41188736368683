import React, { useState } from "react";
import { Field } from "react-final-form";
import styled from "styled-components";
import { compose, maxValue, minValue, required } from "../../js/formhelper";
import Input, { AdjacentFieldError } from "./Input";

const InputContainer = styled.div`
    display: flex;
    align-items: center;
`;

const isNumeric = (value) => /^[0-9]*$/.test(value);

// Controlled Input with type "number"
export const NumberField = ({
    initial,
    fieldName,
    label,
    printField,
    min,
    max,
    width,
    small,
}) => {
    // Create state for controlled input and touched
    const [value, setValue] = useState(initial);
    const [touched, setTouched] = useState(false);

    const onFieldChange = (formOnChange) => (event) => {
        const value = event.target.value;

        // Prevent user from typing non-integers
        if (!isNumeric(value)) return;

        // Prevent user from starting number with "0"
        if (value.length > 1 && value.charAt(0) === "0") {
            const newValue = parseInt(value).toString();
            formOnChange(newValue);
            setValue(newValue);
        }
        // Prevent user from typing in too many characters
        if (value.length > max.toString().length) return;

        formOnChange(value);
        setValue(value);
    };

    return (
        <Field
            name={fieldName}
            validate={compose(
                required,
                minValue(printField || label || fieldName, parseInt(min)),
                maxValue(printField || label || fieldName, parseInt(max))
            )}
        >
            {({ input: { onChange }, meta }) => (
                <InputContainer>
                    <Input
                        id={label}
                        type="number"
                        min={min || "0"}
                        max={max}
                        value={value}
                        width={width || "auto"}
                        step="1"
                        onBlur={() => setTouched(true)}
                        onChange={onFieldChange(onChange)}
                        fontSize={small && "16px"}
                    />
                    {meta.error && touched && (
                        <AdjacentFieldError>{meta.error}</AdjacentFieldError>
                    )}
                </InputContainer>
            )}
        </Field>
    );
};
