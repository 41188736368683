import React, { useState } from "react";
import styled from "styled-components";
import { Radio } from "../../common/Input";
import ColorScheme from "../../styles/colors";
import { InfoContainer, InfoContainerH2 } from "./Container";

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 24px;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
`;

const RadioLabel = styled.label`
    color: ${ColorScheme.blue4.color};
    font-size: 16px;
    font-weight: 400;
    margin-left: 12px;
    cursor: pointer;
`;

const DisplayPlayerDetails = ({
    setDisplayPlayerDetails,
    showDraftboardDetails,
}) => {
    const Options = { On: "On", Off: "Off" };
    const [checked, setChecked] = useState(showDraftboardDetails);
    const onchange = (val) => () => {
        setChecked(val);
        setDisplayPlayerDetails(val);
    };
    return (
        <InfoContainer>
            <InfoContainerH2>Show Draftboard Player Details</InfoContainerH2>
            <OptionsContainer>
                <InputContainer>
                    <Radio
                        name="show-player-details"
                        id={Options.On}
                        type="radio"
                        onChange={onchange(Options.On)}
                        checked={checked === Options.On}
                        small
                    />
                    <RadioLabel htmlFor={Options.On}>On</RadioLabel>
                </InputContainer>
                <InputContainer>
                    <Radio
                        name="show-player-details"
                        id={Options.Off}
                        type="radio"
                        onChange={onchange(Options.Off)}
                        checked={checked === Options.Off}
                        small
                    />
                    <RadioLabel htmlFor={Options.Off}>Off</RadioLabel>
                </InputContainer>
            </OptionsContainer>
        </InfoContainer>
    );
};

export default DisplayPlayerDetails;
