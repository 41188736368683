import { css } from "styled-components";
import Color from "../../js/color";

const ColorScheme = {
    // Primary
    blue1: new Color(206, 100, 97, 1),
    blue2: new Color(206, 79, 82, 1),
    blue3: new Color(208, 60, 62, 1),
    blue4: new Color(208, 61, 49, 1),
    blue5: new Color(208, 64, 38, 1),
    blue6: new Color(208, 63, 27, 1),
    blue7: new Color(207, 45, 23, 1),

    // Grays
    gray1: new Color(210, 16, 98, 1),
    gray2: new Color(207, 22, 91, 1),
    gray3: new Color(212, 18, 84, 1),
    gray4: new Color(207, 18, 76, 1),
    gray5: new Color(215, 15, 60, 1),
    gray6: new Color(213, 13, 43, 1),
    gray7: new Color(215, 22, 17, 1),

    // Accents
    yellow1: new Color(44, 100, 98, 1),
    yellow2: new Color(44, 91, 92, 1),
    yellow3: new Color(44, 90, 80, 1),
    yellow4: new Color(42, 87, 67, 1),
    yellow5: new Color(44, 57, 52, 1),
    yellow6: new Color(43, 64, 34, 1),
    yellow7: new Color(44, 66, 22, 1),

    green1: new Color(178, 100, 95, 1),
    green2: new Color(177, 67, 80, 1),
    green3: new Color(178, 57, 64, 1),
    green4: new Color(174, 49, 46, 1),
    green5: new Color(174, 55, 37, 1),
    green6: new Color(174, 58, 25, 1),
    green7: new Color(179, 58, 17, 1),

    red1: new Color(0, 77, 95, 1),
    red2: new Color(360, 79, 82, 1),
    red3: new Color(360, 70, 64, 1),
    red4: new Color(360, 71, 53, 1),
    red5: new Color(360, 70, 42, 1),
    red6: new Color(360, 67, 32, 1),
    red7: new Color(360, 60, 24, 1),

    // Logo Colors
    logoRed: new Color(360, 100, 46, 1),
    logoBlue: new Color(202, 87, 22, 1),

    // Shadow Black
    shadowBlack: new Color(0, 0, 0, 0.25),
};

export const Draftboard = {
    Border: {
        qb: new Color(353, 93, 51, 1),
        rb: new Color(231, 94, 57, 1),
        wr: new Color(139, 55, 42, 1),
        te: new Color(48, 85, 47, 1),
        k: new Color(281, 94, 56, 1),
        def: new Color(24, 92, 57, 1),
        empty: ColorScheme.gray4,
    },
    Background: {
        blue: css`
            background: linear-gradient(#106393, #08486d);
        `,
        red: css`
            background: linear-gradient(#e74649, #de0d17);
        `,
        lightblue: css`
            background: linear-gradient(#70a3cd, #3a6c8d);
        `,
        empty: css`
            background: linear-gradient(#ffffffff, #ffffff00);
        `,
        emptyposition: new Color(207, 18, 76, 0.25),
        qb: new Color(353, 93, 83, 1),
        rb: new Color(231, 92, 90, 1),
        wr: new Color(141, 41, 79, 1),
        te: new Color(48, 78, 82, 1),
        k: new Color(281, 95, 85, 1),
        def: new Color(22, 100, 90, 1),
    },
    Text: {
        blue: new Color(202, 86, 23, 1),
        white: new Color(0, 0, 1, 1),
    },
};

export default ColorScheme;
