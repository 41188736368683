import React from 'react';
import styled from 'styled-components';
import ColorScheme from '../styles/colors';
import Defaults from '../styles/defaults';
import { StyleStrip } from '../styles/Reset';
import { buttonStyle } from './Button';

const bgcolor = color => {
    switch(color) {
        case buttonStyle.Color.GREEN:
            return ColorScheme.green1.color;
        case buttonStyle.Color.GRAY:
            return ColorScheme.gray1.color;
        case buttonStyle.Color.RED:
            return ColorScheme.red1.color;
        case buttonStyle.Color.YELLOW:
            return ColorScheme.yellow1.color;
        case buttonStyle.Color.BLUE:
        default:
            return ColorScheme.blue1.color;
    }
}

const bordercolor = color => {
    switch(color) {
        case buttonStyle.Color.GREEN:
            return `2px solid ${ColorScheme.green4.color}`;
        case buttonStyle.Color.GRAY:
            return `2px solid ${ColorScheme.gray5.color}`;
        case buttonStyle.Color.RED:
            return `2px solid ${ColorScheme.red3.color}`;
        case buttonStyle.Color.YELLOW:
            return `2px solid ${ColorScheme.yellow5.color}`;
        case buttonStyle.Color.BLUE:
        default:
            return `2px solid ${ColorScheme.blue4.color}`;
    }
}

const textcolor = color => {
    switch(color) {
        case buttonStyle.Color.GREEN:
            return `${ColorScheme.green4.color}`;
        case buttonStyle.Color.GRAY:
            return `${ColorScheme.gray5.color}`;
        case buttonStyle.Color.RED:
            return `${ColorScheme.red3.color}`;
        case buttonStyle.Color.YELLOW:
            return `${ColorScheme.yellow5.color}`;
        case buttonStyle.Color.BLUE:
        default:
            return `${ColorScheme.blue4.color}`;
    }
}

const PlaceholderContainer = styled.div`
    ${StyleStrip}

    // Set desired styling
    border-radius: ${Defaults.borderRadius};
    font-family: inherit;
    line-height: 100%;

    // Style placeholder according to props
    width: ${props => props.small ? '96px' : props.medium ? '128px' : '192px'};
    height: ${props => props.small ? '24px' : props.medium ? '32px' : '48px'};
    font-size: ${props => props.small ? '14px' : props.medium ? '18px' : '24px'};
    background-color: ${props => bgcolor(props.color)};
    color: ${props => textcolor(props.color)};
    border: ${props => bordercolor(props.color)};

    // Center placeholder text
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ButtonPlaceholder = ({ size, color, text }) => {
    const [small, medium, large] = [
        size === buttonStyle.Size.SMALL,
        size === buttonStyle.Size.MEDIUM,
        size === buttonStyle.Size.LARGE
    ];
    return (
        <PlaceholderContainer color={color} small={small} medium={medium} large={large}>
            {text}
        </PlaceholderContainer>
    );
}

export default ButtonPlaceholder;
