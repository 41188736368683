import {
    HEADER_HIDE_HELP,
    HEADER_HIDE_PROFILE,
    HEADER_SHOW_HELP,
    HEADER_SHOW_PROFILE,
} from "../actions/types";

export const showProfile = () => ({ type: HEADER_SHOW_PROFILE });
export const showHelp = () => ({ type: HEADER_SHOW_HELP });
export const hideProfile = () => ({ type: HEADER_HIDE_PROFILE });
export const hideHelp = () => ({ type: HEADER_HIDE_HELP });
