import React from "react";
import Logo from "../../common/Logo";
import { StyleReset } from "../../styles/Reset";
import Container, { FormContainer, LogoContainer } from "./Container";
import HeroImage from "./HeroImage";

const Display = ({ children }) => (
    <>
        <StyleReset />
        <Container>
            <HeroImage />
            <FormContainer>
                <LogoContainer>
                    <Logo link />
                </LogoContainer>
                {children}
            </FormContainer>
        </Container>
    </>
);

export default Display;
