import React, { Component } from "react";
import styled from "styled-components";
import ColorScheme from "../../styles/colors";
import { DefaultTransition, FocusShadow } from "../../styles/defaults";
import { StyleStrip } from "../../styles/Reset";
import Dropdown from "./Dropdown";

const IconContainer = styled.button`
    ${StyleStrip}
    ${FocusShadow}
    ${DefaultTransition}

    // Make sure icon is centered
    display: flex;
    justify-content: center;
    align-items: center;

    // Change mouse appearance on hover
    cursor: pointer;
`;

const DropdownContainer = styled.div`
    // Make sure dropdown area is displayed under icon
    // by setting its position relative to the whole container
    position: relative;
`;

const SVG = styled.svg`
    // Size icons appropriately
    width: 40px;
    height: 40px;
    margin: 0px 8px;

    ${DefaultTransition}
    &:hover {
        filter: drop-shadow(3px 3px 2px ${ColorScheme.shadowBlack.color});
    }
    &:active {
        filter: none;
    }
`;

class IconDropdown extends Component {
    toggleDropdown = (event) => {
        // Prevent dropdown from interpreting icon click as a click outside
        event.stopPropagation();

        // If dropdown is not displayed, display dropdown
        if (!this.props.displayDropdown) {
            this.props.show();
            return;
        }
        // Else, hide dropdown
        this.props.hide();
    };

    render = () => (
        <DropdownContainer>
            <IconContainer onClick={this.toggleDropdown}>
                <SVG>
                    <use
                        fill={this.props.iconColor}
                        href={this.props.iconHref}
                    />
                </SVG>
            </IconContainer>
            <Dropdown
                hide={this.props.hide}
                displaySelf={this.props.displayDropdown}
                elementHeight={48}
            >
                {this.props.children}
            </Dropdown>
        </DropdownContainer>
    );
}

export default IconDropdown;
