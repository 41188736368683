import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn } from "../../actions/auth";
import { refreshDraftConfig } from "../../actions/config";
import { tracker } from "../App";
import { StyleReset } from "../styles/Reset";
import Header from "./common/Header";
import AdvancedFeatures from "./createdraft/AdvancedFeatures";
import CreateDraftForm from "./createdraft/CreateDraftForm";
import DraftType from "./createdraft/DraftType";
import EnterDraftDetails from "./createdraft/EnterDraftDetails";
import Positions, { rosterFormValidate } from "./createdraft/Positions";
import SelectDraft from "./createdraft/SelectDraft";
import Summary from "./createdraft/Summary";

class CreateDraft extends Component {
    componentDidMount = async () => {
        if (!this.props.refreshed) {
            this.props.refreshDraftConfig();
        }
        await this.props.signIn(tracker);
    };

    render = () => (
        <>
            <StyleReset />
            <Header />
            <CreateDraftForm draftConfig={this.props.draftConfig}>
                <SelectDraft title="Select Draft" />
                <EnterDraftDetails title="Draft Details" />
                <Positions
                    title="Roster Settings"
                    validate={rosterFormValidate(this.props.draftConfig)}
                />
                <DraftType title="Draft Type" />
                <AdvancedFeatures title="Advanced Features" />
                <Summary title="Summary" />
            </CreateDraftForm>
        </>
    );
}

const mapStateToProps = (state) => ({
    draftConfig: state.config.draftConfig,
    refreshed: state.config.refreshed,
});
export default connect(mapStateToProps, { refreshDraftConfig, signIn })(
    CreateDraft
);
