import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
    CardLocation,
    DraftboardViews,
    DraftConfig,
} from "../../../js/constants";
import ColorScheme from "../../styles/colors";
import InfoContainer from "../common/InfoContainer";
import PlayerCard from "../common/PlayerCard";

const Container = styled.div`
    // Set container size
    width: 100%;
    height: 100%;

    // Center children
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LabelContainer = styled.div`
    flex: 50%;

    // Style label
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
    color: ${ColorScheme.gray1.color};

    display: flex;
    justify-content: center;
    align-items: center;
`;

const LastPick = ({ auction, lastKeeper, lastPick, view }) => {
    const editKeepers = view === DraftboardViews.EDIT_KEEPER;
    const player = editKeepers ? lastKeeper : lastPick;
    return (
        <InfoContainer maxWidth="256px">
            <Container>
                <LabelContainer>
                    Last
                    <br />
                    Pick
                </LabelContainer>
                <PlayerCard
                    position={player.position}
                    first={player.first}
                    last={player.last}
                    bye={player.bye}
                    team={player.team}
                    cost={auction && player.cost}
                    auction={auction}
                    location={CardLocation.UNDO}
                />
            </Container>
        </InfoContainer>
    );
};

const mapStateToProps = (state) => {
    const {
        draftboard: { draftboard, draft },
    } = state;
    const newState = { auction: false };
    if (draftboard?.view) {
        newState.view = draftboard.view;
    }
    if (draftboard?.lastKeeper) {
        newState.lastKeeper = draftboard.lastKeeper;
    }
    if (draftboard?.lastPick) {
        newState.lastPick = draftboard.lastPick;
    }
    if (draft?.draftConfig === DraftConfig.AUCTION) {
        newState.auction = true;
    }
    return newState;
};

export default connect(mapStateToProps)(LastPick);
