import { Auth } from "@aws-amplify/auth";
import React, { Component } from "react";
import { connect } from "react-redux";
import { unauthSignIn } from "../../actions/auth";
import { refreshDraftConfig } from "../../actions/config";
import {
    changeView,
    finish,
    getDraftboard,
    getPlayers,
    getResults,
    ingestActions,
    postActions,
    resetDraftboard,
} from "../../actions/draftboard";
import {
    DRAFT_CONFIG,
    DRAFT_SYNC_PERIOD,
    DraftActions,
    DraftStatus,
    DraftboardViews,
    HOMEPAGE_URL,
    KEEPERS_ONLY_PAGE,
    REMOTE_DRAFT_HEARTBEAT_PERIOD,
    RESULTS_PAGE,
    SCREENSHOT_PAGE,
    TEST_DRIVE_ID,
} from "../../js/constants";
import { lastKeeper } from "../../js/draftboard/draftboard";
import history from "../../js/history";
import { connectRemote, disconnectRemote, ping } from "../../js/remotedraft";
import { isCommissioner, isMember, isTestDrive } from "../../js/util";
import { tracker } from "../App";
import DraftDoesNotExist from "../common/DraftDoesNotExist";
import LoadingPage from "../common/LoadingPage";
import { StyleReset } from "../styles/Reset";
import ColorScheme from "../styles/colors";
import ActionBar from "./ActionBar";
import Board from "./Board";
import CompletedModal from "./CompletedModal";
import ExitModal from "./ExitModal";
import FinishModal from "./FinishModal";
import InfoBar from "./InfoBar";
import SettingsModal from "./SettingsModal";
import DraftInProgress from "./common/DraftInProgress";

class Draftboard extends Component {
    constructor(props) {
        super(props);

        // Parse URL for page details like page type and DraftID
        const query = new URLSearchParams(this.props.location.search);
        const [keepersPage, screenshotPage, resultsPage, draftConfig] = [
            query.get(KEEPERS_ONLY_PAGE),
            query.get(SCREENSHOT_PAGE),
            query.get(RESULTS_PAGE),
            query.get(DRAFT_CONFIG),
        ];

        // https://v5.reactrouter.com/web/api/match
        const draftID = this.props.match.params.id;

        // Store page details into state
        this.state = {
            draftID,
            draftConfig,
            keepersPage: keepersPage !== null,
            screenshotPage: screenshotPage !== null,
            resultsPage: resultsPage !== null,
            testDrivePage: draftID === TEST_DRIVE_ID,
            showExit: false,
            showSettings: false,
            showFinish: false,
            showCompleted: false,
        };
    }

    componentDidMount = async () => {
        // Fetch draft configuration, if hasn't been fetched already
        if (!this.props.draftConfig.refreshed) {
            await this.props.refreshDraftConfig();
        }
        if (!this.state.testDrivePage) {
            await this.props.unauthSignIn(tracker);
        }

        // Fetch draftboard
        let draft = null;
        if (this.state.resultsPage) {
            draft = await this.props.getResults(this.state.draftID);
        }
        if (draft?.status !== DraftStatus.COMPLETED) {
            draft = await this.props.getDraftboard(
                this.state.draftID,
                this.state.draftConfig
            );
            await this.props.getPlayers();
        }

        // If getDraftboard returned 404 (i.e. draft === null), then return
        if (draft === null) return;

        // If current page is submit keepers, set view to edit keepers
        const { status, keeper } = draft;
        const { keepersPage } = this.state;
        const keepersExist = lastKeeper() !== null;
        if (status === DraftStatus.UPCOMING && keeper && keepersPage) {
            if (keepersExist) {
                this.props.changeView(DraftboardViews.KEEPER);
            } else {
                this.props.changeView(DraftboardViews.EDIT_KEEPER);
            }
        }

        // For test drives, we can skip syncing actions with the backend
        if (this.state.testDrivePage) return;

        // For remote drafts, initiate websocket connection and heartbeat
        if (
            (this.props.isMember || this.props.isCommissioner) &&
            draft?.status !== DraftStatus.COMPLETED &&
            this.props.draft.remoteDraft
        ) {
            // Connect websocket
            const idToken = await (await Auth.currentSession())
                .getIdToken()
                .getJwtToken();
            connectRemote(
                idToken,
                this.state.draftID,
                this.props.ingestActions
            );

            // Initiate heartbeat, so we can keep websocket connection alive
            this.sync = setInterval(() => {
                if (this.props.draft?.status !== DraftStatus.COMPLETED) {
                    ping();
                    return;
                }
                clearInterval(this.sync);
            }, REMOTE_DRAFT_HEARTBEAT_PERIOD);
            return;
        }

        // For all other drafts, initiate periodic backend sync
        this.sync = setInterval(() => {
            if (this.props.draft?.status === DraftStatus.IN_PROGRESS) {
                this.props.postActions();
                return;
            }
            clearInterval(this.sync);
        }, DRAFT_SYNC_PERIOD);
    };

    componentWillUnmount = () => {
        this.props.resetDraftboard();
        clearInterval(this.sync);
        if (this.props.draft.remoteDraft) {
            disconnectRemote();
        }
    };

    openSettingsPortal = () =>
        this.setState((state) => ({ ...state, showSettings: true }));
    closeSettingsPortal = () =>
        this.setState((state) => ({ ...state, showSettings: false }));
    openExitPortal = () =>
        this.setState((state) => ({ ...state, showExit: true }));
    closeExitPortal = () => {
        if (this.props.exitingDraft) return;
        this.setState((state) => ({ ...state, showExit: false }));
    };
    openFinishPortal = () =>
        this.setState((state) => ({ ...state, showFinish: true }));
    closeFinishPortal = () => {
        if (this.props.finishingDraft) return;
        this.setState((state) => ({ ...state, showFinish: false }));
    };
    openCompletedPortal = () =>
        this.setState((state) => ({ ...state, showCompleted: true }));
    closeCompletedPortal = () =>
        this.setState((state) => ({ ...state, showCompleted: false }));

    exitDraft = async () => {
        if (this.state.testDrivePage) {
            return (window.location = HOMEPAGE_URL);
        }
        if (this.props.draft.status === DraftStatus.IN_PROGRESS) {
            await this.props.postActions(DraftActions.EXIT_DRAFT);
        }
        if (this.props.isMember || this.props.isCommissioner) {
            return history.push(`/drafts/${this.state.draftID}`);
        } else if (this.props.isUser) {
            return history.push("/");
        } else {
            return (window.location = HOMEPAGE_URL);
        }
    };

    finishDraft = async () => {
        const err = await this.props.finish(this.state.testDrivePage);
        if (!err) {
            this.closeFinishPortal();
            this.openCompletedPortal();
        }
    };

    render = () => {
        const { boardloaded, dirloaded, draft } = this.props;

        // Set isCommissioner and isMember to true for Test Drive page
        let isCommissioner =
            this.props.isCommissioner ||
            this.props.testDrive ||
            process.env.SUPERUSER === "1";
        let isMember = this.props.isMember || this.props.testDrive;

        // For non-league members, always show In Progress screen
        if (draft === null) return <DraftDoesNotExist />;
        if (!draft) return <LoadingPage />;
        if (draft.status === DraftStatus.IN_PROGRESS) {
            if (!isCommissioner && (!isMember || !draft.remoteDraft)) {
                return <DraftInProgress />;
            }
        }

        // Don't need autocomplete directory, if draft is already completed
        if (draft.status === DraftStatus.COMPLETED) {
            if (!boardloaded) return <LoadingPage />;
        } else {
            if (!dirloaded || !boardloaded) return <LoadingPage />;
        }

        return (
            <>
                <StyleReset bgcolor={ColorScheme.gray1.color} />
                <ActionBar
                    openSettings={this.openSettingsPortal}
                    openExit={this.openExitPortal}
                    exitDraft={this.exitDraft}
                    screenshotPage={this.state.screenshotPage}
                    isCommissioner={isCommissioner}
                    isMember={isMember}
                />
                <Board />
                {(draft.status !== DraftStatus.COMPLETED ||
                    !this.state.screenshotPage) && (
                    <InfoBar
                        keepersPage={this.state.keepersPage}
                        openFinish={this.openFinishPortal}
                    />
                )}
                {this.state.showSettings && (
                    <SettingsModal
                        open={this.state.showSettings}
                        close={this.closeSettingsPortal}
                        isCommissioner={isCommissioner}
                    />
                )}
                {this.state.showExit && (
                    <ExitModal
                        open={this.state.showExit}
                        close={this.closeExitPortal}
                        exitDraft={this.exitDraft}
                        exitingDraft={this.props.exitingDraft}
                    />
                )}
                {this.state.showFinish && (
                    <FinishModal
                        open={this.state.showFinish}
                        close={this.closeFinishPortal}
                        finish={this.finishDraft}
                        finishingDraft={this.props.finishingDraft}
                    />
                )}
                {this.state.showCompleted && (
                    <CompletedModal
                        open={this.state.showCompleted}
                        close={this.closeCompletedPortal}
                        exitDraft={this.exitDraft}
                    />
                )}
            </>
        );
    };
}

const mapStateToProps = (state) => {
    const newState = {
        draftConfig: state.config,
        boardloaded: state.draftboard.draftboard.loaded,
        dirloaded: state.draftboard.directory.loaded,
        draft: state.draftboard?.draft,
        draftboard: state.draftboard?.draftboard,
        isCommissioner: false,
        isMember: false,
        isUser: false,
        testDrive: false,
        exitingDraft: state.draftboard?.draftboard?.loading?.exitingDraft,
        finishingDraft: state.draftboard?.draftboard?.loading?.finishingDraft,
    };
    if (state.auth?.user?.username) newState.isUser = true;
    if (state.draftboard?.draft?.draftID) {
        newState.draftID = state.draftboard.draft.draftID;
        newState.testDrive = isTestDrive(newState.draftID);
    }
    if (state.auth?.user && newState.draft?.commissioners) {
        const userID = state.auth.user.username;
        const commissioners = newState.draft.commissioners;
        const members = newState.draft.members;
        newState.isCommissioner = isCommissioner(userID, commissioners);
        newState.isMember = isMember(userID, members);
    }
    return newState;
};

export default connect(mapStateToProps, {
    changeView,
    finish,
    getDraftboard,
    getPlayers,
    getResults,
    ingestActions,
    postActions,
    refreshDraftConfig,
    resetDraftboard,
    unauthSignIn,
})(Draftboard);
