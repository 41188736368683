import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
    CARD_HEIGHT,
    DraftboardViews,
    DraftStatus,
    Positions,
} from "../../../js/constants";
import ColorScheme, { Draftboard } from "../../styles/colors";

const SidebarContainer = styled.div`
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const CellContainer = styled.div`
    width: 32px;
    height: ${(props) =>
        props.height ? `${props.height}px` : `${CARD_HEIGHT}px`};
    ${(props) =>
        props.selected
            ? Draftboard.Background.red
            : Draftboard.Background.blue};

    // Center text
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TextContainer = styled.div`
    // Style text
    color: ${ColorScheme.gray1.color};
    transform: rotate(-90deg);
    font-size: 16px;
    font-weight: 700;

    // Center text
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Cell = ({ text, selected, height }) => (
    <CellContainer selected={selected} height={height}>
        <TextContainer>{text}</TextContainer>
    </CellContainer>
);
const Round = ({ num, selected, height }) => (
    <Cell selected={selected} height={height} text={`R${num + 1}`} />
);
const Position = ({ position, height }) => (
    <Cell text={position} height={height} />
);

const renderRounds = (numRounds, currRound, showSelected, height) => {
    const rounds = [];
    for (let i = 0; i < numRounds; i++) {
        if (showSelected && i === currRound) {
            rounds.push(
                <Round
                    num={i}
                    height={height}
                    key={`round-${i}-selected-sidebar`}
                    selected
                />
            );
        } else {
            rounds.push(
                <Round num={i} height={height} key={`round-${i}-sidebar`} />
            );
        }
    }
    return rounds;
};

const renderPositions = (board, positions, draftConfig, height) => {
    const allPositions = [];
    for (let i = 0; i < draftConfig.positions.length; i++) {
        const configPosition = draftConfig.positions[i];
        if (positions[configPosition.abbreviation]) {
            for (let j = 0; j < positions[configPosition.abbreviation]; j++) {
                allPositions.push(
                    <Position
                        height={height}
                        position={configPosition.abbreviation}
                        key={`${configPosition.abbreviation}-${j}-sidebar`}
                    />
                );
            }
        }
    }

    // If a team has a full roster, but doesn't draft a position (e.g. Kicker),
    // then we may need to add extra bench spots.
    let maxLength = allPositions.length;
    for (let i = 0; i < board.length; i++) {
        if (board[i].length > maxLength) {
            maxLength = board[i].length;
        }
    }
    if (maxLength > allPositions.length) {
        while (allPositions.length < maxLength) {
            allPositions.push(
                <Position
                    height={height}
                    position={Positions.BN}
                    key={`${Positions.BN}-${allPositions.length}-sidebar`}
                />
            );
        }
    }

    return allPositions;
};

const Sidebar = ({
    board,
    rounds,
    positions,
    currRound,
    view,
    draftConfig,
    inProgress,
    cardHeight,
}) => {
    const labels = [];
    const showSelected = inProgress && view === DraftboardViews.ROUND;
    if (view !== DraftboardViews.ROSTER) {
        labels.push(
            ...renderRounds(rounds, currRound, showSelected, cardHeight)
        );
    } else {
        labels.push(
            ...renderPositions(board, positions, draftConfig, cardHeight)
        );
    }
    return <SidebarContainer>{labels}</SidebarContainer>;
};

const mapStateToProps = (state) => {
    const newState = {};
    if (state.draftboard?.draft) {
        const { draft } = state.draftboard;
        newState.rounds = draft.rounds;
        newState.positions = draft.positions;
        newState.inProgress = draft.status === DraftStatus.IN_PROGRESS;
    }
    if (state.draftboard?.draftboard) {
        newState.view = state.draftboard.draftboard.view;
        newState.currRound = state.draftboard.draftboard.currRound;
        newState.cardHeight = state.draftboard.draftboard.cardHeight;
    }
    if (state.config?.draftConfig) {
        newState.draftConfig = state.config.draftConfig;
    }
    return newState;
};
export default connect(mapStateToProps)(Sidebar);
