import { RestAPI } from "@aws-amplify/api-rest";
import { cognitoAuthHeader } from "../js/auth";
import { DraftConfig, UNSCHEDULED } from "../js/constants";
import { apiErrors, httpErrors } from "../js/errors";
import { createPayment } from "../js/stripe";
import {
    DELETE_DRAFT,
    EDIT_DRAFT,
    EDIT_OPEN,
    GET_DRAFT,
    RESET_DRAFT,
} from "./types";

export const editOpen = (form) => ({ type: EDIT_OPEN, form });

export const getDraft = (draftID) => async (dispatch) => {
    try {
        // Send GetDraft API request
        const request = { headers: await cognitoAuthHeader() };
        const url = `/drafts/${draftID}`;
        const draft = await RestAPI.get(process.env.API_NAME, url, request);
        dispatch({ type: GET_DRAFT, draft });
    } catch (error) {
        if (error.response.status === httpErrors.StatusNotFound) {
            return dispatch({ type: GET_DRAFT, draft: null });
        }
        return { field: "internal" };
    }
};

export const upgradeDraft =
    (draftID, values, cardElement, stripe, price) => async (dispatch) => {
        // Set loading prop to true
        dispatch({ type: EDIT_DRAFT });

        // Create paymentMethod
        let id = "";
        let error = null;
        if (price > 0) {
            const response = await createPayment(values, cardElement, stripe);
            id = response.id;
            error = response.error;
            if (error) return { field: "creditCard", error };
        }

        // Fill in API request body
        let body = { draftType: values.draftType };
        if (id !== "") body.paymentID = id;
        if (values.payment?.couponCode)
            body.couponCode = values.payment.couponCode;

        try {
            // Send UpdateDraft API request
            const request = { body, headers: await cognitoAuthHeader() };
            const url = `/drafts/${draftID}`;
            const draft = await RestAPI.patch(
                process.env.API_NAME,
                url,
                request
            );
            dispatch({ type: EDIT_DRAFT, draft });
        } catch (err) {
            dispatch({ type: EDIT_DRAFT, error: err.response.data });
            const { error, message } = err.response.data;
            switch (error) {
                case apiErrors.CreditCard:
                    return { field: "creditCard", error: { message } };
                case apiErrors.DynamoDB:
                    return { field: "internal", error: { message } };
                case apiErrors.Internal:
                    return { field: "internal", error: { message } };
                case apiErrors.User:
                    return { field: "user", error: { message } };
                default:
                    return { field: "internal", error: { message } };
            }
        }
    };

export const editDraft = (values, draftID) => async (dispatch, getState) => {
    const { draft } = getState().draftdetails;
    const body = {};

    // Marshal given values into request body
    for (const [key, value] of Object.entries(values)) {
        let checkValue = value;
        if (key === "draftDate" && value === "") {
            checkValue = UNSCHEDULED;
        } else if (key === "auctionDraftOptions") {
            checkValue = {
                budget: parseInt(value.budget),
                minimumBid: parseInt(value.minimumBid),
                nominationOrder: value.nominationOrder,
            };
        }

        if (!(key in draft)) {
            body[key] = checkValue;
        } else if (draft[key] !== checkValue) {
            // Perform field specific equality checks
            if (key === "draftOrder") {
                if (draft.draftOrder.length === checkValue.length) {
                    let draftOrderEqual = true;
                    for (let i = 0; i < draft.draftOrder.length; i++) {
                        const currTeam = draft.draftOrder[i];
                        const newTeam = checkValue[i];
                        if (
                            currTeam.team !== newTeam.team ||
                            currTeam.email !== newTeam.email
                        ) {
                            draftOrderEqual = false;
                            break;
                        }
                    }
                    if (draftOrderEqual) continue;
                }
            } else if (key === "positions") {
                if (
                    Object.keys(checkValue).length ===
                    Object.keys(draft.positions).length
                ) {
                    let positionsEqual = true;
                    for (const [pos, value] of Object.entries(checkValue)) {
                        if (draft.positions[pos] !== value) {
                            positionsEqual = false;
                            break;
                        }
                    }
                    if (positionsEqual) continue;
                }
            } else if (key === "auctionDraftOptions") {
                if (
                    draft.draftConfig !== DraftConfig.AUCTION &&
                    values.draftConfig !== DraftConfig.AUCTION
                )
                    continue;
                let auctionOptionsEqual = true;
                for (const [k, v] of Object.entries(checkValue)) {
                    if (draft.auctionDraftOptions[k] !== v) {
                        auctionOptionsEqual = false;
                        break;
                    }
                }
                if (auctionOptionsEqual) continue;
            } else if (key === "customDraftOrder") {
                if (
                    draft.draftConfig !== DraftConfig.CUSTOM &&
                    values.draftConfig !== DraftConfig.CUSTOM
                )
                    continue;
                let customOrderEqual = true;
                for (let i = 0; i < checkValue.length; i++) {
                    if (draft.customDraftOrder[i] !== checkValue[i]) {
                        customOrderEqual = false;
                        break;
                    }
                }
                if (customOrderEqual) continue;
            } else if (key === "timer") {
                let timerEqual = true;
                if (draft.timer.enabled !== checkValue.enabled) {
                    timerEqual = false;
                } else if (draft.timer?.duration !== checkValue?.duration) {
                    timerEqual = false;
                }
                if (timerEqual) continue;
            } else if (key === "trades") {
                if (checkValue.length === draft.trades.length) {
                    let tradesEqual = true;
                    for (let i = 0; i < checkValue.length; i++) {
                        if (
                            checkValue[i].picks[0].teamIndex !==
                                draft.trades[i].picks[0].teamIndex ||
                            checkValue[i].picks[0].round !==
                                draft.trades[i].picks[0].round ||
                            checkValue[i].picks[1].teamIndex !==
                                draft.trades[i].picks[1].teamIndex ||
                            checkValue[i].picks[1].round !==
                                draft.trades[i].picks[1].round
                        ) {
                            tradesEqual = false;
                            break;
                        }
                    }
                    if (tradesEqual) continue;
                }
            }
            body[key] = checkValue;
        }
    }

    // Ignore no-op requests
    if (Object.keys(body).length === 0) return;

    // Set loading prop to true
    dispatch({ type: EDIT_DRAFT });

    try {
        // Send UpdateDraft API request
        const request = { body, headers: await cognitoAuthHeader() };
        const requestURL = `/drafts/${draftID}`;
        const draft = await RestAPI.patch(
            process.env.API_NAME,
            requestURL,
            request
        );
        if (draft.error) dispatch({ type: EDIT_DRAFT, error: draft.error });
        else dispatch({ type: EDIT_DRAFT, draft });
    } catch (error) {
        dispatch({ type: EDIT_DRAFT, error });
        return { field: "internal" };
    }
};

export const deleteDraft = (draftID) => async (dispatch) => {
    // Set loading prop to true
    dispatch({ type: DELETE_DRAFT });

    try {
        // Send DeleteDraft API request
        const request = { headers: await cognitoAuthHeader() };
        const requestURL = `/drafts/${draftID}`;
        await RestAPI.del(process.env.API_NAME, requestURL, request);
        dispatch({ type: DELETE_DRAFT, success: true });
    } catch (error) {
        dispatch({ type: DELETE_DRAFT, error });
        return { field: "internal" };
    }
};

export const resetDraft = () => ({ type: RESET_DRAFT });
