import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import {
    renderEmailPreferenceDetails,
    renderEmailPreferenceForm,
    updateEmailPreferences,
} from "../../../actions/profile";
import Button, { buttonStyle } from "../../common/Button";
import { VerticalContainer } from "../../common/Container";
import { Checkbox } from "../../common/Input";
import {
    ButtonContainer,
    DetailsContainer,
    Header,
} from "../common/DetailsContainer";
import { LabeledValuePartition } from "../common/Label";
import { InfoContainer } from "../common/PageLayout";

const PreferenceInfo = ({ emailPreference, renderForm }) => (
    <VerticalContainer>
        <Header
            heading="Email Preferences"
            secondaryBtn={{
                text: "Edit",
                onClick: renderForm,
            }}
        />
        <DetailsContainer>
            <LabeledValuePartition
                label="Draft Emails"
                value={emailPreference.draft ? "Subscribed" : "Unsubscribed"}
            />
            <LabeledValuePartition
                label="Product Emails"
                value={emailPreference.product ? "Subscribed" : "Unsubscribed"}
            />
            <LabeledValuePartition
                label="Reminder Emails"
                value={
                    emailPreference.reminders ? "Subscribed" : "Unsubscribed"
                }
            />
        </DetailsContainer>
    </VerticalContainer>
);

const Preference = ({ label, display, checked, update }) => (
    <LabeledValuePartition label={display}>
        <Field name={label} type="checkbox">
            {({ input: { onChange } }) => (
                <Checkbox
                    id={label}
                    type="checkbox"
                    checked={checked[label]}
                    onChange={update(onChange, label)}
                />
            )}
        </Field>
    </LabeledValuePartition>
);

const PreferenceForm = ({
    emailPreference,
    loading,
    renderDetails,
    updatePreferences,
}) => {
    const cancel = (e) => {
        e.preventDefault();
        renderDetails();
    };
    const submit = async (values) => {
        const error = await updatePreferences(values);
        if (!error) return;
    };

    // Make Preference Checkboxes controlled inputs
    const [checked, setChecked] = useState(emailPreference);
    const updateChecked = (onChange, field) => () => {
        const origVal = checked[field];
        setChecked({ ...checked, [field]: !origVal });
        onChange(!origVal);
    };

    return (
        <Form
            onSubmit={submit}
            initialValues={emailPreference}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <VerticalContainer>
                        <Header heading="Email Preferences" />
                        <DetailsContainer>
                            <Preference
                                label="draft"
                                display="Draft Emails"
                                checked={checked}
                                update={updateChecked}
                            />
                            <Preference
                                label="product"
                                display="Product Emails"
                                checked={checked}
                                update={updateChecked}
                            />
                            <Preference
                                label="reminders"
                                display="Reminder Emails"
                                checked={checked}
                                update={updateChecked}
                            />
                        </DetailsContainer>
                        <ButtonContainer>
                            <Button
                                size={buttonStyle.Size.MEDIUM}
                                color={buttonStyle.Color.BLUE}
                                priority={buttonStyle.Priority.SECONDARY}
                                disabled={submitting || loading}
                                onClick={cancel}
                                type="button"
                                text="Cancel"
                            />
                            <Button
                                size={buttonStyle.Size.MEDIUM}
                                color={buttonStyle.Color.BLUE}
                                priority={buttonStyle.Priority.PRIMARY}
                                disabled={submitting}
                                loading={loading}
                                type="submit"
                                text="Submit"
                            />
                        </ButtonContainer>
                    </VerticalContainer>
                </form>
            )}
        />
    );
};

const EmailPreference = ({
    emailPreference,
    loading,
    renderEmailPreferenceDetails,
    renderEmailPreferenceForm,
    renderForm,
    updateEmailPreferences,
}) => (
    <InfoContainer>
        {renderForm ? (
            <PreferenceForm
                emailPreference={emailPreference}
                renderDetails={renderEmailPreferenceDetails}
                updatePreferences={updateEmailPreferences}
                loading={loading}
            />
        ) : (
            <PreferenceInfo
                emailPreference={emailPreference}
                renderForm={renderEmailPreferenceForm}
            />
        )}
    </InfoContainer>
);

const mapStateToProps = (state) => {
    const newState = {};
    if (state.profile?.metadata) {
        const { emailPreference } = state.profile.metadata;
        newState.emailPreference = emailPreference;
    }
    if (state.profile?.updateEmailPreference) {
        newState.loading = state.profile.updateEmailPreference.loading;
    }
    newState.renderForm = state.profile?.shouldRenderEmailPreferenceForm;
    return newState;
};
export default connect(mapStateToProps, {
    renderEmailPreferenceDetails,
    renderEmailPreferenceForm,
    updateEmailPreferences,
})(EmailPreference);
