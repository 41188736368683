import { Auth } from "@aws-amplify/auth";
import React, { Component } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { login } from "../../actions/login";
import { cognitoErrors } from "../../js/errors";
import { required } from "../../js/formhelper";
import history from "../../js/history";
import Button, { buttonStyle } from "../common/Button";
import { ButtonWithLinkContainer, InfoContainer } from "./common/Container";
import Display from "./common/Display";
import { LabeledInput, LabeledInputWithLink } from "./common/LabeledInput";
import MessageLink from "./common/MessageLink";

const errors = {
    EMAIL_BLANK: { email: "Email cannot be blank" },
    PASSWORD_BLANK: { email: "Password cannot be blank" },
    USER_DOES_NOT_EXIST: { email: "Invalid email address" },
    USER_NOT_CONFIRMED: { email: "User is not confirmed" },
    INCORRECT_PASSWORD: { password: "Incorrect password" },
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.login = async ({ email, password }) => {
            const error = await this.props.login(email, password);
            if (!error) return;
            if (error === cognitoErrors.UserNotFound) {
                return errors.USER_DOES_NOT_EXIST;
            } else if (error === cognitoErrors.NotAuthorized) {
                return errors.INCORRECT_PASSWORD;
            } else if (error === cognitoErrors.UserNotConfirmed) {
                return errors.USER_NOT_CONFIRMED;
            }
        };
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                history.push("/");
            })
            .catch((error) => {});
    }

    renderForm = () => (
        <Form
            onSubmit={this.login}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <InfoContainer>
                        <LabeledInput
                            labelText="Email"
                            fieldName="email"
                            type="email"
                            tabIndex="1"
                            validate={required}
                        />
                        <LabeledInputWithLink
                            labelText="Password"
                            fieldName="password"
                            type="password"
                            dest="/forgot"
                            destText="Forgot Password"
                            tabIndex="2"
                            validate={required}
                        />
                    </InfoContainer>
                    <ButtonWithLinkContainer>
                        <Button
                            priority={buttonStyle.Priority.PRIMARY}
                            size={buttonStyle.Size.LARGE}
                            color={buttonStyle.Color.BLUE}
                            loading={this.props.loading}
                            disabled={submitting}
                            type="submit"
                            text="Sign In"
                        />
                        <MessageLink
                            message="Don't have an account? "
                            dest="/register"
                            destText="Sign up"
                        />
                    </ButtonWithLinkContainer>
                </form>
            )}
        />
    );

    render = () => <Display>{this.renderForm()}</Display>;
}

const mapStateToProps = (state) => ({ loading: state.login.loading });
export default connect(mapStateToProps, { login })(Login);
