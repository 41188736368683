import React from "react";
import styled from "styled-components";
import { DraftClass, DraftConfig } from "../../../js/constants";
import { DISCOUNT_DESCRIPTION } from "../../../js/descriptions";
import Button, { buttonStyle } from "../../common/Button";
import { LeagueTypeListItem } from "../../common/LeagueType";
import ColorScheme from "../../styles/colors";
import Defaults from "../../styles/defaults";
import PageHeader from "../common/PageHeader";
import { PageContainer } from "../common/PageLayout";
import CreateDraftForm from "./CreateDraftForm";

const DraftsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 32px;
`;

const DiscountContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 32px;

    border: 2px solid ${ColorScheme.green4.color};
    border-radius: ${Defaults.borderRadius};

    color: ${ColorScheme.green4.color};
    font-size: 24px;
    font-weight: 500;
`;

const PriceSpan = styled.span`
    color: ${ColorScheme.logoRed.color};
    font-weight: 700;
`;

const DraftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    width: 100%;
`;

const textColor = (draftType) => {
    switch (draftType) {
        case DraftClass.BASIC:
            return ColorScheme.gray1.color;
        case DraftClass.PRO:
            return ColorScheme.green1.color;
        case DraftClass.PREMIUM:
            return ColorScheme.blue1.color;
        default:
            return ColorScheme.gray1.color;
    }
};

const bgColor = (draftType) => {
    switch (draftType) {
        case DraftClass.BASIC:
            return ColorScheme.gray6.color;
        case DraftClass.PRO:
            return ColorScheme.green5.color;
        case DraftClass.PREMIUM:
            return ColorScheme.blue5.color;
        default:
            return ColorScheme.gray6.color;
    }
};

const DetailsContainer = styled.ul`
    // Strip bullet points from list
    list-style-type: none;

    // Align details vertically
    display: flex;
    flex-direction: column;
    align-items: center;

    // Style text
    color: ${(props) => textColor(props.type)};

    // Style container
    box-shadow: ${Defaults.shadow};
    border-radius: ${Defaults.borderRadius};
    background-color: ${(props) => bgColor(props.draftType)};
    width: 100%;
    height: 100%;

    // Make sure details are sufficiently spaced
    padding: 24px 0px;
    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const DraftDetail = styled.li`
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
    margin-top: 24px;
`;

const PriceContainer = styled.li`
    font-size: 72px;
    font-weight: 700;
    margin: 16px 0;
`;

const Cents = styled.span`
    font-size: 30px;
    font-weight: 700;
    vertical-align: text-top;
`;

const Price = ({ dollars, cents }) => (
    <PriceContainer>
        ${dollars}
        <Cents>
            {`.${cents.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            })}`}
        </Cents>
    </PriceContainer>
);

const SupportsBasicDraft = ({ config }) => {
    if (
        !config.validDraftTypes.includes(DraftConfig.SNAKE) ||
        !config.validDraftTypes.includes(DraftConfig.LINEAR)
    ) {
        return null;
    }
    return <DraftDetail>Snake and Linear Drafts</DraftDetail>;
};

const SupportsAuctionDraft = ({ config }) => {
    if (!config.validDraftTypes.includes(DraftConfig.AUCTION)) {
        return null;
    }
    return <DraftDetail>Auction / Salary Cap Drafts</DraftDetail>;
};

const SupportsDraftTimer = ({ config }) => {
    if (!config.timer) {
        return null;
    }
    return <DraftDetail>Draft Timer</DraftDetail>;
};

const SupportsRemoteDraft = ({ config }) => {
    if (!config.remoteDraft) {
        return null;
    }
    return <DraftDetail>Remote Draft</DraftDetail>;
};

const SupportsKeeperDraft = ({ config }) => {
    if (!config.keeper) {
        return null;
    }
    return <DraftDetail>Keeper Drafts</DraftDetail>;
};

const SupportsCustomDraftOrder = ({ config }) => {
    if (!config.validDraftTypes.includes(DraftConfig.CUSTOM)) {
        return null;
    }
    return <DraftDetail>Customize Draft Order by Round</DraftDetail>;
};

const SupportsIDP = ({ config }) => {
    if (!config.idp) {
        return null;
    }
    return <DraftDetail>Individual Defensive Players</DraftDetail>;
};

const SupportsTradePicks = ({ config }) => {
    if (!config.tradePicks) {
        return null;
    }
    return <DraftDetail>Trade Picks</DraftDetail>;
};

const SupportsLiveChat = ({ config }) => {
    if (!config.liveChat) {
        return null;
    }
    return <DraftDetail>Live Chat Support</DraftDetail>;
};

const Draft = ({ draftType, config, updateDraftType }) => (
    <DraftContainer>
        <DetailsContainer draftType={draftType}>
            <LeagueTypeListItem
                draftType={draftType}
                size={buttonStyle.Size.LARGE}
            />
            <Price
                dollars={Math.trunc(config.price / 100)}
                cents={config.price % 100}
            />
            <DraftDetail>Up to {config.maxLeagueSize} teams</DraftDetail>
            <DraftDetail>Up to {config.maxRounds} rounds</DraftDetail>
            <SupportsBasicDraft config={config} />
            <SupportsAuctionDraft config={config} />
            <SupportsDraftTimer config={config} />
            <SupportsRemoteDraft config={config} />
            <SupportsKeeperDraft config={config} />
            <SupportsCustomDraftOrder config={config} />
            <SupportsIDP config={config} />
            <SupportsTradePicks config={config} />
        </DetailsContainer>
        <ButtonContainer>
            <Button
                size={buttonStyle.Size.LARGE}
                priority={buttonStyle.Priority.PRIMARY}
                color={
                    draftType === DraftClass.BASIC
                        ? buttonStyle.Color.GRAY
                        : draftType === DraftClass.PRO
                        ? buttonStyle.Color.GREEN
                        : draftType === DraftClass.PREMIUM
                        ? buttonStyle.Color.BLUE
                        : buttonStyle.Color.GRAY
                }
                onClick={() => updateDraftType(draftType)}
                text={`Go ${draftType}`}
                type="submit"
                analytics={{ name: `create-draft-${draftType}` }}
            />
        </ButtonContainer>
    </DraftContainer>
);

const Discount = () => (
    <DiscountContainer>
        {DISCOUNT_DESCRIPTION}&nbsp;
        <PriceSpan>FIVEOFFFIRST</PriceSpan>
    </DiscountContainer>
);

const SelectDraft = ({ draftConfig, updateDraftType }) => (
    <CreateDraftForm.Page>
        <PageContainer>
            <PageHeader heading="Select Draft" />
            <Discount />
            <DraftsContainer>
                <Draft
                    draftType={DraftClass.BASIC}
                    config={draftConfig[DraftClass.BASIC]}
                    updateDraftType={updateDraftType}
                />
                <Draft
                    draftType={DraftClass.PRO}
                    config={draftConfig[DraftClass.PRO]}
                    updateDraftType={updateDraftType}
                />
                <Draft
                    draftType={DraftClass.PREMIUM}
                    config={draftConfig[DraftClass.PREMIUM]}
                    updateDraftType={updateDraftType}
                />
            </DraftsContainer>
        </PageContainer>
    </CreateDraftForm.Page>
);

export default SelectDraft;
