import React from "react";
import styled, { css } from "styled-components";
import { DraftClass } from "../../js/constants";
import ColorScheme from "../styles/colors";
import Defaults, { DefaultTransition } from "../styles/defaults";
import { StyleStrip } from "../styles/Reset";
import { buttonStyle } from "./Button";

const bgcolor = (draftType, disabled) => {
    if (disabled) return ColorScheme.gray1.color;
    switch (draftType) {
        case DraftClass.BASIC:
            return ColorScheme.gray2.color;
        case DraftClass.PRO:
            return ColorScheme.green1.color;
        case DraftClass.PREMIUM:
            return ColorScheme.blue1.color;
        default:
            return ColorScheme.gray2.color;
    }
};

const bordercolor = (draftType, disabled) => {
    if (disabled) return `1px solid ${ColorScheme.gray3.color}`;
    switch (draftType) {
        case DraftClass.BASIC:
            return `1px solid ${ColorScheme.gray6.color}`;
        case DraftClass.PRO:
            return `1px solid ${ColorScheme.green5.color}`;
        case DraftClass.PREMIUM:
            return `1px solid ${ColorScheme.blue4.color}`;
        default:
            return `1px solid ${ColorScheme.gray6.color}`;
    }
};

const textcolor = (draftType, disabled) => {
    if (disabled) return ColorScheme.gray3.color;
    switch (draftType) {
        case DraftClass.BASIC:
            return ColorScheme.gray6.color;
        case DraftClass.PRO:
            return ColorScheme.green5.color;
        case DraftClass.PREMIUM:
            return ColorScheme.blue6.color;
        default:
            return ColorScheme.gray6.color;
    }
};

const LeagueTypeContainerStyle = css`
    ${StyleStrip}

    // Set desired styling
    border-radius: ${Defaults.borderRadius};
    font-family: inherit;
    line-height: 100%;

    // Style box according to props
    width: ${(props) =>
        props.small ? "48px" : props.medium ? "64px" : "96px"};
    height: ${(props) =>
        props.small ? "16px" : props.medium ? "24px" : "32px"};
    background-color: ${(props) => bgcolor(props.draftType, props.disabled)};
    color: ${(props) => textcolor(props.draftType, props.disabled)};
    border: ${(props) => bordercolor(props.draftType, props.disabled)};

    display: flex;
    justify-content: center;
    align-items: center;
`;

const LeagueTypeTextStyle = css`
    font-size: ${(props) =>
        props.small ? "12px" : props.medium ? "14px" : "18px"};
    text-align: center;
    vertical-align: middle;
`;

const LeagueTypeContainer = styled.div`
    ${LeagueTypeContainerStyle}
`;

const LeagueTypeText = styled.h2`
    ${StyleStrip}
    ${LeagueTypeTextStyle}
    cursor: default;
`;

const LeagueTypeListItemContainer = styled.li`
    ${StyleStrip}
    ${LeagueTypeContainerStyle}
    cursor: default;
`;

const pressDownEffect = css`
    // Animate press down effect
    box-shadow: 0 2px 2px ${ColorScheme.shadowBlack.color};
    ${DefaultTransition}

    &:hover {
        // Animate press down effect
        box-shadow: 0 4px 4px ${ColorScheme.shadowBlack.color};
    }

    &:active {
        // Animate press down effect
        box-shadow: 0 1px 1px ${ColorScheme.shadowBlack.color};
    }
`;

const LeagueTypeButtonContainer = styled.button`
    ${LeagueTypeContainerStyle}
    ${pressDownEffect}
    cursor: pointer;
`;

const LeagueType = ({ size, draftType, disabled }) => {
    const [small, medium, large] = [
        size === buttonStyle.Size.SMALL,
        size === buttonStyle.Size.MEDIUM,
        size === buttonStyle.Size.LARGE,
    ];
    return (
        <LeagueTypeContainer
            small={small}
            medium={medium}
            large={large}
            draftType={draftType}
            disabled={disabled}
        >
            <LeagueTypeText disabled={disabled}>{draftType}</LeagueTypeText>
        </LeagueTypeContainer>
    );
};

export const LeagueTypeButton = ({ size, draftType, onClick }) => {
    const [small, medium, large] = [
        size === buttonStyle.Size.SMALL,
        size === buttonStyle.Size.MEDIUM,
        size === buttonStyle.Size.LARGE,
    ];
    return (
        <LeagueTypeButtonContainer
            small={small}
            medium={medium}
            large={large}
            draftType={draftType}
            onClick={onClick}
            type="button"
        >
            {draftType}
        </LeagueTypeButtonContainer>
    );
};

export const LeagueTypeListItem = ({ size, draftType }) => {
    const [small, medium, large] = [
        size === buttonStyle.Size.SMALL,
        size === buttonStyle.Size.MEDIUM,
        size === buttonStyle.Size.LARGE,
    ];
    return (
        <LeagueTypeListItemContainer
            small={small}
            medium={medium}
            large={large}
            draftType={draftType}
        >
            <LeagueTypeText>{draftType}</LeagueTypeText>
        </LeagueTypeListItemContainer>
    );
};

export default LeagueType;
