import styled from "styled-components";
import { Draftboard } from "../../styles/colors";
import Defaults from "../../styles/defaults";

const InfoContainer = styled.div`
    ${Draftboard.Background.lightblue};
    box-shadow: ${Defaults.shadow};

    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "16px")};
    padding-bottom: ${(props) =>
        props.paddingTop ? props.paddingTop : "16px"};
    padding-left: 12px;
    padding-right: 12px;

    border-radius: ${Defaults.borderRadius};

    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "none")};
    height: 100%;
    width: 100%;
`;

export default InfoContainer;
