import React from "react";
import { Field } from "react-final-form";
import styled from "styled-components";
import { EmailNotification } from "../../../js/constants";
import { EMAIL_NOTIFICATION_DESCRIPTION } from "../../../js/descriptions";
import { Radio } from "../../common/Input";
import ColorScheme from "../../styles/colors";
import { InfoContainer, InfoContainerH2 } from "../common/PageLayout";

const InputContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Description = styled.div`
    color: ${ColorScheme.blue3.color};
    font-size: 16px;
    font-weight: 400;
`;

const EmailOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 48px;
    margin-top: 8px;
`;

const RadioLabel = styled.label`
    color: ${ColorScheme.blue4.color};
    font-size: 20px;
    font-weight: 400;
    margin-left: 12px;
    cursor: pointer;
`;

const EmailOption = ({ label, value }) => (
    <InputContainer>
        <Field name="emailSetting" type="radio" value={value}>
            {({ input }) => <Radio id={label} type="radio" small {...input} />}
        </Field>
        <RadioLabel htmlFor={label}>{label}</RadioLabel>
    </InputContainer>
);

const EmailFeature = () => (
    <InfoContainer>
        <InfoContainerH2>Email Notifications</InfoContainerH2>
        <Description>{EMAIL_NOTIFICATION_DESCRIPTION}</Description>
        <EmailOptionsContainer>
            <EmailOption
                label="Send to all teams"
                value={EmailNotification.ALL}
            />
            <EmailOption
                label="Send only to me"
                value={EmailNotification.SELF}
            />
            <EmailOption label="Don't Send" value={EmailNotification.NONE} />
        </EmailOptionsContainer>
    </InfoContainer>
);

export default EmailFeature;
