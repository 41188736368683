import React from "react";
import styled from "styled-components";
import { DraftClass, FEATURES_URL, HELP_ICON } from "../../../js/constants";
import { IconWithTextAnchor } from "../../common/IconButton";
import Line from "../../common/Line";
import ColorScheme from "../../styles/colors";
import { DefaultTransition, FocusShadow } from "../../styles/defaults";
import { StyleStrip } from "../../styles/Reset";
import Dropdown from "./Dropdown";

const UpsellButton = styled.button`
    ${StyleStrip}
    ${FocusShadow}
    ${DefaultTransition}

    // Center button text
    display: flex;
    align-items: center;
    justify-content: flex-start;

    // Make sure container occupies entire width of Dropdown
    width: 100%;
    padding: 6px 16px;

    // Style container elements
    color: ${ColorScheme.blue4.color};
    cursor: pointer;

    // Change background color when user hovers over Icon
    &:hover {
        background-color: ${ColorScheme.gray2.color};
    }
`;

const Upsell = ({ action, draftType }) => (
    <UpsellButton
        onClick={action}
        type="button"
        analytics={{ name: `upsell-${draftType}` }}
    >
        {`Upgrade to ${draftType}`}
    </UpsellButton>
);

const renderUpsell = (draftType, updateDraftType, hide) => {
    const pro = () => {
        updateDraftType(DraftClass.PRO);
        hide();
    };
    const premium = () => {
        updateDraftType(DraftClass.PREMIUM);
        hide();
    };

    const upgradePro = (
        <Upsell
            action={pro}
            upgrade
            draftType={DraftClass.PRO}
            key="upgrade-pro"
        />
    );
    const upgradePremium = (
        <Upsell
            action={premium}
            upgrade
            draftType={DraftClass.PREMIUM}
            key="upgrade-premium"
        />
    );

    const buttons = [];
    switch (draftType) {
        case DraftClass.BASIC:
            buttons.push(upgradePro);
            buttons.push(upgradePremium);
            break;
        case DraftClass.PRO:
            buttons.push(upgradePremium);
            break;
        case DraftClass.PREMIUM:
            break;
        default:
            buttons.push(upgradePro);
            buttons.push(upgradePremium);
    }
    return buttons;
};

// Upsell Button height = 32px
const UpsellDropdown = ({ hide, displaySelf, draftType, updateDraftType }) => (
    <Dropdown
        hide={hide}
        displaySelf={displaySelf}
        draftType={draftType}
        elementHeight={32}
    >
        {renderUpsell(draftType, updateDraftType, hide)}
        <Line margin={8} />
        <IconWithTextAnchor
            iconHref={HELP_ICON}
            dest={FEATURES_URL}
            destText="Feature Help"
            newTab
        />
    </Dropdown>
);

export default UpsellDropdown;
