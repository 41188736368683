import {
    AuctionNominationOrder,
    DraftActions,
    DraftConfig,
} from "../constants";

let draftboard = null;

// General utilities for managing draftboard data structure
const draftboardIndex = (team, round, numPlayers) => round * numPlayers + team;
const roundTeam = (draftboardIndex) => {
    const round = Math.floor(draftboardIndex / draftboard.numPlayers);
    const team = draftboardIndex % draftboard.numPlayers;
    return [round, team];
};
export const tradeKey = (team, round) => `${team}-${round}`;

// Draftboard initializer / destructor
export const initializeDraftboard = (draft) => {
    draftboard = new Draftboard(draft);
};
export const resetDraftboard = () => (draftboard = null);
export const resetDraftboardActions = () => draftboard.resetActions();

// Exposed draftboard setters, getters, and methods
export const auctionBudgets = () => structuredClone(draftboard.budgets);
export const maximumBids = () => structuredClone(draftboard.maximumBids);
export const actions = () => structuredClone(draftboard.actions);
export const trades = () => structuredClone(draftboard.trades);
export const actionsValid = (actions) => draftboard.valid(actions);
export const graftActions = (actions) => draftboard.graft(actions);
export const isDrafted = (playerID) => playerID in draftboard.drafted;
export const repeatAction = (action) => draftboard.repeatAction(action);
export const getPlayer = (team, round) => {
    const index = draftboardIndex(team, round, draftboard.numPlayers);
    if (index in draftboard.draftboard) {
        const actionIndex = draftboard.draftboard[index];
        return structuredClone(draftboard.actions[actionIndex].fantasyPlayer);
    }
    return null;
};
export const lastPick = () => {
    const picksLen = draftboard.picks.length;
    if (picksLen === 0) return null;
    return structuredClone(
        draftboard.actions[draftboard.picks[picksLen - 1]].fantasyPlayer
    );
};
export const lastKeeper = () => {
    const keepersLen = draftboard.keepers.length;
    if (keepersLen === 0) return null;
    return structuredClone(
        draftboard.actions[draftboard.keepers[keepersLen - 1]].fantasyPlayer
    );
};
export const totalPicks = () =>
    draftboard.keepers.length + draftboard.picks.length;
export const actionIndex = () => draftboard.actions.length;
export const processAction = (action) => draftboard.process(action);
export const ingestActions = (actions) => draftboard.ingest(actions);
export const board = () => {
    const teams = [];
    for (let i = 0; i < draftboard.numPlayers; i++) {
        teams.push(new Array(draftboard.numRounds));
    }
    for (const [index, actionIndex] of Object.entries(draftboard.draftboard)) {
        const [round, team] = roundTeam(index);
        const { keeper, fantasyPlayer } = draftboard.actions[actionIndex];
        fantasyPlayer.keeper = keeper;
        teams[team][round] = fantasyPlayer;
    }
    return structuredClone(teams);
};
export const currTeamRoundOpenings = () => {
    const numPlayers = draftboard.numPlayers;
    const rounds = draftboard.numRounds;
    const draftConfig = draftboard.draftConfig;
    const customDraftOrder = draftboard.customDraftOrder;
    const trades = draftboard.trades;

    // Check if draft is completed
    if (totalPicks() === numPlayers * rounds) return [-1, -1, []];

    // Iterate over draftboard to find the current team/round
    switch (draftConfig) {
        case DraftConfig.CUSTOM:
        case DraftConfig.LINEAR:
        case DraftConfig.SNAKE:
            let draftForward = true;
            for (let i = 0; i < rounds; i++) {
                for (let j = 0; j < numPlayers; j++) {
                    // Initialize teamIndex and round
                    let [round, teamIndex] = [i, j];

                    // Modify teamIndex based on snake draft direction
                    if (!draftForward) teamIndex = numPlayers - j - 1;

                    // Copy over round/teamIndex so that we can check for
                    // trades without losing a handle on the current round/team
                    let [checkRound, checkIndex] = [round, teamIndex];

                    // Modify index/round, if pick has been traded
                    const key = tradeKey(checkIndex, checkRound);
                    let pickTraded = false;
                    if (key in trades) {
                        checkIndex = trades[key].teamIndex;
                        checkRound = trades[key].round;
                        pickTraded = true;
                    }

                    // If spot has been picked, continue
                    if (draftboard.occupied(checkIndex, checkRound)) continue;

                    // Return opening + corresponding traded pick
                    if (pickTraded) {
                        return [
                            teamIndex,
                            round,
                            [
                                [checkIndex, checkRound, false],
                                [teamIndex, round, true],
                            ],
                        ];
                    }

                    return [
                        teamIndex,
                        round,
                        [[checkIndex, checkRound, false]],
                    ];
                }

                // Set draft direction according to draftConfig
                if (draftConfig === DraftConfig.SNAKE) {
                    draftForward = !draftForward;
                } else if (draftConfig === DraftConfig.CUSTOM) {
                    if (i + 1 < customDraftOrder.length) {
                        const nextDraftOrder = customDraftOrder[i + 1];
                        if (nextDraftOrder === DraftConfig.LINEAR) {
                            draftForward = true;
                        } else if (nextDraftOrder === DraftConfig.SNAKE) {
                            draftForward = !draftForward;
                        }
                    }
                }
            }
            break;
        case DraftConfig.AUCTION:
            const openings = [];
            // Iterate over all players, and find the first draft opening
            for (let i = 0; i < numPlayers; i++) {
                for (let j = 0; j < rounds; j++) {
                    if (draftboard.occupied(i, j)) continue;
                    openings.push([i, j, false]);
                    break;
                }
            }

            // Calculate current round by using the total number of picks
            let currentRound = Math.floor(totalPicks() / numPlayers);

            return [draftboard.auctionState.nextTeam, currentRound, openings];
        default:
            break;
    }
    return [-1, -1, []];
};

class Draftboard {
    constructor(draft) {
        // Initialize draftboard state
        this.numRounds = draft.rounds;
        this.numPlayers = draft.numPlayers;
        this.trades = {};
        this.draftConfig = draft.draftConfig;
        this.auction = this.draftConfig === DraftConfig.AUCTION;
        this.auctionDraftOptions = draft.auctionDraftOptions;
        this.auctionState = { draftForward: true, nextTeam: 0 };
        this.customDraftOrder = draft.customDraftOrder;
        this.actions = [];
        this.picks = [];
        this.keepers = [];
        this.drafted = {};
        this.draftboard = {};
        this.budgets = [];
        this.maximumBids = [];

        // Set auction budgets for auction drafts
        if (this.auction) {
            const { budget, minimumBid } = this.auctionDraftOptions;
            for (let i = 0; i < this.numPlayers; i++) {
                this.budgets.push(budget);
                this.maximumBids.push(
                    budget - (this.numRounds - 1) * minimumBid
                );
            }
        }
        const initBudgets = structuredClone(this.budgets);

        // Load all draft trades into trades object
        if (draft.trades && draft.trades.length > 0) {
            for (let i = 0; i < draft.trades.length; i++) {
                const currTrade = draft.trades[i];

                // Seed draft.trades with trade from first pick
                let firstPick = currTrade.picks[0];
                let lastPick = currTrade.picks[currTrade.picks.length - 1];
                let key = tradeKey(firstPick.teamIndex, firstPick.round);
                this.trades[key] = lastPick;

                // Fill in the rest of the picks from the trade
                let prevPick = firstPick;
                for (let j = 1; j < currTrade.picks.length; j++) {
                    const pick = currTrade.picks[j];
                    key = tradeKey(pick.teamIndex, pick.round);
                    this.trades[key] = prevPick;
                    prevPick = currTrade.picks[j];
                }
            }
        }

        // Process actions
        if (!draft.actions || draft.actions.length === 0) return;
        for (let i = 0; i < draft.actions.length; i++) {
            this.process(draft.actions[i]);
        }
        if (this.picks.length === 0 && this.auction) {
            this.budgets = initBudgets;
        }
    }

    resetActions() {
        // These don't get reset because this is the state that should persist
        // this.trades = {};

        this.actions = [];
        this.budgets = [];
        this.keepers = [];
        this.picks = [];
        this.drafted = {};
        this.draftboard = {};
        this.maximumBids = [];

        // Set auction budgets for auction drafts
        if (this.auction) {
            const { budget, minimumBid } = this.auctionDraftOptions;
            for (let i = 0; i < this.numPlayers; i++) {
                this.budgets.push(budget);
                this.maximumBids.push(
                    budget - (this.numRounds - 1) * minimumBid
                );
            }
        }
    }

    occupied(team, round) {
        const index = draftboardIndex(team, round, this.numPlayers);
        return index in this.draftboard;
    }

    teamFull(team) {
        let openSpots = false;
        for (let i = 0; i < this.numRounds; i++) {
            if (!this.occupied(team, i)) {
                openSpots = true;
                break;
            }
        }
        return !openSpots;
    }

    repeatAction(action) {
        if (this.actions.length === 0) return false;
        const { index } = action;
        const lastActionsIndex = this.actions[this.actions.length - 1].index;
        if (index > lastActionsIndex) return false;
        const currAction = this.actions[index];
        return (
            currAction.action === action.action &&
            currAction.team === action.team &&
            currAction.round === action.round &&
            currAction.keeper === action.keeper &&
            currAction.fantasyPlayer?.id === action.fantasyPlayer?.id &&
            currAction.fantasyPlayer?.cost === action.fantasyPlayer?.cost
        );
    }

    processAuctionState(newAction) {
        const { action, keeper } = newAction;

        // Keepers and no-ops don't affect auction state
        if (keeper || action === DraftActions.NOP) return;

        const { nominationOrder } = this.auctionDraftOptions;
        const { nextTeam, draftForward } = this.auctionState;

        // If draft is full, leave nextTeam the way it is:
        // The only possible action is undo, which will revert us back
        // to the right state
        const totalPicks = this.picks.length + this.keepers.length;
        if (totalPicks === this.numPlayers * this.numRounds) return;

        switch (action) {
            case DraftActions.SELECT:
                switch (nominationOrder) {
                    case AuctionNominationOrder.LINEAR: {
                        let newTeam = nextTeam;
                        do {
                            newTeam = (newTeam + 1) % this.numPlayers;
                        } while (this.teamFull(newTeam));

                        this.auctionState.nextTeam = newTeam;
                        break;
                    }
                    case AuctionNominationOrder.SNAKE:
                        let checkForward = draftForward;
                        let newTeam = nextTeam;
                        do {
                            if (checkForward) {
                                newTeam = (newTeam + 1) % this.numPlayers;
                                if (newTeam === 0) {
                                    newTeam = this.numPlayers - 1;
                                    checkForward = !checkForward;
                                }
                            } else {
                                newTeam =
                                    (newTeam - 1 + this.numPlayers) %
                                    this.numPlayers;
                                if (newTeam === this.numPlayers - 1) {
                                    newTeam = 0;
                                    checkForward = !checkForward;
                                }
                            }
                        } while (this.teamFull(newTeam));

                        this.auctionState.nextTeam = newTeam;
                        this.auctionState.draftForward = checkForward;
                        break;
                    case AuctionNominationOrder.HIGHEST_BID:
                        // Iterate backwards over all picks
                        // until we find the last team with the highest bid
                        for (let i = this.picks.length - 1; i >= 0; i--) {
                            const currIndex = this.picks[i];
                            const currAction = this.actions[currIndex];
                            if (!this.teamFull(currAction.team)) {
                                this.auctionState.nextTeam = currAction.team;
                                return;
                            }
                        }
                        // If we iterated over all picks, and we didn't find a team,
                        // choose the first team with an open roster spot
                        for (let i = 0; i < this.numPlayers; i++) {
                            if (!this.teamFull(i)) {
                                this.auctionState.nextTeam = i;
                                break;
                            }
                        }
                        break;
                    default:
                        break;
                }
                break;
            case DraftActions.UNDO:
                const currIndex = this.picks[this.picks.length - 1];
                const currAction = this.actions[currIndex];
                this.auctionState.nextTeam = currAction.nominated;
                break;
            default:
                break;
        }
    }

    process(newAction) {
        const {
            action,
            index: actionIndex,
            team,
            round,
            keeper,
            fantasyPlayer,
        } = newAction;

        // Auction drafts need access to the last pick for undo actions,
        // so process auction state first for undos
        if (this.auction && action === DraftActions.UNDO) {
            this.processAuctionState(newAction);
        }
        switch (action) {
            case DraftActions.NOP:
                break;
            case DraftActions.SELECT: {
                const { id, cost } = fantasyPlayer;

                // Ignore duplicates
                if (id in this.drafted) return;

                // Mark player as drafted and add player to keepers/picks list
                if (keeper) {
                    this.drafted[id] = this.keepers.length;
                    this.keepers.push(actionIndex);
                } else {
                    this.drafted[id] = this.picks.length;
                    this.picks.push(actionIndex);
                }

                // Add player to draftboard
                const i = draftboardIndex(team, round, this.numPlayers);
                this.draftboard[i] = actionIndex;

                // Deduct player cost from team budget
                if (this.auction) {
                    this.budgets[team] -= cost;
                    this.maximumBids[team] -=
                        cost - this.auctionDraftOptions.minimumBid;
                }
                break;
            }
            case DraftActions.UNDO:
                // Get last pick from either the picks or keepers list
                let pickList = this.picks;
                if (keeper) {
                    pickList = this.keepers;
                }

                // Extract the necessary information from draftboard state
                const lastPick = pickList[pickList.length - 1];
                const lastAction = this.actions[lastPick];
                const {
                    team: lastTeam,
                    round: lastRound,
                    fantasyPlayer: { id, cost },
                } = lastAction;

                // Ignore spurious undos
                if (!(id in this.drafted)) return;

                // Unmark fantasy player as drafted
                delete this.drafted[id];

                // Remove last pick from picks/keepers list
                if (keeper) {
                    this.keepers = pickList.slice(0, pickList.length - 1);
                } else {
                    this.picks = pickList.slice(0, pickList.length - 1);
                }

                // Remove last pick from draftboard
                const i = draftboardIndex(lastTeam, lastRound, this.numPlayers);
                delete this.draftboard[i];

                // Add player cost back to team budget
                if (this.auction) {
                    this.budgets[lastTeam] += cost;
                    this.maximumBids[lastTeam] +=
                        cost - this.auctionDraftOptions.minimumBid;
                }
                break;
            case DraftActions.REPLACE: {
                const { id, cost } = fantasyPlayer;

                // Get the action which added the previous pick we're replacing
                const i = draftboardIndex(team, round, this.numPlayers);
                const prevActionIndex = this.draftboard[i];

                // Update action index in draftboard
                this.draftboard[i] = actionIndex;

                // Get metadata from replaced player
                const prevAction = this.actions[prevActionIndex];
                const prevPlayerID = prevAction.fantasyPlayer.id;

                // Update list of picks with reference to replaced player
                const pickIndex = this.drafted[prevPlayerID];
                if (prevAction.keeper) {
                    this.keepers[pickIndex] = actionIndex;
                } else {
                    this.picks[pickIndex] = actionIndex;
                }

                // Mark replacement player as drafted
                // Remove replaced player from drafted list
                this.drafted[id] = pickIndex;
                delete this.drafted[prevPlayerID];

                // Make sure to copy over any relevant metadata
                if (prevAction.nominated) {
                    newAction.nominated = prevAction.nominated;
                }

                // Adjust auction budget
                if (this.auction) {
                    this.budgets[team] += prevAction.fantasyPlayer.cost - cost;
                    this.maximumBids[team] +=
                        prevAction.fantasyPlayer.cost - cost;
                }
                break;
            }
            default:
                break;
        }
        this.actions.push(newAction);

        // Update auction state
        if (this.auction && action !== DraftActions.UNDO) {
            this.processAuctionState(newAction);
        }
    }

    graft(actions) {
        const { index } = actions[0];
        return this.actions.slice(0, index).concat(actions);
    }

    valid(actions) {
        if (actions === null) {
            return true;
        }
        if (actions.length === 0) {
            return true;
        }
        if (actions[0].index === 0 && this.actions.length === 0) {
            return true;
        }

        // If the ingested actions overlap with the current actions,
        // check to see if the overlapping actions match the existing actions
        const { index } = actions[0];
        const lastActionsIndex = actions[actions.length - 1].index;
        if (index <= lastActionsIndex) {
            for (
                let i = index;
                i < Math.min(this.actions.length, lastActionsIndex + 1);
                i++
            ) {
                const curr = this.actions[i];
                const check = actions[i - index];
                if (
                    curr.action !== check.action ||
                    curr.index !== check.index ||
                    curr.action !== check.action ||
                    curr.team !== check.team ||
                    curr.round !== check.round ||
                    curr.keeper !== check.keeper ||
                    curr.fantasyPlayer?.id !== check.fantasyPlayer?.id ||
                    curr.fantasyPlayer?.cost !== check.fantasyPlayer?.cost
                ) {
                    return false;
                }
            }
        }

        // Verify actions are contiguous
        for (let i = 1; i < actions.length; i++) {
            if (actions[i].index !== actions[i - 1].index + 1) {
                return false;
            }
        }
        return true;
    }
}
