import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Checkbox } from "../../common/Input";
import {
    RowContainer,
    ThreeColumnFieldStyle,
    ThreeColumnFirstFieldStyle,
    ThreeColumnTable,
} from "../../common/Table";
import ColorScheme from "../../styles/colors";
import { Header, InfoContainer } from "./Container";

const TextStyle = css`
    // Style text
    font-family: inherit;
    font-size: 18px;

    // Give space between input text and bounding box
    padding: 8px 16px;

    background-color: transparent;
`;

const DraftOrderContainer = styled.div`
    ${TextStyle}
    ${ThreeColumnFirstFieldStyle}
    color: ${ColorScheme.gray6.color};
`;

const TeamNameContainer = styled.div`
    ${TextStyle}
    ${ThreeColumnFieldStyle}
    color: ${ColorScheme.blue5.color};
`;

const AutodraftContainer = styled.div`
    ${TextStyle}
    ${ThreeColumnFieldStyle}
    color: ${ColorScheme.blue5.color};
`;

const Team = ({ order, team, autodraft, updateAutodraft }) => {
    // Make checkbox a controlled input
    const [checked, setChecked] = useState(
        autodraft === undefined ? false : autodraft
    );
    const updateChecked = () => {
        updateAutodraft(order, !checked);
        setChecked(!checked);
    };

    return (
        <RowContainer index={order}>
            <DraftOrderContainer>{order + 1}</DraftOrderContainer>
            <TeamNameContainer>{team}</TeamNameContainer>
            <AutodraftContainer>
                <Checkbox
                    type="checkbox"
                    checked={checked}
                    onChange={updateChecked}
                    dark
                />
            </AutodraftContainer>
        </RowContainer>
    );
};

const Autodraft = ({ draftOrder, updateAutodraft }) => {
    const teams = [];
    for (let i = 0; i < draftOrder.length; i++) {
        teams.push(
            <Team
                key={`autodraft-${i}`}
                order={i}
                team={draftOrder[i].team}
                autodraft={draftOrder[i].autodraft}
                updateAutodraft={updateAutodraft}
            />
        );
    }
    return (
        <InfoContainer>
            <Header heading="Autodraft" />
            <ThreeColumnTable headerFields={["Pick", "Team Name", "Autodraft"]}>
                {teams}
            </ThreeColumnTable>
        </InfoContainer>
    );
};

export default Autodraft;
