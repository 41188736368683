import React from "react";
import { REGISTER_SUCCESS_DESCRIPTION } from "../../js/descriptions";
import history from "../../js/history";
import Button, { buttonStyle } from "../common/Button";
import {
    ButtonContainer,
    InfoContainer,
    MessageContainer,
} from "./common/Container";
import Display from "./common/Display";

const RegisterSuccess = () => (
    <Display>
        <InfoContainer>
            <MessageContainer>{REGISTER_SUCCESS_DESCRIPTION}</MessageContainer>
        </InfoContainer>
        <ButtonContainer>
            <Button
                priority={buttonStyle.Priority.PRIMARY}
                size={buttonStyle.Size.LARGE}
                color={buttonStyle.Color.BLUE}
                onClick={() => history.push("/login")}
                text="Sign In"
            />
        </ButtonContainer>
    </Display>
);

export default RegisterSuccess;
