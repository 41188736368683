import React, { Component } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import {
    resendCode,
    resetResendStatus,
    verifyEmail,
} from "../../actions/login";
import {
    VERIFY_EMAIL_DESCRIPTION,
    VERIFY_EMAIL_DESCRIPTION_WITH_EMAIL,
} from "../../js/descriptions";
import { cognitoErrors } from "../../js/errors";
import { required } from "../../js/formhelper";
import Button, { buttonStyle } from "../common/Button";
import {
    ButtonWithLinkContainer,
    EmailContainer,
    InfoContainer,
    MessageContainer,
} from "./common/Container";
import Display from "./common/Display";
import { LabeledInput, LabeledInputWithAction } from "./common/LabeledInput";

// FIXME: Need to add back buttons

const errors = {
    CODE_BLANK: { code: "Code cannot be blank" },
    INVALID_CODE: { code: "Invalid code" },
};

class VerifyEmail extends Component {
    constructor(props) {
        super(props);
        this.verify = async ({ code, email }) => {
            const error = await this.props.verifyEmail(code, email);
            if (!error) return;
            if (error === cognitoErrors.CodeMismatch) {
                return errors.INVALID_CODE;
            }
        };
        this.resendCode = () => {
            this.props.resendCode();
            setTimeout(() => this.props.resetResendStatus(), 3500);
        };
    }

    renderForm = () => {
        // Show message if status is null or undefined
        // Show error if status.success is false
        // Show success if status.success is true
        const status = this.props.resendCodeStatus;
        const showErr = !!status && !status.loading && !status.success;
        const showSuccess = !!status && !status.loading && status.success;

        let errMsg;
        if (status && status.error) {
            if (status.error.code === cognitoErrors.LimitExceeded) {
                errMsg = "Attempt Limit Exceeded";
            } else {
                errMsg = status.error.message;
            }
        }

        const action = {
            showMsg: !status,
            text: "Resend Code",
            callback: this.resendCode,
            loading: !!status ? status.loading : false,
            error: showErr ? { message: errMsg } : null,
            success: showSuccess ? { message: "Code sent" } : null,
        };

        return (
            <Form
                onSubmit={this.verify}
                render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <InfoContainer>
                            <MessageContainer>
                                {this.props.email
                                    ? VERIFY_EMAIL_DESCRIPTION_WITH_EMAIL
                                    : VERIFY_EMAIL_DESCRIPTION}
                                {this.props.email && (
                                    <EmailContainer>
                                        {this.props.email}
                                    </EmailContainer>
                                )}
                            </MessageContainer>
                            {!this.props.email && (
                                <LabeledInput
                                    labelText="Email"
                                    fieldName="email"
                                    type="email"
                                    tabIndex="1"
                                    validate={required}
                                />
                            )}
                            <LabeledInputWithAction
                                labelText="Verification Code"
                                fieldName="code"
                                type="text"
                                tabIndex="2"
                                action={action}
                                validate={required}
                            />
                        </InfoContainer>
                        <ButtonWithLinkContainer>
                            <Button
                                loading={this.props.loading}
                                priority={buttonStyle.Priority.PRIMARY}
                                size={buttonStyle.Size.LARGE}
                                color={buttonStyle.Color.BLUE}
                                disabled={submitting}
                                type="submit"
                                text="Submit"
                            />
                        </ButtonWithLinkContainer>
                    </form>
                )}
            />
        );
    };
    render = () => <Display>{this.renderForm()}</Display>;
}

const mapStateToProps = (state) => {
    const newState = {};
    if (state.register.verify) {
        newState.loading = state.register.verify.loading;
    }
    if (state.register.resendCode) {
        newState.resendCodeStatus = state.register.resendCode;
    }
    if (state.register.email) {
        newState.email = state.register.email;
    }
    return newState;
};

export default connect(mapStateToProps, {
    resendCode,
    resetResendStatus,
    verifyEmail,
})(VerifyEmail);
