import React from "react";
import styled from "styled-components";
import { LOGIN_PICTURE_URL } from "../../../js/constants";
import ColorScheme from "../../styles/colors";
import Defaults from "../../styles/defaults";

const ImageContainer = styled.div`
    // Make image occupy the entire left side of the Container
    height: 100%;
    width: 50%;

    // Set border-radius
    border-radius: ${Defaults.borderRadius};
    overflow: hidden;
    position: relative;

    @media (max-width: 1020px) {
        display: none;
        height: 0;
        width: 0;
    }
`;

const Img = styled.img`
    // Make image occupy the entire container
    height: 100%;
    width: 100%;
    position: absolute;

    // Make sure to center image and keep picture aspect ratio
    object-fit: cover;

    // Desaturate image
    filter: saturate(20%) contrast(80%);
`;

const TextContainer = styled.div`
    // Overlay blue background over image
    background-color: ${ColorScheme.blue6.withAlpha(0.7).color};

    // Have background occupy entire container
    width: 100%;
    height: 100%;
    position: absolute;

    // Center text inside the container
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // Set text properties
    color: ${ColorScheme.gray1.color};
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    text-shadow: ${Defaults.shadow};
`;

const HeroImage = () => (
    <ImageContainer>
        <Img src={LOGIN_PICTURE_URL} />
        <TextContainer>
            <div>Dominate</div>
            <div>Your Fantasy Draft</div>
        </TextContainer>
    </ImageContainer>
);

export default HeroImage;
