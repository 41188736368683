import {
    DEFAULT_BUDGET_BID_RATIO,
    DefFlex,
    DraftConfig,
    DraftStatus,
    Flex,
    Positions,
    TEST_DRIVE_ID,
} from "./constants";

// Draft information utilities
export const featureEnabled = (feature, draftType, draftConfig) =>
    draftConfig[draftType][feature];

export const numRounds = (positions, draftType, idp, draftConfig) => {
    const idpAllowed = draftConfig[draftType].idp;
    let totalRounds = 0;
    for (let i = 0; i < positions.length; i++) {
        if (draftConfig.positions[i].idp && !(idp && idpAllowed)) {
            continue;
        }
        const value = parseInt(positions[i].value);
        if (!isNaN(value)) {
            totalRounds += parseInt(positions[i].value);
        }
    }
    return totalRounds;
};

export const draftOrder = (leagueSize, draftOrder) => {
    const finalDraftOrder = [];
    for (let i = 0; i < leagueSize; i++) {
        let defaultTeam = `Team ${i + 1}`;
        if (!draftOrder || i >= draftOrder.length || !draftOrder[i]) {
            finalDraftOrder.push({ team: defaultTeam });
            continue;
        }
        if (!draftOrder[i].team) {
            finalDraftOrder.push({
                team: defaultTeam,
                email: draftOrder[i].email,
            });
            continue;
        }
        finalDraftOrder.push({
            team: draftOrder[i].team,
            email: draftOrder[i].email,
        });
    }
    return finalDraftOrder;
};

export const currSeason = () => {
    const now = new Date();
    if (now.getMonth() + 1 < 3) {
        return now.getFullYear() - 1;
    }
    return now.getFullYear();
};

export const parsePositions = (values, draftConfig) => {
    const positions = {};
    for (let i = 0; i < values.positions.length; i++) {
        if (!values.idp && draftConfig.positions[i].idp) {
            continue;
        }
        if (parseInt(values.positions[i].value) === 0) {
            continue;
        }
        positions[values.positions[i].position] = parseInt(
            values.positions[i].value
        );
    }
    return positions;
};

export const positionsList = (positions, draftConfig) => {
    const positionsList = [];
    for (let i = 0; i < draftConfig.positions.length; i++) {
        const currPosition = draftConfig.positions[i].abbreviation;
        const val = { position: currPosition, value: "0" };
        if (positions[currPosition] && positions[currPosition] > 0) {
            val.value = `${positions[currPosition]}`;
        }
        positionsList.push(val);
    }
    return positionsList;
};

export const draftlabel = (value) => {
    switch (value) {
        case DraftConfig.AUCTION:
            return "Auction/Salary Cap";
        case DraftConfig.CUSTOM:
            return "Custom Draft Order";
        default:
            return `${value} Draft`;
    }
};

export const statuslabel = (value) => {
    switch (value) {
        case DraftStatus.UPCOMING:
            return "Upcoming";
        case DraftStatus.IN_PROGRESS:
            return "In Progress";
        case DraftStatus.COMPLETED:
            return "Completed";
        default:
            return value;
    }
};

export const customOrderList = (order, numRounds) => {
    const customDraftOrder = [];
    for (let i = 0; i < numRounds; i++) {
        if (!order || i >= order.length || !order[i]) {
            customDraftOrder.push(DraftConfig.SNAKE);
        } else {
            customDraftOrder.push(order[i]);
        }
    }
    return customDraftOrder;
};

export const isCommissioner = (userID, commissioners) => {
    if (!commissioners) return false;
    for (let i = 0; i < commissioners.length; i++) {
        if (commissioners[i] === userID) {
            return true;
        }
    }
    return false;
};

export const isMember = (userID, members) => {
    if (!members) return false;
    for (let i = 0; i < members.length; i++) {
        if (members[i] === userID) {
            return true;
        }
    }
    return false;
};

export const isTestDrive = (draftID) => draftID === TEST_DRIVE_ID;
// Draftboard utilities

// Current suggested costs assume $200 budget, $1 minimum bid.
// For fairness, we need to scale costs according to the draft's specific
// auction budget and minimum bid
export const costMultiplier = (auctionDraftOptions) => {
    const { budget, minimumBid: minBid } = auctionDraftOptions;
    const currBudgetBidRatio = minBid === 0 ? budget : budget / minBid;
    let costMultiplier = currBudgetBidRatio / DEFAULT_BUDGET_BID_RATIO;
    if (minBid > 1) costMultiplier *= minBid;
    return costMultiplier;
};

export const costSortFn = (a, b) => {
    if (!a && !b) return 0;
    if (!a) return 1;
    if (!b) return -1;
    if (a.cost !== b.cost) return b.cost - a.cost;

    // If costs are the same, sort by name
    const [aName, bName] = [`${a.first} ${a.last}`, `${b.first} ${b.last}`];
    if (bName > aName) return -1;
    return 1;
};

export const groupByPosition = (picks, rosterLimit) => {
    const roster = {};
    roster[Positions.BN] = [];
    if (Positions.FLEX in rosterLimit) roster[Positions.FLEX] = [];
    if (Positions.DEF in rosterLimit) roster[Positions.DEF] = [];
    for (let j = 0; j < picks.length; j++) {
        // Check to see that there is an existing pick in this spot
        if (!picks[j]) continue;

        // If roster does not have the current position, create a new list
        const { position } = picks[j];
        if (!(position in roster)) {
            roster[position] = [picks[j]];
            continue;
        }

        // If we have space in our roster for this position, add them to it
        if (roster[position].length < rosterLimit[position]) {
            roster[position].push(picks[j]);
            continue;
        }

        // If we've exceeded the roster limit for this position:
        // 1. Check if the position qualifies for a flex position
        // 2. If it does and the current draft supports flex players,
        //    add the player to the flex position
        if (position in Flex && Positions.FLEX in rosterLimit) {
            const flexLimit = rosterLimit[Positions.FLEX];
            if (roster[Positions.FLEX].length < flexLimit) {
                roster[Positions.FLEX].push(picks[j]);
                continue;
            }
        } else if (position in DefFlex && Positions.DEF in rosterLimit) {
            const flexLimit = rosterLimit[Positions.DEF];
            if (roster[Positions.DEF].length < flexLimit) {
                roster[Positions.DEF].push(picks[j]);
                continue;
            }
        }

        // If we can't fit the pick anywhere, add them to the bench
        roster[Positions.BN].push(picks[j]);
    }
    return roster;
};

// Date/Time utilities
export const convertDateFormat = (date) => {
    if (!date) return "";
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
    return dateTimeFormat.format(new Date(date.replace(/-/g, "/")));
};

export const getDayOfWeek = (date) => {
    const dateObj = new Date(date.replace(/-/g, "/"));
    const week = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    return week[dateObj.getDay()];
};

export const timeDelta = (date) => {
    const dayLength = 24 * 60 * 60 * 1000;
    const today = new Date();
    const dateUntil = new Date(date.replace(/-/g, "/"));
    today.setHours(0, 0, 0);
    dateUntil.setHours(0, 0, 0);
    return Math.round((dateUntil - today) / dayLength);
};

export const dateToString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};

export const getPastDate = (years) => {
    const pastDate = new Date();
    pastDate.setHours(0, 0, 0);
    pastDate.setFullYear(pastDate.getFullYear() - years);
    return pastDate;
};

export const getMinSec = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds - mins * 60;
    return [mins, secs];
};

// timeDiff returns the difference in milliseconds
export const timeDiff = (date1, date2) => {
    const diff = Math.abs(date1 - date2);
    return diff;
};

// Miscellaneous javascript utilities
export const toggleFullscreen = async () => {
    if (!document.fullscreenElement) {
        await document.documentElement.requestFullscreen();
    } else {
        await document.exitFullscreen();
    }
};

export const debounce = (callback, timeout) => {
    let timer = null;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => callback(...args), timeout);
    };
};

export const throttle = (callback, timeout) => {
    let waiting = false;
    return (...args) => {
        if (waiting) return;
        waiting = true;
        setTimeout(() => (waiting = false), timeout);
        return callback(...args);
    };
};

export const memoize = (callback) => {
    const results = {};
    return (arg) => {
        if (!(arg in results)) {
            const result = callback(arg);
            results[arg] = result;
        }
        return results[arg];
    };
};

export const sprintf = (str, ...argv) =>
    !argv.length
        ? str
        : sprintf(
              (str = str.replace(sprintf.token || "$", argv.shift())),
              ...argv
          );
