import React from "react";
import styled from "styled-components";
import ColorScheme from "../../styles/colors";

const StyledH1 = styled.h1`
    font-size: 36px;
    font-family: inherit;
    font-weight: 400;
    color: ${ColorScheme.blue6.color};
`;

const PageHeader = ({ heading }) => <StyledH1>{heading}</StyledH1>;

export default PageHeader;
