import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import {
    CARD_HEIGHT,
    CARD_WIDTH,
    CardLocation,
    FONT_SIZES,
    Positions,
} from "../../../js/constants";
import { StyleStrip } from "../../styles/Reset";
import ColorScheme, { Draftboard } from "../../styles/colors";
import Defaults, { DefaultTransition } from "../../styles/defaults";

const heightMultiplier = (height) => (height - 4) / 6;
const widthMultiplier = (width) => (width * 9) / 32;

const fontSize = (index, name, first, last, details) => {
    const sanitize = (index) =>
        Math.max(Math.min(index, FONT_SIZES.length - 1), 0);

    let fontIndex = index;
    if (first) fontIndex = index + 3;
    else if (last) fontIndex = index + 5;

    // If details, return immediately
    if (details) return FONT_SIZES[sanitize(fontIndex)];

    // Shrink font size, if name is too long
    if (name.length > 7 && name.length < 10) fontIndex -= 1;
    else if (name.length >= 10) fontIndex -= 2;

    return FONT_SIZES[sanitize(fontIndex)];
};

const bordercolor = (position) => {
    switch (position) {
        case Positions.QB:
            return Draftboard.Border.qb.color;
        case Positions.RB:
            return Draftboard.Border.rb.color;
        case Positions.WR:
            return Draftboard.Border.wr.color;
        case Positions.TE:
            return Draftboard.Border.te.color;
        case Positions.K:
            return Draftboard.Border.k.color;
        case Positions.DST:
        case Positions.DB:
        case Positions.DL:
        case Positions.LB:
            return Draftboard.Border.def.color;
        default:
            return Draftboard.Border.empty.color;
    }
};

const bgcolor = (position) => {
    switch (position) {
        case Positions.QB:
            return Draftboard.Background.qb.color;
        case Positions.RB:
            return Draftboard.Background.rb.color;
        case Positions.WR:
            return Draftboard.Background.wr.color;
        case Positions.TE:
            return Draftboard.Background.te.color;
        case Positions.K:
            return Draftboard.Background.k.color;
        case Positions.DST:
        case Positions.DB:
        case Positions.DL:
        case Positions.LB:
            return Draftboard.Background.def.color;
        default:
            return Draftboard.Background.def.color;
    }
};

const CardSize = css`
    height: ${(props) =>
        props.height ? `${props.height}px` : `${CARD_HEIGHT}px`};
    width: ${(props) => (props.width ? `${props.width}px` : `${CARD_WIDTH}px`)};
`;

const CardStyle = css`
    // Set background color, border, and shadow
    background-color: ${(props) => bgcolor(props.position)};
    border: 2px solid ${(props) => bordercolor(props.position)};
    outline: ${(props) =>
        props.selected ? `4px solid ${ColorScheme.yellow4.color}` : "none"};
    ${(props) => props.empty && Draftboard.Background.empty};
    box-shadow: ${(props) => (props.empty ? "none" : Defaults.shadow)};

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
`;

const CardButton = styled.button`
    ${StyleStrip}
    ${CardSize}
    ${CardStyle}

    cursor: pointer;

    // Make card hover if location is in undo or autocomplete
    &:hover {
        box-shadow: ${(props) =>
            props.empty
                ? "none"
                : props.location === CardLocation.DRAFTBOARD
                ? Defaults.shadow
                : Defaults.hoverShadow};
    }
    &:active {
        box-shadow: ${(props) =>
            props.empty
                ? "none"
                : props.location === CardLocation.DRAFTBOARD
                ? Defaults.shadow
                : Defaults.activeShadow};
    }
`;

const Card = styled.div`
    ${CardSize}
    ${CardStyle}

    > * {
        ${DefaultTransition}
        &:last-child {
            ${(props) =>
                props.location === CardLocation.DRAFTBOARD &&
                props.autohide &&
                `transform: translate(0, -10px); opacity: 0;`}
        }
    }

    &:hover > * {
        &:last-child {
            ${(props) =>
                props.location === CardLocation.DRAFTBOARD &&
                props.autohide &&
                `transform: translate(0); opacity: 100;`}
        }
    }
`;

export const HeadlessCard = styled.div`
    ${CardSize}
`;

// top / left are -2px because the border is -2px
const PositionBlock = styled.div`
    background-color: ${(props) =>
        props.empty
            ? Draftboard.Background.emptyposition.color
            : bordercolor(props.position)};
    color: ${ColorScheme.gray1.color};
    border: ${(props) =>
        props.empty ? `1px solid ${ColorScheme.gray4.color}` : "none"};

    font-family: inherit;
    font-size: ${(props) =>
        props.fontIndex
            ? `${fontSize(props.fontIndex, props.name, true, false, false)}px`
            : "16px"};
    font-weight: 600;

    width: ${(props) =>
        props.width ? `${widthMultiplier(props.width)}px` : "36px"};
    height: ${(props) =>
        props.height ? `${heightMultiplier(props.height) * 2}px` : "20px"};
    line-height: ${(props) =>
        props.height ? `${heightMultiplier(props.height) * 2}px` : "20px"};

    display: flex;
    justify-content: center;

    position: absolute;
    top: -2px;
    left: -2px;
`;

const EmptyCardContainer = styled.div`
    ${CardSize}
    ${CardStyle}
`;

export const EmptyCard = ({ location, height, width }) => (
    <EmptyCardContainer location={location} height={height} width={width} empty>
        <PositionBlock empty />
    </EmptyCardContainer>
);

const TextStyle = css`
    color: ${Draftboard.Text.blue.color};
    font-weight: 700;
    text-transform: uppercase;
    text-align: right;
    padding: 0px 2px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

// width = 128px - 4px (border) - 34px (position block)
const FirstName = styled.div`
    ${TextStyle}
    font-size: ${(props) =>
        props.fontIndex
            ? `${fontSize(props.fontIndex, props.name, true, false, false)}px`
            : props.name.length <= 7
            ? "16px"
            : props.name.length < 10
            ? "14px"
            : "12px"};
    line-height: ${(props) =>
        props.height ? `${heightMultiplier(props.height) * 2}px` : "20px"};
    width: ${(props) =>
        props.width
            ? `${props.width - widthMultiplier(props.width) - 2}px`
            : "90px"};
`;

const LastName = styled.div`
    ${TextStyle}
    font-size: ${(props) =>
        props.fontIndex
            ? `${fontSize(props.fontIndex, props.name, false, true, false)}px`
            : props.name.length <= 7
            ? "20px"
            : props.name.length < 10
            ? "18px"
            : "16px"};
    line-height: ${(props) =>
        props.height ? `${heightMultiplier(props.height) * 2}px` : "20px"};
    width: 100%;
`;

const Details = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: baseline;

    font-size: ${(props) =>
        props.fontIndex
            ? `${fontSize(props.fontIndex, "", false, false, true)}px`
            : "10px"};
    line-height: ${(props) =>
        props.height ? `${heightMultiplier(props.height)}px` : "10px"};
`;

const Detail = styled.div`
    ${TextStyle}
`;

export const PlayerButton = forwardRef(
    (
        {
            position,
            first,
            last,
            bye,
            team,
            location,
            auction,
            cost,
            selected,
            onClick,
        },
        ref
    ) => (
        <CardButton
            ref={ref}
            position={position}
            onClick={onClick}
            location={location}
            selected={selected}
        >
            <PositionBlock position={position} name={position}>
                {position}
            </PositionBlock>
            <Row>
                <FirstName name={first}>{first}</FirstName>
            </Row>
            <Row>
                <LastName name={last}>{last}</LastName>
            </Row>
            <Details>
                <Detail>{`BYE ${bye}`}</Detail>
                {auction && location !== CardLocation.AUTOCOMPLETE && (
                    <Detail>{`$${cost}`}</Detail>
                )}
                <Detail>{team}</Detail>
            </Details>
        </CardButton>
    )
);

const PlayerCard = forwardRef(
    (
        {
            position,
            first,
            last,
            bye,
            team,
            location,
            auction,
            cost,
            height,
            width,
            fontIndex,
            showDetails,
        },
        ref
    ) => (
        <Card
            position={position}
            location={location}
            height={height}
            width={width}
            autohide={showDetails === "Off"}
            ref={ref}
        >
            <PositionBlock
                name={position}
                position={position}
                height={height}
                width={width}
                fontIndex={fontIndex}
            >
                {position}
            </PositionBlock>
            <Row>
                <FirstName
                    name={first}
                    height={height}
                    width={width}
                    fontIndex={fontIndex}
                >
                    {first}
                </FirstName>
            </Row>
            <Row>
                <LastName
                    name={last}
                    height={height}
                    width={width}
                    fontIndex={fontIndex}
                >
                    {last}
                </LastName>
            </Row>
            <Details height={height} width={width} fontIndex={fontIndex}>
                <Detail>{`BYE ${bye}`}</Detail>
                {auction && location !== CardLocation.AUTOCOMPLETE && (
                    <Detail>{`$${cost}`}</Detail>
                )}
                <Detail>{team}</Detail>
            </Details>
        </Card>
    )
);

export default PlayerCard;
