import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import ColorScheme from "../styles/colors";
import Defaults from "../styles/defaults";

export const Shade = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
    background-color: ${ColorScheme.gray3.withAlpha(0.8).color};
`;

export const ModalContainer = styled.div`
    // Set size of modal appropriately, and allow it to scroll even if
    // modal height longer than body height
    max-width: ${(props) => props.width || "768px"};
    width: 100%;
    max-height: 100%;
    overflow-y: auto;

    // Center modal in middle of screen
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    // Add padding from the edge
    padding: 24px;

    // Decorate modal
    background-color: ${(props) =>
        props.bgcolor ? props.bgcolor : ColorScheme.gray2.color};
    border-radius: ${Defaults.borderRadius};
    box-shadow: ${Defaults.shadow};

    // Align Modal items properly
    display: flex;
    flex-direction: column;
`;

class Modal extends Component {
    componentDidMount = () => {
        if (this.props.open) document.body.style.overflow = "hidden";
    };

    componentWillUnmount = () => (document.body.style.overflow = "unset");

    render = () => {
        const { children, width, close, bgcolor } = this.props;
        return ReactDOM.createPortal(
            <Shade onClick={close}>
                <ModalContainer
                    width={width}
                    onClick={(event) => event.stopPropagation()}
                    bgcolor={bgcolor}
                >
                    {children}
                </ModalContainer>
            </Shade>,
            document.querySelector("#portal")
        );
    };
}

export default Modal;
