import React from "react";
import styled from "styled-components";
import Button, { buttonStyle } from "../../common/Button";
import ColorScheme from "../../styles/colors";

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const StyledH2 = styled.h2`
    font-size: 18px;
    font-family: inherit;
    font-weight: 400;
    color: ${ColorScheme.blue4.color};
`;

const HeaderButtonContainer = styled.div`
    // Evenly space out group items
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    > * {
        &:last-child {
            margin-left: 8px;
        }
        &:only-child {
            margin-left: 0px;
        }
    }
`;

export const Header = ({ heading, secondaryBtn, primaryBtn }) => (
    <HeaderContainer>
        <StyledH2>{heading}</StyledH2>
        <HeaderButtonContainer>
            {secondaryBtn && (
                <Button
                    size={buttonStyle.Size.SMALL}
                    color={buttonStyle.Color.BLUE}
                    priority={buttonStyle.Priority.SECONDARY}
                    disabled={secondaryBtn.disabled}
                    onClick={secondaryBtn.onClick}
                    type="button"
                    text={secondaryBtn.text}
                />
            )}
            {primaryBtn && (
                <Button
                    size={buttonStyle.Size.SMALL}
                    color={buttonStyle.Color.BLUE}
                    priority={buttonStyle.Priority.PRIMARY}
                    disabled={primaryBtn.disabled}
                    loading={primaryBtn.loading}
                    type="submit"
                    text={primaryBtn.text}
                />
            )}
        </HeaderButtonContainer>
    </HeaderContainer>
);

export const DetailsContainer = styled.div`
    // Set maximum width and center details container
    max-width: 640px;
    width: 100%;

    // Horizontally center container and set spacing properly
    margin: 0 auto 24px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const MessageContainer = styled.div`
    // Set maximum width and center message container
    max-width: 640px;
    width: 100%;

    // Horizontally center container and set spacing properly
    margin: 24px auto 0;

    // Style text
    font-size: 20px;
    font-family: inherit;
    color: ${ColorScheme.blue5.color};
`;

export const ButtonContainer = styled.div`
    // Horizontally center container and set spacing properly
    margin: 48px auto 0;

    // Center buttons
    display: flex;
    justify-content: center;
    align-items: baseline;

    > * {
        &:last-child {
            margin-left: 8px;
        }
        &:only-child {
            margin-left: 0px;
        }
    }
`;
