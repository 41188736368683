import React from "react";
import { useFormState } from "react-final-form";
import styled, { css } from "styled-components";
import { DraftConfig, EmailNotification } from "../../../js/constants";
import { convertDateFormat, draftlabel, numRounds } from "../../../js/util";
import { buttonStyle } from "../../common/Button";
import { VerticalContainer } from "../../common/Container";
import LeagueType from "../../common/LeagueType";
import Line from "../../common/Line";
import ColorScheme from "../../styles/colors";
import {
    LabeledFieldContainer,
    OffsetFieldContainer,
    OffsetLabelContainer,
} from "../common/Label";
import { InfoContainer } from "../common/PageLayout";
import { AuctionSummary } from "../displaydraft/AuctionOptions";
import { CustomDraftSummary } from "../displaydraft/CustomDraftOrder";
import { DraftOrderSummary } from "../displaydraft/DraftOrder";
import CreateDraftForm from "./CreateDraftForm";

const LabelStyle = css`
    font-family: inherit;
    color: ${ColorScheme.blue5.color};
`;

const FieldStyle = css`
    font-family: inherit;
    color: ${ColorScheme.blue3.color};
`;

const HeaderLabel = styled.h2`
    ${LabelStyle}
    font-size: 30px;
    font-weight: 400;
    margin-right: 30px;
`;

const Label = styled.div`
    ${LabelStyle}
    font-size: 20px;
`;

const DetailLabel = styled.div`
    ${LabelStyle}
    font-size: 16px;
    margin-right: 12px;
`;

const Field = styled.div`
    ${FieldStyle}
    font-size: 20px;
`;

const DetailField = styled.div`
    ${FieldStyle}
    font-size: 16px;
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
`;

const SummaryContainer = styled.div`
    > * {
        margin-top: 8px;
        &:first-child {
            margin-top: 0px;
        }
        &:last-child {
            margin-top: 24px;
        }
    }
`;

const DetailContainer = styled.div`
    display: flex;
    align-items: baseline;
`;

const PriceRow = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const PriceContainer = styled.div`
    display: flex;
`;

const TotalLabel = styled.div`
    ${LabelStyle}
    font-size: 30px;
    margin-right: 64px;
`;

const PriceField = styled.div`
    ${FieldStyle}
    font-size: 30px;
`;

// rosterSettings enumerates the user's roster settings as a string
const rosterSettings = (positions, idp, draftConfig) => {
    let roster = "";
    for (let i = 0; i < positions.length; i++) {
        // Skip IDP positions if not drafting IDP
        if (!idp && draftConfig.positions[i].idp) {
            continue;
        }
        // Skip positions which aren't being drafted
        if (positions[i].value === "0") {
            continue;
        }
        // Append current position to roster string
        if (i > 0) roster += ", ";
        roster += `${positions[i].value} ${positions[i].position}`;
    }
    return roster;
};

const Header = ({ draftType }) => (
    <HeaderContainer>
        <HeaderLabel>Draft Configuration</HeaderLabel>
        <LeagueType draftType={draftType} size={buttonStyle.Size.LARGE} />
    </HeaderContainer>
);

const StandardRow = ({ labelText, fieldText }) => (
    <LabeledFieldContainer>
        <OffsetLabelContainer>
            <Label>{labelText}</Label>
        </OffsetLabelContainer>
        <OffsetFieldContainer>
            <Field>{fieldText}</Field>
        </OffsetFieldContainer>
    </LabeledFieldContainer>
);

const DetailedRow = ({ labelText, fieldText, children }) => (
    <LabeledFieldContainer>
        <OffsetLabelContainer>
            <Label>{labelText}</Label>
        </OffsetLabelContainer>
        <OffsetFieldContainer>
            <Field>{fieldText}</Field>
            <VerticalContainer>{children}</VerticalContainer>
        </OffsetFieldContainer>
    </LabeledFieldContainer>
);

const Detail = ({ labelText, fieldText }) => (
    <DetailContainer>
        <DetailLabel>{`${labelText}:`}</DetailLabel>
        <DetailField>{fieldText}</DetailField>
    </DetailContainer>
);

const DraftType = ({ values, rounds }) => {
    switch (values.draftConfig) {
        case DraftConfig.AUCTION:
            const options = values.auctionDraftOptions;
            return (
                <DetailedRow
                    labelText="Draft Type"
                    fieldText={draftlabel(values.draftConfig)}
                >
                    <AuctionSummary options={options} />
                </DetailedRow>
            );
        case DraftConfig.CUSTOM:
            const order = values.customDraftOrder;
            return (
                <DetailedRow
                    labelText="Draft Type"
                    fieldText={draftlabel(values.draftConfig)}
                >
                    <CustomDraftSummary order={order} numRounds={rounds} />
                </DetailedRow>
            );
        default:
            return (
                <StandardRow
                    labelText="Draft Type"
                    fieldText={values.draftConfig}
                />
            );
    }
};

const Timer = ({ timer }) =>
    timer.enabled ? (
        <DetailedRow labelText="Timer" fieldText="Yes">
            <Detail
                labelText="Duration"
                fieldText={`${timer.duration} seconds`}
            />
        </DetailedRow>
    ) : (
        <StandardRow labelText="Timer" fieldText="No" />
    );

const Price = ({ draftConfig, draftType }) => {
    const price = draftConfig[draftType].price / 100;
    return (
        <PriceRow>
            <PriceContainer>
                <TotalLabel>Total</TotalLabel>
                <PriceField>{price !== 0 ? `$${price}` : "$0.00"}</PriceField>
            </PriceContainer>
        </PriceRow>
    );
};

const Summary = ({ draftConfig }) => {
    // Get current form values
    const { values } = useFormState();
    const rounds = numRounds(
        values.positions,
        values.draftType,
        values.idp,
        draftConfig
    );

    return (
        <CreateDraftForm.Page>
            <InfoContainer>
                <Header draftType={values.draftType} />
                <Line />
                <SummaryContainer>
                    <StandardRow
                        labelText="League Name"
                        fieldText={values.name}
                    />
                    {values.draftDate && (
                        <StandardRow
                            labelText="Draft Date"
                            fieldText={convertDateFormat(values.draftDate)}
                        />
                    )}
                    <StandardRow labelText="Rounds" fieldText={rounds} />
                    <StandardRow
                        labelText="Positions"
                        fieldText={rosterSettings(
                            values.positions,
                            values.idp,
                            draftConfig
                        )}
                    />
                    <DraftType values={values} rounds={rounds} />
                    <StandardRow
                        labelText="Keeper Draft"
                        fieldText={values.keeper ? "Yes" : "No"}
                    />
                    <StandardRow
                        labelText="Allow Trading Picks"
                        fieldText={values.tradePicks ? "Yes" : "No"}
                    />
                    <StandardRow
                        labelText="Remote Draft"
                        fieldText={values.remoteDraft ? "Yes" : "No"}
                    />
                    <Timer timer={values.timer} />
                    <StandardRow
                        labelText="Email Notifications"
                        fieldText={
                            values.emailSetting === EmailNotification.ALL
                                ? "Send to all teams"
                                : values.emailSetting === EmailNotification.SELF
                                ? "Send only to me"
                                : "None"
                        }
                    />
                    <DraftOrderSummary
                        leagueSize={values.leagueSize}
                        draftOrder={values.draftOrder}
                    />
                </SummaryContainer>
                <Line />
                <Price draftConfig={draftConfig} draftType={values.draftType} />
            </InfoContainer>
        </CreateDraftForm.Page>
    );
};

export default Summary;
