import React from "react";
import { Field, Form, useFormState } from "react-final-form";
import { connect } from "react-redux";
import { editDraft, editOpen } from "../../../actions/draftdetails";
import {
    CREATE_DRAFT_DEFAULT,
    DraftConfig,
    DraftStatus,
} from "../../../js/constants";
import { customOrderList, draftlabel, positionsList } from "../../../js/util";
import { VerticalContainer } from "../../common/Container";
import { Select } from "../../common/Input";
import { DetailsContainer, Header } from "../common/DetailsContainer";
import {
    InputLabel,
    LabeledSectionContainer,
    LabeledValueDivision,
    OffsetFieldContainer,
    OffsetLabelContainer,
} from "../common/Label";
import AuctionOptions, { AuctionSummary } from "../displaydraft/AuctionOptions";
import CustomDraftOrder, {
    CustomDraftSummary,
} from "../displaydraft/CustomDraftOrder";

const OPTION_WIDTH = "256px";

// NOTE: Body keys must be kept in sync with EditDraft API request
const editDraftDetails = (draft, editDraft, displayInfo) => async (values) => {
    const body = {};
    for (let [key, value] of Object.entries(values)) {
        if (!value) continue;
        if (key === "customDraftOrder") {
            // Ignore customDraftOrder field, if this is not a Custom Draft
            if (
                values?.draftConfig !== DraftConfig.CUSTOM &&
                draft.draftConfig !== DraftConfig.CUSTOM
            ) {
                continue;
            }
            body[key] = customOrderList(value, draft.rounds);
            continue;
        } else if (key === "auctionDraftOptions") {
            // Ignore auctionDraftOptions field, if this is not an Auction Draft
            if (
                values?.draftConfig !== DraftConfig.AUCTION &&
                draft.draftConfig !== DraftConfig.AUCTION
            ) {
                continue;
            }
        }
        body[key] = value;
    }
    // FIXME: Reflect error if something fails
    await editDraft(body, draft.draftID);
    displayInfo();
};

const DraftTypeDetailsForm = ({ draft, draftConfig }) => {
    const { values } = useFormState();
    const draftType = values.draftConfig;
    if (draftType !== DraftConfig.AUCTION && draftType !== DraftConfig.CUSTOM) {
        return null;
    }
    if (draftType === DraftConfig.AUCTION) return <AuctionOptions small />;
    else if (draftType === DraftConfig.CUSTOM) {
        return (
            <CustomDraftOrder
                positions={positionsList(draft.positions, draftConfig)}
                draftConfig={draftConfig}
                idp={draft.idp}
                draftType={draft.draftType}
            />
        );
    }
};

const selectOptions = (draftType, draftConfig) => {
    const options = [];
    const validDraftTypes = draftConfig[draftType].validDraftTypes;
    for (let i = 0; i < validDraftTypes.length; i++) {
        options.push(
            <DraftOption key={validDraftTypes[i]} value={validDraftTypes[i]} />
        );
    }
    return options;
};

const DraftOption = ({ value }) => (
    <option value={value}>{draftlabel(value)}</option>
);

const DraftTypeSelect = ({ draft, draftConfig }) => (
    <LabeledSectionContainer>
        <OffsetLabelContainer>
            <InputLabel htmlFor="draftConfig">Type</InputLabel>
        </OffsetLabelContainer>
        <OffsetFieldContainer>
            <Field name="draftConfig">
                {({ input }) => (
                    <Select id="draftConfig" width={OPTION_WIDTH} {...input}>
                        {selectOptions(draft.draftType, draftConfig)}
                    </Select>
                )}
            </Field>
        </OffsetFieldContainer>
    </LabeledSectionContainer>
);

const DraftTypeForm = ({
    draft,
    draftConfig,
    editDraft,
    displayInfo,
    loading,
}) => (
    <Form
        onSubmit={editDraftDetails(draft, editDraft, displayInfo)}
        initialValues={{
            draftConfig: draft.draftConfig,
            auctionDraftOptions:
                draft.auctionDraftOptions ||
                CREATE_DRAFT_DEFAULT.auctionDraftOptions,
            customDraftOrder:
                draft.customDraftOrder || CREATE_DRAFT_DEFAULT.customDraftOrder,
        }}
        render={({ handleSubmit }) => (
            <VerticalContainer>
                <form onSubmit={handleSubmit}>
                    <Header
                        heading="Draft Type"
                        primaryBtn={{
                            text: "Save",
                            disabled: loading,
                            loading,
                        }}
                        secondaryBtn={{
                            text: "Cancel",
                            disabled: loading,
                            onClick: displayInfo,
                        }}
                    />
                    <DetailsContainer>
                        <DraftTypeSelect
                            draft={draft}
                            draftConfig={draftConfig}
                        />
                        <DraftTypeDetailsForm
                            draft={draft}
                            draftConfig={draftConfig}
                            displayInfo={displayInfo}
                            loading={loading}
                        />
                    </DetailsContainer>
                </form>
            </VerticalContainer>
        )}
    />
);

const DraftTypeDetails = ({ draft }) => {
    const draftType = draft.draftConfig;
    if (draftType !== DraftConfig.AUCTION && draftType !== DraftConfig.CUSTOM) {
        return null;
    }
    if (draftType === DraftConfig.AUCTION) {
        return (
            <VerticalContainer>
                <AuctionSummary options={draft.auctionDraftOptions} />
            </VerticalContainer>
        );
    } else if (draftType === DraftConfig.CUSTOM) {
        return (
            <VerticalContainer>
                <CustomDraftSummary
                    order={draft.customDraftOrder}
                    numRounds={draft.rounds}
                />
            </VerticalContainer>
        );
    }
};

const DraftTypeInfo = ({ draft, displayForm, loading, isCommissioner }) => (
    <VerticalContainer>
        <Header
            heading="Draft Type"
            secondaryBtn={
                isCommissioner &&
                draft.status === DraftStatus.UPCOMING && {
                    text: "Edit",
                    onClick: displayForm,
                    disabled: loading,
                }
            }
        />
        <LabeledValueDivision
            label="Draft Type"
            value={draftlabel(draft.draftConfig)}
        >
            <DraftTypeDetails draft={draft} />
        </LabeledValueDivision>
    </VerticalContainer>
);

const DraftType = ({
    draft,
    draftConfig,
    editOpen,
    editDraft,
    form,
    loading,
    isCommissioner,
}) => {
    const DRAFT_TYPE_FORM = "Draft Type";
    const showForm = form === DRAFT_TYPE_FORM;
    const displayForm = () => editOpen(DRAFT_TYPE_FORM);
    const displayInfo = () => editOpen("");

    return showForm ? (
        <DraftTypeForm
            draft={draft}
            draftConfig={draftConfig}
            editDraft={editDraft}
            displayInfo={displayInfo}
            loading={loading}
        />
    ) : (
        <DraftTypeInfo
            draft={draft}
            displayForm={displayForm}
            draftConfig={draftConfig}
            isCommissioner={isCommissioner}
        />
    );
};

const mapStateToProps = (state) => {
    const newState = {
        form: state.draftdetails.form,
        loading: state.draftdetails.loading,
        draftConfig: state.config.draftConfig,
    };
    if (state?.draftdetails?.draft) {
        newState.draft = state.draftdetails.draft;
        if (state?.auth?.user && newState?.draft?.commissioners) {
            const userID = state.auth.user.username;
            const commissioners = newState.draft.commissioners;
            for (let i = 0; i < commissioners.length; i++) {
                if (commissioners[i].userID === userID) {
                    newState.isCommissioner = true;
                    break;
                }
            }
        }
    }
    return newState;
};

export default connect(mapStateToProps, { editDraft, editOpen })(DraftType);
