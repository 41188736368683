import React, { useState } from "react";
import styled from "styled-components";
import { AuctionDisplay } from "../../../js/constants";
import { Radio } from "../../common/Input";
import ColorScheme from "../../styles/colors";
import { InfoContainer, InfoContainerH2 } from "./Container";

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 24px;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
`;

const RadioLabel = styled.label`
    color: ${ColorScheme.blue4.color};
    font-size: 16px;
    font-weight: 400;
    margin-left: 12px;
    cursor: pointer;
`;

const AuctionBudgetSetting = ({ auctionDisplay, setAuctionDisplay }) => {
    const [checked, setChecked] = useState(auctionDisplay);
    const onchange = (val) => () => {
        setChecked(val);
        setAuctionDisplay(val);
    };
    return (
        <InfoContainer>
            <InfoContainerH2>Auction Display</InfoContainerH2>
            <OptionsContainer>
                <InputContainer>
                    <Radio
                        name="auction-budget"
                        id={AuctionDisplay.BUDGET}
                        type="radio"
                        onChange={onchange(AuctionDisplay.BUDGET)}
                        checked={checked === AuctionDisplay.BUDGET}
                        small
                    />
                    <RadioLabel htmlFor={AuctionDisplay.BUDGET}>
                        Auction Budget
                    </RadioLabel>
                </InputContainer>
                <InputContainer>
                    <Radio
                        name="auction-max-bid"
                        id={AuctionDisplay.MAX_BID}
                        type="radio"
                        onChange={onchange(AuctionDisplay.MAX_BID)}
                        checked={checked === AuctionDisplay.MAX_BID}
                        small
                    />
                    <RadioLabel htmlFor={AuctionDisplay.MAX_BID}>
                        Maximum Bid
                    </RadioLabel>
                </InputContainer>
            </OptionsContainer>
        </InfoContainer>
    );
};

export default AuctionBudgetSetting;
