import { createGlobalStyle, css } from 'styled-components';
import ColorScheme from './colors';

export const StyleReset = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    html {
        box-sizing: border-box;
    }

    body {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.7;
        background-color: ${props => props.bgcolor ? props.bgcolor : ColorScheme.gray3.color};
    }
`;

export const StyleStrip = css`
	background: none;
	color: inherit;
	font: inherit;
	border: none;
	outline: inherit;
    text-decoration: none;
`;
