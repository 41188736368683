import {
    FORGOT_PASSWORD,
    LOGIN,
    REGISTER,
    RESEND_CODE,
    RESET_PASSWORD,
    RESET_RESEND_STATUS,
    SIGN_OUT,
    VERIFY_EMAIL
} from '../actions/types';

// loginReducer keeps track of login and logout requests
export const loginReducer = (state = {}, action) => {
    switch (action.type) {
        case LOGIN:
            if (!action.error && !action.success) {
                return { loading: true };
            }
            return {};
        case SIGN_OUT:
            return {};
        default:
            return state;
    }
};

// registerReducer keeps track of requests relating to registering a user
export const registerReducer = (state = {}, action) => {
    switch (action.type) {
        case REGISTER: {
            if (!action.error && !action.success) {
                return { loading: true };
            }
            return { email: action.email };
        }
        case RESEND_CODE:
            if (!action.error && !action.success) {
                return {
                    ...state,
                    resendCode: { loading: true }
                };
            } else if (action.error) {
                return {
                    ...state,
                    resendCode: { success: false, error: action.error }
                };
            }
            return { ...state, resendCode: { success: true } };
        case RESET_RESEND_STATUS:
            // Removes resendCode field
            const {resendCode, ...newState} = state;
            return newState;
        case VERIFY_EMAIL:
            if (!action.error && !action.success) {
                return { ...state, verify: { loading: true } };
            } else if (action.error) {
                return { ...state, verify: { loading: false } };
            }
            return {};
        default:
            return state;
    }
};

// forgetReducer keeps track of requests relating to a user forgetting their password
export const forgetReducer = (state = {}, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD: {
            if (!action.error && !action.success) {
                return { forgot: { loading: true } };
            } else if (action.error) {
                return { forgot: { error: action.error.message } };
            }
            return { email: action.email };
        }
        case RESET_PASSWORD:
            if (!action.error && !action.success) {
                return { reset: { loading: true }, email: state.email };
            } else if (action.error) {
                return { reset: { loading: false }, email: state.email };
            }
            return {};
        default:
            return state;
    }
}
