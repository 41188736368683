import {
    DELETE_DRAFT,
    EDIT_DRAFT,
    EDIT_OPEN,
    GET_DRAFT,
    RESET_DRAFT,
    SIGN_OUT,
} from "../actions/types";

const DEFAULT_STATE = { form: "", loading: false, deleting: false };
export const draftDetailsReducer = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case DELETE_DRAFT:
            if (!action.error && !action.success) {
                return { ...state, deleting: true };
            } else if (action.success) {
                const newState = { ...state, form: "", deleting: false };
                delete newState.draft;
                return newState;
            }
            return { ...state, deleting: false, error: action.error };
        case EDIT_DRAFT:
            if (!action.error && !action.draft) {
                return { ...state, loading: true };
            } else if (action.draft) {
                return { ...state, loading: false, draft: action.draft };
            }
            return { ...state, loading: false, error: action.error };
        case EDIT_OPEN:
            return { ...state, form: action.form };
        case GET_DRAFT:
            return { ...state, draft: action.draft };
        case RESET_DRAFT:
            const newState = { ...state, form: "" };
            delete newState.draft;
            return newState;
        case SIGN_OUT:
            return DEFAULT_STATE;
        default:
            return state;
    }
};
