import React from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import {
    auctionChange,
    filterChange,
    searchChange,
} from "../../../actions/draftboard";
import { DraftConfig, Positions } from "../../../js/constants";
import ColorScheme from "../../styles/colors";
import Defaults from "../../styles/defaults";

const SearchBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    > * {
        margin-left: 12px;
        &:first-child {
            margin-left: 0;
        }
    }
`;

const InputLabel = styled.label`
    cursor: pointer;
    font-family: inherit;
    font-weight: 700;
    font-size: 12px;
    color: ${ColorScheme.gray1.color};
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const InputStyle = css`
    // Style input value
    font-family: inherit;
    color: ${ColorScheme.gray7.color};
    font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};

    // Give space between input value and bounding box
    padding: 8px 12px;

    // Style bounding box
    width: ${(props) => (props.width ? props.width : "100%")};
    outline: none; // outline property doesn't hug border-radius
    border: ${ColorScheme.gray5.color} solid 1px;
    border-radius: ${Defaults.borderRadius};
    background-color: ${ColorScheme.gray1.color};
    box-shadow: ${Defaults.insetShadow};

    // Style placeholder text
    ::placeholder {
        color: ${ColorScheme.gray5.color};
    }
`;

const Input = styled.input`
    ${InputStyle}
`;

const Select = styled.select`
    ${InputStyle}
`;

const AuctionInput = ({ val, onChange, min, max }) => {
    // Create a regular expression to check whether input is numeric
    const isNumeric = (value) => /^[0-9]*$/.test(value);

    // Create onChange handler to sanitize inputs before sending action
    const onAuctionChange = (event) => {
        const value = event.target.value;

        // Allow blank values
        if (value === "") return onChange(value);

        // Prevent user from typing non-integers
        if (!isNumeric(value)) return;

        // Prevent user from typing in out-of-range numbers
        if (parseInt(value) < min || parseInt(value) > max) return;

        // Prevent user from starting number with "0"
        if (value.length > 1 && value.charAt(0) === "0") {
            const newValue = parseInt(value).toString();
            onChange(newValue);
        }
        // Prevent user from typing in too many characters
        if (value.length > max.toString().length) return;

        onChange(value);
    };

    // If user leaves input blank, reset val back to the minimum
    const onBlur = () => {
        if (val === "") onChange(min.toString());
    };

    return (
        <InputContainer>
            <InputLabel htmlFor="amount">Amount</InputLabel>
            <Input
                type="number"
                id="amount"
                min={min}
                max={max}
                step="1"
                onChange={onAuctionChange}
                onBlur={onBlur}
                value={val}
            />
        </InputContainer>
    );
};

const FilterInput = ({ val, onChange, idp, draftConfig }) => {
    const options = [];
    options.push(
        <option key={Positions.ALL} value={Positions.ALL}>
            {Positions.ALL}
        </option>
    );
    for (let i = 0; i < draftConfig.positions.length; i++) {
        if (!draftConfig.positions[i].idp || idp) {
            const position = draftConfig.positions[i].abbreviation;
            if (position === Positions.BN) continue;
            // TODO: Enable searching for defensive/offensive flex players
            if (position === Positions.DEF || position === Positions.FLEX)
                continue;
            options.push(
                <option key={position} value={position}>
                    {position}
                </option>
            );
        }
    }
    return (
        <InputContainer>
            <InputLabel htmlFor="position">Position</InputLabel>
            <Select id="position" onChange={onChange} defaultValue={val}>
                {options}
            </Select>
        </InputContainer>
    );
};

const SearchInput = ({ val, onChange }) => (
    <InputContainer>
        <InputLabel htmlFor="player">Player</InputLabel>
        <Input
            type="text"
            id="player"
            placeholder="Aaron Rodgers"
            onChange={onChange}
            value={val}
        />
    </InputContainer>
);

const SearchBar = ({
    search,
    searchChange,
    filter,
    filterChange,
    auction,
    auctionChange,
    draft,
    draftConfig,
    isCommissioner,
}) => {
    const searchOnChange = (event) => searchChange(event.target.value);
    const filterOnChange = (event) => filterChange(event.target.value);
    return (
        <SearchBarContainer>
            <SearchInput val={search} onChange={searchOnChange} />
            <FilterInput
                val={filter}
                onChange={filterOnChange}
                idp={draft.idp}
                draftConfig={draftConfig}
            />
            {draft.draftConfig === DraftConfig.AUCTION && isCommissioner && (
                <AuctionInput
                    val={auction}
                    onChange={auctionChange}
                    min={draft.auctionDraftOptions.minimumBid.toString()}
                    max={draft.auctionDraftOptions.budget.toString()}
                />
            )}
        </SearchBarContainer>
    );
};

const mapStateToProps = (state) => {
    const newState = { draftConfig: state.config.draftConfig };
    if (state.draftboard) {
        const {
            draft,
            autocomplete: { search, auction, filter, players },
        } = state.draftboard;
        newState.draft = draft;
        newState.search = search;
        newState.auction = auction;
        newState.filter = filter;
        newState.players = players;
    }
    return newState;
};

export default connect(mapStateToProps, {
    auctionChange,
    filterChange,
    searchChange,
})(SearchBar);
