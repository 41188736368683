import React, { Component } from "react";
import styled from "styled-components";
import { DraftClass } from "../../../js/constants";
import { buttonStyle } from "../../common/Button";
import LeagueType, { LeagueTypeButton } from "../../common/LeagueType";
import ColorScheme from "../../styles/colors";
import { DefaultTransition } from "../../styles/defaults";
import PageHeader from "./PageHeader";
import UpsellDropdown from "./UpsellDropdown";

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LeagueTypeContainer = styled.div`
    position: relative;

    ${(props) =>
        props.showUpgrade &&
        `> * {
            ${DefaultTransition}
            &:first-child {
                opacity: 0;
            }
        }

        &:hover > * {
            &:first-child {
                transform: translate(0, -20px);
                opacity: 100;
            }
        }`}
`;

const HoverContainer = styled.div`
    position: absolute;
    width: max-content;

    color: ${ColorScheme.green4.color};
    font-size: 12px;
`;

class UpsellHeader extends Component {
    constructor(props) {
        super(props);
        this.state = { showDropdown: false };
    }

    show = () => this.setState({ showDropdown: true });
    hide = () => this.setState({ showDropdown: false });

    toggleDropdown = (event) => {
        // Prevent dropdown from interpreting click as a click outside
        event.stopPropagation();

        // If dropdown is not displayed, display dropdown
        if (!this.state.showDropdown) return this.show();

        // Else, hide dropdown
        this.hide();
    };

    render = () => {
        const showUpgrade =
            this.props.draftType !== DraftClass.PREMIUM &&
            this.props.showButton;
        return (
            <HeaderContainer>
                <PageHeader heading={this.props.heading} />
                {this.props.show && (
                    <LeagueTypeContainer showUpgrade={showUpgrade}>
                        {showUpgrade ? (
                            <>
                                <HoverContainer>
                                    Click to Upgrade
                                </HoverContainer>
                                <LeagueTypeButton
                                    draftType={this.props.draftType}
                                    size={buttonStyle.Size.LARGE}
                                    onClick={this.toggleDropdown}
                                />
                            </>
                        ) : (
                            <LeagueType
                                draftType={this.props.draftType}
                                size={buttonStyle.Size.LARGE}
                            />
                        )}
                        <UpsellDropdown
                            hide={this.hide}
                            displaySelf={this.state.showDropdown}
                            draftType={this.props.draftType}
                            updateDraftType={this.props.updateDraftType}
                        />
                    </LeagueTypeContainer>
                )}
            </HeaderContainer>
        );
    };
}

export default UpsellHeader;
