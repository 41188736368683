import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { DraftStatus, UNSCHEDULED } from "../../../js/constants";
import {
    convertDateFormat,
    dateToString,
    getDayOfWeek,
    statuslabel,
    timeDelta,
} from "../../../js/util";
import ColorScheme from "../../styles/colors";
import Defaults from "../../styles/defaults";

const bgcolor = (status) => {
    switch (status) {
        case DraftStatus.IN_PROGRESS:
            return ColorScheme.yellow1.color;
        case DraftStatus.UPCOMING:
            return ColorScheme.blue1.color;
        case DraftStatus.COMPLETED:
            return ColorScheme.gray1.color;
        default:
            return ColorScheme.gray1.color;
    }
};

const bordercolor = (status) => {
    switch (status) {
        case DraftStatus.IN_PROGRESS:
            return ColorScheme.yellow4.color;
        case DraftStatus.UPCOMING:
            return ColorScheme.blue4.color;
        case DraftStatus.COMPLETED:
            return ColorScheme.gray5.color;
        default:
            return ColorScheme.gray5.color;
    }
};

const color = (status) => {
    switch (status) {
        case DraftStatus.IN_PROGRESS:
            return ColorScheme.yellow5.color;
        case DraftStatus.UPCOMING:
            return ColorScheme.blue5.color;
        case DraftStatus.COMPLETED:
            return ColorScheme.gray6.color;
        default:
            return ColorScheme.gray6.color;
    }
};

const titlecolor = (status) => {
    switch (status) {
        case DraftStatus.IN_PROGRESS:
            return ColorScheme.yellow6.color;
        case DraftStatus.UPCOMING:
            return ColorScheme.blue6.color;
        case DraftStatus.COMPLETED:
            return ColorScheme.gray6.color;
        default:
            return ColorScheme.gray6.color;
    }
};

const timedeltacolor = (status) => {
    switch (status) {
        case DraftStatus.IN_PROGRESS:
            return ColorScheme.yellow4.color;
        case DraftStatus.UPCOMING:
            return ColorScheme.blue3.color;
        case DraftStatus.COMPLETED:
            return ColorScheme.gray5.color;
        default:
            return ColorScheme.gray5.color;
    }
};

const DraftsContainer = styled.div`
    width: 100%;
    padding: 64px 32px;
    background-color: transparent;

    display: flex;
    flex-direction: column;
`;

const DraftsWithStatusContainer = styled.div`
    max-width: 640px;
    width: 100%;
    margin: 0px auto 32px;

    display: flex;
    flex-direction: column;
`;

const DraftCardsContainer = styled.div`
    > * {
        margin-top: 12px;
        &:first-child {
            margin-top: 0px;
        }
    }
`;

const DraftCardContainer = styled(Link)`
    width: 100%;
    border-radius: ${Defaults.borderRadius};
    background-color: ${(props) => bgcolor(props.status)};
    border: 1px solid ${(props) => bordercolor(props.status)};
    padding: 16px;

    color: ${(props) => color(props.status)};
    box-shadow: ${Defaults.shadow};
    text-decoration: none;

    display: flex;
    flex-direction: column;

    cursor: pointer;
`;

const Title = styled.h3`
    color: ${(props) => titlecolor(props.status)};
    font-weight: 600;
    font-size: 24px;
`;

const DraftStatusHeading = styled.h2`
    color: ${ColorScheme.blue6.color};
    font-weight: 400;
    font-size: 18px;
`;

const Label = styled.div`
    font-size: 16px;
    margin-left: 8px;
`;

const Value = styled.div`
    font-size: 24px;
`;

const DraftDetailsRowContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin-left: 24px;
    justify-content: ${(props) =>
        props.date ? "space-between" : "flex-start"};
`;

const DraftDetail = styled.div`
    display: flex;
    align-items: baseline;
`;

const TimeDeltaContainer = styled.div`
    color: ${(props) => timedeltacolor(props.status)};
    font-size: 18px;
`;

const TimeDelta = ({ draft }) => {
    if (draft.status === DraftStatus.UPCOMING) {
        const daysAway = timeDelta(draft.date);
        let deltaString = `${daysAway} Days Away`;

        if (daysAway < 0) return null;
        else if (daysAway === 1) deltaString = "Tomorrow";
        else if (daysAway === 0) deltaString = `Today`;

        return (
            <TimeDeltaContainer status={draft.status}>
                {deltaString}
            </TimeDeltaContainer>
        );
    } else if (draft.status === DraftStatus.IN_PROGRESS) {
        const d = new Date(Date.parse(draft.startTime));
        const daysAgo = timeDelta(dateToString(d)) * -1;
        let deltaString = `Started at ${d.toLocaleTimeString("en-US")}`;

        if (daysAgo < 0) return null;
        else if (daysAgo === 1) deltaString = "Started Yesterday";
        else if (daysAgo > 1) deltaString = `Started ${daysAgo} Days Ago`;
        return (
            <TimeDeltaContainer status={draft.status}>
                {deltaString}
            </TimeDeltaContainer>
        );
    } else if (draft.status === DraftStatus.COMPLETED) {
        const d = new Date(Date.parse(draft.endTime));
        const daysAgo = timeDelta(dateToString(d)) * -1;
        let deltaString = `Finished ${daysAgo} Days Ago`;

        if (daysAgo < 0) return null;
        else if (daysAgo === 0)
            deltaString = `Finished at ${d.toLocaleTimeString("en-US")}`;
        else if (daysAgo === 1) deltaString = "Finished Yesterday";
        return (
            <TimeDeltaContainer status={draft.status}>
                {deltaString}
            </TimeDeltaContainer>
        );
    }
};

const NumPlayers = ({ draft }) => (
    <DraftDetail>
        <Value>{draft.numPlayers}</Value>
        <Label>Players</Label>
    </DraftDetail>
);

const NumRounds = ({ draft }) => (
    <DraftDetail>
        <Value>{draft.rounds}</Value>
        <Label>Rounds</Label>
    </DraftDetail>
);

const DraftDate = ({ draft }) => {
    let date = null;
    if (draft.status === DraftStatus.UPCOMING) {
        if (draft.date === UNSCHEDULED) return null;
        date = draft.date;
    } else if (draft.status === DraftStatus.IN_PROGRESS) {
        const d = new Date(Date.parse(draft.startTime));
        date = dateToString(d);
    } else if (draft.status === DraftStatus.COMPLETED) {
        const d = new Date(Date.parse(draft.endTime));
        date = dateToString(d);
    }

    return <Value>{`${getDayOfWeek(date)}, ${convertDateFormat(date)}`}</Value>;
};

const DraftCard = ({ draft }) => (
    <DraftCardContainer to={`/drafts/${draft.draftID}`} status={draft.status}>
        <Title status={draft.status}>{draft.name}</Title>
        <DraftDetailsRowContainer date={draft.date}>
            <NumPlayers draft={draft} />
            <DraftDate draft={draft} />
        </DraftDetailsRowContainer>
        <DraftDetailsRowContainer date={draft.date}>
            <NumRounds draft={draft} />
            {(draft.date !== UNSCHEDULED ||
                draft.status === DraftStatus.COMPLETED) && (
                <TimeDelta draft={draft} />
            )}
        </DraftDetailsRowContainer>
    </DraftCardContainer>
);

const renderDraftCards = (drafts) => {
    const draftCards = [];
    for (let i = 0; i < drafts.length; i++) {
        const { status, draftID } = drafts[i];
        draftCards.push(
            <DraftCard status={status} draft={drafts[i]} key={draftID} />
        );
    }
    return draftCards;
};

const DraftsWithStatus = ({ status, drafts }) => (
    <DraftsWithStatusContainer>
        <DraftStatusHeading>{statuslabel(status)}</DraftStatusHeading>
        <DraftCardsContainer>{renderDraftCards(drafts)}</DraftCardsContainer>
    </DraftsWithStatusContainer>
);

const renderDrafts = (drafts, status) => {
    // Sort all drafts by draft date
    drafts.sort((draft1, draft2) => {
        if (!draft1.date && !draft2.date) return 0;
        else if (!draft1.date && draft2.date) return 1;
        else if (draft1.date && !draft2.date) return -1;
        else if (draft1.date === draft2.date) return 0;
        else if (draft1.date > draft2.date) return 1;
        else return -1;
    });

    const inProgress = [];
    const upcoming = [];
    const completed = [];
    for (let i = 0; i < drafts.length; i++) {
        switch (drafts[i].status) {
            case DraftStatus.IN_PROGRESS:
                inProgress.push(drafts[i]);
                break;
            case DraftStatus.UPCOMING:
                upcoming.push(drafts[i]);
                break;
            case DraftStatus.COMPLETED:
                completed.push(drafts[i]);
                break;
            default:
                completed.push(drafts[i]);
                break;
        }
    }

    const draftList = [];
    if (
        inProgress.length > 0 &&
        (status === DraftStatus.IN_PROGRESS || status === DraftStatus.ALL)
    ) {
        draftList.push(
            <DraftsWithStatus
                status={DraftStatus.IN_PROGRESS}
                drafts={inProgress}
                key="inprogress-drafts"
            />
        );
    }
    if (
        upcoming.length > 0 &&
        (status === DraftStatus.UPCOMING || status === DraftStatus.ALL)
    ) {
        draftList.push(
            <DraftsWithStatus
                status={DraftStatus.UPCOMING}
                drafts={upcoming}
                key="upcoming-drafts"
            />
        );
    }
    if (
        completed.length > 0 &&
        (status === DraftStatus.COMPLETED || status === DraftStatus.ALL)
    ) {
        draftList.push(
            <DraftsWithStatus
                status={DraftStatus.COMPLETED}
                drafts={completed}
                key="completed-drafts"
            />
        );
    }
    return draftList;
};

const Drafts = ({ drafts, status }) => (
    <DraftsContainer>{renderDrafts(drafts, status)}</DraftsContainer>
);

export default Drafts;
