import React from "react";
import { connect } from "react-redux";
import { postActions } from "../../../actions/draftboard";
import { DraftActions } from "../../../js/constants";
import { buttonStyle } from "../../common/Button";
import ButtonLabel from "./ButtonLabel";

const SaveKeepers = ({ resetKeeperView, postActions, savingKeepers }) => {
    const action = async () => {
        await postActions(DraftActions.SAVE_KEEPERS);
        if (resetKeeperView) resetKeeperView();
    };
    return (
        <ButtonLabel
            label={"Save Keepers"}
            action={action}
            buttonText={"Save"}
            buttonColor={buttonStyle.Color.YELLOW}
            loading={savingKeepers}
        />
    );
};

const mapStateToProps = (state) => ({
    savingKeepers: state.draftboard?.draftboard?.loading?.savingKeepers,
});
export default connect(mapStateToProps, { postActions })(SaveKeepers);
