import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ColorScheme from '../../styles/colors';

const LabelLink = styled(Link)`
    color: ${ColorScheme.blue4.color};
    font-size: 14px;
    text-decoration: none;
    font-weight: 600;
    vertical-align: baseline;
    display: inline-block;
`;

const Message = styled.div`
    display: inline-block;
    color: ${ColorScheme.gray6.color};
    text-align: center;
`;

const MessageLink = ({message, dest, destText}) => (
    <Message>
        {message}<LabelLink to={dest}>{destText}</LabelLink>
    </Message>
);

export default MessageLink;
