import React from "react";
import { connect } from "react-redux";
import { reset } from "../../../actions/draftboard";
import { buttonStyle } from "../../common/Button";
import ButtonLabel from "./ButtonLabel";

const ResetDraft = ({ reset, resettingDraft, testdrive }) => (
    <ButtonLabel
        label={"Reset Draft"}
        action={() => reset(testdrive)}
        buttonText={"Reset"}
        buttonColor={buttonStyle.Color.YELLOW}
        loading={resettingDraft}
    />
);

const mapStateToProps = (state) => ({
    resettingDraft: state.draftboard?.draftboard?.loading?.resettingDraft,
});
export default connect(mapStateToProps, { reset })(ResetDraft);
