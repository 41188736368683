import React, { useState } from "react";
import { Field, useFormState } from "react-final-form";
import styled from "styled-components";
import {
    DraftClass,
    MAX_TIMER_DURATION,
    MIN_TIMER_DURATION,
    PremiumFeatures,
} from "../../../js/constants";
import {
    KEEPER_DESCRIPTION,
    REMOTE_DRAFT_DESCRIPTION,
    REMOTE_DRAFT_ERROR,
    TIMER_DESCRIPTION,
    TIMER_ERROR,
    TRADE_PICKS_DESCRIPTION,
} from "../../../js/descriptions";
import { featureEnabled } from "../../../js/util";
import { buttonStyle } from "../../common/Button";
import { NumberField } from "../../common/Field";
import { Checkbox } from "../../common/Input";
import LeagueType from "../../common/LeagueType";
import ColorScheme from "../../styles/colors";
import GridDescriptionOption from "../common/GridDescriptionOption";
import {
    FieldContainer,
    LabelContainer,
    LabeledDivisionContainer,
} from "../common/Label";
import { InfoContainer } from "../common/PageLayout";
import CreateDraftForm from "./CreateDraftForm";

const FeatureContainer = styled.div`
    margin-top: 16px;
`;

const FeatureLabel = styled.label`
    color: ${(props) =>
        props.enabled ? ColorScheme.blue5.color : ColorScheme.gray4.color};
    font-size: 30px;
    font-weight: 400;
    cursor: ${(props) => (props.enabled ? "pointer" : "not-allowed")};

    display: flex;
    align-items: center;
    > * {
        &:last-child {
            margin-left: 8px;
        }
    }
`;

const Description = styled.div`
    color: ${(props) =>
        props.enabled ? ColorScheme.blue3.color : ColorScheme.gray4.color};
    font-size: 16px;
    font-weight: 400;
`;

const DURATION_WIDTH = "96px";

const DurationLabel = styled.label`
    font-size: 20px;
    color: ${ColorScheme.blue4.color};
    cursor: pointer;
`;

const Duration = ({ initial }) => (
    <LabeledDivisionContainer>
        <LabelContainer>
            <DurationLabel htmlFor="timerDuration">
                Duration (seconds)
            </DurationLabel>
        </LabelContainer>
        <FieldContainer>
            <NumberField
                initial={initial}
                fieldName="timer.duration"
                label="timerDuration"
                printField="Duration"
                min={MIN_TIMER_DURATION.toString()}
                max={MAX_TIMER_DURATION.toString()}
                width={DURATION_WIDTH}
            />
        </FieldContainer>
    </LabeledDivisionContainer>
);

const Timer = ({ label, description, enabled, remoteDraftChecked, config }) => {
    const updateChecked = (onChange) => () => {
        config.setChecked(!config.checked);
        onChange(!config.checked);
    };
    return (
        <FeatureContainer>
            <GridDescriptionOption>
                <Field name="timer.enabled" type="checkbox">
                    {({ input: { onChange } }) => (
                        <Checkbox
                            id={label}
                            type="checkbox"
                            disabled={!enabled}
                            checked={config.checked}
                            onChange={updateChecked(onChange)}
                        />
                    )}
                </Field>
                <FeatureLabel htmlFor={label} enabled={enabled}>
                    {label}
                    <LeagueType
                        size={buttonStyle.Size.LARGE}
                        draftType={DraftClass.PRO}
                        disabled={!enabled}
                    />
                </FeatureLabel>
                <Description enabled={enabled}>
                    {remoteDraftChecked ? TIMER_ERROR : description}
                </Description>
                {config.checked && <Duration initial={config.duration} />}
            </GridDescriptionOption>
        </FeatureContainer>
    );
};

const RemoteDraft = ({
    label,
    description,
    enabled,
    timerChecked,
    draftType,
    config,
}) => {
    const updateChecked = (onChange) => () => {
        config.setChecked(!config.checked);
        onChange(!config.checked);
    };
    return (
        <FeatureContainer>
            <GridDescriptionOption>
                <Field name="remoteDraft" type="checkbox">
                    {({ input: { onChange } }) => (
                        <Checkbox
                            id={label}
                            type="checkbox"
                            disabled={!enabled}
                            checked={config.checked}
                            onChange={updateChecked(onChange)}
                        />
                    )}
                </Field>
                <FeatureLabel htmlFor={label} enabled={enabled}>
                    {label}
                    <LeagueType
                        size={buttonStyle.Size.LARGE}
                        draftType={DraftClass.PREMIUM}
                        disabled={!enabled}
                    />
                </FeatureLabel>
                <Description enabled={enabled}>
                    {timerChecked && draftType === DraftClass.PREMIUM
                        ? REMOTE_DRAFT_ERROR
                        : description}
                </Description>
            </GridDescriptionOption>
        </FeatureContainer>
    );
};

const Feature = ({ fieldName, label, description, enabled }) => (
    <FeatureContainer>
        <GridDescriptionOption>
            <Field name={fieldName} type="checkbox">
                {({ input }) => (
                    <Checkbox
                        id={label}
                        type="checkbox"
                        disabled={!enabled}
                        {...input}
                    />
                )}
            </Field>
            <FeatureLabel htmlFor={label} enabled={enabled}>
                {label}
                {(fieldName === PremiumFeatures.KEEPER ||
                    fieldName === PremiumFeatures.TRADE_PICKS) && (
                    <LeagueType
                        size={buttonStyle.Size.LARGE}
                        draftType={DraftClass.PREMIUM}
                        disabled={!enabled}
                    />
                )}
            </FeatureLabel>
            <Description enabled={enabled}>{description}</Description>
        </GridDescriptionOption>
    </FeatureContainer>
);

const Features = ({ draftConfig }) => {
    // Get current form values
    const { values } = useFormState();

    // Make timer and remote draft controlled inputs
    const [timerChecked, setTimerChecked] = useState(values.timer.enabled);
    const [remoteDraftChecked, setRemoteDraftChecked] = useState(
        values.remoteDraft
    );

    // Create list of features to display in Advanced Features section
    const featureMap = {
        [PremiumFeatures.TIMER]: {
            label: "Draft Timer",
            description: TIMER_DESCRIPTION,
        },
        [PremiumFeatures.REMOTE_DRAFT]: {
            label: "Remote Draft",
            description: REMOTE_DRAFT_DESCRIPTION,
        },
        [PremiumFeatures.KEEPER]: {
            label: "Keeper Draft",
            description: KEEPER_DESCRIPTION,
        },
        [PremiumFeatures.TRADE_PICKS]: {
            label: "Trade Picks",
            description: TRADE_PICKS_DESCRIPTION,
        },
    };

    // Add all feature components into features array
    const features = [];
    const enabled = (feature) =>
        featureEnabled(feature, values.draftType, draftConfig);
    for (const [feature, featureData] of Object.entries(featureMap)) {
        // Need to special handle Timer and RemoteDraft components
        if (feature === PremiumFeatures.TIMER) {
            features.push(
                <Timer
                    key={`feature-${feature}`}
                    label={featureData.label}
                    description={featureData.description}
                    enabled={
                        enabled(PremiumFeatures.TIMER) && !remoteDraftChecked
                    }
                    remoteDraftChecked={remoteDraftChecked}
                    config={{
                        checked: timerChecked,
                        setChecked: setTimerChecked,
                        duration: values.timer.duration,
                    }}
                />
            );
            continue;
        } else if (feature === PremiumFeatures.REMOTE_DRAFT) {
            features.push(
                <RemoteDraft
                    key={`feature-${feature}`}
                    label={featureData.label}
                    description={featureData.description}
                    enabled={
                        enabled(PremiumFeatures.REMOTE_DRAFT) && !timerChecked
                    }
                    draftType={values.draftType}
                    timerChecked={timerChecked}
                    config={{
                        checked: remoteDraftChecked,
                        setChecked: setRemoteDraftChecked,
                    }}
                />
            );
            continue;
        }
        features.push(
            <Feature
                fieldName={feature}
                label={featureData.label}
                description={featureData.description}
                enabled={enabled(feature)}
                key={`feature-${feature}`}
            />
        );
    }
    return <InfoContainer>{features}</InfoContainer>;
};

const AdvancedFeatures = ({ draftConfig }) => (
    <CreateDraftForm.Page>
        <Features draftConfig={draftConfig} />
    </CreateDraftForm.Page>
);

export default AdvancedFeatures;
