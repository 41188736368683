export const createPayment = async (values, cardElement, stripe) => {
    if (!stripe || !cardElement) return { id: "" };
    const {
        paymentMethod: { id },
        error,
    } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
            name: values.payment.name,
            email: values.payment.email,
            address: {
                city: values.payment.city,
                country: "US",
                line1: values.payment.address1,
                line2: values.payment.address2,
                postal_code: values.payment.zip,
                state: values.payment.state,
            },
        },
    });
    return { id, error };
};
