import { RestAPI } from "@aws-amplify/api-rest";
import {
    ADD_ACTION,
    AUCTION_CHANGE,
    CHANGE_VIEW,
    FILTER_CHANGE,
    GET_DRAFTBOARD,
    GET_PLAYERS,
    GET_RESULTS,
    INGEST_ACTIONS,
    LOADING,
    PAUSE_TIMER,
    POST_ACTIONS,
    RESET_DRAFTBOARD,
    RESET_SELECT_PLAYER,
    RESET_TIMER,
    RESUME_TIMER,
    SEARCH_CHANGE,
    SET_AUCTION_DISPLAY,
    SET_AUTOCOMPLETE_PLAYERS,
    SET_CARD_HEIGHT,
    SET_CARD_WIDTH,
    SET_DISPLAY_PLAYER_DETAILS,
    SET_FONT_INDEX,
    SORT_BY_NAME,
    SORT_BY_RANK,
    TOGGLE_SELECT_PLAYER,
    UPDATE_AUTODRAFT,
} from "../actions/types";
import { cognitoAuthHeader } from "../js/auth";
import {
    DEBOUNCE_POST_PERIOD,
    DraftActions,
    DraftboardViews,
    DraftConfig,
    IDP_PLAYERS_URL,
    STANDARD_PLAYERS_URL,
} from "../js/constants";
import {
    autodraft as auto,
    draft as drafted,
    find,
    loadPlayers,
    remove,
    resetDirectory,
    sortPreferenceByName,
} from "../js/draftboard/directory";
import {
    getPlayer,
    isDrafted,
    resetDraftboard as resetBoard,
} from "../js/draftboard/draftboard";
import { httpErrors } from "../js/errors";
import { costMultiplier, debounce, isTestDrive } from "../js/util";

// Initial loading actions
export const getDraftboard = (draftID, draftConfig) => async (dispatch) => {
    // Draftboard depends on the return value here in componentDidMount
    try {
        // Send GetDraftboard API request
        const request = { headers: { Authorization: null } };
        let url = `/drafts/${draftID}/board`;
        if (draftConfig) url += `?config=${draftConfig}`;
        const draft = await RestAPI.get(process.env.API_NAME, url, request);
        dispatch({ type: GET_DRAFTBOARD, draft });
        return draft;
    } catch (error) {
        if (error.response.status === httpErrors.StatusNotFound) {
            dispatch({ type: GET_DRAFTBOARD, draft: null });
            return null;
        }
        return { field: "internal" };
    }
};
export const getResults = (draftID) => async (dispatch) => {
    try {
        // Send GetResults API request
        const request = { headers: { Authorization: null } };
        const url = `/drafts/${draftID}/results`;
        const draft = await RestAPI.get(process.env.API_NAME, url, request);
        dispatch({ type: GET_RESULTS, draft });
        return draft;
    } catch (error) {
        return null;
    }
};
export const getPlayers = () => async (dispatch, getState) => {
    const { draft } = getState().draftboard;
    if (!draft) return;

    const load = (players) => {
        loadPlayers(players);

        // Be sure to remove all currently drafted players
        const { board } = getState().draftboard.draftboard;
        for (let i = 0; i < draft.numPlayers; i++) {
            for (let j = 0; j < draft.rounds; j++) {
                if (board[i][j]) {
                    const fantasyPlayer = board[i][j];
                    drafted(fantasyPlayer);
                }
            }
        }

        // Once the players have been loaded, dispatch autocomplete action
        // so we can fill out the autocomplete section on draftboard load
        const { autocomplete } = getState().draftboard;
        const results = find(autocomplete.search, autocomplete.filter);
        dispatch({ type: SET_AUTOCOMPLETE_PLAYERS, players: results });

        // Set loaded to true for autocomplete
        dispatch({ type: GET_PLAYERS, loaded: true });
    };

    if (draft.idp) {
        fetch(IDP_PLAYERS_URL)
            .then((response) => response.json())
            .then((players) => load(players));
    } else {
        fetch(STANDARD_PLAYERS_URL)
            .then((response) => response.json())
            .then((players) => load(players));
    }
};

// Unloading action
export const resetDraftboard = () => {
    resetDirectory();
    resetBoard();
    return { type: RESET_DRAFTBOARD };
};

// Autocomplete searchbar actions
export const searchChange = (val) => (dispatch, getState) => {
    const { autocomplete } = getState().draftboard;
    const players = find(val, autocomplete.filter);
    dispatch({ type: SET_AUTOCOMPLETE_PLAYERS, players });
    dispatch({ type: SEARCH_CHANGE, val });
};
export const filterChange = (val) => (dispatch, getState) => {
    const { autocomplete } = getState().draftboard;
    const players = find(autocomplete.search, val);
    dispatch({ type: SET_AUTOCOMPLETE_PLAYERS, players });
    dispatch({ type: FILTER_CHANGE, val });
};
export const auctionChange = (val) => ({ type: AUCTION_CHANGE, val });
const resetAutocomplete = (dispatch, getState) => {
    const { draft, autocomplete } = getState().draftboard;
    const players = find("", autocomplete.filter);
    dispatch({ type: SET_AUTOCOMPLETE_PLAYERS, players });
    dispatch({ type: SEARCH_CHANGE, val: "" });
    if (draft.draftConfig === DraftConfig.AUCTION) {
        const { minimumBid } = draft.auctionDraftOptions;
        dispatch({ type: AUCTION_CHANGE, val: minimumBid });
    }
};

// Autocomplete player selection actions
export const toggleSelect = (index) => ({ type: TOGGLE_SELECT_PLAYER, index });
const getAutocompletePlayer = (getState, player) => {
    const {
        draftboard: { autocomplete },
    } = getState();
    const { players, selectedPlayer } = autocomplete;
    return players[selectedPlayer === -1 ? player : selectedPlayer];
};
const getAutocompleteCost = (getState) => {
    const {
        draftboard: { autocomplete },
    } = getState();
    const { auction } = autocomplete;
    return parseInt(auction);
};

// Settings actions
export const sortByName = () => (dispatch, getState) => {
    sortPreferenceByName(true);
    const { autocomplete } = getState().draftboard;
    const players = find(autocomplete.search, autocomplete.filter);
    dispatch({ type: SET_AUTOCOMPLETE_PLAYERS, players });
    dispatch({ type: SORT_BY_NAME });
};
export const sortByRank = () => (dispatch, getState) => {
    sortPreferenceByName(false);
    const { autocomplete } = getState().draftboard;
    const players = find(autocomplete.search, autocomplete.filter);
    dispatch({ type: SET_AUTOCOMPLETE_PLAYERS, players });
    dispatch({ type: SORT_BY_RANK });
};
export const updateAutodraft = (playerIndex, autodraft) => ({
    type: UPDATE_AUTODRAFT,
    playerIndex,
    autodraft,
});
export const setCardHeight = (height) => ({ type: SET_CARD_HEIGHT, height });
export const setCardWidth = (width) => ({ type: SET_CARD_WIDTH, width });
export const setFontIndex = (index) => ({ type: SET_FONT_INDEX, index });
export const setAuctionDisplay = (setting) => ({
    type: SET_AUCTION_DISPLAY,
    setting,
});
export const setDisplayPlayerDetails = (setting) => ({
    type: SET_DISPLAY_PLAYER_DETAILS,
    setting,
});

// Info bar actions
export const changeView = (view) => ({ type: CHANGE_VIEW, view });
export const pauseTimer = () => ({ type: PAUSE_TIMER });
export const resumeTimer = () => ({ type: RESUME_TIMER });
export const autodraft = () => async (dispatch, getState) => {
    // Extract necessary information from getState
    const { draft, draftboard } = getState().draftboard;
    const { currTeam, board, maximumBids, openings } = draftboard;
    const { auctionDraftOptions, draftConfig, positions } = draft;
    const auction = draftConfig === DraftConfig.AUCTION;

    // Perform autodraft
    const picks = board[currTeam];
    const maxBid = maximumBids[currTeam];
    const player = auto(picks, positions, auction, auctionDraftOptions, maxBid);

    // Calculate player cost for auction drafts
    let cost = undefined;
    if (draftConfig === DraftConfig.AUCTION) {
        const multiplier = costMultiplier(auctionDraftOptions);
        const { minimumBid } = auctionDraftOptions;
        cost = player.suggestedCost * multiplier;
        if (cost === 0) cost = minimumBid;
    }

    // We need to find the opening for current team's roster
    let round = -1;
    let pickTraded = false;
    let selectTeam = currTeam;
    for (let i = 0; i < openings.length; i++) {
        const [openTeam, openRound, traded] = openings[i];
        if (openTeam === currTeam) {
            if (traded) {
                pickTraded = true;
                break;
            }
            round = openRound;
            break;
        }
    }

    // If currTeam's pick was traded, go through the list of openings again
    // and find the first non-traded opening
    if (pickTraded) {
        for (let i = 0; i < openings.length; i++) {
            const [openTeam, openRound, traded] = openings[i];
            if (!traded) {
                round = openRound;
                selectTeam = openTeam;
                break;
            }
        }
    }

    // Issue select player
    selectPlayer(
        player,
        selectTeam,
        round,
        selectTeam,
        false,
        dispatch,
        getState,
        cost
    );

    await debouncedPostActions(dispatch, getState);
};

// Backend actions
// sendActions is a helper function to send a CreateDraftActions API request
const sendActions = async (draftID, actions) => {
    const request = { body: { actions }, headers: await cognitoAuthHeader() };
    const endpoint = `/drafts/${draftID}/actions`;
    return await RestAPI.post(process.env.API_NAME, endpoint, request);
};
// ingestActions is assumed to be called only by the backend, so we do not need
// to sync our actions with the backend and only update our local state
export const ingestActions = (actions) => async (dispatch, getState) => {
    if (!actions || actions.length === 0) return;
    dispatch({ type: INGEST_ACTIONS, actions });

    // Reset autocomplete
    const { autocomplete } = getState().draftboard;
    const results = find(autocomplete.search, autocomplete.filter);
    dispatch({ type: SET_AUTOCOMPLETE_PLAYERS, players: results });
};
// postActions is a helper function to sync the frontend and backend draft state
export const postActions = (action) => async (dispatch, getState) => {
    const { commitIndex, actions } = getState().draftboard.draftboard;
    const { draftID } = getState().draftboard.draft;

    // Ignore test drive actions
    if (isTestDrive(draftID)) return;

    // Verify there are actions to send
    if (actions.length === commitIndex) return;

    // Trigger loading animation, if required
    if (action) dispatch({ type: LOADING, action });

    // Send API request
    try {
        const newActions = actions.slice(commitIndex);
        const { lastIndex } = await sendActions(draftID, newActions);
        dispatch({
            type: POST_ACTIONS,
            lastIndex: lastIndex + 1,
            success: true,
        });
    } catch (error) {
        const correctActions = error.response?.data;
        if (correctActions) {
            await ingestActions(correctActions)(dispatch, getState);
        } else {
            dispatch({ type: POST_ACTIONS, error: true });
        }
    }
};
const debouncedPostActions = debounce(postActions(), DEBOUNCE_POST_PERIOD);

// Draft actions
// Synchronous draft actions (start + reset + finish)
export const start = (testdrive) => async (dispatch, getState) => {
    // Begin loading animation
    if (!testdrive) dispatch({ type: LOADING, action: DraftActions.START });

    // Add start action to list of new actions
    const { commitIndex, actions, view } = getState().draftboard.draftboard;
    const { remoteDraft } = getState().draftboard.draft;
    const startAction = { action: DraftActions.START, index: actions.length };
    const newActions = actions.slice(commitIndex);
    newActions.push(startAction);

    try {
        // Send API Request
        let lastIndex = -1;
        if (!testdrive) {
            const { draftID } = getState().draftboard.draft;
            const response = await sendActions(draftID, newActions);
            lastIndex = response.lastIndex + 1;
        } else {
            lastIndex = actions.length;
        }

        // Once API request has succeeded, update store
        if (!remoteDraft) {
            dispatch({ type: ADD_ACTION, action: startAction });
            dispatch({ type: POST_ACTIONS, lastIndex, success: true });
        }
    } catch (error) {
        return error;
    }
    if (view !== DraftboardViews.ROUND) {
        dispatch({ type: CHANGE_VIEW, view: DraftboardViews.ROUND });
    }
};
export const reset = (testdrive) => async (dispatch, getState) => {
    // Begin loading animation
    if (!testdrive) dispatch({ type: LOADING, action: DraftActions.RESET });

    // Add reset action to list of new actions
    const { commitIndex, actions } = getState().draftboard.draftboard;
    const { remoteDraft } = getState().draftboard.draft;
    const resetAction = { action: DraftActions.RESET, index: actions.length };
    const newActions = actions.slice(commitIndex);
    newActions.push(resetAction);

    try {
        // Send API Request
        let lastIndex = -1;
        if (!testdrive) {
            const { draftID } = getState().draftboard.draft;
            const response = await sendActions(draftID, newActions);
            lastIndex = response.lastIndex + 1;
        } else {
            lastIndex = actions.length;
        }

        // Once API request has succeeded, update store
        if (!remoteDraft) {
            dispatch({ type: ADD_ACTION, action: resetAction });
            dispatch({ type: POST_ACTIONS, lastIndex, success: true });
        }
    } catch (error) {
        return error;
    }
};
export const finish = (testdrive) => async (dispatch, getState) => {
    // Begin loading animation
    if (!testdrive) dispatch({ type: LOADING, action: DraftActions.FINISH });

    // Add finish action to list of actions
    const { commitIndex, actions } = getState().draftboard.draftboard;
    const { remoteDraft } = getState().draftboard.draft;
    const finishAction = { action: DraftActions.FINISH, index: actions.length };
    const newActions = actions.slice(commitIndex);
    newActions.push(finishAction);

    try {
        // Send API Request
        let lastIndex = -1;
        if (!testdrive) {
            const { draftID } = getState().draftboard.draft;
            const response = await sendActions(draftID, newActions);
            lastIndex = response.lastIndex + 1;
        } else {
            lastIndex = actions.length;
        }

        // Once API request has succeeded, update store
        if (!remoteDraft) {
            dispatch({ type: ADD_ACTION, action: finishAction });
            dispatch({ type: POST_ACTIONS, lastIndex, success: true });
        }
    } catch (error) {
        dispatch({ type: POST_ACTIONS, error });
    }
};

// Asynchronous draft actions (select + replace + undo)
// selectReplace is a helper function to create a select/replace action,
// which are similar in structure
const selectReplace = (
    player,
    team,
    round,
    keeper,
    nominated,
    replace,
    getState,
    cost
) => {
    const {
        draftboard: { draftboard, draft },
    } = getState();
    const { actionIndex } = draftboard;
    const auctionEnabled = draft.draftConfig === DraftConfig.AUCTION;

    // Create select action
    const action = {
        action: replace ? DraftActions.REPLACE : DraftActions.SELECT,
        index: actionIndex,
        team,
        round,
        keeper,
        nominated,
        fantasyPlayer: {
            id: player.id,
            first: player.first,
            last: player.last,
            team: player.team,
            position: player.position,
            bye: player.bye,
            rank: player.rank,
        },
    };
    if (auctionEnabled) action.fantasyPlayer.cost = cost;
    return action;
};
export const select =
    (team, round, nominated, keeper) => async (dispatch, getState) => {
        const {
            draftboard: {
                autocomplete,
                draft: { remoteDraft, draftConfig },
            },
        } = getState();

        const auctionEnabled = draftConfig === DraftConfig.AUCTION;
        const { selectedPlayer: index } = autocomplete;

        let cost = undefined;
        let player = getAutocompletePlayer(getState, index);
        if (auctionEnabled) cost = getAutocompleteCost(getState);
        selectPlayer(
            player,
            team,
            round,
            nominated,
            keeper,
            dispatch,
            getState,
            cost
        );

        if (remoteDraft) await debouncedPostActions(dispatch, getState);
    };
export const selectAutocompletePlayer =
    (index, team, round, nominated, keeper) => async (dispatch, getState) => {
        const { draft } = getState().draftboard;
        const auctionEnabled = draft.draftConfig === DraftConfig.AUCTION;

        let cost = undefined;
        let player = getAutocompletePlayer(getState, index);
        if (auctionEnabled) cost = getAutocompleteCost(getState);
        selectPlayer(
            player,
            team,
            round,
            nominated,
            keeper,
            dispatch,
            getState,
            cost
        );

        if (draft.remoteDraft) await debouncedPostActions(dispatch, getState);
    };
const selectPlayer = (
    player,
    team,
    round,
    nominated,
    keeper,
    dispatch,
    getState,
    cost
) => {
    const action = selectReplace(
        player,
        team,
        round,
        keeper,
        nominated,
        false,
        getState,
        cost
    );

    // Ignore select requests for drafted players
    if (isDrafted(player.id)) return;

    dispatch({ type: ADD_ACTION, action });
    dispatch({ type: RESET_SELECT_PLAYER });

    // Remove player from autocomplete directory and reset autocomplete
    drafted(action.fantasyPlayer);
    resetAutocomplete(dispatch, getState);

    // Reset timer
    if (!keeper) {
        const timerEnabled = getState().draftboard.draft.timer.enabled;
        if (timerEnabled) dispatch({ type: RESET_TIMER });
    }
};
export const replace =
    (index, team, round, keeper) => async (dispatch, getState) => {
        const {
            draftboard: {
                draft: { draftConfig, remoteDraft },
            },
        } = getState();
        const auctionEnabled = draftConfig === DraftConfig.AUCTION;

        // Get new player from autocomplete
        let cost = undefined;
        let newPlayer = getAutocompletePlayer(getState, index);
        if (auctionEnabled) cost = getAutocompleteCost(getState);

        // Get old player from draftboard
        const oldPlayer = getPlayer(team, round);

        // Ignore invalid replace requests
        if (oldPlayer === null || isDrafted(newPlayer.id)) return;

        const action = selectReplace(
            newPlayer,
            team,
            round,
            keeper,
            oldPlayer.nominated,
            true,
            getState,
            cost
        );
        dispatch({ type: ADD_ACTION, action });
        dispatch({ type: RESET_SELECT_PLAYER });

        // Add newPlayer to and remove oldPlayer from autocomplete directory
        drafted(action.fantasyPlayer);
        remove(oldPlayer);

        // Reset autocomplete
        resetAutocomplete(dispatch, getState);

        if (remoteDraft) await debouncedPostActions(dispatch, getState);
    };
export const undo = (keeper) => async (dispatch, getState) => {
    const {
        draftboard: {
            draftboard: { actionIndex, lastPick, lastKeeper },
            draft: {
                remoteDraft,
                timer: { enabled: timerEnabled },
            },
        },
    } = getState();

    const action = { action: DraftActions.UNDO, index: actionIndex, keeper };
    dispatch({ type: ADD_ACTION, action });

    // Add player back to autocomplete directory and reset autocomplete
    remove(keeper ? lastKeeper : lastPick);
    resetAutocomplete(dispatch, getState);

    // Reset timer, if necessary
    if (timerEnabled) dispatch({ type: RESET_TIMER });

    if (remoteDraft) await debouncedPostActions(dispatch, getState);
};
