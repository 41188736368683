import React from "react";
import history from "../../js/history";
import Button, { buttonStyle } from "../common/Button";
import {
    ButtonContainer,
    InfoContainer,
    MessageContainer,
} from "./common/Container";
import Display from "./common/Display";

const ResetSuccess = () => (
    <Display>
        <InfoContainer>
            <MessageContainer>
                Password reset successful. You can now login to your account
                with your new password.
            </MessageContainer>
        </InfoContainer>
        <ButtonContainer>
            <Button
                priority={buttonStyle.Priority.PRIMARY}
                size={buttonStyle.Size.LARGE}
                color={buttonStyle.Color.BLUE}
                onClick={() => history.push("/login")}
                text="Sign In"
            />
        </ButtonContainer>
    </Display>
);

export default ResetSuccess;
