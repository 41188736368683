import { BrowserStorageCache } from "@aws-amplify/cache";
import { Amplify } from "@aws-amplify/core";
import Tracker from "@openreplay/tracker";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { Component } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Route, Router, Switch } from "react-router-dom";
import { STRIPE_KEY } from "../js/constants";
import history from "../js/history";
import DraftDoesNotExist from "./common/DraftDoesNotExist";
import Draftboard from "./draftboard/Draftboard";
import Forgot from "./login/Forgot";
import Login from "./login/Login";
import Register from "./login/Register";
import RegisterSuccess from "./login/RegisterSuccess";
import Reset from "./login/Reset";
import ResetSuccess from "./login/ResetSuccess";
import Verify from "./login/Verify";
import CreateDraft from "./signedin/CreateDraft";
import Dashboard from "./signedin/Dashboard";
import DraftCreated from "./signedin/DraftCreated";
import DraftDetails from "./signedin/DraftDetails";
import Profile from "./signedin/Profile";

// Load Stripe
const stripePromise = loadStripe(STRIPE_KEY);

// Add OpenReplay tracker
export const tracker =
    process.env.ENVIRONMENT === "prod"
        ? new Tracker({
              projectKey: process.env.OPEN_REPLAY_KEY,
              __DISABLE_SECURE_MODE: process.env.ENVIRONMENT === "dev",
          })
        : null;
if (process.env.ENVIRONMENT === "prod") tracker.start();

class App extends Component {
    constructor(props) {
        // Configure Amplify to use the created Cognito user pool
        Amplify.configure({
            Auth: {
                region: process.env.AWS_REGION,
                userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
                userPoolWebClientId: process.env.AWS_COGNITO_CLIENT_ID,
                authenticationFlowType: "USER_SRP_AUTH",
                storage: BrowserStorageCache,
            },
            API: {
                endpoints: [
                    {
                        name: process.env.API_NAME,
                        endpoint: process.env.API_URL,
                    },
                ],
            },
        });
        super(props);
    }

    render = () => (
        <Elements stripe={stripePromise}>
            <DndProvider backend={HTML5Backend}>
                <Router history={history}>
                    <Switch>
                        <Route path="/" exact component={Dashboard} />
                        <Route
                            path="/drafts/new"
                            exact
                            component={CreateDraft}
                        />
                        <Route
                            path="/drafts/created"
                            exact
                            component={DraftCreated}
                        />
                        <Route
                            path="/drafts/:id"
                            exact
                            component={DraftDetails}
                        />
                        <Route
                            path="/drafts/:id/draftboard"
                            exact
                            component={Draftboard}
                        />
                        <Route path="/forgot" exact component={Forgot} />
                        <Route path="/login" exact component={Login} />
                        <Route path="/profile" exact component={Profile} />
                        <Route path="/register" exact component={Register} />
                        <Route
                            path="/register_success"
                            exact
                            component={RegisterSuccess}
                        />
                        <Route path="/reset" exact component={Reset} />
                        <Route
                            path="/reset_success"
                            exact
                            component={ResetSuccess}
                        />
                        <Route path="/verify" exact component={Verify} />
                        <Route component={DraftDoesNotExist} />
                    </Switch>
                </Router>
            </DndProvider>
        </Elements>
    );
}

export default App;
