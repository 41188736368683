import React from "react";
import { connect } from "react-redux";
import { autodraft } from "../../../actions/draftboard";
import { buttonStyle } from "../../common/Button";
import ButtonLabel from "./ButtonLabel";

const Autodraft = ({ autodraft }) => (
    <ButtonLabel
        label="Autodraft"
        action={autodraft}
        buttonText="Draft"
        buttonColor={buttonStyle.Color.YELLOW}
    />
);

export default connect(null, { autodraft })(Autodraft);
