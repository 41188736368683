import { RestAPI } from "@aws-amplify/api-rest";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { cognitoAuthHeader } from "../../../js/auth";
import { required } from "../../../js/formhelper";
import { memoize } from "../../../js/util";
import Button, { buttonStyle } from "../../common/Button";
import Input, { FieldError, inputError, Select } from "../../common/Input";
import Modal from "../../common/Modal";
import Spinner from "../../common/Spinner";
import { CreditCardField } from "../../common/Stripe";
import ColorScheme from "../../styles/colors";
import Defaults from "../../styles/defaults";
import PageHeader from "./PageHeader";

const MODAL_WIDTH = "700px";
const STATE_WIDTH = "128px";

const errors = {
    PAYMENT_METHOD: {
        payment: { creditCard: "Issue with credit card" },
    },
};

const couponErrors = {
    Expired: "Coupon is expired",
    Invalid: "Coupon is invalid",
};

const submit = (stripe, elements, action, price) => async (values) => {
    const cardElement = elements.getElement(CardElement);
    const result = await action(values, cardElement, stripe, price);
    if (!result || result === undefined) return;

    // Identify error
    const { field, error } = result;
    if (field === "creditCard") {
        if (error && error.message) {
            return { payment: { creditCard: error.message } };
        }
        return errors.PAYMENT_METHOD;
    }
};

const checkCoupon = async (couponCode) => {
    if (!couponCode) return;
    try {
        // Send CreateDraft API request
        const request = { headers: await cognitoAuthHeader() };
        const endpoint = `/coupons?code=${couponCode}`;
        const response = await RestAPI.get(
            process.env.API_NAME,
            endpoint,
            request
        );
        if (response) {
            const { active, valid, value } = response;
            if (!active) return { error: couponErrors.Expired };
            if (!valid) return { error: couponErrors.Invalid };
            return { value };
        }
    } catch (error) {
        // Most likely a 404
        return { error: couponErrors.Invalid };
    }
};

let memoizedCheckCoupon = memoize(checkCoupon);

const StyledForm = styled.form`
    width: 100%;
`;

const DetailsContainer = styled.div`
    // Set width, margins, and padding for info container
    max-width: 640px;
    width: 100%;
    height: 100%;
    margin: 24px auto 0px;
    padding: 24px 32px;

    // Style container
    background-color: ${ColorScheme.gray1.color};
    border: 1px solid ${ColorScheme.gray4.color};
    border-radius: ${Defaults.borderRadius};

    // Remove top margin from first-child
    > * {
        margin-top: ${(props) => props.marginTop || "24px"};
        &:first-child {
            margin-top: 0px;
        }
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-top: 24px;
    > * {
        &:first-child {
            margin-right: 12px;
        }
        &:only-child {
            margin-right: 0;
        }
    }
`;

const FormRow = styled.div`
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

const HalfRow = styled.div`
    width: 50%;
    display: flex;
    align-items: baseline;
`;

const CheckoutLabelContainer = styled.div`
    flex: ${(props) => (props.coupon ? "30%" : props.half ? "40%" : "20%")};
    display: flex;
    align-items: center;
`;

const CheckoutFieldContainer = styled.div`
    flex: ${(props) => (props.coupon ? "70%" : props.half ? "60%" : "80%")};
`;

const Label = styled.label`
    font-family: inherit;
    font-size: 18px;
    color: ${ColorScheme.blue6.color};
    cursor: pointer;
    margin-right: 8px;
`;

const PriceStyle = css`
    font-family: inherit;
    font-size: 30px;
    color: ${ColorScheme.blue5.color};
`;

const PriceText = styled.div`
    ${PriceStyle}
    margin-right: 64px;
`;

const PriceField = styled.div`
    ${PriceStyle}
`;

const PriceRow = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
`;

const PriceContainer = styled.div`
    display: flex;
`;

const CheckoutLabeledInput = ({
    label,
    displayText,
    fieldName,
    validate,
    coupon,
}) => (
    <FormRow>
        <CheckoutLabelContainer coupon={coupon}>
            <Label htmlFor={label}>{displayText}</Label>
        </CheckoutLabelContainer>
        <Field name={fieldName} validate={validate} validateFields={[]}>
            {({ input, meta }) => {
                const { error, showError } = inputError(meta);
                return (
                    <CheckoutFieldContainer coupon={coupon}>
                        <Input
                            id={label}
                            type="text"
                            error={showError}
                            {...input}
                        />
                        {showError && <FieldError>{error}</FieldError>}
                    </CheckoutFieldContainer>
                );
            }}
        </Field>
    </FormRow>
);

// const CouponCode = () => (
//     <DetailsContainer>
//         <CheckoutLabeledInput
//             label="referralCode"
//             displayText="Referral Code"
//             fieldName="payment.referralCode"
//             coupon
//         />
//         <CheckoutLabeledInput
//             label="couponCode"
//             displayText="Coupon Code"
//             fieldName="payment.couponCode"
//             coupon
//         />
//     </DetailsContainer>
// );

const CouponCode = ({ validate }) => (
    <DetailsContainer>
        <Field
            name="payment.couponCode"
            validate={validate}
            validateFields={[]}
        >
            {({ input, meta }) => {
                const { error, showError } = inputError(meta);
                return (
                    <FormRow>
                        <CheckoutLabelContainer coupon>
                            <Label htmlFor="couponCode">Coupon Code</Label>
                            {meta.validating && (
                                <Spinner
                                    color={buttonStyle.Color.BLUE}
                                    medium
                                />
                            )}
                        </CheckoutLabelContainer>
                        <CheckoutFieldContainer coupon>
                            <Input
                                id="couponCode"
                                type="text"
                                error={showError}
                                {...input}
                            />
                            {showError && <FieldError>{error}</FieldError>}
                        </CheckoutFieldContainer>
                    </FormRow>
                );
            }}
        </Field>
    </DetailsContainer>
);

const State = () => (
    <HalfRow>
        <CheckoutLabelContainer half>
            <Label htmlFor="state">State</Label>
        </CheckoutLabelContainer>
        <CheckoutFieldContainer half>
            <Field
                name={`payment.state`}
                validate={required}
                validateFields={[]}
            >
                {({ input, meta }) => {
                    const { error, showError } = inputError(meta);
                    return (
                        <>
                            <Select
                                id="state"
                                width={STATE_WIDTH}
                                error={showError}
                                {...input}
                            >
                                <option value=""></option>
                                <option value="AL">AL</option>
                                <option value="AK">AK</option>
                                <option value="AZ">AZ</option>
                                <option value="AR">AR</option>
                                <option value="CA">CA</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DE">DE</option>
                                <option value="DC">DC</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="HI">HI</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="IA">IA</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="ME">ME</option>
                                <option value="MD">MD</option>
                                <option value="MA">MA</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MS">MS</option>
                                <option value="MO">MO</option>
                                <option value="MT">MT</option>
                                <option value="NE">NE</option>
                                <option value="NV">NV</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NY">NY</option>
                                <option value="NC">NC</option>
                                <option value="ND">ND</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="RI">RI</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="TN">TN</option>
                                <option value="TX">TX</option>
                                <option value="UT">UT</option>
                                <option value="VT">VT</option>
                                <option value="VA">VA</option>
                                <option value="WA">WA</option>
                                <option value="WV">WV</option>
                                <option value="WI">WI</option>
                                <option value="WY">WY</option>
                            </Select>
                            {showError && <FieldError>{error}</FieldError>}
                        </>
                    );
                }}
            </Field>
        </CheckoutFieldContainer>
    </HalfRow>
);

const Zip = () => (
    <HalfRow>
        <CheckoutLabelContainer>
            <Label htmlFor="zip">Zip</Label>
        </CheckoutLabelContainer>
        <CheckoutFieldContainer>
            <Field name="payment.zip" validate={required} validateFields={[]}>
                {({ input, meta }) => {
                    const { error, showError } = inputError(meta);
                    return (
                        <>
                            <Input
                                id="zip"
                                type="text"
                                error={showError}
                                width="100%"
                                {...input}
                            />
                            {showError && <FieldError>{error}</FieldError>}
                        </>
                    );
                }}
            </Field>
        </CheckoutFieldContainer>
    </HalfRow>
);

const UserInfo = () => (
    <DetailsContainer>
        <CheckoutLabeledInput
            label="name"
            displayText="Name"
            fieldName="payment.name"
            validate={required}
        />
        <CheckoutLabeledInput
            label="email"
            displayText="Email"
            fieldName="payment.email"
            validate={required}
        />
        <CheckoutLabeledInput
            label="address1"
            displayText="Address"
            fieldName="payment.address1"
            validate={required}
        />
        <CheckoutLabeledInput
            label="address2"
            displayText=""
            fieldName="payment.address2"
        />
        <CheckoutLabeledInput
            label="city"
            displayText="City"
            fieldName="payment.city"
            validate={required}
        />
        <FormRow>
            <State />
            <Zip />
        </FormRow>
    </DetailsContainer>
);

// const SaveCreditCardContainer = styled.div`
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//     > * {
//         &:first-child {
//             margin-right: 16px;
//         }
//     }
// `;

// const SaveCreditCardLabel = styled.label`
//     font-family: inherit;
//     font-size: 18px;
//     color: ${ColorScheme.blue4.color};
//     cursor: pointer;
// `;

// const CreditCardInfo = () => (
//     <DetailsContainer marginTop="12px">
//         <CreditCardField fieldName="payment.creditCard" />
//         <SaveCreditCardContainer>
//             <Field name="payment.saveCreditCard" type="checkbox" validateFields={[]}>
//                 {({ input }) => (
//                     <Checkbox id="saveCreditCard" type="checkbox" {...input} />
//                 )}
//             </Field>
//             <SaveCreditCardLabel htmlFor="saveCreditCard">
//                 Save Information
//             </SaveCreditCardLabel>
//         </SaveCreditCardContainer>
//     </DetailsContainer>
// );

const CreditCardInfo = ({ price }) => (
    <DetailsContainer marginTop="12px">
        <CreditCardField fieldName="payment.creditCard" price={price} />
    </DetailsContainer>
);

const Price = ({ price }) => (
    <PriceRow>
        <PriceContainer>
            <PriceText>Total</PriceText>
            <PriceField>
                {`$${price / 100}${price % 100 === 0 ? ".00" : ""}`}
            </PriceField>
        </PriceContainer>
    </PriceRow>
);

const Buttons = ({ close, loading }) => (
    <ButtonContainer>
        <Button
            size={buttonStyle.Size.MEDIUM}
            color={buttonStyle.Color.GRAY}
            priority={buttonStyle.Priority.SECONDARY}
            disabled={loading}
            onClick={close}
            type="button"
            text="Cancel"
            analytics={{ name: "checkout-cancel" }}
        />
        <Button
            size={buttonStyle.Size.MEDIUM}
            color={buttonStyle.Color.BLUE}
            priority={buttonStyle.Priority.PRIMARY}
            disabled={loading}
            loading={loading}
            type="submit"
            text="Confirm"
            analytics={{ name: "checkout-confirm" }}
        />
    </ButtonContainer>
);

const CheckoutModal = ({
    open,
    close,
    loading,
    action,
    price,
    user,
    values,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [showPrice, setShowPrice] = useState(price);

    // Make sure to reset memoization upon dismount
    useEffect(() => (memoizedCheckCoupon = memoize(checkCoupon)), []);

    const validateCoupon = (code) => {
        if (!code) {
            if (showPrice !== price) setShowPrice(price);
            return;
        }
        return memoizedCheckCoupon(code).then((response) => {
            if (response.error) return response.error;
            const newPrice = Math.max(0, price - response.value);
            setShowPrice(newPrice);
        });
    };

    return (
        <Modal width={MODAL_WIDTH} open={open} close={close}>
            <PageHeader heading="Payment Information" />
            <Form
                initialValues={{
                    ...values,
                    payment: {
                        name: user.attributes.name,
                        email: user.attributes.email,
                    },
                }}
                onSubmit={submit(stripe, elements, action, showPrice)}
                validateOnBlur={true}
                keepDirtyOnReinitialize
                render={({ handleSubmit }) => (
                    <StyledForm onSubmit={handleSubmit}>
                        <UserInfo />
                        <CouponCode validate={validateCoupon} />
                        <CreditCardInfo price={showPrice} />
                        <Price price={showPrice} />
                        <Buttons
                            close={close}
                            submit={handleSubmit}
                            loading={loading}
                        />
                    </StyledForm>
                )}
            />
        </Modal>
    );
};

const mapStateToProps = (state) => {
    const newState = {
        draftConfig: state.config.draftConfig,
    };
    if (state.auth) {
        newState.user = state.auth.user;
    }
    return newState;
};
export default connect(mapStateToProps)(CheckoutModal);
