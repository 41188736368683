import React from "react";
import styled from "styled-components";
import Button, { buttonStyle } from "../../common/Button";
import { FieldError } from "../../common/Input";

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ErrorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
`;

const CreateDraftButton = ({ user, createDraft }) => (
    <ButtonContainer>
        {user.attributes.email_verified ? (
            <Button
                size={buttonStyle.Size.LARGE}
                color={buttonStyle.Color.BLUE}
                priority={buttonStyle.Priority.PRIMARY}
                onClick={createDraft}
                type="button"
                text="Create Draft"
                analytics={{ name: "create-draft-button" }}
            />
        ) : (
            <>
                <Button
                    size={buttonStyle.Size.LARGE}
                    color={buttonStyle.Color.GRAY}
                    priority={buttonStyle.Priority.PRIMARY}
                    onClick={createDraft}
                    type="button"
                    text="Create Draft"
                    disabled
                />
                <ErrorContainer>
                    <FieldError>
                        You must verify your email to proceed
                    </FieldError>
                </ErrorContainer>
            </>
        )}
    </ButtonContainer>
);

export default CreateDraftButton;
