import React, { Component } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { resetPassword } from "../../actions/login";
import { MIN_PASSWORD_LENGTH } from "../../js/constants";
import { cognitoErrors } from "../../js/errors";
import {
    compose,
    confirmCheck,
    minLength,
    required,
} from "../../js/formhelper";
import Button, { buttonStyle } from "../common/Button";
import { ButtonWithLinkContainer, InfoContainer } from "./common/Container";
import Display from "./common/Display";
import { LabeledInput } from "./common/LabeledInput";
import MessageLink from "./common/MessageLink";

const errors = {
    CODE_BLANK: { code: "Code cannot be blank" },
    PASSWORD_BLANK: { email: "Password cannot be blank" },
    INVALID_CODE: { code: "Invalid code" },
};

class Reset extends Component {
    constructor(props) {
        super(props);
        this.reset = async ({ code, password, email }) => {
            const error = await this.props.resetPassword(code, password, email);
            if (!error) return;
            if (error === cognitoErrors.CodeMismatch) {
                return errors.INVALID_CODE;
            }
        };
    }

    renderForm = () => (
        <Form
            onSubmit={this.reset}
            validate={confirmCheck}
            render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <InfoContainer>
                        {this.props.email ? null : (
                            <LabeledInput
                                labelText="Email"
                                fieldName="email"
                                type="email"
                                tabIndex="1"
                                validate={required}
                            />
                        )}
                        <LabeledInput
                            labelText="New Password"
                            fieldName="password"
                            type="password"
                            tabIndex={this.props.email ? "2" : "1"}
                            minLength={MIN_PASSWORD_LENGTH}
                            validate={compose(
                                required,
                                minLength("Password", MIN_PASSWORD_LENGTH)
                            )}
                        />
                        <LabeledInput
                            labelText="Confirm Password"
                            fieldName="confirm"
                            type="password"
                            tabIndex={this.props.email ? "3" : "2"}
                            minLength={MIN_PASSWORD_LENGTH}
                        />
                        <LabeledInput
                            labelText="Verification Code"
                            fieldName="code"
                            type="text"
                            tabIndex={this.props.email ? "4" : "3"}
                            validate={required}
                        />
                    </InfoContainer>
                    <ButtonWithLinkContainer>
                        <Button
                            priority={buttonStyle.Priority.PRIMARY}
                            size={buttonStyle.Size.LARGE}
                            color={buttonStyle.Color.BLUE}
                            loading={this.props.loading}
                            disabled={submitting}
                            type="submit"
                            text="Submit"
                        />
                        <MessageLink
                            message=""
                            dest="/login"
                            destText="Return to sign in"
                        />
                    </ButtonWithLinkContainer>
                </form>
            )}
        />
    );
    render = () => <Display>{this.renderForm()}</Display>;
}

const mapStateToProps = (state) => {
    const newState = {};
    if (state.forget.reset) {
        newState.loading = state.forget.reset.loading;
    }
    if (state.forget.email) {
        newState.email = state.forget.email;
    }
    return newState;
};

export default connect(mapStateToProps, { resetPassword })(Reset);
