import React from "react";
import { connect } from "react-redux";
import {
    setAuctionDisplay,
    setCardHeight,
    setCardWidth,
    setDisplayPlayerDetails,
    setFontIndex,
    sortByName,
    sortByRank,
    updateAutodraft,
} from "../../actions/draftboard";
import { DraftConfig } from "../../js/constants";
import { VerticalContainer } from "../common/Container";
import Modal from "../common/Modal";
import ColorScheme from "../styles/colors";
import AuctionBudgetSetting from "./settings/AuctionBudgetSetting";
import AutocompleteSortOption from "./settings/Autocomplete";
import Autodraft from "./settings/Autodraft";
import DisplayPlayerDetails from "./settings/DisplayPlayerDetails";
import GridSize from "./settings/GridSize";
import Heading from "./settings/Heading";

const SettingsModal = ({
    open,
    close,
    auction,
    auctionDisplay,
    draftOrder,
    autocompleteByRank,
    cardHeight,
    cardWidth,
    fontIndex,
    setAuctionDisplay,
    setCardHeight,
    setCardWidth,
    setFontIndex,
    sortByName,
    sortByRank,
    updateAutodraft,
    isCommissioner,
    setDisplayPlayerDetails,
    showDraftboardDetails,
}) => (
    <Modal open={open} close={close} bgcolor={ColorScheme.gray2.color}>
        <VerticalContainer>
            <Heading close={close} />
            <GridSize
                height={cardHeight}
                width={cardWidth}
                fontIndex={fontIndex}
                setCardHeight={setCardHeight}
                setCardWidth={setCardWidth}
                setFontIndex={setFontIndex}
            />
            <DisplayPlayerDetails
                setDisplayPlayerDetails={setDisplayPlayerDetails}
                showDraftboardDetails={showDraftboardDetails}
            />
            {auction && (
                <AuctionBudgetSetting
                    auctionDisplay={auctionDisplay}
                    setAuctionDisplay={setAuctionDisplay}
                />
            )}
            <AutocompleteSortOption
                autocompleteByRank={autocompleteByRank}
                sortByName={sortByName}
                sortByRank={sortByRank}
            />
            {isCommissioner && (
                <Autodraft
                    draftOrder={draftOrder}
                    updateAutodraft={updateAutodraft}
                />
            )}
        </VerticalContainer>
    </Modal>
);

const mapStateToProps = (state) => ({
    autocompleteByRank: state.draftboard?.autocomplete?.sortByRank,
    draftOrder: state.draftboard?.draft?.draftOrder,
    auction: state.draftboard?.draft?.draftConfig === DraftConfig.AUCTION,
    auctionDisplay: state.draftboard?.draftboard?.auctionDisplay,
    cardHeight: state.draftboard.draftboard.cardHeight,
    cardWidth: state.draftboard.draftboard.cardWidth,
    fontIndex: state.draftboard.draftboard.fontIndex,
    showDraftboardDetails: state.draftboard.draftboard.showDraftboardDetails,
    user: state.auth?.user,
});
export default connect(mapStateToProps, {
    setAuctionDisplay,
    setCardHeight,
    setCardWidth,
    setDisplayPlayerDetails,
    setFontIndex,
    sortByName,
    sortByRank,
    updateAutodraft,
})(SettingsModal);
